import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

class PRBVersion extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <p>
          <strong>Aceptación de los Términos</strong>
        </p>
        <p>
          Al utilizar el sitio web legalpredictabill.com (el “Sitio”) o
          cualquier aplicación de Legal Predictabill o complementos de
          aplicación (“Aplicaciones”), usted acepta seguir y estar sujeto a
          estos términos de uso (los “Términos y Condiciones”) y acepta cumplir
          con todas las leyes y regulaciones aplicables. Al hacer clic en
          “Acepto”, en la página de pago o de registro de la cuenta, o utilizar
          el Sitio de cualquier modo, usted acepta cumplir y quedar obligado por
          estos Términos y Condiciones y todas las normas, políticas y
          exenciones de responsabilidad que se encuentran publicados en el Sitio
          o sobre los que se lo notifique (en conjunto, los “Términos”). Si no
          acepta la totalidad de los Términos, no utilice el Sitio. Revise todos
          los Términos y Condiciones cuidadosamente antes de utilizar el Sitio.
          Este es un Acuerdo legalmente vinculante entre usted como Cliente y
          PREDICTABILL S.A cédula jurídica tres- ciento uno- setecientos noventa
          y siete mil quinientos treinta y seis, (el “Acuerdo”).
        </p>
        <p>
          Es su responsabilidad revisar estos Términos y Condiciones
          periódicamente. Si en algún momento considera que estos Términos y
          Condiciones son inaceptables o si no está de acuerdo con estos
          Términos y Condiciones, no utilice este Sitio ni ninguna de las
          Aplicaciones. Podemos revisar estos Términos y Condiciones en
          cualquier momento sin notificárselo. Si tiene alguna pregunta sobre
          estos Términos y Condiciones, comuníquese con alguno de nuestros
          asesores al correo info@legalpredictabill.com.
        </p>
        <p>
          Al utilizar el Sitio, usted (i) acepta quedar obligado por los
          Términos y (ii) declara que es mayor de 18 años y puede celebrar
          contratos vinculantes en forma legal.
        </p>
        <p>
          <strong>Definiciones</strong>
        </p>
        <p>
          En estos Términos y Condiciones, las palabras “Cliente”, "usted" y
          "su" se refieren a cada cliente, visitante del Sitio, persona que
          contrata los servicios de PREDICTABILL mediante el Sitio o quien cree
          una Cuenta en el Sitio. "PREDICTABILL", "nosotros", "nos" y "nuestro"
          se refieren a legalpredictabill.com o a PREDICTABILL S.A., una
          sociedad existente y vigente bajo las leyes de la República de Costa
          Rica con cédula jurídica número 3-101-797536 y "Servicios" se refiere
          a todos los servicios proporcionados por nosotros que se pueden
          contratar mediante el Sitio.
        </p>
        <p>
          <strong>Sobre PREDICTABILL</strong>
        </p>
        <p>
          PREDICTABILL es un proveedor de servicios legales bajo esquemas
          alternativos de facturación, que combina trayectoria, experiencia,
          talento, áreas de práctica sólidas, energía, y una forma muy
          distintiva en el mercado legal y de negocios. PREDICTABILL está
          diseñado específicamente para atender las necesidades legales del día
          a día de sus clientes por un costo fijo. PREDICTABILL ofrece una
          combinación única del mejor talento humano y la mejor tecnología, para
          proveer soluciones legales innovadoras en tiempo y presupuesto
          definido; cambiando así la forma en que se desarrolla el negocio del
          Derecho.
        </p>
        <p>
          PREDICTABILL proporciona un portal legal en línea para brindar a los
          visitantes servicios legales. El Cliente no necesita descargar ni
          licenciar ningún software de PREDICTABILL. El Sitio incluye
          información general sobre escenarios y necesidades legales que se
          encuentran comúnmente. Aunque se brinde alguna información de interés,
          el uso del Sitio no consiste en ninguna asesoría legal ni constituye
          una relación abogado-cliente. Esta únicamente se genera al momento de
          contratar los Servicios.
        </p>
        <p>
          Al contratar los Servicios, los mismos serán brindados (cuando así sea
          requerido) por un Profesional con título universitario de Licenciado
          (a) en Derecho e incorporado al Colegio de Abogados de Costa Rica.
          Asimismo, si los servicios contratados deban ser brindados por un
          Notario Público, entonces un Fedatario (a) público (a) debidamente
          inscrito y habilitado ante la Dirección Nacional de Notariado asistirá
          el Servicio. En algunos casos, ciertas asignaciones específicas
          podrían ser delegadas a otros abogados o colaboradores siempre bajo
          supervisión de los abogados de PREDICTABILL. Tal asignación puede ser
          el resultado de nuestro interés de participar a otros profesionales o
          asistentes cuya experiencia y especialización esté concentrada en
          áreas específicas aquí no contempladas pero que pueden resultar de
          nuestro trabajo en el Servicio. En la medida de lo posible, le
          informaremos oportunamente de los nombres de estos profesionales.
        </p>
        <p>
          <strong>Liberación de Responsabilidad</strong>
        </p>
        <p>
          Al utilizar el Sitio, usted estará utilizando un página web propiedad
          de PREDICTABILL, usted reconoce y acepta que su uso es bajo su propio
          riesgo y que ninguna de las partes involucradas para crear, producir o
          entregar este Sitio es responsable de ningún daño directo, incidental,
          consecuente, indirecto o punitivo ni de otras pérdidas, costos o
          gastos de ningún tipo (incluyendo pero no limitando a pago de
          comisiones por transacciones) que puedan surgir, directa o
          indirectamente, por el acceso a, uso de o búsqueda en este Sitio o por
          descargar algún material, dato, texto, imagen, video o audio de este
          Sitio, o por utilizar funciones de este Sitio, incluyendo pero no
          limitándose a cualquier cosa que pase por un virus, errores del
          sistema (pulgas), procedimiento humano o falta de procedimiento humano
          o mal funcionamiento de algún sistema de cómputo, línea telefónica,
          hardware, software o programa o cualquier otro error, falla o retraso
          en transmisiones electrónicas o conexiones de red. PREDICTABILL no
          será responsable por cualquier daño, responsabilidad o pérdida que
          deriven del uso o dependencia del Sitio.
        </p>
        <p>
          Usted acuerda mantener indemnes y responder frente a PREDICTABILL y
          sus consejeros, directores, empleados y agentes, empresas y sociedades
          afiliadas o relacionadas, por cualquier reclamación, demanda, pérdida,
          responsabilidad y gasto (incluidos los honorarios de abogados) que
          deriven de: (i) incorrecto uso del Sitio; (ii) incumplimiento o
          violación de cualquiera de estos Términos y Condiciones; (iii) el uso
          por parte de PREDICTABILL de su Contenido.
        </p>
        <p>
          <strong>Titularidad</strong>
        </p>
        <p>
          La titularidad del Sitio y todos los derechos relativos a este son y
          permanecerán en propiedad de PREDICTABILL. Ninguna de estos Términos y
          Condiciones ni el uso del Sitio le transfieren u otorgan ningún
          derecho: (i) sobre o en relación con el Sitio; o bien (ii) a utilizar
          o mencionar en cualquier modo a los nombres de empresa, logotipos,
          facilidades o la marca de PREDICTABILL.
        </p>
        <p>
          <strong>Restricciones</strong>
        </p>
        <p>
          Usted no podrá: (i) retirar cualquier nota de derechos de autor, marca
          registrada u otra nota de propiedad de cualquier parte del Sitio; (ii)
          reproducir, modificar, preparar obras derivadas sobre el Sitio, o
          distribuir, licenciar, arrendar, revender, transferir, exhibir
          públicamente, presentar públicamente, transmitir, retransmitir o
          explotar de otra forma el Sitio, excepto como se permita expresamente
          por PREDICTABILL; (iii) descompilar, realizar ingeniería inversa o
          desmontar el Sitio, excepto como se permita por la ley aplicable; (iv)
          enlazar, reflejar o enmarcar cualquier parte del Sitio; (v) causar o
          lanzar cualquier programa o script con el objeto de extraer, indexar,
          analizar o de otro modo realizar prospección de datos de cualquier
          parte de del Sitio o sobrecargar o bloquear indebidamente la operación
          y/o funcionalidad de cualquier aspecto del Sitio; o (vi) intentar
          obtener un acceso no autorizado o dañar cualquier aspecto de la
          Aplicación o sus sistemas o redes relacionados.
        </p>
        <p>
          <strong>Cuentas de Usuario</strong>
        </p>
        <p>
          Con el fin de usar la mayor parte del Sitio, usted debe registrarse y
          mantener activa una cuenta personal de asociado al Sitio (“Cuenta de
          Afiliado” o “Cuenta”). Para obtener una Cuenta de Afiliado debe de
          cumplir con los siguientes requisitos: (i) Personas Jurídica. Ser una
          entidad debidamente inscrita; y, (ii) Persona Fisca. Tener como mínimo
          18 años. El registro de la Cuenta de Afiliado le requiere que
          comunique a PREDICTABILL determinada información personal, como su
          nombre, dirección, número de teléfono móvil, entre otras requeridas
          por las autoridades competentes para la emisión de facturas
          electrónicos. Usted garantiza y está de acuerdo en que, mientras use
          el Sitio, usted no: (a) suplantará la identidad de ninguna persona ni
          desvirtuará su afiliación con alguna otra persona o entidad; (b)
          insertará su propio anuncio, posicionamiento de marca o algún otro
          contenido promocional o el de un tercero en cualquiera de los
          contenidos, materiales o servicios o materiales, ni usará,
          redistribuirá, republicará o explotará dichos contenidos o servicios
          con cualquier otro propósito adicional comercial o promocional; (c)
          intentará ganar acceso no autorizado a otros sistemas de cómputo a
          través del Sitio; (d) usará el Sitio o los servicios puestos a su
          disposición en o a través del Sitio de alguna manera con la intención
          de interrumpir, dañar, deshabilitar, sobrecargar o deteriorar el Sitio
          o dichos servicios, incluyendo, sin limitación, mandar mensajes
          masivos no solicitados o "inundar" servidores con solicitudes; (e)
          usará el Sitio o los servicios o artículos del Sitio en violación de
          la propiedad intelectual o de otros derechos legales o patrimoniales
          de PREDICTABILL o de algún tercero; ni (f) usará el Sitio en violación
          de cualquier ley aplicable. Usted se obliga, a no intentar (o alentar
          o apoyar el intento de otro) a embaucar, destruir, decodificar, o de
          otro modo alterar o interferir con el Sitio o con los servicios del
          Sitio, o con cualquier contenido de este, o hacer cualquier uso no
          autorizado del mismo. Usted se obliga a no usar el Sitio de alguna
          manera que pudiera dañar, deshabilitar, sobrecargar o deteriorar el
          Sitio o interferir con que cualquier otra persona pueda usar o
          disfrutar del Sitio o de cualquiera de sus servicios. Usted no
          obtendrá ni intentará obtener algún material o información a través de
          cualquier medio que no haya sido estipulado o puesto a la disposición
          del público intencionalmente a través del Sitio.
        </p>
        <p>
          Con la aceptación de los presentes Términos y Condiciones, usted
          declara entender, conocer, aceptar y autorizar a que PREDICTABILL o
          cualquiera de sus aliados comerciales pueda debitar de su cuenta
          bancaria debidamente indicada al momento de realizar cada compra de
          Servicios el monto correspondiente. PREDICTABILL no almacenará la
          información de su cuenta bancaria y/o tarjetas de crédito o débito,
          por lo cual para cada pago usted deberá incluir la información.
        </p>
        <p>
          <strong>Requisitos &amp; Conducta del Usuario</strong>
        </p>
        <p>
          El Sitio no está disponible para el uso de personas menores de 18 años
          o para quienes no tengan plena capacidad legal para obligarse. Usted
          no podrá autorizar a terceros a utilizar su Cuenta. No podrá ceder o
          transferir de otro modo su Cuenta a cualquier otra persona o entidad.
          Usted acuerda cumplir con todas las leyes aplicables al utilizar el
          Sitio y solo podrá utilizar el Sitio con fines legítimos.
        </p>
        <p>
          En algunos casos, se le podrá requerir que facilite un documento de
          identidad para el acceso o uso del Sitio, y usted acepta que se le
          podrá denegar el acceso o uso del Sitio si se niega a facilitar el
          documento de identidad.
        </p>
        <p>
          <strong>Protección de Datos Personales</strong>
        </p>
        <p>
          Tanto PREDICTABILL como el Cliente se obligan a cumplir con la
          legislación vigente en Costa Rica y/o las políticas de PREDICTABILL en
          materia de protección de datos personales en posesión de los
          particulares, por lo que garantizan que tratarán como confidenciales
          los datos personales que reciban o se transmitan en virtud del uso del
          Sitio o Aplicaciones de PREDICTABILL, incluyendo los datos personales
          de sus compañías, socios, empleados y/o representantes legale (“Datos
          Personales”). Asimismo, se obligan a dar a conocer a los titulares de
          los datos personales los avisos de privacidad que correspondan y a
          mantener las medidas de seguridad, administrativas, técnicas y físicas
          que permitan proteger los datos personales, contra daño, pérdida,
          alteración, destrucción, uso, acceso o tratamiento no autorizado. Lo
          anterior, con el fin de garantizar el cabal cumplimiento con la
          legislación de Costa Rica, sus reglamentos y los lineamientos que en
          su caso publiquen las autoridades correspondientes. La obligación de
          confidencialidad subsistirá inclusive aun después de finalizar la
          relación comercial entre PREDICTABILL y el Cliente .
        </p>
        <p>
          <strong>
            Derechos de Propiedad Intelectual del Sitio o Aplicaciones
          </strong>
        </p>
        <p>
          El sonido, gráficos, cuadros, texto, video, juegos, información o
          imágenes de lugares o personas que se encuentren en el Sitio son
          propiedad de PREDICTABILL, o son usadas con la correspondiente
          autorización. Ninguna porción de este Sitio o alguna Aplicación,
          incluyendo, pero sin limitarse al texto, imágenes, audio, juegos,
          aplicaciones o video, puede ser usada de ninguna forma o para ningún
          propósito, sin una autorización escrita de PREDICTABILL, excepto si se
          indica aquí. El nombre “PREDICTABILL”, y sus logotipos y marcas
          pertenecen a PREDICTABILL. Al utilizar el Sitio usted reconoce y
          acepta que cualquier nombre, logotipo, marca registrada, o marca de
          servicio, que aparezca en este Sitio es propiedad exclusiva, o está
          siendo utilizada con la debida autorización por PREDICTABILL y no
          puede ser usada y/o reproducida en todo o en parte por usted, sin la
          previa autorización escrita de PREDICTABILL.
        </p>
        <p>
          PREDICTABILL hará cumplir sus derechos de propiedad intelectual con
          toda la fuerza de la ley. El uso de cualquiera de estos materiales es
          prohibido a menos que aquí se especifique expresamente lo contrario.
          Modificar, republicar, o usar el material de este Sitio viola los
          derechos legales de PREDICTABILL. Cualquier uso no autorizado de estos
          materiales puede estar sujeto a penalidades o daños, incluyendo, pero
          no limitándose a aquellos relacionados con la violación de derechos de
          autor, de marcas registradas, de privacidad y de publicidad.
        </p>
        <p>
          No promocione ni transfiera por medio de este Sitio ningún material
          pornográfico, obsceno, inmoral, difamatorio, calumniante, amenazador,
          discriminatorio, ofensivo, ilegal u otro material que pudiera
          constituir o motivar una conducta que pudiera ser considerada una
          ofensa criminal, crear una responsabilidad civil o violar alguna ley o
          regulación.{" "}
        </p>
        <p>
          Al ingresar en este Sitio usted reconoce y acepta que cualquier
          comunicación o material que usted transfiera, de cualquier forma y por
          cualquier razón, no necesariamente será tratado como confidencial, ni
          de su propiedad, a no ser que el mismo se encuentre resguardado por el
          Secreto Profesional de la relación Abogado-Cliente.
        </p>
        <p>
          En algún momento podría ser necesario que usted suministre sus datos
          personales, incluyendo su nombre completo, edad, ocupación, genero,
          número de cédula, dirección exacta de su residencia, lugar de trabajo
          y contactos de referencia (los “Datos Personales”). Estos Datos
          Personales podrán utilizados por PREDICTABILL para llevar control de
          las Cuentas que utilicen el Sitio y los servicios contratados por
          estas. PREDICTABILL podrá custodiar, tratar y almacenar los Datos
          Personales, asimismo, esta información podrá ser utilizada con fines
          en caso de ser requerido para prospección comercial y usar además la
          información de contacto de la Cuenta.
        </p>
        <p>
          En lo relativo al respeto del derecho de autodeterminación informativa
          que asiste a las personas, PREDICTABILL podría tener una base de datos
          donde se recopilarán los datos personales que sean solicitados en
          cualquier formulario, o documento que se utilice para el acceso a la
          Aplicación. La información contenida en la base de datos será de uso
          de PREDICTABILL y este queda autorizado a traspasar dichos datos a
          la(s) empresa(s) que considere(n) pertinentes, incluyendo sus asesores
          externos y/o Afiliados comerciales. Usted, declara haber sido
          informado de las condiciones sobre protección de Datos Personales
          anteriormente descritas, aceptando y consintiendo el tratamiento
          automatizado de los mismos por parte de PREDICTABILL, en la forma y
          para las finalidades indicadas en estos Términos y Condiciones y en
          los documentos que suscriba, durante el proceso de formalización de
          los servicios que contrate a PREDICTABILL.
        </p>
        <p>
          En relación con la información que puede ser almacenada y utilizada en
          este sitio, esta además la información de carácter “no personal”, la
          cual puede (no exclusivamente), ser información técnica o información
          demográfica, como su edad, o intereses. A continuación, encontrará
          algunos ejemplos de Información que no es de identificación personal
          que puede ser recolectada por esta Aplicación y una descripción de
          cómo esta información puede llegar a ser usada:
        </p>
        <p>
          Identificación de dispositivo. PREDICTABILL podrá recopilar la
          información relativa al dispositivo que se utiliza ingresar al Sitio.
        </p>
        <p>
          Información Demográfica. La “Información Demográfica” como su género,
          edad, código postal, e intereses, que usted voluntariamente nos
          suministra, puede ser usada para analizar tendencias, para asegurar
          que la información de la Aplicación llene sus necesidades.
        </p>
        <p>
          Incumplimiento material. Una Parte puede rescindir este Acuerdo en
          cualquier momento por un incumplimiento importante o persistente por
          parte de la otra Parte. Cuando dicha infracción pueda remediarse, una
          Parte solo podrá rescindirla si la Parte infractora no ha subsanado
          dicha infracción dentro de los noventa días naturales posteriores a la
          notificación por escrito de dicha infracción.
        </p>
        <p>
          <strong>Duración</strong>
        </p>
        <p>
          Este Acuerdo entrará en vigor a partir de la Fecha de inicio o
          aceptación y continuará a menos que se cancele de conformidad con
          algún acuerdo paralelo a éste. Asimismo, estas condiciones podrán ser
          modificadas por PREDICTABILL en cualquier momento. Cada cambio entrará
          en vigencia un día hábil después de haberse publicado y es
          responsabilidad suya revisar estas condiciones cada vez que utiliza el
          Sitio.
        </p>
        <p>
          <strong>Relación</strong>
        </p>
        <p>
          Los presentes Términos y Condiciones (regulan el acceso o uso que
          usted haga, como persona, desde Costa Rica de aplicaciones, páginas
          web, contenido, productos y servicios en relación con el Sitio puestos
          a disposición por PREDICTABILL.
        </p>
        <p>
          Mediante su acceso y uso del Sitio, usted acuerda vincularse
          jurídicamente por estos Términos y Condiciones, que establecen una
          relación entre usted y PREDICTABILL exclusivamente para efectos del
          uso del Sitio y la eventual compra de Servicios.
        </p>
        <p>
          Si usted no acepta estos Términos y Condiciones, no podrá acceder o
          usar el Sitio ni contratar Servicios. Estos Términos y Condiciones
          sustituyen expresamente los acuerdos o compromisos previos con usted.
          PREDICTABILL podrá poner fin de inmediato a estos Términos y
          Condiciones o cualquiera de las facilidades asociadas del Sitio
          respecto de usted o, en general, dejar de ofrecer o denegar el acceso
          al Sitio o cualquier parte de este, en cualquier momento y por
          cualquier motivo. Se podrán aplicar condiciones suplementarias a
          determinadas facilidades suministrados por el Sitio.{" "}
        </p>
        <p>
          No existe entre usted y PREDICTABILL, una empresa conjunta o relación
          de afiliados, empleo o agencia como resultado del contrato entre usted
          y PREDICTABILL o del uso del Sitio; y la única relación existente
          existirá si usted contrata los Servicios de PREDICTABILL. Dichos
          Servicios se regularán por lo dispuesto en la LEY ORGÁNICA DEL COLEGIO
          DE ABOGADOS Y ABOGADAS DE COSTA RICA, y EL CÓDIGO DE DEBERES
          JURÍDICOS, MORALES Y ÉTICOS DEL PROFESIONAL EN DERECHO.
        </p>
        <p>
          La relación con PREDICTABILL concluirá una vez que se realice el
          servicio contratado. En este sentido, PREDICTABILL no tendrá ninguna
          obligación post-contractual una vez que ser brindado dicho servicio.
        </p>
        <p>
          <strong>Precios &amp; Pago</strong>
        </p>
        <p>
          Usted podrá realizar el pago de Servicios mediante transferencia a las
          cuentas bancarias de PREDICTABILL cuya información consta en este
          Sitio, tarjeta de crédito o débito, una vez aceptada la cotización por
          los servicios solicitados. Los precios aplicables a cada servicio son
          los indicados en la cotización remitida e incluye los gastos señalados
          y los impuestos. Los honorarios establecidos se basan en las tarifas
          mínimas establecidas en el ARANCEL DE HONORARIOS POR SERVICIOS
          PROFESIONALES DE ABOGACÍA Y NOTARIADO (el “Arancel”).
        </p>
        <p>
          Los precios provistos en el presente Sitio incluyen el Impuesto al
          Valor Agregado (IVA). Los precios reflejados en este sitio son
          estimados con base en parámetros razonables del mercado, por lo que
          podrían variar dependiendo de las particularidades de cada caso. Por
          favor comuníquese con el equipo de PREDICTABILL para información
          adicional. Los montos señalados han sido calculados, asumiendo que el
          Cliente proporcionará oportunamente a PREDICTABILL, toda la
          información y documentación de soporte requerida por la firma, para
          prestar los servicios legales correspondientes. La información de
          soporte entregada por el cliente, a solicitud de PREDICTABILL, deberá
          de estar estar vigente, legible y preferiblemente en formato digital.
          Salvo en los casos señalados, ninguno de los montos indicados incluye
          gastos como timbres y cobros administrativos de entidades locales como
          el Registro Público. Por favor contacte a nuestro equipo para
          información. Salvo en los casos señalados, ninguno de los montos
          indicados incluye gastos notariales, los cuales se cobrarán con base
          en el Arancel vigente.
        </p>
        <p>
          El Cliente está de acuerdo en pagar los honorarios de PREDICTABILL y
          los gastos en que se incurran, vinculados con el servicio que usted
          adquiere. No se procederá con la realización de los Servicios hasta
          que se haya confirmado el pago por parte de usted y se cuente con la
          información necesaria para la ejecución de estos. Una vez confirmado
          lo anterior, recibirá el comprobante de la transacción.{" "}
        </p>
        <p>
          Una vez realizado el pago y se cuente con la información necesaria
          para la ejecución del Servicios, el plazo de duración dependerá del
          Servicio adquirido. Cada servicio tiene referencia al plazo estimado
          para su ejecución. No existe responsabilidad en caso de que el
          servicio adquirido se atrase por culpa del cliente, instituciones
          públicas o de terceros. En caso de ser necesario la entrega de alguna
          documentación, el Cliente deberá ajustarse a los horarios y el lugar
          señalado por la persona a cargo del Servicio.
        </p>
        <p>
          El Cliente reconoce que las tarifas horarias podrán incrementar, según
          lo determine PREDICTABILL. Sin embargo, antes de realizar cualquier
          aumento en nuestras tarifas, se solicitará su autorización previa y
          pago por la pasarela de pago de PREDICTABILL.{" "}
        </p>
        <p>
          <strong>Gastos e Impuestos</strong>
        </p>
        <p>
          No se incurrirá en ningún otro cargo sin su acuerdo previo. Cualquier
          honorario o gasto adicional que pueda generarse, fuera del alcance del
          trabajo acordado, será cotizado por PREDICTABILL Legal con base en el
          Arancel. Todos los gastos relacionados con los servicios prestados
          (copias, traducciones, legalizaciones, timbres, impuesto, especies
          fiscales, Courier, etc.) no forman parte de esta cotización a no ser
          que en su descripción haya sido expresamente incluido, por ende,
          correrán por cuenta exclusiva suya y serán facturados antes de
          incurrirse en dicho gasto, previa notificación al Cliente.
        </p>
        <p>
          De conformidad con lo dispuesto en la Ley 9635, de Fortalecimiento de
          las Finanzas Públicas, los servicios legales se entenderán localizados
          en el territorio de aplicación del Impuesto de Valor Agregado
          costarricense. El tipo de Impuesto de Valor Agregado aplicable será el
          legalmente vigente en cada momento en función del servicio concreto de
          que se trate.
        </p>
        <p>
          <strong>Confidencialidad y Secreto Profesional</strong>
        </p>
        <p>
          PREDICTABILL garantiza que toda la información que sea provista por el
          Cliente será utilizada para cumplir los fines del Servicio y será
          tratada como información confidencial. En caso de que una autoridad
          gubernamental solicite información privilegiada/confidencial del
          cliente, PREDICTABILL le informará al Cliente inmediatamente, salvo
          orden judicial en contra.{" "}
        </p>
        <p>
          El Cliente acepta revelar de manera completa y precisa todos los
          hechos e información que puedan ser relevantes para proveer el
          Servicio de manera integral, o bien, toda aquella información que
          PREDICTABILL le pueda solicitar. Asimismo, el Cliente acuerda
          participar de reuniones y conferencias telefónicas, que se consideren
          indispensables para lograr entregar los Servicios.
        </p>
        <p>
          Durante la prestación de servicios legales, PREDICTABILL podrá
          expresar opiniones o predicciones sobre asuntos presentes o
          contingencias futuras. Dichas expresiones o manifestaciones realizadas
          por cualquier abogado de PREDICTABILL, debe considerarse como una
          recomendación o apreciación, basada en información que tuvimos a la
          mano en dicho momento y en ninguna circunstancia debe ser interpretado
          como una promesa o garantía.
        </p>
        <p>
          <strong>Derecho de Retracto &amp; Garantía</strong>
        </p>
        <p>
          El servicio está implícitamente garantizado, en cuanto al cumplimiento
          de los estándares de calidad y los requerimientos técnicos que, por
          razones de legalidad, salud, medio ambiente, seguridad; establezcan
          las leyes, los reglamentos y las normas respectivas dictadas por la
          Administración Pública. Esta garantía implícita se refiere a la
          naturaleza, características, condiciones, calidad y utilidad o
          finalidad previsible para el cual normalmente es adquirido.
        </p>
        <p>
          Además, el Cliente, amparado al derecho de retracto, puede rescindir,
          sin su responsabilidad, la compra de los Servicios en un plazo de ocho
          días contados a partir de su perfeccionamiento. El retracto sólo
          procederá en el tanto los Servicios no se hubieren prestado
          efectivamente de manera parcial o total.
        </p>
        <p>
          En caso de aplicar el derecho de retracto y el mismo sea ejercido por
          el Cliente dentro del plazo de ocho (8) días luego de realizar la
          compra, se reintegrará los importes recibidos por el mismo medio
          utilizado para el pago. No obstante, el rubro correspondiente al costo
          de la transferencia bancaria, de ser necesaria, no será reintegrado.
        </p>
        <p>
          El reintegro del dinero se hará dentro del plazo de ocho días hábiles
          contados a partir de la notificación de su devolución.
        </p>
        <p>
          <strong>Derecho Mutuo para Rescindir la Relación Profesional</strong>
        </p>
        <p>
          Cualquier Cliente tiene el irrestricto derecho de dar por concluida su
          relación con PREDICTABILL en cualquier momento y por cualquier razón.
          PREDICTABILL se reserva este derecho siempre que cumplamos con ciertos
          requisitos básicos como son una notificación oportuna y la posibilidad
          de trasladar toda la información pertinente a otro profesional. Dentro
          de las razones típicas por las que podemos dar por terminada nuestra
          representación están: (1) el no pago de los honorarios, gastos o
          costos relacionados; (2) la negativa por parte del Cliente de dirigir,
          apoyar o cooperar con los esfuerzos desplegados por PREDICTABILL en su
          favor; (3) la falta de veracidad o el no revelar hechos relevantes en
          torno al servicio legal prestado; (4) la negativa por parte del
          Cliente en aceptar nuestros servicios o consejos; (5) el advenimiento
          o desarrollo de un conflicto de intereses, o (6) cualquier otra razón
          aceptada por el Código de Ética Profesional del Colegio de Abogados de
          la República de Costa Rica. Al darse la terminación, sea por voluntad
          del Cliente o de PREDICTABILL, el Cliente asume la obligación de
          firmar, en caso de ser requerido, todos los documentos necesarios para
          dar por concluida la relación.
        </p>
        <p>
          <strong>Notificaciones</strong>
        </p>
        <p>
          PREDICTABILL podrá realizar notificaciones por medio de una
          notificación en el Sitio, o mediante una comunicación enviada al
          correo electrónico o por comunicación escrita enviada a su dirección,
          según lo dispuesto en su Cuenta.
        </p>
        <p>
          <strong>Nulidad</strong>
        </p>
        <p>
          Si cualquier disposición de estas Condiciones se considerara ilegal,
          nula o inexigible, ya sea en su totalidad o en parte, de conformidad
          con cualquier legislación, dicha disposición o parte de esta se
          considerará que no forma parte de estas Condiciones, aunque la
          legalidad, validez y exigibilidad del resto de las disposiciones de
          estas Condiciones no se verá afectada. En ese caso, PREDICTABILL
          buscará reemplazar dicha disposición ilegal, nula o inexigible, en
          todo o en parte por una disposición legal, válida y exigible que
          tenga, en la medida de lo posible, un efecto similar al que tenía la
          disposición ilegal, nula o inexigible, dados los contenidos y el
          propósito de estas Condiciones.
        </p>
        <p>
          <strong>Ley aplicable</strong>
        </p>
        <p>
          Al ingresar este sito usted reconoce y acepta que este Sitio será
          interpretado y evaluado de acuerdo a las leyes de la República de
          Costa Rica. Todas las controversias, diferencias, disputas o reclamos
          que pudieran derivarse del presente Contrato, o el negocio y la
          materia a la que este se refiere, su ejecución, incumplimiento,
          liquidación, interpretación o validez, se resolverán por medio de
          arbitraje de conformidad con los reglamentos del Centro Internacional
          de Conciliación y Arbitraje de la Cámara Costarricense-Norteamericana
          de Comercio ("CICA"), a cuyas normas las PARTES se someten en forma
          voluntaria e incondicional.
        </p>
      </div>
    );
  }
}

export default withTranslation()(PRBVersion);
