import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { withTranslation } from "react-i18next";
import { Chip } from "@mui/material";

class CPTip extends React.Component {
  render() {
    const { t, text } = this.props;

    return (
      <Chip
        icon={
          <LightbulbOutlinedIcon
            style={{
              color: window.$brand === "PRB" ? "#000" : "#fff",
            }}
          />
        }
        label={text}
        size="small"
        sx={{
          backgroundColor: window.$brand === "PRB" ? "#ffcc0099" : "#41baab",
          color: window.$brand === "PRB" ? "#000" : "#fff",
          mb: 2,
        }}
      />
    );
  }
}

export default withTranslation()(CPTip);
