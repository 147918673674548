import React from "react";
import Container from "@mui/material/Container";
import PageTitle from "../../PageTitle";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { authContext } from "../../../contexts/AuthContext";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import ADUserCard from "./ADUserCard";

class UsuariosAD extends React.Component {
  // Pagina de administracion de usuarios internos de AAD
  // deben registrarse aqui para ingresar al sitio

  constructor() {
    super();

    this.state = {
      users: undefined,
      filteredUsers: undefined,
      add: {},
      edit: {},
      delete: {},
      currPassword: "",
    };
  }

  static contextType = authContext;

  handleAddOpen = (user) => {
    this.setState({
      add: user,
    });
    this.generatePassword();
  };

  handleAddClose = () => {
    if (this.state.add.id) {
      this.setState({
        users: _.concat(this.state.users, this.state.add),
        filteredUsers: _.concat(this.state.filteredUsers, this.state.add),
        add: {},
      });
    } else {
      this.setState({
        add: {},
      });
    }
  };

  handleDialogClose = () => {
    this.setState({
      add: {},
    });
  };

  handleAddUserTypeChange = (event) => {
    let modified = this.state.add;
    modified.type = event.target.value;
    this.setState({
      add: modified,
    });
  };

  handleAddRoleChange = (event) => {
    let modified = this.state.add;
    modified.role = event.target.value;
    this.setState({
      add: modified,
    });
  };

  componentDidMount() {
    this.fetchADUserList();
  }

  fetchADUserList = () => {
    let params = {
      brandLabel: window.$brand,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListADUsers?code=sHDaeBFa7a2FeY5Kb3YmJU0iobSmkySTIkg7Bkjw8BauaL2He9hO5g==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          users: data,
          filteredUsers: data,
        });
      });
  };

  generatePassword = () => {
    const valid =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result = result.concat(valid[Math.floor(Math.random() * valid.length)]);
    }

    this.setState({
      currPassword: result,
    });
  };

  insert = (user) => {
    user.first_name = document.getElementById("insert_firstName").value;
    user.last_name = document.getElementById("insert_lastName").value;
    user.email = document.getElementById("insert_email").value;
    user.phone = document.getElementById("insert_phone").value;
    user.mobile = document.getElementById("insert_mobile").value;
    user.loggedUserId = this.context.auth.user.id;
    user.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertADUser?code=OvTicAuAVpa6y2X0Lew3V8BiwYlPvvevfloyWweOUwpOmwDVBbNxXQ==";

    if (user.type === "admin_zapotal") {
      user.password = document.getElementById("insert_password").value;
      url =
        "https://blpclientportalfunctions.azurewebsites.net/api/InsertZapotalAdminUser?code=WGd7EqiHZYwCKL_GfxvF8tCbIvCqTgIt-t_hU4KhZCjAAzFumkKaMQ==";
    }

    if (user.type === "admin_IDB"
        || user.type === "admin_epa"
        || user.type === "admin_asal"
        || user.type === "admin_vololoans"
        || user.type === "admin_subway"
        || user.type === "admin_kraft"
      ) {
      user.password = document.getElementById("insert_password").value;
      url =
        "https://blpclientportalfunctions.azurewebsites.net/api/InsertZapotalAdminUser?code=WGd7EqiHZYwCKL_GfxvF8tCbIvCqTgIt-t_hU4KhZCjAAzFumkKaMQ==";
    }

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          add: data,
        });

        this.handleAddClose();
      })
      .catch((error) => console.log(error));
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const res = this.state.users.filter((user) => {
        return (
          user.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          user.last_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (
            user.first_name.toLowerCase() +
            " " +
            user.last_name.toLowerCase()
          ).includes(keyword.toLowerCase()) ||
          user.email.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      this.setState({
        filteredUsers: res,
      });
    } else {
      this.setState({
        filteredUsers: this.state.users,
      });
    }
  };

  cardDeleteHandler = (e) => {
    _.remove(this.state.users, (c) => {
      return c.id === e.id;
    });
    _.remove(this.state.filteredUsers, (c) => {
      return c.id === e.id;
    });
    this.forceUpdate();
  };

  render() {
    if (!this.state.users) {
      return <div />;
    }

    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={6}>
            <PageTitle>{window.$brand + " " + t("Users")}</PageTitle>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            {this.context.auth.user.userType === "blp_admin" && (
              <Button
                sx={{
                  m: 3,
                  backgroundColor:
                    window.$brand === "PRB" ? "#17afb6" : "#102a46",
                  borderRadius: window.$brand === "PRB" ? 0 : 5,
                }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                  this.handleAddOpen({
                    created_by: this.context.auth.user.id,
                  })
                }
              >
                {t("Add_user")}
              </Button>
            )}
          </Grid>
        </Grid>
        <TextField
          id="user-search"
          label={t("User_filter")}
          onChange={this.filter}
          type="text"
          fullWidth
          variant="standard"
          sx={{ mb: 4, mt: 2 }}
        />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {this.state.filteredUsers.map((user) => (
            <Grid item xs={12} sm={8} md={4} key={user.id}>
              <ADUserCard
                user={user}
                cardDeleteHandler={this.cardDeleteHandler}
              />
            </Grid>
          ))}
        </Grid>
        <Dialog open={!_.isEmpty(this.state.add)} onClose={this.handleAddClose}>
          <DialogTitle>{t("add_user")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="insert_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="insert_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="insert_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <FormControl fullWidth>
              <InputLabel id="insert_type_label">{t("User_type")}</InputLabel>
              <Select
                labelId="insert_type_label"
                id="insert_type"
                label={t("User_type")}
                onChange={this.handleAddUserTypeChange}
              >
                <MenuItem value={"blp_admin"}>{t("Administrator")}</MenuItem>
                <MenuItem value={"blp_user"}>
                  {window.$brand + " " + t("User")}
                </MenuItem>
                {window.$brand === "ZAP" && (
                  <MenuItem value="admin_zapotal">Admin Zapotal</MenuItem>
                )}
                {window.$brand === "IDB" && (
                  <MenuItem value="admin_IDB">Admin IDB</MenuItem>
                )}
                {window.$brand === "EPA" && (
                  <MenuItem value="admin_epa">Admin EPA</MenuItem>
                )}
                {window.$brand === "ASAL" && (
                  <MenuItem value="admin_asal">Admin ASAL</MenuItem>
                )}
                {window.$brand === "VOLOLOANS" && (
                  <MenuItem value="admin_vololoans">Admin VOLOLOANS</MenuItem>
                )}
                {window.$brand === "SUBWAY" && (
                  <MenuItem value="admin_subway">Admin SUBWAY</MenuItem>
                )}
                {window.$brand === "KRAFT" && (
                  <MenuItem value="admin_kraft">Admin KRAFT</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="insert_role_label">{t("Role")}</InputLabel>
              <Select
                labelId="insert_role_label"
                id="insert_role"
                label={t("Role")}
                onChange={this.handleAddRoleChange}
              >
                <MenuItem value={"Associate"}>{t("Associate")}</MenuItem>
                <MenuItem value={"Partner"}>{t("Partner")}</MenuItem>
                <MenuItem value={"Director"}>{t("Director")}</MenuItem>
                <MenuItem value={"Senior Associate"}>
                  {t("Senior Associate")}
                </MenuItem>
                <MenuItem value={"Special Counsel"}>
                  {t("Special Counsel")}
                </MenuItem>
                <MenuItem value={"Senior Counsel"}>
                  {t("Senior Counsel")}
                </MenuItem>
                <MenuItem value={"Paralegal"}>{t("Paralegal")}</MenuItem>
                <MenuItem value={"Administrative Assistant"}>
                  {t("Administrative Assistant")}
                </MenuItem>
                {window.$brand === "ZAP" && (
                  <MenuItem value="Gerente Zapotal">Gerente Zapotal</MenuItem>
                )}
              </Select>
            </FormControl>
            {window.$brand === "ZAP" &&
              this.state.add.type === "admin_zapotal" && (
                <TextField
                  required
                  id="insert_password"
                  label={t("Password")}
                  type="text"
                  value={this.state.currPassword}
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              )}
              {window.$brand === "IDB" &&
              this.state.add.type === "admin_IDB" && (
                <TextField
                  required
                  id="insert_password"
                  label={t("Password")}
                  type="text"
                  value={this.state.currPassword}
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              )}
              {window.$brand === "EPA" &&
              this.state.add.type === "admin_epa" && (
                <TextField
                  required
                  id="insert_password"
                  label={t("Password")}
                  type="text"
                  value={this.state.currPassword}
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              )}
              {window.$brand === "ASAL" &&
              this.state.add.type === "admin_asal" && (
                <TextField
                  required
                  id="insert_password"
                  label={t("Password")}
                  type="text"
                  value={this.state.currPassword}
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              )}
              {window.$brand === "VOLOLOANS" &&
              this.state.add.type === "admin_vololoans" && (
                <TextField
                  required
                  id="insert_password"
                  label={t("Password")}
                  type="text"
                  value={this.state.currPassword}
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              )}
              {window.$brand === "SUBWAY" &&
              this.state.add.type === "admin_subway" && (
                <TextField
                  required
                  id="insert_password"
                  label={t("Password")}
                  type="text"
                  value={this.state.currPassword}
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              )}
              {window.$brand === "KRAFT" &&
              this.state.add.type === "admin_kraft" && (
                <TextField
                  required
                  id="insert_password"
                  label={t("Password")}
                  type="text"
                  value={this.state.currPassword}
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAddClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.insert(this.state.add)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(UsuariosAD);
