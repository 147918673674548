import React from "react";
import { withTranslation } from "react-i18next";
import {
  Autocomplete,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import "react-block-ui/style.css";
import Box from "@mui/material/Box";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  listWorkspacesByBrandLabel,
  listWorkspacesByUserId,
} from "../../../services/workspaces";
import CPTip from "../../Utils/CPTip";
import { getMainClient } from "../../../services/clients";
import { listFeatures } from "../../../services/features";
import { listPermissions } from "../../../services/permissions";

class Permissions extends React.Component {
  // Permisos para el usuario externo de tipo client_user

  constructor() {
    super();

    this.state = {
      clients: [],
      assignedClients: [],
      workspaces: [],
      assignedWorkspaces: [],
      clientFeatures: [],
      userPermissions: [],
    };
  }

  static contextType = authContext;

  async componentDidMount() {
    this.fetchAssignedClients(this.props.user.id);
    this.fetchClientList();
    if (window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT" || window.$brand === "IDB" || window.$brand === "EPA" || window.$brand === "ASAL" || window.$brand === "VOLOLOANS") {
      this.fetchAssignedWorkspaces(this.props.user.id);
      this.fetchWorkspaces();
    }

    let client = await getMainClient(this.props.user.id);
    let features = await listFeatures(client.id);
    let permissions = await listPermissions(this.props.user.id);
    this.setState({
      clientFeatures: features,
      userPermissions: permissions,
    });
  }

  hasFeature = (featureName) => {
    return (
      _.find(this.state.clientFeatures, (feature) => {
        return feature.name === featureName;
      }) !== undefined
    );
  };

  hasPermission = (permissionName) => {
    return (
      _.find(this.state.userPermissions, (permission) => {
        return permission.name === permissionName;
      }) !== undefined
    );
  };

  fetchClientList = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListClientAndCompaniesByUserId?code=XUHjiLhavoUBVWWXdeFfJ4Lb5YEFRSquI9efJOeCl3ONAzFudZyoFg==&userId=" +
      this.props.user.id;

    fetch(url, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          clients: data,
        });
      });
  };

  fetchAssignedClients = (userId) => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/ListAuthorizedClientsByUser?code=v1c8akCxuMaoZZBSp1HY6GU1tylrr3sH9EawaqupECxUjysiNRmxJQ==&userId=" +
        userId
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          assignedClients: _.map(data, "client_id"),
        });
      });
  };

  toggleClientAuthorization = (event, clientId) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAuthorizeUserClient?code=RqfSxvAHm/xgyay7eGciL0zr3WqMzlK9nQI/N2FR0nVMXmaRTWhwNQ==";

    let assignedClients = this.state.assignedClients;
    if (event.target.checked) {
      assignedClients.push(clientId);
    } else {
      _.remove(assignedClients, (cid) => {
        return cid == clientId;
      });
    }
    this.setState({
      assignedClients: assignedClients,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        userId: this.props.user.id,
        clientId: clientId,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  fetchWorkspaces = async () => {
    let list = await listWorkspacesByBrandLabel();
    list.push({
      id: -1,
      name_en: "Court Proceedings",
      name_es: "Procesos Judiciales",
    });
    list.push({
      id: -2,
      name_en: "Administrative Processes",
      name_es: "Procesos Administrativos",
    });
    this.setState({
      workspaces: list,
    });
  };

  fetchAssignedWorkspaces = async (userId) => {
    let list = await listWorkspacesByUserId(userId);
    this.setState({
      assignedWorkspaces: _.map(list, "workspace_id"),
    });
  };

  toggleWorkspaceAuthorization = (event, workspaceId) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignUserWorkspace?code=EwqC4JT94cp9dIqd0VbADZKTdGgqFatAVhXZEShLorMXAzFuzu9YQg==";

    let assignedWorkspaces = this.state.assignedWorkspaces;
    if (event.target.checked) {
      assignedWorkspaces.push(workspaceId);
    } else {
      _.remove(assignedWorkspaces, (wid) => {
        return wid == workspaceId;
      });
    }
    this.setState({
      assignedWorkspaces: assignedWorkspaces,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        workspaceId: workspaceId,
        userId: this.props.user.id,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  toggleAssignMyFeesPermission = (event) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignUserPermission?code=VljY-_czlNS1u8IpMfSSkBVPUl6hsCMR1THAtkVFUzWxAzFuI3L7Jg==";

    let userPermissions = this.state.userPermissions;
    if (event.target.checked) {
      userPermissions.push({ id: 2, name: "My Fees" });
    } else {
      _.remove(userPermissions, (permission) => {
        return permission.name === "My Fees";
      });
    }
    this.setState({
      userPermissions: userPermissions,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        permissionId: 2,
        userId: this.props.user.id,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { t } = this.props;

    return (
      <Box sx={{ flexGrow: 1, mb: 4, p: 2, minWidth: 400 }}>
        <Box sx={{ flexGrow: 1, mb: 4, p: 2 }} component={Paper}>
          <SectionTitle>{t("Clients")}</SectionTitle>
          <CPTip text={t("Client_permissions")} />
          <Autocomplete
            multiple
            options={this.state.clients}
            disableCloseOnSelect
            disableClearable
            getOptionLabel={(c) => c.name}
            value={
              this.state.clients.filter((c) =>
                this.state.assignedClients.includes(c.id)
              ) || null
            }
            ChipProps={{
              onDelete: null,
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  onChange={(e) => this.toggleClientAuthorization(e, option.id)}
                  checked={this.state.assignedClients.includes(option.id)}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label={t("Clients")} />
            )}
          />
        </Box>
        {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT" || window.$brand === "EPA"  || window.$brand === "IDB" || window.$brand === "ASAL" || window.$brand === "VOLOLOANS") && (
          <>
            <Box sx={{ flexGrow: 1, mb: 4, p: 2 }} component={Paper}>
              <SectionTitle>{t("Workspaces")}</SectionTitle>
              <CPTip text={t("Workspace_permissions")} />
              <Autocomplete
                multiple
                options={this.state.workspaces}
                disableCloseOnSelect
                disableClearable
                getOptionLabel={(w) =>
                  window.$lang === "en"
                    ? w.name_en
                    : w.name_es
                    ? w.name_es
                    : w.name_en
                }
                value={
                  this.state.workspaces.filter((w) =>
                    this.state.assignedWorkspaces.includes(w.id)
                  ) || null
                }
                ChipProps={{
                  onDelete: null,
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      onChange={(e) =>
                        this.toggleWorkspaceAuthorization(e, option.id)
                      }
                      checked={this.state.assignedWorkspaces.includes(
                        option.id
                      )}
                    />
                    {window.$lang === "en"
                      ? option.name_en
                      : option.name_es
                      ? option.name_es
                      : option.name_en}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Workspaces")} />
                )}
              />
            </Box>
            {this.hasFeature("My Fees") && (
              <Box sx={{ flexGrow: 1, mb: 4, p: 2 }} component={Paper}>
                <SectionTitle>{t("Permissions")}</SectionTitle>
                <List dense sx={{ maxWidth: 360 }}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <Checkbox
                        edge="start"
                        onChange={(e) => this.toggleAssignMyFeesPermission(e)}
                        checked={this.hasPermission("My Fees")}
                      />
                      <ListItemText primary={t("My Fees")} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
}

export default withTranslation()(Permissions);
