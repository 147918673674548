import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@mui/material";
import { ClienteDetalleIndex } from "./ClienteDetalleIndex";
import ClienteDetalleOld from "./ClienteDetalleOld";
import { useLocation } from "react-router-dom";

export const ClienteDetalle = (props) => {
  // Hubo un cambio en el UI de la pantalla de navegacion de documentos del cliente
  // Actualmente se usa ClienteDetalleIndex
  const { t } = useTranslation();
  const [listView, setListView] = useState(true);
  const location = useLocation();

  useEffect(() => {}, []);

  return (
    <div>
      {/* {window.$brand !== "ZAP" && (
        <FormControlLabel
          sx={{ ml: 1, mt: 1 }}
          control={
            <Switch
              checked={listView}
              onChange={(e) => setListView(e.target.checked)}
              name="list-view"
            />
          }
          label={t("List mode")}
        />
      )} */}
      {listView ? (
        <ClienteDetalleIndex />
      ) : (
        <ClienteDetalleOld client={location.state.client} />
      )}
    </div>
  );
};
