import React from "react";
import { withTranslation } from "react-i18next";
import { Paper, Tooltip, Typography } from "@mui/material";
import "react-block-ui/style.css";
import Box from "@mui/material/Box";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

class NotificationsCard extends React.Component {
  // Configuracion en el perfil del cliente
  // para definir cuales notificaciones desea recibir
  constructor() {
    super();

    this.state = {
      notifications: [],
      assignedNotifications: [],
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchNotificationList();
  }

  fetchNotificationList = () => {
    this.setState({
      notifications: [
        {
          id: 1,
          name: "E-mail me on document uploads",
          description:
            "You will receive an e-mail whenever a new document is uploaded to your account.",
        },
        {
          id: 2,
          name: "E-mail me before document expirations",
          description:
            "You will receive an e-mail 1 month prior to any document expirations.",
        },
        {
          id: 3,
          name: "E-mail me for Predict Your Dates alerts",
          description:
            "You will receive an e-mail on the date you set alerts for Predict Your Dates.",
        },
      ],
      assignedNotifications: [1, 2, 3],
    });
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListClientUserNotifications?code=SzvMnBow1zaRi2a_NmUJxoeJB2oxr2BOr7TtgxQybrdSAzFup3u-RQ==";

    fetch(url, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            notifications: data,
          },
          this.fetchAssignedNotifications()
        );
      });
  };

  fetchAssignedNotifications = () => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedClientUserNotificationsByUser?code=IQCPs04F4w3ptE_F9nC65w4k8xkkjgfn7y-db71qidWUAzFuTtCH6g==&userId=" +
        this.context.auth.user.id
    )
      .then((response) => response.json())
      .then((data) => {
        let list = _.cloneDeep(this.state.notifications);
        let unassigned = _.map(
          _.filter(data, (n) => n.deleted == 1),
          "id"
        );

        _.remove(list, (n) => {
          return unassigned.includes(n.id);
        });
        this.setState({
          assignedNotifications: _.map(list, "id"),
        });
      });
  };

  toggleAssignNotification = (event, notificationId) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignUserClientUserNotification?code=e5eVFlSBSht0_wRnOjyr4T3iau9hz6fIg7ILgIpTuuIlAzFu_-fZUw==";

    let assignedNotifications = this.state.assignedNotifications;
    if (event.target.checked) {
      assignedNotifications.push(notificationId);
    } else {
      _.remove(assignedNotifications, (nid) => {
        return nid == notificationId;
      });
    }
    this.setState({
      assignedNotifications: assignedNotifications,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        clientUserNotificationId: notificationId,
        userId: this.context.auth.user.id,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { t } = this.props;

    return (
      <Box sx={{ flexGrow: 1, mb: 4, p: 2, maxWidth: 400 }} component={Paper}>
        <SectionTitle>Email Notifications</SectionTitle>
        <List dense sx={{ maxWidth: 360 }}>
          {this.state.notifications.map((notification) => (
            <ListItem
              key={notification.id}
              secondaryAction={this.state.assignedNotifications.includes(
                notification.id
              )}
              disablePadding
            >
              <ListItemButton>
                <Checkbox
                  edge="start"
                  onChange={(e) =>
                    this.toggleAssignNotification(e, notification.id)
                  }
                  checked={this.state.assignedNotifications.includes(
                    notification.id
                  )}
                />
                <ListItemText primary={t(notification.name)} />
                {!_.isEmpty(notification.description) && (
                  <Tooltip title={t(notification.description)}>
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }
}

export default withTranslation()(NotificationsCard);
