export const msalConfig = {
  auth: {
    clientId: "a98a110a-82cf-4f5d-9421-dcb4b0e9c761",
    authority:
      "https://login.microsoftonline.com/b6357f72-8f4e-4ba9-b28c-3ea6aaaef0af",
    redirectUri: window.location.href.includes("portal.legalpredictabill")
      ? "https://portal.legalpredictabill.com/"
      : window.location.href.includes("legally.legalpredictabill")
      ? "https://legally.legalpredictabill.com/"
      : window.location.href.includes("zapotal")
      ? "https://zapotal.blplegal.com/"
      : window.location.href.includes("idbinvest")
      ? "https://idbinvest.blplegal.com/"
      : window.location.href.includes("epa")
      ? "https://epa.blplegal.com/"
      : window.location.href.includes("subway")
      ? "https://subway.legalpredictabill.com/"
      : window.location.href.includes("kraft")
      ? "https://kraft.legalpredictabill.com/"
      : window.location.href.includes("vololoans")
      ? "https://vololoans.blplegal.com/"
      : window.location.href.includes("asal")
      ? "https://asal.blplegal.com/"
      : window.location.href.includes("blplegal")
      ? "https://portal.blplegal.com/"
      : window.location.href.includes("blp-clientportal-qa")
      ? "https://blp-clientportal-qa.azurewebsites.net/"
      : window.location.href.includes("blp-clientportal-dev")
      ? "https://blp-clientportal-dev.azurewebsites.net/"
      : "http://localhost:3000",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read", "email"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
