export async function listWorkspacesByBrandLabel() {
  let params = {
    brandLabel: window.$brand,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListWorkspacesByBrandLabel?code=QJ516UhDlh1Xgk-wsizTsGHzHSS4iQdM7qYw5RZdGkd5AzFuqsEFwg==";

  return sendReq(params, url);
}

export async function listWorkspacesByClientId(clientId) {
  let params = {
    clientId: clientId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedWorkspacesByClient?code=6AbIYOFvEcS4-AnnFfHxy2YiC2LKONd3n8NICwWkpp1LAzFuHFtdxA==";

  return sendReq(params, url);
}

export async function listWorkspacesByUserId(userId) {
  let params = {
    userId: userId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedWorkspacesByUser?code=6d1q81vZZkajGzUqmmMmfhMRe5rVTZ8AB7wKi84PbX46AzFuKJg2qw==";

  return sendReq(params, url);
}

export async function listWorkspaceSubcategories(workspaceId) {
  let params = {
    workspaceId: workspaceId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListWorkspaceSubcategories?code=Zlc0JaVdSxUjwUVbOfBZjS9EZo3-khwVOpwt-bQ0SOQMAzFuNao5zw==";

  return sendReq(params, url);
}

export async function listFilesByClient(clientId) {
  let params = {
    clientId: clientId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListFilesByClient?code=kyoL881taSJUElM1ZJN8UFuLuU1PGm7nZk3vG8w4jvJZAzFu-wohww==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
