import React, { useState, useEffect, useContext } from "react";
import _, { forEach } from "lodash";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { Badge, Paper } from "@mui/material";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import CPTip from "../../Utils/CPTip";
import { misDocumentosContext } from "../../../contexts/MisDocumentosContext";
import { useLocation } from "react-router-dom";

export const FullMisDocumentosNavigator = (props) => {
  // Card de navegacion para jerarquia de documentos de MisDocumentosIndex
  // Pagina para clientes
  const { t } = useTranslation();
  const { search } = useLocation();

  const {
    setCurrentViewerDoc,
    id,
    setId,
    data,
    selectionChain,
    setSelectionChain,
  } = useContext(misDocumentosContext);

  useEffect(() => {
    setId(props.client.id);
    setTimeout(() => {
      setSelectionChainFromQueryParams();
    }, 2000);
  }, []);

  const setSelectionChainFromQueryParams = () => {
    const d =
      search.indexOf("&") > -1
        ? search.substring(search.indexOf("d=") + 2, search.indexOf("&"))
        : search.substring(search.indexOf("d=") + 2);

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetSelectionChainFromDocId?code=GW8vR9p6LmPCZzA7FS16yjQlqVF-R6ne3tmVVzOp9-4aAzFuvx8MPA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({ docId: d }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSelectionChain([
          data.parent === null ? data.assigned_to : data.parent,
          data.parent === null ? -1 : parseInt(data.assigned_to),
          data.category_en,
          data.subcategory_en,
          data.file_id === null ? -1 : parseInt(data.file_id),
          data.id,
        ]);
        getSASToken(data);
      })
      .catch((error) => console.log(error));
  };

  const SideBar = (props) => {
    return (
      <span
        style={{
          color: "#fff",
          backgroundImage: props.selected
            ? window.$brand === "PRB"
              ? 'url("/assets/PRBSideBar.png")'
              : 'url("/assets/BLPSideBar.png")'
            : 'url("/assets/greySideBar.png")',
          backgroundSize: "contain",
          backgroundRepeat: "repeat",
          margin: 0,
          paddingBottom: "6px",
        }}
      >
        &nbsp;&nbsp;
      </span>
    );
  };

  const compare = (chain) => {
    return JSON.stringify(selectionChain) === JSON.stringify(chain);
  };

  const showDocs = (chain) => {
    for (let i = 0; i < chain.length - 1; i++) {
      if (i === selectionChain.length) {
        return false;
      }
      if (selectionChain[i] !== chain[i]) {
        return false;
      }
    }
    return true;
  };

  const showWorkspaces = (chain) => {
    if (selectionChain.length === 1) {
      return chain[0] === selectionChain[0] && chain[1] === -1;
    } else {
      return chain[0] === selectionChain[0] && chain[1] === selectionChain[1];
    }
  };

  const show = (chain) => {
    for (let i = 0; i < chain.length; i++) {
      if (i === selectionChain.length) {
        return false;
      }
      if (selectionChain[i] !== chain[i]) {
        return false;
      }
    }
    return true;
  };

  const RenderDocuments = (props) => {
    if (
      _.isEmpty(props.docs) ||
      !showDocs([
        id,
        props.clientOrCompany.type === "company"
          ? props.clientOrCompany.id
          : -1,
        props.workspace.name_en,
        props.subcategory.subcategory_en,
        props.file ? props.file.id : -1,
      ])
    )
      return <></>;

    return (
      <div
        style={{
          margin: 0,
          marginLeft: "30px",
          padding: 0,
          overflow: "hidden",
        }}
      >
        <Typography component="p" variant="body" style={{ fontSize: "0.8em" }}>
          {t("Documents")}
        </Typography>
        {props.docs.map((doc) => (
          <div key={doc.id}>
            <SideBar
              selected={compare([
                id,
                props.clientOrCompany.type === "company"
                  ? props.clientOrCompany.id
                  : -1,
                props.workspace.name_en,
                props.subcategory.subcategory_en,
                props.file ? props.file.id : -1,
                doc.id,
              ])}
            />
            {compare([
              id,
              props.clientOrCompany.type === "company"
                ? props.clientOrCompany.id
                : -1,
              props.workspace.name_en,
              props.subcategory.subcategory_en,
              props.file ? props.file.id : -1,
              doc.id,
            ]) ? (
              <Typography
                component="span"
                variant="body"
                style={{
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
              >
                <strong
                  style={{
                    background:
                      window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
                    color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
                    borderRadius: "10px",
                    padding: "6px",
                  }}
                >
                  <DescriptionOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                  {doc.filename}
                </strong>
              </Typography>
            ) : (
              <Typography
                component="span"
                variant="body"
                style={{
                  color: "#000",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectionChain([
                    id,
                    props.clientOrCompany.type === "company"
                      ? props.clientOrCompany.id
                      : -1,
                    props.workspace.name_en,
                    props.subcategory.subcategory_en,
                    props.file ? props.file.id : -1,
                    doc.id,
                  ]);
                  getSASToken(doc);
                }}
              >
                <DescriptionOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                {doc.filename}
              </Typography>
            )}
          </div>
        ))}
      </div>
    );
  };

  const getSASToken = (doc) => {
    if (_.isEmpty(doc)) return;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetDocumentSASToken?code=60cfPysOUg_RthP_VGzenaYx6E3JEiTCJnKWTAoc7pJEAzFuJb6uDg==";
    let params = { documentUrl: doc.url };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrentViewerDoc({ uri: data.uri });
      });
  };

  const RenderFiles = (props) => {
    if (
      _.isEmpty(props.subcategory.files) ||
      !show([
        id,
        props.clientOrCompany.type === "company"
          ? props.clientOrCompany.id
          : -1,
        props.workspace.name_en,
        props.subcategory.subcategory_en,
      ])
    )
      return <></>;

    return (
      <div
        style={{
          margin: 0,
          marginLeft: "30px",
          padding: 0,
          overflow: "hidden",
        }}
      >
        <Typography component="p" variant="body" style={{ fontSize: "0.8em" }}>
          {t("Files")}
        </Typography>
        {props.subcategory.files.map(
          (file) =>
            file.docs && (
              <div key={file.id}>
                <SideBar
                  selected={compare([
                    id,
                    props.clientOrCompany.type === "company"
                      ? props.clientOrCompany.id
                      : -1,
                    props.workspace.name_en,
                    props.subcategory.subcategory_en,
                    file.id,
                  ])}
                />
                {compare([
                  id,
                  props.clientOrCompany.type === "company"
                    ? props.clientOrCompany.id
                    : -1,
                  props.workspace.name_en,
                  props.subcategory.subcategory_en,
                  file.id,
                ]) ? (
                  <Typography
                    component="span"
                    variant="body"
                    style={{
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <strong
                      style={{
                        background:
                          window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
                        color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
                        borderRadius: "10px",
                        padding: "6px",
                      }}
                    >
                      <FolderOpenOutlinedIcon
                        sx={{ pt: 0.5 }}
                        fontSize="small"
                      />
                      {file.name}
                    </strong>
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    variant="body"
                    style={{
                      color: "#000",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setSelectionChain([
                        id,
                        props.clientOrCompany.type === "company"
                          ? props.clientOrCompany.id
                          : -1,
                        props.workspace.name_en,
                        props.subcategory.subcategory_en,
                        file.id,
                      ])
                    }
                  >
                    <FolderOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                    {file.name}
                  </Typography>
                )}
                <RenderDocuments
                  clientOrCompany={props.clientOrCompany}
                  workspace={props.workspace}
                  subcategory={props.subcategory}
                  file={file}
                  docs={file.docs}
                />
              </div>
            )
        )}
      </div>
    );
  };

  const RenderSubcategories = (props) => {
    if (
      _.isEmpty(props.workspace.subcategories) ||
      !show([
        id,
        props.clientOrCompany.type === "company"
          ? props.clientOrCompany.id
          : -1,
        props.workspace.name_en,
      ])
    )
      return <></>;

    return (
      <div
        style={{
          margin: 0,
          marginLeft: "20px",
          padding: 0,
          overflow: "hidden",
        }}
      >
        <Typography component="p" variant="body" style={{ fontSize: "0.8em" }}>
          {t("Subcategories")}
        </Typography>
        {props.workspace.subcategories.map(
          (subcategory) =>
            (() => {
              let sum = 0;
              if (subcategory.files) {
                _.forEach(subcategory.files, (file) => {
                  if (file.docs) sum += file.docs.length;
                });
              }
              sum += subcategory.docs ? subcategory.docs.length : 0;
              return sum;
            })() > 0 && (
              <div key={subcategory.subcategory_en}>
                <SideBar
                  selected={compare([
                    id,
                    props.clientOrCompany.type === "company"
                      ? props.clientOrCompany.id
                      : -1,
                    props.workspace.name_en,
                    subcategory.subcategory_en,
                  ])}
                />
                {compare([
                  id,
                  props.clientOrCompany.type === "company"
                    ? props.clientOrCompany.id
                    : -1,
                  props.workspace.name_en,
                  subcategory.subcategory_en,
                ]) ? (
                  <Typography
                    component="span"
                    variant="body"
                    style={{
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <strong
                      style={{
                        background:
                          window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
                        color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
                        borderRadius: "10px",
                        padding: "6px",
                      }}
                    >
                      <FolderOpenOutlinedIcon
                        sx={{ pt: 0.5 }}
                        fontSize="small"
                      />
                      {window.$lang === "en"
                        ? subcategory.subcategory_en
                        : subcategory.subcategory_es
                        ? subcategory.subcategory_es
                        : subcategory.subcategory_en}
                    </strong>
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    variant="body"
                    style={{
                      color: "#000",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setSelectionChain([
                        id,
                        props.clientOrCompany.type === "company"
                          ? props.clientOrCompany.id
                          : -1,
                        props.workspace.name_en,
                        subcategory.subcategory_en,
                      ])
                    }
                  >
                    <FolderOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                    <Badge
                      badgeContent={(() => {
                        let sum = 0;
                        if (subcategory.files) {
                          _.forEach(subcategory.files, (file) => {
                            if (file.docs) sum += file.docs.length;
                          });
                        }
                        sum += subcategory.docs ? subcategory.docs.length : 0;
                        return sum;
                      })()}
                      color="info"
                    >
                      {window.$lang === "en"
                        ? subcategory.subcategory_en
                        : subcategory.subcategory_es
                        ? subcategory.subcategory_es
                        : subcategory.subcategory_en}
                    </Badge>
                  </Typography>
                )}
                <RenderDocuments
                  clientOrCompany={props.clientOrCompany}
                  workspace={props.workspace}
                  subcategory={subcategory}
                  docs={subcategory.docs}
                />
                <RenderFiles
                  clientOrCompany={props.clientOrCompany}
                  workspace={props.workspace}
                  subcategory={subcategory}
                />
              </div>
            )
        )}
      </div>
    );
  };

  const RenderWorkspaces = (props) => {
    if (
      _.isEmpty(props.clientOrCompany.workspaces) ||
      !showWorkspaces([
        id,
        props.clientOrCompany.type === "company"
          ? props.clientOrCompany.id
          : -1,
      ])
    )
      return <></>;

    return (
      <div
        style={{
          margin: 0,
          marginLeft: "10px",
          padding: 0,
          overflow: "hidden",
        }}
      >
        <Typography component="p" variant="body" style={{ fontSize: "0.8em" }}>
          {t("Workspaces")}
        </Typography>
        {props.clientOrCompany.workspaces.map(
          (workspace) =>
            (() => {
              let sum = 0;
              if (workspace.subcategories) {
                _.forEach(workspace.subcategories, (subcategory) => {
                  if (subcategory.files) {
                    _.forEach(subcategory.files, (file) => {
                      if (file.docs) sum += file.docs.length;
                    });
                  }
                  sum += subcategory.docs ? subcategory.docs.length : 0;
                });
              }
              return sum;
            })() > 0 && (
              <div key={workspace.name_en}>
                <SideBar
                  selected={compare([
                    id,
                    props.clientOrCompany.type === "company"
                      ? props.clientOrCompany.id
                      : -1,
                    workspace.name_en,
                  ])}
                />
                {compare([
                  id,
                  props.clientOrCompany.type === "company"
                    ? props.clientOrCompany.id
                    : -1,
                  workspace.name_en,
                ]) ? (
                  <Typography
                    component="span"
                    variant="body"
                    style={{
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <strong
                      style={{
                        background:
                          window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
                        color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
                        borderRadius: "10px",
                        padding: "6px",
                      }}
                    >
                      <FolderOpenOutlinedIcon
                        sx={{ pt: 0.5 }}
                        fontSize="small"
                      />
                      {window.$lang === "en"
                        ? workspace.name_en
                        : workspace.name_es
                        ? workspace.name_es
                        : workspace.name_en}
                    </strong>
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    variant="body"
                    style={{
                      color: "#000",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setSelectionChain([
                        id,
                        props.clientOrCompany.type === "company"
                          ? props.clientOrCompany.id
                          : -1,
                        workspace.name_en,
                      ])
                    }
                  >
                    <FolderOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                    <Badge
                      badgeContent={(() => {
                        let sum = 0;
                        if (workspace.subcategories) {
                          _.forEach(workspace.subcategories, (subcategory) => {
                            if (subcategory.files) {
                              _.forEach(subcategory.files, (file) => {
                                if (file.docs) sum += file.docs.length;
                              });
                            }
                            sum += subcategory.docs
                              ? subcategory.docs.length
                              : 0;
                          });
                        }
                        return sum;
                      })()}
                      color="info"
                    >
                      {window.$lang === "en"
                        ? workspace.name_en
                        : workspace.name_es
                        ? workspace.name_es
                        : workspace.name_en}
                    </Badge>
                  </Typography>
                )}
                <RenderSubcategories
                  clientOrCompany={props.clientOrCompany}
                  workspace={workspace}
                />
              </div>
            )
        )}
      </div>
    );
  };

  const RenderCompanies = (props) => {
    if (_.isEmpty(data.companies)) return <></>;

    return (
      <div>
        <Typography component="p" variant="body" style={{ fontSize: "0.8em" }}>
          {window.$brand === "ZAP" ? t("Properties") : window.$brand === "IDB" ? t("Financings") : t("Companies")}
        </Typography>
        <div style={{ margin: 0, padding: 0, overflow: "hidden" }}>
          {data.companies.map(
            (company) =>
              (() => {
                let sum = 0;
                if (company.workspaces) {
                  _.forEach(company.workspaces, (workspace) => {
                    if (workspace.subcategories) {
                      _.forEach(workspace.subcategories, (subcategory) => {
                        if (subcategory.files) {
                          _.forEach(subcategory.files, (file) => {
                            if (file.docs) sum += file.docs.length;
                          });
                        }
                        sum += subcategory.docs ? subcategory.docs.length : 0;
                      });
                    }
                  });
                }
                return sum;
              })() > 0 && (
                <div key={company.id}>
                  <SideBar selected={compare([id, company.id])} />
                  {compare([id, company.id]) ? (
                    <Typography
                      component="span"
                      variant="body"
                      style={{
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <strong
                        style={{
                          background:
                            window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
                          color:
                            window.$brand === "PRB" ? "#017cbc" : "#05539b",
                          borderRadius: "10px",
                          padding: "6px",
                        }}
                      >
                        <FolderOpenOutlinedIcon
                          sx={{ pt: 0.5 }}
                          fontSize="small"
                        />
                        {company.name}
                      </strong>
                    </Typography>
                  ) : (
                    <Typography
                      component="span"
                      variant="body"
                      style={{
                        color: "#000",
                        marginBottom: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => setSelectionChain([id, company.id])}
                    >
                      <FolderOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                      {company.name}
                    </Typography>
                  )}
                  <RenderWorkspaces clientOrCompany={company} />
                </div>
              )
          )}
        </div>
      </div>
    );
  };

  return (
    <Container
      component={Paper}
      sx={{
        minWidth: 275,
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: window.$brand === "BLP" ? 5 : 0,
        p: 2,
        mb: 2,
        mr: window.$brand === "BLP" ? 10 : 0,
      }}
    >
      {props.caption && <CPTip text={props.caption} />}
      <Typography component="p" variant="body" style={{ fontSize: "0.8em" }}>
        {window.$brand === "ZAP" ? t("Condominium") : t("Client")}
      </Typography>
      <Typography
        component="p"
        variant="body"
        style={{
          marginBottom: "10px",
          cursor: "pointer",
        }}
        onClick={() => setSelectionChain([id])}
      >
        {compare([id]) ? (
          <strong
            style={{
              background: window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
              color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
              borderRadius: "10px",
              padding: "6px",
            }}
          >
            <FolderOpenOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
            {data.name}
          </strong>
        ) : (
          <>
            <FolderOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
            {data.name}
          </>
        )}
      </Typography>
      <RenderWorkspaces clientOrCompany={data} />
      <RenderCompanies />
    </Container>
  );
};
