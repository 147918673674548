import React from "react";
import { authContext } from "../../../../contexts/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { withTranslation } from "react-i18next";
import LocalUploadDialog from "./LocalUploadDialog";
import DocuWareSearchDialog from "./DocuWareSearchDialog";

class UploadTypeDialog extends React.Component {
  // Modal para elegir si cargar desde maquina local o DocuWare
  constructor() {
    super();

    this.state = {
      selection: "local",
      open: false,
      subcategories: [],
      selectedClientId: -1,
      openUpload: false,
      openDocuWare: false,
    };
  }

  static contextType = authContext;

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        open: this.props.data.open,
        subcategories: this.props.data.subcategories,
        selectedClientId: this.props.data.selectedClientId,
        preselectedCustomFile: this.props.data.preselectedCustomFile,
        selectCompanyFor: this.props.data.selectCompanyFor,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Dialog open={this.state.open} onClose={this.props.handleDialogClose}>
          <DialogTitle>{t("Assign_document")}</DialogTitle>
          <DialogContent>
            <RadioGroup
              value={this.state.selection}
              name="radio-buttons-group"
              onChange={(e) =>
                this.setState({
                  selection: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="local"
                control={<Radio />}
                label={
                  <>
                    <strong>{t("Local_document")}</strong>:{" "}
                    {t("Local_document_caption")}
                  </>
                }
              />
              <FormControlLabel
                value="docuware"
                control={<Radio />}
                label={
                  <>
                    <strong>DocuWare</strong>: {t("DocuWare_document_caption")}
                  </>
                }
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleDialogClose}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  openUpload: this.state.selection === "local",
                  openDocuWare: this.state.selection === "docuware",
                });
                this.props.handleDialogClose();
              }}
            >
              {t("Continue")}
            </Button>
          </DialogActions>
        </Dialog>

        <LocalUploadDialog
          data={{
            open: this.state.openUpload,
            item: {
              source: "2",
              assignedBy: this.context.auth.user.id,
              assignedTo: this.state.selectedClientId
                ? this.state.selectedClientId
                : -1,
            },
            subcategories: this.state.subcategories,
            selectedClientId: this.state.selectedClientId,
            preselectedCustomFile: this.state.preselectedCustomFile,
            selectCompanyFor: this.state.selectCompanyFor,
          }}
          handleDialogClose={() => this.setState({ openUpload: false })}
          refreshFunc={this.props.refreshFunc}
        />

        <DocuWareSearchDialog
          data={{
            open: this.state.openDocuWare,
            item: {
              source: "4",
              assignedBy: this.context.auth.user.id,
              assignedTo: this.state.selectedClientId
                ? this.state.selectedClientId
                : -1,
            },
            subcategories: this.state.subcategories,
            selectedClientId: this.state.selectedClientId,
            preselectedCustomFile: this.state.preselectedCustomFile,
            selectCompanyFor: this.state.selectCompanyFor,
          }}
          handleDialogClose={() => this.setState({ openDocuWare: false })}
          refreshFunc={this.props.refreshFunc}
        />
      </>
    );
  }
}

export default withTranslation()(UploadTypeDialog);
