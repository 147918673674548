import React from "react";
import { authContext } from "../../../contexts/AuthContext";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import SectionTitle from "../../SectionTitle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteDialog from "../../DeleteDialog";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPText from "../../Utils/CPText";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Permissions from "../Usuarios/Permissions";
import CPSettingsIconButton from "../../Utils/CPSettingsIconButton";
import CPTip from "../../Utils/CPTip";

class ClientUsers extends React.Component {
  //Creacion de client users (restringidos con permisos) en la config de cada cliente
  constructor() {
    super();

    this.state = {
      list: [],
      addClientUser: {},
      editClientUser: {},
      deleteClientUser: {},
      settingsClientUser: {},
      reset: {},
      snackbarInfoOpen: false,
      currPassword: "",
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchList();
  }

  handleAddPreferredLangChange = (event) => {
    let modified = this.state.addClientUser;
    modified.preferredLang = event.target.value;
    this.setState({
      addClientUser: modified,
    });
  };

  handleSettingsOpen = (user) => {
    this.setState({
      settingsClientUser: user,
    });
  };

  handleSettingsClose = () => {
    this.setState({
      settingsClientUser: {},
    });
  };

  handleAddClientUserOpen = (clientUser) => {
    this.setState({
      addClientUser: clientUser,
    });
    this.generatePassword();
  };

  handleAddClientUserClose = () => {
    this.state.list.push(this.state.addClientUser);

    this.handleDialogClose();
  };

  handleEditClientUserOpen = (clientUser) => {
    this.setState({
      editClientUser: clientUser,
    });
  };

  handleEditClientUserClose = (clientUser) => {
    clientUser.first_name = this.state.editClientUser.first_name;
    clientUser.last_name = this.state.editClientUser.last_name;
    clientUser.email = this.state.editClientUser.email;
    clientUser.phone = this.state.editClientUser.phone;
    clientUser.mobile = this.state.editClientUser.mobile;

    this.handleDialogClose();
  };

  handleResetClose = () => {
    this.setState({
      reset: {},
    });
  };

  handleDeleteClientUserClose = () => {
    this.setState({
      deleteClientUser: {},
    });
  };

  handleDialogClose = () => {
    this.setState({
      addClientUser: {},
      editClientUser: {},
      deleteClientUser: {},
      reset: {},
      currPassword: "",
    });
  };

  generatePassword = () => {
    const valid =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result = result.concat(valid[Math.floor(Math.random() * valid.length)]);
    }

    this.setState({
      currPassword: result,
    });
  };

  insertClientUser = (clientUser) => {
    clientUser.first_name = document.getElementById(
      "insert_user_firstName"
    ).value;
    clientUser.last_name = document.getElementById(
      "insert_user_lastName"
    ).value;
    clientUser.email = document.getElementById("insert_user_email").value;
    clientUser.password = document.getElementById("insert_user_password").value;
    clientUser.phone = document.getElementById("insert_user_phone").value;
    clientUser.mobile = document.getElementById("insert_user_mobile").value;
    clientUser.preferredLang = this.state.addClientUser.preferredLang;
    clientUser.loggedUserId = this.context.auth.user.id;
    clientUser.loggedUserEmail = this.context.auth.user.email;
    clientUser.created_by = this.context.auth.user.id;
    clientUser.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertUserWithPassword?code=bA4BAJDQRXcILuBxMqZj9lo_BT_KFatSNCPP-yNqZJNAAzFuGnwjHw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(clientUser),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          addClientUser: data,
        });
        this.handleAddClientUserClose();
      })
      .catch((error) => console.log(error));
  };

  updateClientUser = (clientUser) => {
    clientUser.first_name = document.getElementById(
      "edit_user_firstName"
    ).value;
    clientUser.last_name = document.getElementById("edit_user_lastName").value;
    clientUser.email = document.getElementById("edit_user_email").value;
    clientUser.phone = document.getElementById("edit_user_phone").value;
    clientUser.mobile = document.getElementById("edit_user_mobile").value;
    clientUser.loggedUserId = this.context.auth.user.id;
    clientUser.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateUser?code=AYcWxCsFFGpiced2Hm57ZTcPSshn99vArFV6w3fsJ97lFp3mkfMrnw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(clientUser),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleEditClientUserClose(clientUser);
  };

  resetPassword = (user) => {
    user.brandLabel = window.$brand;
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ResetPassword?code=mMv/ro86SAchaxEmEsxzoMvavartQnot3XQ3Ul3bYV4cP1AXR/STuA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Reset successful");
        this.setState({
          snackbarInfoOpen: true,
        });
      })
      .catch((error) => console.log(error));

    this.handleResetClose();
  };

  deleteClientUser = (user) => {
    user.loggedUserId = this.context.auth.user.id;
    user.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteUser?code=FhyC/yldBaINF9fi0CaKkp192uvlRAODGI8O/mxqqrxBaay94/uJXg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        _.remove(this.state.list, (u) => {
          return u.id === user.id;
        });
        this.forceUpdate();
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  fetchList = () => {
    let params = {
      clientId: this.props.clientId,
      brandLabel: window.$brand,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListUsersByClient?code=1HHTimsMBRy8WaX06a6RIVA5NDg5j5aFaOEd1tH6uMOubWaWNvk83g==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          list: data,
        });
      });
  };

  render() {
    const { t } = this.props;

    return (
      <Box
        sx={{
          flexGrow: 1,
          mb: 4,
          p: 2,
          maxWidth: 400,
          borderRadius: window.$brand === "PRB" ? 0 : 5,
        }}
        component={Paper}
      >
        <SectionTitle>
          {window.$brand === "ZAP" ? t("Condo_Users") : t("Client_Users")}
          <Button
            sx={{
              m: 3,
              backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#102a46",
              borderRadius: window.$brand === "PRB" ? 0 : 5,
            }}
            variant="contained"
            onClick={() =>
              this.handleAddClientUserOpen({
                type: "client_user",
                clientId: this.props.clientId,
                preferredLang: "es",
              })
            }
          >
            {t("Add")}
          </Button>
        </SectionTitle>
        <List dense sx={{ maxWidth: 360 }}>
          {this.state.list.map((user) => (
            <ListItem
              key={user.id}
              secondaryAction={
                <>
                  <CPSettingsIconButton
                    onClick={() => {
                      this.handleSettingsOpen(user);
                    }}
                  />
                  <CPEditIconButton
                    onClick={() => this.handleEditClientUserOpen(user)}
                  />
                  <CPDeleteIconButton
                    onClick={() => this.setState({ deleteClientUser: user })}
                  />
                </>
              }
              disablePadding
            >
              <ListItemButton>
                <CPText text={user.first_name + " " + user.last_name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <DeleteDialog
          item={this.state.deleteClientUser}
          title={t("Delete_user")}
          caption={t("delete_user_full_caption")}
          targetFunc={this.deleteClientUser}
          handleDialogClose={this.handleDeleteClientUserClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.addClientUser)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("create_client_user_title")}</DialogTitle>
          <DialogContent>
            {window.$brand === "PRB" && (
              <Box sx={{ pb: 2 }}>
                <CPTip text={t("Sending_new_user_email")} />
              </Box>
            )}
            <Typography variant="body2" sx={{ pb: 4 }}>
              {t("Remember_to_configure")}{" "}
              <SettingsOutlinedIcon fontSize="small" />{" "}
              {t("complete_user_permissions")}
            </Typography>
            <TextField
              required
              id="insert_user_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_user_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_user_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              required
              id="insert_user_password"
              label={t("Password")}
              type="text"
              value={this.state.currPassword}
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
              style={{ display: `${window.$brand === "PRB" ? "none" : ""}` }}
            />
            <TextField
              id="insert_user_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="insert_user_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <FormControl fullWidth>
              <InputLabel id="insert_preferred_lang_label">
                {t("Preferred_lang")}
              </InputLabel>
              <Select
                labelId="insert_preferred_lang_label"
                id="insert_preferred_lang"
                label={t("Preferred_lang")}
                onChange={this.handleAddPreferredLangChange}
                defaultValue={"es"}
              >
                <MenuItem value={"es"}>{t("Spanish")}</MenuItem>
                <MenuItem value={"en"}>{t("English")}</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => this.insertClientUser(this.state.addClientUser)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.editClientUser)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("edit_client_user_title")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_user_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editClientUser.first_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="edit_user_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editClientUser.last_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="edit_user_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editClientUser.email}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="edit_user_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editClientUser.phone}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="edit_user_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editClientUser.mobile}
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <Button
              onClick={() =>
                this.setState({ reset: this.state.editClientUser })
              }
              sx={{ m: 4 }}
              variant="contained"
              size="small"
            >
              {t("reset_password")}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => this.updateClientUser(this.state.editClientUser)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.reset)}
          onClose={this.handleResetClose}
        >
          <DialogTitle>{t("reset_password")}</DialogTitle>
          <DialogContent>
            <p>{t("reset_password_caption")}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleResetClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.resetPassword(this.state.reset)}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.settingsClientUser)}
          onClose={this.handleSettingsClose}
        >
          <DialogContent>
            <Permissions user={this.state.settingsClientUser} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSettingsClose}>{t("Close")}</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarInfoOpen}
          autoHideDuration={4000}
          onClose={() =>
            this.setState({
              snackbarInfoOpen: false,
            })
          }
          key={"bottomcentererror"}
        >
          <Alert elevation={6} variant="filled" severity="info">
            {t("password_sent")}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default withTranslation()(ClientUsers);
