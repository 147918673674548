import React, { useContext } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useMsal } from "@azure/msal-react";
import { authContext } from "../contexts/AuthContext";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

export const NotificationBellButton = (props) => {
  const { instance } = useMsal();
  const { setAuthData, auth } = useContext(authContext);

  const [anchorElem, setAnchorElem] = React.useState(false);
  const { t } = useTranslation();

  function handleMenuClick(event) {
    setAnchorElem(event.currentTarget);
  }

  function handleMenuClose(event) {
    setAnchorElem(null);
  }

  return (
    <>
      <IconButton onClick={handleMenuClick} sx={{ color: "white" }}>
        <Tooltip title={t("Notifications")}>
          <NotificationsNoneOutlinedIcon />
        </Tooltip>
      </IconButton>
      <Menu
        id="menu-elem"
        anchorEl={anchorElem}
        open={anchorElem}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "btn-nav-area",
        }}
      >
        <MenuItem sx={{ width: 300 }}>
          {t("No_notifications")}
        </MenuItem>
      </Menu>
    </>
  );
};
