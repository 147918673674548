import React from "react";
import Container from "@mui/material/Container";
import { authContext } from "../../../contexts/AuthContext";
import _ from "lodash";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import DeleteDialog from "../../DeleteDialog";
import AssignedLawyersCard from "./AssignedLawyersCard";
import FeaturesCard from "./FeaturesCard";
import UserAdmins from "./UserAdmins";
import ClientNavigation from "../../ClientNavigation";
import ClientTitle from "../../ClientTitle";
import { withTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import CPButton from "../../Utils/CPButton";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import AssignedWorkspacesCard from "./AssignedWorkspacesCard";
import ClientUsers from "./ClientUsers";

class Administracion extends React.Component {
  // Pagina de configuracion de cada cliente
  
  constructor() {
    super();

    this.state = {
      client: {},
      selectedClientDetail: {},
      companies: [],
      addClient: {},
      editClient: {},
      deleteClient: {},
      remountKey: 0,
      updateNavTo: null,
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static contextType = authContext;

  handleAddClientOpen = (client) => {
    this.setState({
      addClient: client,
    });
  };

  handleAddClientClose = () => {
    if (this.state.addClient.type === "company") {
      this.setState({
        companies: _.concat(this.state.companies, this.state.addClient),
      });
    } else if (this.state.addClient.type === "area") {
      let cps = this.state.companies;
      _.find(cps, (cp) => {
        return cp.id === this.state.addClient.parent;
      }).areas.push(this.state.addClient);
      this.setState({
        companies: cps,
      });
    }

    this.setState({
      updateNavTo: this.state.addClient,
      selectedClientDetail: this.state.addClient,
    });
    this.handleDialogClose();
  };

  handleEditClientOpen = (client) => {
    this.setState({
      editClient: client,
    });
  };

  handleDeleteClientOpen = (client) => {
    this.setState({
      deleteClient: client,
    });
  };

  handleDialogClose = () => {
    this.setState({
      addClient: {},
      editClient: {},
      deleteClient: {},
    });
  };

  updateClient = (client) => {
    client.name = document.getElementById("edit_name").value;
    client.license = document.getElementById("edit_license").value;
    client.loggedUserId = this.context.auth.user.id;
    client.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateClient?code=WaCj6aKC1GXugrtEQfzMaFwXaRJGtTiVH3I/qpalcLen1or7KFRVXQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(client),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  deleteClient = (client) => {
    client.loggedUserId = this.context.auth.user.id;
    client.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteClient?code=30WVuEccojkUfLbHaxmEjP82anP1WUcAbo2bPNB6pWlsR4yzunPi/g==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(client),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        this.setState({
          remountKey: this.state.remountKey + 1,
        });

        let parent = this.state.client;
        if (client.type === "area")
          parent = _.find(this.state.companies, (company) => {
            return company.id === client.parent;
          });
        this.setState({
          updateNavTo: parent,
          selectedClientDetail: parent,
        });
      })
      .catch((error) => console.log(error));

    if (this.state.deleteClient.type === "company") {
      _.remove(this.state.companies, (c) => {
        return c.id == this.state.deleteClient.id;
      });
    } else if (this.state.deleteClient.type === "area") {
      let cps = this.state.companies;
      let cp = _.find(cps, { id: this.state.deleteClient.parent });
      _.remove(cp.areas, (c) => {
        return c.id == this.state.deleteClient.id;
      });
    }
  };

  insertClient = (client) => {
    client.name = document.getElementById("insert_name").value;
    client.license = document.getElementById("insert_license").value;
    client.workspaceId = this.state.client.workspace_id;
    client.loggedUserId = this.context.auth.user.id;
    client.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertClient?code=2YQQ0zi/p9FpHduvTiMjtJdFgGZpSTycjLHhx7PwcInYyFU5BmDXnw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(client),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          addClient: data,
          remountKey: this.state.remountKey + 1,
        });
        this.handleAddClientClose();
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    const { match, location, history } = this.props;
    this.setState({
      client: this.props.location.state.client,
      selectedClientDetail: this.props.location.state.client,
    });
    this.fetchClientList();
  }

  fetchClientList = () => {
    let params = {
      parent: this.props.location.state.client.id,
      brandLabel: window.$brand,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByParent?code=ABu6YYdXe9PQSfpvdSG9slk9rJqSCLMmHxXbZ1rk4NzY6yyrfGviOg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((companies) => {
        _.forEach(companies, (company) => {
          params.parent = company.id;

          fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((areas) => {
              _.find(companies, { id: company.id }).areas = areas;
              company.areas = areas;
              this.forceUpdate();
            });
        });
        this.setState({
          companies: companies,
        });
      });
  };

  updateClientNavigation = (scd) => {
    this.setState({
      selectedClientDetail: scd,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {this.state.client.type === "client" ? (
          <>
            <ClientNavigation
              key={this.state.remountKey}
              client={this.state.client}
              updateClientNavigation={this.updateClientNavigation}
              updateTo={this.state.updateNavTo}
              caption={
                window.$brand === "ZAP"
                  ? t("Navigate_the_hierarchy_edit_condo")
                  : t("Navigate_the_hierarchy_edit_client")
              }
              titleCaption={t("You_are_editing")}
            ></ClientNavigation>
            <CPEditIconButton
              onClick={() =>
                this.handleEditClientOpen(this.state.selectedClientDetail)
              }
            />
            {this.state.selectedClientDetail.type !== "client" && (
              <CPDeleteIconButton
                onClick={() =>
                  this.handleDeleteClientOpen(this.state.selectedClientDetail)
                }
              />
            )}
            {this.state.selectedClientDetail.type === "client" && (
              <Button
                sx={{
                  m: 3,
                  backgroundColor:
                    window.$brand === "PRB" ? "#17afb6" : "#102a46",
                  borderRadius: window.$brand === "PRB" ? 0 : 5,
                }}
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() =>
                  this.handleAddClientOpen({
                    type: "company",
                    parent: this.state.selectedClientDetail.id,
                    id: "-1",
                  })
                }
              >
                {t("Add")}{" "}
                {window.$brand === "ZAP" ? t("property") : window.$brand === "IDB" ? t("Financings")  : t("company")}
              </Button>
            )}
            <Button
              sx={{
                m: 3,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() => {
                let client = this.state.client;
                this.props.history.push({
                  pathname: `/client/${client.id}`,
                  state: { client: client },
                });
              }}
            >
              {window.$brand === "ZAP" ? t("go_to_condo") : window.$brand === "IDB" ? t("go_to_clientTransactions") : t("go_to_client")}
            </Button>
          </>
        ) : (
          <>
            <ClientTitle
              client={this.state.client}
              titleCaption={t("You_are_editing")}
            ></ClientTitle>
            <Box>
              <CPEditIconButton
                onClick={() =>
                  this.handleEditClientOpen(this.state.selectedClientDetail)
                }
              />
            </Box>
          </>
        )}

        <Box sx={{ mt: 4 }}>
          <AssignedWorkspacesCard client={this.props.location.state.client} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <UserAdmins clientId={this.props.location.state.client.id} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <ClientUsers clientId={this.props.location.state.client.id} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <AssignedLawyersCard client={this.props.location.state.client} />
        </Box>
        {window.$brand === "PRB" && (
          <Box sx={{ mt: 4 }}>
            <FeaturesCard client={this.props.location.state.client} />
          </Box>
        )}

        <DeleteDialog
          item={this.state.deleteClient}
          title={
            t("Delete") +
            " " +
            (this.state.selectedClientDetail.type === "client"
              ? t("business")
              : this.state.selectedClientDetail.type === "company"
              ? t("company")
              : t("area"))
          }
          caption={
            t("delete_client_caption") +
            (this.state.selectedClientDetail.type === "client"
              ? t("business")
              : this.state.selectedClientDetail.type === "company"
              ? t("company")
              : t("area")) +
            "?"
          }
          targetFunc={this.deleteClient}
          handleDialogClose={this.handleDialogClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.editClient)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>
            {t("Update")}{" "}
            {this.state.selectedClientDetail.type === "client"
              ? t("business")
              : this.state.selectedClientDetail.type === "company"
              ? t("company")
              : t("area")}
          </DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_name"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editClient.name}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="edit_license"
              label={t("ID")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editClient.license}
              inputProps={{ maxLength: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.updateClient(this.state.editClient)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.addClient)}
          onClose={this.handleAddClientClose}
        >
          <DialogTitle>
            {t("Add")}{" "}
            {this.state.addClient.type === "company" ? t("company") : window.$brand === "IDB" ? t("Financings") : t("area")}
          </DialogTitle>
          <DialogContent>
            <TextField
              required
              id="insert_name"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="insert_license"
              label={t("ID")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.insertClient(this.state.addClient)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(Administracion);
