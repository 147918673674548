import React from "react";
import Container from "@mui/material/Container";
import SubcategoryTitle from "./SubcategoryTitle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import _ from "lodash";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { authContext } from "../contexts/AuthContext";
import { Box } from "@mui/system";
import { withTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import DocumentCard from "./DocumentCard";
import "react-block-ui/style.css";
import SectionTitle from "./SectionTitle";
import CPText from "./Utils/CPText";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListSubheader,
  Select,
  TextField,
} from "@mui/material";
import CustomDirectoryTitle from "./CustomDirectoryTitle";
import CPEditIconButton from "./Utils/CPEditIconButton";
import CPDeleteIconButton from "./Utils/CPDeleteIconButton";
import DeleteDialog from "./DeleteDialog";
import UploadTypeDialog from "./Pages/ClienteDetalle/UploadDialogs/UploadTypeDialog";
import CPButton from "./Utils/CPButton";

class DocumentTable extends React.Component {
  constructor() {
    super();

    this.state = {
      docs: undefined,
      customFileDocs: [],
      anchorCategoryEl: false,
      selectedCategory: null,
      customDirectory: {},
      customDirectoryError: {},
      editCustomDirectory: {},
      editCustomDirectoryError: {},
      deleteCustomDirectory: {},
      customDirectories: [],
      categories: [],
      deleteDocument: {},
      blockingScreen: false,
      snackbarDownloadingOpen: false,
      notifyEmails: null,
      addNotifyEmail: "",
      addNotifyEmailDocId: null,
      remountKey: 0,
      openUploadTypeDialog: false,
      uploadCustomFile: null,
    };
  }

  static contextType = authContext;

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.client != this.props.client) {
      this.fetchDocumentList(this.props.client);
    }
  }
  //includeVisible
  fetchDocumentList(client) {
    let params = {
      clientId: client.id,
      includeNotVisible: this.context.auth.type === "blp" ? "1" : "0",
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetClientDocuments?code=wdbPh7A/5DUKNw3AM3sYPMeRd0/wgCMa3f2fvT/Z53oOeZhDqMLyow==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        if (_.isEmpty(data)) {
          this.setState({
            docs: undefined,
          });
          return;
        }

        let categorizedDocs = _(
          data.filter((d) => {
            return !d.file_id;
          })
        )
          .groupBy("category_en")
          .value();

        let tables = [];
        for (var category in categorizedDocs) {
          if (categorizedDocs.hasOwnProperty(category)) {
            tables.push(categorizedDocs[category]);
          }
        }
        for (var i = 0; i < tables.length; i++) {
          let subcategorizedDocs = _(tables[i])
            .groupBy("subcategory_en")
            .value();
          let subtables = [];
          for (var subcategory in subcategorizedDocs) {
            if (subcategorizedDocs.hasOwnProperty(subcategory)) {
              subtables.push(subcategorizedDocs[subcategory]);
            }
          }
          tables[i] = subtables;
        }

        let categories = [];
        _.forEach(tables, (table) => {
          categories.push({
            category_en: table[0][0].category_en,
            category_es: table[0][0].category_es,
          });
        });

        this.setState(
          {
            docs: tables,
            customFileDocs: data.filter((d) => {
              return d.file_id;
            }),
            selectedCategory: 0,
            categories: categories,
            remountKey: this.state.remountKey + 1,
          },
          this.fetchCustomDirectoryList(this.props.client)
        );
      });
  }

  fetchCustomDirectoryList(client) {
    let params = {
      clientId: client.id,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListFilesByClient?code=kyoL881taSJUElM1ZJN8UFuLuU1PGm7nZk3vG8w4jvJZAzFu-wohww==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        let categories = this.state.categories;
        _.forEach(data, (file) => {
          let val = {
            category_en: file.category_en,
            category_es: file.category_es,
          };

          let exists = false;
          _.forEach(categories, (c) => {
            if (
              c.category_en == val.category_en &&
              c.category_es &&
              val.category_es
            ) {
              exists = true;
            }
          });

          if (!exists) {
            val.noDocs = true;
            categories.push(val);
          }
        });

        this.setState({
          customDirectories: data,
          categories: categories,
        });
      });
  }

  downloadDocuwareDocument = (documentId) => {
    window.location.href =
      "https://blp-abogados-sa.docuware.cloud/docuware/platform/FileCabinets/8e17fc6f-898e-454c-a3b8-16556390619f/Documents/7923/FileDownload?targetFileType=Auto&keepAnnotations=false";
  };

  downloadDocuwareDocument2 = (doc) => {
    this.setState({
      snackbarDownloadingOpen: true,
    });

    let rqData = {
      url: doc.url,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadDocuware2?code=QqEqj/xEWeIym9ZtLILp8gZ9UJSq9AoK0zHgvW53aGfWumbCo1dMFg==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {

        if (done) {

          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  downloadStorageDocument = (doc) => {
    this.setState({
      snackbarDownloadingOpen: true,
    });

    let filename = /[^/]*$/.exec(doc.url)[0];
    let rqData = {
      container:
        doc.source === "docusign"
          ? "prbdocusign"
          : "client-" + this.props.client.id,
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.context.auth.user.id,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {

        if (done) {

          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarDownloadingOpen: false,
    });
  };

  handleCategoryMenuClick = (event) => {
    this.setState({
      anchorCategoryEl: event.currentTarget,
    });
  };

  handleCategoryMenuClose = (event) => {
    let text = event.target.innerText;
    let selected = this.state.selectedCategory;
    if (!_.isEmpty(text)) {
      if (window.$lang === "en") {
        selected = _.findIndex(
          this.state.categories,
          (c) => c.category_en === text
        );
      } else {
        selected = _.findIndex(this.state.categories, (c) =>
          c.category_es ? c.category_es === text : c.category_en === text
        );
      }
    }

    this.setState({
      anchorCategoryEl: null,
      selectedCategory: selected,
    });
  };

  handleDeleteDocumentOpen = (document) => {
    this.setState({
      deleteDocument: document,
    });
  };

  handleDialogClose = () => {
    this.setState({
      deleteDocument: {},
      notifyEmails: null,
      addNotifyEmail: "",
      addNotifyEmailDocId: null,
    });
  };

  handleOpenNotifyEmails = (docId, emails) => {
    this.setState({
      addNotifyEmailDocId: docId,
      notifyEmails: emails == null ? [] : emails.split(";"),
    });
  };

  handleAddNotifyEmail = () => {
    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!this.state.addNotifyEmail.match(re)) {
      return;
    }

    let notifyEmails = this.state.notifyEmails;
    notifyEmails.push(this.state.addNotifyEmail);
    this.setState({
      notifyEmails: notifyEmails,
      addNotifyEmail: "",
    });
  };

  handleDeleteNotifyEmail = (email) => {
    let emails = this.state.notifyEmails;
    _.remove(emails, (item) => {
      return _.isEqual(item, email);
    });
    this.setState({
      notifyEmails: emails,
    });
  };

  saveNotifyEmails = () => {
    let selectedDocId = this.state.addNotifyEmailDocId;
    let notifyEmails = this.state.notifyEmails;
    let rqBody = {
      document_id: selectedDocId,
      emails: notifyEmails,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateDocumentEmails?code=otha7tU5A0ix1cr37NwfgfFXWBk5nkYZF0fYe5Fz6ron6JaDug94rA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(rqBody),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");

        let modifiedDocs = this.state.docs;
        let tableIdx = _.findIndex(modifiedDocs, (table) => {
          return _.isEqual(
            table[0][0].category,
            this.state.categories[this.state.selectedCategory]
          );
        });
        _.forEach(modifiedDocs[tableIdx], (subtable) => {
          let foundDoc = _.find(subtable, (doc) => {
            return _.isEqual(doc.id, selectedDocId);
          });
          if (foundDoc)
            foundDoc.emails = _.isEmpty(notifyEmails)
              ? null
              : notifyEmails.join(";");
        });
        this.setState({
          docs: modifiedDocs,
        });
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  cardDeleteHandler = (e) => {
    let docs = this.state.docs;
    _.forEach(docs, (category) => {
      _.forEach(category, (table) => {
        _.remove(table, (d) => {
          return d.id == e.id;
        });
        if (_.isEmpty(table)) {
          _.remove(category, (t) => {
            return t == table;
          });
        }
      });
      if (_.isEmpty(category)) {
        _.remove(docs, (c) => {
          return c == category;
        });
      }
    });

    let customFileDocs = this.state.customFileDocs;
    _.remove(customFileDocs, (d) => {
      return d.id == e.id;
    });

    this.setState({
      docs: docs,
      customFileDocs: customFileDocs,
    });
  };

  handleCustomDirectoryClose = () => {
    this.setState({
      customDirectory: {},
      customDirectoryError: {},
      editCustomDirectory: {},
      editCustomDirectoryError: {},
    });
  };

  validCustomDirectory = () => {
    let nameEl = document.getElementById("input_customDirectory_name");

    let customDirectoryError = {};
    if (!this.state.customDirectory.category_id)
      customDirectoryError.category = true;
    if (_.isEmpty(nameEl.value)) customDirectoryError.name = true;

    this.setState({
      customDirectoryError: customDirectoryError,
    });

    return _.isEmpty(customDirectoryError);
  };

  validEditCustomDirectory = () => {
    let nameEl = document.getElementById("input_editCustomDirectory_name");

    let editCustomDirectoryError = {};
    if (_.isEmpty(nameEl.value)) editCustomDirectoryError.name = true;

    this.setState({
      editCustomDirectoryError: editCustomDirectoryError,
    });

    return _.isEmpty(editCustomDirectoryError);
  };

  handleCustomDirectoryCategoryChange = (event) => {
    let modified = this.state.customDirectory;
    let selectedValue = event.target.value;

    let workspace =
      this.props.subcategories[parseInt(selectedValue / 1000)].workspace;
    let subcategory =
      this.props.subcategories[parseInt(selectedValue / 1000)].subcategories[
        parseInt(selectedValue % 1000)
      ];

    if (workspace.workspace_id) {
      modified.category_id = workspace.workspace_id;
      modified.subcategory_id = subcategory.id;
    }

    this.setState({
      customDirectory: modified,
    });
  };

  enableCustomDirectory = () => {
    let params = this.state.customDirectory;
    params.name = document.getElementById("input_customDirectory_name").value;
    params.client_id = this.props.client.id;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertFile?code=mwIauyOLC3pjU8IwQOYXwdQkHY5qZszioxQDwnmsrlvtAzFukc_fTw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Custom directory enabled");

        this.fetchCustomDirectoryList(this.props.client);
      })
      .catch((error) => console.log(error));

    this.handleCustomDirectoryClose();
  };

  updateCustomDirectory = () => {
    let params = this.state.editCustomDirectory;
    params.name = document.getElementById(
      "input_editCustomDirectory_name"
    ).value;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateFile?code=fGyBqm_nGsaxaBsbwdOS5Wp1yRTaDTN15vKFuHJ3_RfEAzFuD-fxug==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Custom directory updated");
      })
      .catch((error) => console.log(error));

    this.handleCustomDirectoryClose();
  };

  deleteCustomDirectory = (customDirectory) => {
    customDirectory.loggedUserId = this.context.auth.user.id;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteFile?code=XxdvjNnamPbgE959qY58Vj38QxCK614jtnNfMEGhtkfyAzFunMahaA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(customDirectory),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        this.fetchCustomDirectoryList(this.props.client);
      })
      .catch((error) => console.log(error));

    //this.props.cardDeleteHandler(customDirectory);
  };

  renderSelectGroup = (workspace, idxWorkspace) => {
    // Skip administrative and court proceedings
    if (workspace.workspace.workspace_id === undefined) return;

    const { t } = this.props;

    const items = workspace.subcategories.map((subcategory, idxSubcategory) => {
      return (
        <MenuItem
          key={idxWorkspace * 1000 + idxSubcategory}
          value={idxWorkspace * 1000 + idxSubcategory}
        >
          {/* EN is required, ES is optional, default to EN */}
          {window.$lang === "en"
            ? subcategory.subcategory_en
            : subcategory.subcategory_es
            ? subcategory.subcategory_es
            : subcategory.subcategory_en}
        </MenuItem>
      );
    });

    return [
      <ListSubheader key={workspace.workspace.name_en}>
        {/* EN is required, ES is optional, default to EN */}
        {window.$lang === "en"
          ? workspace.workspace.name_en
          : workspace.workspace.name_es
          ? workspace.workspace.name_es
          : workspace.workspace.name_en}
      </ListSubheader>,
      items,
    ];
  };

  refreshDocumentTable = () => {
    this.fetchDocumentList(this.props.client);
    this.setState({ remountKey: this.state.remountKey + 1 });
  };

  render() {
    const { t } = this.props;

    if (!this.state.docs || _.isEmpty(this.state.docs)) {
      return (
        <Box sx={{ p: 4 }}>
          <CPText text={t("no_documents_assigned")} />
        </Box>
      );
    }

    return (
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
        key={this.state.remountKey}
      >
        <Box>
          <SectionTitle>{t("Document_category")}</SectionTitle>
        </Box>
        <Button
          id="category-button"
          aria-controls="category-menu"
          aria-haspopup="true"
          aria-expanded={this.state.anchorCategoryEl ? "true" : undefined}
          onClick={this.handleCategoryMenuClick}
          endIcon={<KeyboardArrowDownIcon />}
          style={{
            backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#102a46",
          }}
        >
          {/* EN is required, ES is optional, default to EN */}
          {window.$lang === "en"
            ? this.state.categories[this.state.selectedCategory].category_en
            : this.state.categories[this.state.selectedCategory].category_es
            ? this.state.categories[this.state.selectedCategory].category_es
            : this.state.categories[this.state.selectedCategory].category_en}
        </Button>
        {this.context.auth.type === "blp" && (
          <Button
            sx={{
              mb: 4,
              ml: 4,
              backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#102a46",
              borderRadius: window.$brand === "PRB" ? 0 : 5,
            }}
            variant="contained"
            onClick={() => {
              this.setState({
                customDirectory: { id: -1 },
              });
            }}
          >
            {"Enable custom directory"}
          </Button>
        )}
        <Menu
          id="category-menu"
          anchorEl={this.state.anchorCategoryEl}
          open={this.state.anchorCategoryEl}
          onClose={this.handleCategoryMenuClose}
          MenuListProps={{
            "aria-labelledby": "category-button",
          }}
        >
          {this.state.categories.map((category) => (
            <MenuItem
              key={category.category_en}
              onClick={this.handleCategoryMenuClose}
            >
              {/* EN is required, ES is optional, default to EN */}
              {window.$lang === "en"
                ? category.category_en
                : category.category_es
                ? category.category_es
                : category.category_en}
            </MenuItem>
          ))}
        </Menu>

        {this.state.categories[this.state.selectedCategory].noDocs &&
          this.state.customDirectories
            .filter((file) => {
              return (
                file.category_en ===
                this.state.categories[this.state.selectedCategory].category_en
              );
            })
            .map((file) => {
              return (
                <div key={file.name}>
                  <SubcategoryTitle>
                    {/* EN is required, ES is optional, default to EN */}
                    {window.$lang === "en"
                      ? file.subcategory_en
                      : file.subcategory_es
                      ? file.subcategory_es
                      : file.subcategory_en}
                  </SubcategoryTitle>
                  <div>
                    <CustomDirectoryTitle>{file.name}</CustomDirectoryTitle>
                    {this.context.auth.type === "blp" && (
                      <>
                        <CPButton
                          icon={<AddIcon />}
                          tooltip={t("Assign_document")}
                          color="#78BE20"
                          onClick={() => {
                            this.setState({
                              uploadCustomFile: file,
                              openUploadTypeDialog: true,
                            });
                          }}
                        />
                        <CPEditIconButton
                          onClick={() =>
                            this.setState({ editCustomDirectory: file })
                          }
                        />
                        <CPDeleteIconButton
                          onClick={() =>
                            this.setState({ deleteCustomDirectory: file })
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        {this.state.docs
          .filter((table) => {
            return (
              table[0][0].category_en ===
              this.state.categories[this.state.selectedCategory].category_en
            );
          })
          .map((table, idx) => (
            <div key={idx}>
              {table.map((subtable) => {
                return (
                  <div
                    key={subtable[0].subcategory_en}
                    className="custom-spacing"
                  >
                    <SubcategoryTitle>
                      {/* EN is required, ES is optional, default to EN */}
                      {window.$lang === "en"
                        ? subtable[0].subcategory_en
                        : subtable[0].subcategory_es
                        ? subtable[0].subcategory_es
                        : subtable[0].subcategory_en}
                    </SubcategoryTitle>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {subtable.map((row) => (
                        <Grid item xs={12} sm={8} md={4} key={row.id}>
                          <DocumentCard
                            document={row}
                            clientId={this.props.client.id}
                            cardDeleteHandler={this.cardDeleteHandler}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {this.state.customDirectories
                      .filter((file) => {
                        return (
                          file.category_en === table[0][0].category_en &&
                          file.category_es === table[0][0].category_es &&
                          file.subcategory_en === subtable[0].subcategory_en &&
                          file.subcategory_es === subtable[0].subcategory_es
                        );
                      })
                      .map((file) => (
                        <div key={file.name}>
                          <CustomDirectoryTitle>
                            {file.name}
                          </CustomDirectoryTitle>
                          {this.context.auth.type === "blp" && (
                            <>
                              <CPButton
                                icon={<AddIcon />}
                                tooltip={t("Assign_document")}
                                color="#78BE20"
                                onClick={() => {
                                  this.setState({
                                    uploadCustomFile: file,
                                    openUploadTypeDialog: true,
                                  });
                                }}
                              />
                              <CPEditIconButton
                                onClick={() =>
                                  this.setState({ editCustomDirectory: file })
                                }
                              />
                              <CPDeleteIconButton
                                onClick={() =>
                                  this.setState({
                                    deleteCustomDirectory: file,
                                  })
                                }
                              />
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                );
              })}
              {this.state.customDirectories
                .filter((file) => {
                  return (
                    file.category_en ===
                      this.state.categories[this.state.selectedCategory]
                        .category_en &&
                    !table
                      .map((st) => st[0].subcategory_en)
                      .includes(file.subcategory_en)
                  );
                })
                .map((file) => {
                  return (
                    <div key={file.name}>
                      <SubcategoryTitle>
                        {/* EN is required, ES is optional, default to EN */}
                        {window.$lang === "en"
                          ? file.subcategory_en
                          : file.subcategory_es
                          ? file.subcategory_es
                          : file.subcategory_en}
                      </SubcategoryTitle>
                      <div>
                        <CustomDirectoryTitle>{file.name}</CustomDirectoryTitle>

                        {this.context.auth.type === "blp" && (
                          <>
                            <CPButton
                              icon={<AddIcon />}
                              tooltip={t("Assign_document")}
                              color="#78BE20"
                              onClick={() => {
                                this.setState({
                                  uploadCustomFile: file,
                                  openUploadTypeDialog: true,
                                });
                              }}
                            />
                            <CPEditIconButton
                              onClick={() =>
                                this.setState({ editCustomDirectory: file })
                              }
                            />
                            <CPDeleteIconButton
                              onClick={() =>
                                this.setState({ deleteCustomDirectory: file })
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          ))}
        <Dialog
          open={!_.isEmpty(this.state.customDirectory)}
          onClose={this.handleCustomDirectoryClose}
        >
          <DialogTitle>{"Enable custom directory"}</DialogTitle>
          <DialogContent>
            <FormControl
              sx={{ m: 1, minWidth: 250 }}
              error={this.state.customDirectoryError.category}
            >
              <InputLabel htmlFor="grouped-select">{t("Category")}</InputLabel>
              <Select
                defaultValue=""
                id="grouped-select"
                label="Grouping"
                onChange={this.handleCustomDirectoryCategoryChange}
              >
                {this.props.subcategories?.map((workspace, idx) =>
                  this.renderSelectGroup(workspace, idx)
                )}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="input_customDirectory_name"
                label={t("Name")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 40 }}
                error={this.state.customDirectoryError.name}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCustomDirectoryClose}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                if (this.validCustomDirectory()) this.enableCustomDirectory();
              }}
            >
              {t("Enable")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.editCustomDirectory)}
          onClose={this.handleCustomDirectoryClose}
        >
          <DialogTitle>{"Edit custom directory"}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="input_editCustomDirectory_name"
                label={t("Name")}
                type="text"
                fullWidth
                variant="standard"
                defaultValue={this.state.editCustomDirectory.name}
                inputProps={{ maxLength: 40 }}
                error={this.state.editCustomDirectoryError.name}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCustomDirectoryClose}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                if (this.validEditCustomDirectory())
                  this.updateCustomDirectory();
              }}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <DeleteDialog
          item={this.state.deleteCustomDirectory}
          title={t("delete_custom_directory")}
          caption={t("delete_custom_directory_full_caption")}
          targetFunc={this.deleteCustomDirectory}
          handleDialogClose={() => this.setState({ deleteCustomDirectory: {} })}
        />

        <UploadTypeDialog
          data={{
            open: this.state.openUploadTypeDialog,
            subcategories: this.props.subcategories,
            selectedClientId: this.props.client.id,
            preselectedCustomFile: this.state.uploadCustomFile,
          }}
          handleDialogClose={() =>
            this.setState({ openUploadTypeDialog: false })
          }
          refreshFunc={this.refreshDocumentTable}
        />

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarDownloadingOpen}
          onClose={this.handleSnackbarClose}
          key={"bottomcentererror"}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="info"
            icon={<HourglassTopIcon />}
          >
            {t("downloading_document")}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withTranslation()(DocumentTable);
