import React from "react";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ImageViewer from "react-simple-image-viewer";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CPButton from "../../Utils/CPButton";
import DeleteDialog from "../../DeleteDialog";
import { withTranslation } from "react-i18next";
import BlockUi from "react-block-ui";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import { getPredictYourClosingEnabled } from "../../../services/closing";
import { PropTypes } from "prop-types";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPTip from "../../Utils/CPTip";

class PredictYourClosing extends React.Component {
  // Pagina de Predict Your Closing para procesos de compra de propiedades
  constructor() {
    super();

    this.state = {
      client: null,
      steps: [],
      docs: [],
      projects: [],
      selectedProject: null,
      selectedStep: "",
      uploadTypeRadioSelection: "",
      upload: {},
      docuware: {},
      docuwareResults: [],
      selectedDocuwareDoc: {},
      uploadDocuware: {},
      uploadError: {},
      isViewerOpen: false,
      viewerImages: [],
      blockingDialog: false,
      inputFile: null,
      valueVencimientoUpload: undefined,
      valueVencimientoDocuware: undefined,
      autocomplete: {
        CLIENTE: [],
        C_DULA_JUR_DICA: [],
        IDENTIFICACION: [],
      },
      selectedCabinet: "libros",
      snackbarDownloadingOpen: false,
      deleteDocument: {},
    };
  }

  static contextType = authContext;

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState(
        {
          client: this.props.location.state.client,
        },
        () => {
          this.fetchProjects();
        }
      );
    } else {
      this.fetchClientOrPersonByUser();
    }
  }

  fetchClientOrPersonByUser = () => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/GetClientOrPersonByUser?code=kqDgy6GbOa8BuRiZlmMWlbyeZuqLOYm3XEGw0bBYz9QqrzSa5sVpeA==&userId=" +
        this.context.auth.user.id
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState(
          {
            client: data,
          },
          () => this.fetchProjects()
        );
      });
  };

  toggleComplete = (complete, step) => {
    let params = {
      clientId: this.state.client.id,
      projectName: this.state.selectedProject.project_name,
      value: complete ? "1" : "0",
      step: step,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/TogglePredictYourClosingComplete?code=uN9t3EyNiyWrj9ck0xKbHbB6HzzPAJwZZHM2m2V6dCcwAzFuWb_taQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Step complete");

        let steps = this.state.steps;
        _.find(steps, (s) => s.code === step).complete = complete;
        this.setState({ steps: steps });
      });
  };

  updateSteps = () => {
    if (!this.state.selectedProject) return;

    let steps = [];
    if (this.state.selectedProject.letter_of_intent_enabled) {
      steps.push({
        label: "Letter of Intent",
        code: "letter_of_intent_complete",
        complete: this.state.selectedProject.letter_of_intent_complete,
      });
    }
    if (this.state.selectedProject.right_to_purchase_enabled) {
      steps.push({
        label: "Right to Purchase & Sale Agreement",
        code: "right_to_purchase_complete",
        complete: this.state.selectedProject.right_to_purchase_complete,
      });
    }
    if (this.state.selectedProject.escrow_agreement_enabled) {
      steps.push({
        label: "Escrow Agreement",
        code: "escrow_agreement_complete",
        complete: this.state.selectedProject.escrow_agreement_complete,
      });
    }
    if (this.state.selectedProject.due_diligence_enabled) {
      steps.push({
        label: "Due Diligence",
        code: "due_diligence_complete",
        complete: this.state.selectedProject.due_diligence_complete,
      });
    }
    if (this.state.selectedProject.closing_conditions_enabled) {
      steps.push({
        label: "Closing Conditions Confirmation",
        code: "closing_conditions_complete",
        complete: this.state.selectedProject.closing_conditions_complete,
      });
    }
    if (this.state.selectedProject.closing_enabled) {
      steps.push({
        label: "Closing",
        code: "closing_complete",
        complete: this.state.selectedProject.closing_complete,
      });
    }
    if (this.state.selectedProject.postclosing_enabled) {
      steps.push({
        label: "Postclosing",
        code: "postclosing_complete",
        complete: this.state.selectedProject.postclosing_complete,
      });
    }
    this.setState({
      steps: steps,
    });
  };

  fetchProjects = async () => {
    let projects = await getPredictYourClosingEnabled(this.state.client.id);
    this.setState(
      {
        projects: projects,
        selectedProject: _.last(projects),
      },
      () => {
        this.updateSteps();
        this.fetchDocuments();
      }
    );
  };

  handleSelectProjectChange = (event) => {
    this.setState(
      {
        selectedProject: _.find(
          this.state.projects,
          (project) => project.id === event.target.value
        ),
      },
      () => {
        this.updateSteps();
        this.fetchDocuments();
      }
    );
  };

  fetchDocuments() {
    if (!this.state.selectedProject) return;

    let params = {
      clientId: this.state.client.id,
      projectId: this.state.selectedProject.id,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListRealEstateDocumentsByClient?code=5O5sDSFe_X6w2RBogSN9R7DTmk49Jmf-72HHl8RCLJLQAzFu7J1fVA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          docs: data,
        });
      });
  }

  handleUploadOpen = (file) => {
    this.setState({
      upload: file,
    });
  };

  handleUploadClose = () => {
    this.setState({
      upload: {},
      uploadError: {},
    });
  };

  handleDocuwareOpen = (file) => {
    this.setState({
      docuware: file,
    });
  };

  handleDocuwareClose = () => {
    this.setState({
      docuware: {},
      docuwareResults: [],
      selectedDocuwareDoc: {},
      uploadDocuware: {},
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarDownloadingOpen: false,
    });
  };

  handleDeleteDocumentOpen = (doc) => {
    this.setState({
      deleteDocument: doc,
    });
  };

  handleDialogClose = () => {
    this.setState({
      deleteDocument: {},
    });
  };

  handleRadioDocuwareChange = (id, cabinet, extension) => {
    this.setState({
      selectedDocuwareDoc: {
        id: id,
        cabinet: cabinet,
        extension: extension,
      },
    });
  };

  handleAddDocuwareCabinetChange = (event) => {
    let modified = this.state.docuware;
    modified.cabinet = event.target.value;
    this.setState({
      docuware: modified,
      selectedCabinet: event.target.value,
    });
  };

  validUpload = () => {
    let filenameEl = document.getElementById("input_upload_filename");
    let fileEl = document.getElementById("input_upload_file");

    let uploadError = {};
    if (_.isEmpty(this.state.upload.category_en)) uploadError.category = true;
    if (_.isEmpty(fileEl.value)) uploadError.file = true;
    if (_.isEmpty(filenameEl.value)) uploadError.filename = true;

    if (
      window.$brand === "BLP" &&
      this.state.selectedFile &&
      this.state.selectedFile.type !== "application/pdf"
    ) {
      uploadError.notpdf = true;
    }

    this.setState({
      uploadError: uploadError,
    });

    return _.isEmpty(uploadError);
  };

  onFileChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  deleteDocument = (doc) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteDocument?code=maatAGlqaso51CmMTzUHx6ewyogzQkA/hFpTTKdoe3QokjZOgAI3Hg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(doc),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        this.fetchDocuments();
      })
      .catch((error) => console.log(error));
  };

  uploadFile = () => {
    let formData = new FormData();
    formData.append("file", this.state.selectedFile);
    formData.append("clientId", this.state.client.id);

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UploadFileToStorage?code=ZDKuIDH83i4Yd5Z1BloqtKL8C2kKUWIfhba0b5OA5CnacQhh18OXjA==";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Upload successful");

        this.setState({
          selectedFile: null,
        });

        let uploadData = this.state.upload;
        uploadData.url = data.url;
        let extension = data.url.substring(data.url.lastIndexOf("."));
        uploadData.extension = extension;
        this.insertDocumentToDB(uploadData);
      })
      .catch((error) => console.log(error));
  };

  insertDocumentToDB = (uploadData) => {
    uploadData.filename = document.getElementById(
      "input_upload_filename"
    ).value;
    uploadData.notes = document.getElementById("input_upload_notes").value;
    uploadData.visible = document.getElementById("input_upload_visible_cb")
      .checked
      ? "1"
      : "0";
    uploadData.closingProject = this.state.selectedProject.id;

    if (document.getElementById("input_upload_expiration_cb").checked) {
      let month = this.state.valueVencimientoUpload.getMonth() + 1;
      let monthStr = month < 10 ? `0${month}` : month;
      let day = this.state.valueVencimientoUpload.getDate();
      let dayStr = day < 10 ? `0${day}` : day;
      uploadData.expiration = `${this.state.valueVencimientoUpload.getFullYear()}-${monthStr}-${dayStr}`;
    }

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertDocument?code=74hsURB0abOx7ak88UW/Y/Q2TPDe1QuNunuduLXWfq2SB6J/orepdw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(uploadData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert document successful");

        this.fetchDocuments();
      })
      .catch((error) => console.log(error));
    this.handleUploadClose();
  };

  searchDocuware = () => {
    this.setState({
      blockingDialog: true,
    });

    let docuwareData = {};
    docuwareData.cabinet = this.state.selectedCabinet;
    if (document.getElementById("input_docuware_cliente").value)
      docuwareData.client = document.getElementById(
        "input_docuware_cliente"
      ).value;
    if (document.getElementById("input_docuware_cedula_juridica").value)
      docuwareData.license = document.getElementById(
        "input_docuware_cedula_juridica"
      ).value;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/SearchDocuware?code=7B6gNwZdWH3Im8GwMNB4yqLGEMxSrkMrg9Ao3rQPCZmmsct/RhswVQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(docuwareData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Search successful");

        let results = [];
        data.rows.forEach((row) => {
          let document = {};
          let idIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "DWDOCID";
          });
          document.id = row.items[idIdx];
          let clientIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "CLIENTE";
          });
          document.client = row.items[clientIdx];
          let caseIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "CASO";
          });
          document.case = row.items[caseIdx];
          let razonSocialIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "RAZ_N_SOCIAL";
          });
          document.razonSocial = row.items[razonSocialIdx];
          let expedienteIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "EXPEDIENTE";
          });
          document.expediente = row.items[expedienteIdx];
          let ubicacionIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "UBICACI_N_F_SICA";
          });
          document.ubicacion = row.items[ubicacionIdx];
          let remitidoPorIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "REMITIDO_POR";
          });
          document.remitidoPor = row.items[remitidoPorIdx];
          let sectionCountIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "DWSECTIONCOUNT";
          });
          let sectionCount = row.items[sectionCountIdx];
          let extensionIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "DWEXTENSION";
          });
          document.extension =
            parseInt(sectionCount) > 1 ? ".zip" : row.items[extensionIdx];
          let typeIdx = _.findIndex(data.headers, (header) => {
            return (
              header.fieldName ===
              (docuwareData.cabinet === "libros"
                ? "TIPO_LIBRO"
                : "TIPO_DOCUMENTAL")
            );
          });
          document.type = row.items[typeIdx];
          let dateIdx = _.findIndex(data.headers, (header) => {
            return (
              header.fieldName ===
              (docuwareData.cabinet === "libros"
                ? "FECHA_DOCUMENTO"
                : "FECHA_DEL_DOCUMENTO")
            );
          });
          document.date = row.items[dateIdx];
          let nameIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "NOMBRE_DOCUMENTO";
          });
          document.name = row.items[nameIdx];
          document.cabinet = docuwareData.cabinet;
          results.push(document);
        });

        this.setState({
          docuwareResults: results,
          blockingDialog: false,
        });
      })
      .catch((error) => console.log(error));
    this.handleUploadClose();
  };

  selectDocuware = () => {
    if (!_.isEmpty(this.state.selectedDocuwareDoc)) {
      this.setState({
        uploadDocuware: {
          source: this.state.docuware.source,
          assignedBy: this.state.docuware.assignedBy,
          assignedTo: this.state.docuware.assignedTo,
          category_en: this.state.docuware.category_en,
          subcategory_en: this.state.docuware.subcategory_en,
          extension: this.state.selectedDocuwareDoc.extension,
          url:
            this.state.selectedDocuwareDoc.cabinet +
            "_" +
            this.state.selectedDocuwareDoc.id,
        },
      });
    }
  };

  insertDocumentToDBDocuware = () => {
    let uploadData = this.state.uploadDocuware;
    uploadData.filename = document.getElementById(
      "input_upload_docuware_filename"
    ).value;
    uploadData.notes = document.getElementById(
      "input_upload_docuware_notes"
    ).value;
    uploadData.visible = document.getElementById("input_docuware_visible_cb")
      .checked
      ? "1"
      : "0";
    uploadData.closingProject = this.state.selectedProject.id;

    if (
      document.getElementById("input_upload_docuware_expiration_cb").checked
    ) {
      let month = this.state.valueVencimientoDocuware.getMonth() + 1;
      let monthStr = month < 10 ? `0${month}` : month;
      let day = this.state.valueVencimientoDocuware.getDate();
      let dayStr = day < 10 ? `0${day}` : day;
      uploadData.expiration = `${this.state.valueVencimientoDocuware.getFullYear()}-${monthStr}-${dayStr}`;
    }

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertDocument?code=74hsURB0abOx7ak88UW/Y/Q2TPDe1QuNunuduLXWfq2SB6J/orepdw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(uploadData),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert document successful");

        this.fetchDocuments();
      })
      .catch((error) => console.log(error));
    this.handleDocuwareClose();
  };

  previewDocument = (id, cabinet) => {
    this.setState({
      blockingDialog: true,
    });

    let rqData = {
      id: id,
      cabinet: cabinet,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetDocuwareLowQualityImage?code=hFthtkMKEC3PyY03W9kA0X84xDWWyI7HSXzf83hoNS/HFpeCy3GssA==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {

        if (done) {

          const blob = new Blob([chunks], { type: "image/png" });

          //window.open(URL.createObjectURL(blob));
          parentComponentInReadClientModal.openImageViewer(
            URL.createObjectURL(blob)
          );
          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });

      this.setState({
        blockingDialog: false,
      });
    })();
  };

  changeAutocomplete = (event, fieldName) => {
    let params = {
      cabinet: this.state.selectedCabinet,
      fieldName: fieldName,
      valuePrefix: event.target.value,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetDocuwareOptions?code=yhp507aBXmHmamGwBRbOGo8A/eQwtyxQw7xi4zRp/CRY0drq4KWkRw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        let autocomplete = this.state.autocomplete;
        autocomplete[fieldName] = data.value;
        this.setState({
          autocomplete: autocomplete,
        });
      })
      .catch((error) => console.log(error));
  };

  openImageViewer = (image) => {
    this.setState({
      viewerImages: [image],
      isViewerOpen: true,
    });
  };

  formatDate = (dateStr) => {
    if (!dateStr) return "";

    let d = new Date(dateStr);

    const { t } = this.props;
    const months = [
      t("m_jan"),
      "feb",
      "mar",
      t("m_apr"),
      "may",
      "jun",
      "jul",
      t("m_aug"),
      t("m_sep"),
      "oct",
      "nov",
      t("m_dec"),
    ];

    return `${d.getDate()}-${months[d.getMonth()]}-${d.getFullYear()}`;
  };

  downloadDocuwareDocument2 = (doc) => {
    this.setState({
      snackbarDownloadingOpen: true,
    });

    let rqData = {
      url: doc.url,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadDocuware2?code=QqEqj/xEWeIym9ZtLILp8gZ9UJSq9AoK0zHgvW53aGfWumbCo1dMFg==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {

        if (done) {

          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  downloadStorageDocument = (doc) => {
    this.setState({
      snackbarDownloadingOpen: true,
    });

    let filename = /[^/]*$/.exec(doc.url)[0];
    let rqData = {
      container:
        doc.source === "docusign"
          ? "prbdocusign"
          : "client-" + this.state.client.id,
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.context.auth.user.id,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {

        if (done) {

          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  allStepsCompleted = () => {
    return Object.keys(this.state.completed).length === this.state.steps.length;
  };

  calculateProgress = () => {
    return (
      (Object.keys(this.state.completed).length / this.state.steps.length) * 100
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Container
          component={Paper}
          sx={{ p: 2, mt: 4 }}
          style={{ maxWidth: 600 }}
        >
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Box
              component="img"
              alignItems="center"
              sx={{
                width: 120,
              }}
              alt="Predict Your Closing Logo"
              src={"/assets/PredictYourClosing.png"}
            />
            <CPTip
              text={
                "Keep track of your closing projects and access documents for each step along the way"
              }
            />
            <FormControl sx={{ mt: 6, width: "100%", textAlign: "left" }}>
              <InputLabel id="select_project_label">Projects</InputLabel>
              <Select
                labelId="select_project_label"
                id="select_project"
                label="Project"
                onChange={this.handleSelectProjectChange}
                value={
                  !_.isEmpty(this.state.selectedProject)
                    ? this.state.selectedProject.id
                    : ""
                }
              >
                {this.state.projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.project_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Container>
        {this.state.steps.map((step, index) => (
          <Container
            component={Paper}
            sx={{ p: 2, mt: 4 }}
            style={{ maxWidth: 600 }}
          >
            <Box sx={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Tooltip title={step.complete ? "Done" : "Pending"}>
                    <Avatar
                      sx={{
                        color: "#555",
                        backgroundColor: step.complete ? "#c5e1a5" : "#fff59d",
                        cursor:
                          this.context.auth.type === "blp"
                            ? "pointer"
                            : "default",
                      }}
                      onClick={() => {
                        if (this.context.auth.type === "blp") {
                          this.toggleComplete(!step.complete, step.code);
                        }
                      }}
                    >
                      {index + 1}
                    </Avatar>
                  </Tooltip>
                </Grid>
                {this.context.auth.type === "blp" && (
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Tooltip title={t("Assign_document")}>
                      <Avatar
                        sx={{
                          color: "#fff",
                          backgroundColor:
                            window.$brand === "PRB" ? "#17afb6" : "#78BE20",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.setState({
                            uploadTypeRadioSelection: "local",
                            selectedStep: step.label,
                          });
                        }}
                      >
                        <AddIcon />
                      </Avatar>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <SectionTitle>{step.label}</SectionTitle>
            </Box>
            <Box sx={{ width: "100%" }}>
              <List dense>
                {this.state.docs
                  .filter((doc) => doc.subcategory_en === step.label)
                  .map((doc) => (
                    <>
                      <Divider sx={{ m: 1 }} />
                      <ListItem
                        key={doc.id}
                        secondaryAction={
                          <>
                            {doc.source === "docuware" ? (
                              <CPButton
                                onClick={() => {
                                  this.downloadDocuwareDocument2(doc);
                                }}
                                icon={<FileDownloadOutlinedIcon />}
                                tooltip={t("Download")}
                              />
                            ) : (
                              <CPButton
                                onClick={() => {
                                  this.downloadStorageDocument(doc);
                                }}
                                icon={<FileDownloadOutlinedIcon />}
                                tooltip={t("Download")}
                              />
                            )}
                            {this.context.auth.type === "blp" && (
                              <CPDeleteIconButton
                                onClick={() =>
                                  this.handleDeleteDocumentOpen(doc)
                                }
                              />
                            )}
                          </>
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemText primary={doc.filename} />
                        </ListItemButton>
                      </ListItem>
                    </>
                  ))}
              </List>
            </Box>
          </Container>
        ))}

        <input
          id="fileInput"
          type="file"
          ref={(ref) => (this.state.inputFile = ref)}
          style={{ display: "none" }}
          {...this.fileinputAcceptProps}
          onChange={this.onFileChange}
        ></input>
        <Dialog
          open={!_.isEmpty(this.state.uploadTypeRadioSelection)}
          onClose={() =>
            this.setState({
              uploadTypeRadioSelection: "",
            })
          }
        >
          <DialogTitle>Asignar documento</DialogTitle>
          <DialogContent>
            <RadioGroup
              value={this.state.uploadTypeRadioSelection}
              name="radio-buttons-group"
              onChange={(e) =>
                this.setState({
                  uploadTypeRadioSelection: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="local"
                control={<Radio />}
                label={
                  <>
                    <strong>Documento local</strong>: elegir un documento de su
                    máquina local para asignar al cliente.
                  </>
                }
              />
              <FormControlLabel
                value="docuware"
                control={<Radio />}
                label={
                  <>
                    <strong>DocuWare</strong>: buscar un documento en el sistema
                    de Archivo de BLP para asignar al cliente.
                  </>
                }
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({
                  uploadTypeRadioSelection: "",
                })
              }
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  uploadTypeRadioSelection: "",
                });
                this.state.uploadTypeRadioSelection === "local"
                  ? this.handleUploadOpen({
                      source: "2",
                      assignedBy: this.context.auth.user.id,
                      assignedTo: this.state.client.id,
                      category_en: "Real Estate",
                      subcategory_en: this.state.selectedStep,
                    })
                  : this.handleDocuwareOpen({
                      source: "1",
                      assignedBy: this.context.auth.user.id,
                      assignedTo: this.state.client.id,
                      category_en: "Real Estate",
                      subcategory_en: this.state.selectedStep,
                    });
              }}
            >
              Continuar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.upload)}
          onClose={this.handleUploadClose}
        >
          <DialogTitle>{t("upload_document")}</DialogTitle>
          <DialogContent>
            <FormControl sx={{ m: 1, minWidth: 250 }}>
              <InputLabel htmlFor="grouped-select">{t("Category")}</InputLabel>
              <Select
                defaultValue="1"
                id="grouped-select"
                label="Grouping"
                disabled
              >
                <MenuItem value={1}>{t(this.state.selectedStep)}</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <FileUploadIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input_upload_file"
                label={window.$brand === "BLP" ? t("PDFFile") : t("File")}
                variant="standard"
                onClick={() => this.state.inputFile.click()}
                disabled
                value={
                  this.state.selectedFile ? this.state.selectedFile.name : ""
                }
                error={
                  this.state.uploadError.file || this.state.uploadError.notpdf
                }
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="input_upload_filename"
                label={t("Filename")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 100 }}
                error={this.state.uploadError.filename}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="input_upload_notes"
                multiline
                label={t("Notes")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 255 }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3 }}>
              <Checkbox id="input_upload_expiration_cb"></Checkbox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Expires")}
                  value={this.state.valueVencimientoUpload}
                  onChange={(newValue) => {
                    this.setState({
                      valueVencimientoUpload: newValue.$d,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3, ml: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked id="input_upload_visible_cb" />
                }
                label="Visible for clients"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleUploadClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => {
                if (this.validUpload()) this.uploadFile();
              }}
            >
              {t("Upload")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.docuware)}
          onClose={this.handleDocuwareClose}
        >
          {!_.isEmpty(this.state.uploadDocuware) ? (
            <>
              <DialogTitle>{t("upload_document")}</DialogTitle>
              <DialogContent>
                <FormControl sx={{ m: 1, minWidth: 250 }}>
                  <InputLabel htmlFor="grouped-select">
                    {t("Category")}
                  </InputLabel>
                  <Select
                    defaultValue="1"
                    id="grouped-select-docuware"
                    label="Grouping"
                    disabled
                  >
                    <MenuItem value={1}>{t(this.state.selectedStep)}</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    required
                    id="input_upload_docuware_filename"
                    label={t("Filename")}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="input_upload_docuware_notes"
                    multiline
                    label={t("Notes")}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ maxLength: 255 }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3 }}>
                  <Checkbox id="input_upload_docuware_expiration_cb"></Checkbox>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label={t("Expires")}
                      value={this.state.valueVencimientoDocuware}
                      onChange={(newValue) => {
                        this.setState({
                          valueVencimientoDocuware: newValue.$d,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "flex-end", pt: 3, ml: 1 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox defaultChecked id="input_docuware_visible_cb" />
                    }
                    label="Visible for clients"
                  />
                </Box>
              </DialogContent>
            </>
          ) : _.isEmpty(this.state.docuwareResults) ? (
            <>
              <DialogTitle>{t("search_document")}</DialogTitle>
              <DialogContent>
                <BlockUi tag="div" blocking={this.state.blockingDialog}>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="input_docuware_cabinet_label">
                      {t("Document_type")}
                    </InputLabel>
                    <Select
                      labelId="input_docuware_cabinet_label"
                      id="input_docuware_cabinet"
                      defaultValue={"libros"}
                      label={t("Document_type")}
                      required
                      onChange={this.handleAddDocuwareCabinetChange}
                    >
                      <MenuItem value={"libros"}>Libros</MenuItem>
                      <MenuItem value={"expedientes"}>Expedientes</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Autocomplete
                      freeSolo
                      id="input_docuware_cliente"
                      options={this.state.autocomplete["CLIENTE"]}
                      fullWidth
                      variant="standard"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Client")}
                          variant="standard"
                          onChange={(e) =>
                            this.changeAutocomplete(e, "CLIENTE")
                          }
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Autocomplete
                      freeSolo
                      id="input_docuware_cedula_juridica"
                      options={
                        this.state.autocomplete[
                          this.state.selectedCabinet === "libros"
                            ? "C_DULA_JUR_DICA"
                            : "IDENTIFICACION"
                        ]
                      }
                      fullWidth
                      variant="standard"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Cédula jurídica"}
                          variant="standard"
                          onChange={(e) =>
                            this.changeAutocomplete(
                              e,
                              this.state.selectedCabinet === "libros"
                                ? "C_DULA_JUR_DICA"
                                : "IDENTIFICACION"
                            )
                          }
                        />
                      )}
                    />
                  </Box>
                </BlockUi>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle>{t("select_document")}</DialogTitle>
              <DialogContent>
                <BlockUi tag="div" blocking={this.state.blockingDialog}>
                  {this.state.docuwareResults.map(
                    (row) =>
                      row.client !== "BLP ABOGADOS" && (
                        <Container sx={{ p: 2, minWidth: 400 }}>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                          >
                            <Radio
                              value={row.id}
                              name="radio-documents"
                              checked={
                                this.state.selectedDocuwareDoc.id === row.id
                              }
                              onChange={() => {
                                this.handleRadioDocuwareChange(
                                  row.id,
                                  row.cabinet,
                                  row.extension
                                );
                              }}
                            />
                            <Paper
                              elevation={3}
                              sx={{ p: 2 }}
                              className="docuwareItem"
                            >
                              <Typography variant="subtitle2">
                                <strong>{t("Client")}: </strong>
                                {row.client}
                              </Typography>
                              <Typography variant="subtitle2">
                                <strong>{t("Case")}: </strong>
                                {row.case}
                              </Typography>
                              {row.razonSocial && (
                                <Typography variant="subtitle2">
                                  <strong>{"Razón social"}: </strong>
                                  {row.razonSocial}
                                </Typography>
                              )}
                              {row.expediente && (
                                <Typography variant="subtitle2">
                                  <strong>{"Expediente"}: </strong>
                                  {row.expediente}
                                </Typography>
                              )}
                              <Typography variant="subtitle2">
                                <strong>{t("Type")}: </strong>
                                {row.type}
                              </Typography>
                              <Typography variant="subtitle2">
                                <strong>{t("Document")}: </strong>
                                {row.name}
                              </Typography>
                              <Typography variant="subtitle2">
                                <strong>{t("Date")}: </strong>
                                {this.formatDate(row.date)}
                              </Typography>
                              <Typography variant="subtitle2">
                                <strong>{"Remitido por"}: </strong>
                                {row.remitidoPor}
                              </Typography>
                              {row.ubicacion && (
                                <Typography variant="subtitle2">
                                  <strong>{"Ubicacion Física"}: </strong>
                                  {row.ubicacion}
                                </Typography>
                              )}
                              <CPButton
                                onClick={() =>
                                  this.previewDocument(row.id, row.cabinet)
                                }
                                icon={<RemoveRedEyeIcon />}
                                tooltip={t("Preview")}
                              />
                            </Paper>
                          </Grid>
                        </Container>
                      )
                  )}
                </BlockUi>
              </DialogContent>
            </>
          )}
          <DialogActions>
            <Button onClick={this.handleDocuwareClose}>{t("Cancel")}</Button>
            {!_.isEmpty(this.state.uploadDocuware) ? (
              <Button onClick={() => this.insertDocumentToDBDocuware()}>
                {t("Upload")}
              </Button>
            ) : _.isEmpty(this.state.docuwareResults) ? (
              <Button onClick={() => this.searchDocuware()}>
                {t("Search")}
              </Button>
            ) : (
              <Button onClick={() => this.selectDocuware()}>
                {t("Select")}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <DeleteDialog
          item={this.state.deleteDocument}
          title={t("delete_document_title")}
          caption={t("delete_document_caption")}
          targetFunc={this.deleteDocument}
          handleDialogClose={this.handleDialogClose}
        />
        {this.state.isViewerOpen && (
          <ImageViewer
            src={this.state.viewerImages}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={() =>
              this.setState({
                isViewerOpen: false,
              })
            }
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarDownloadingOpen}
          onClose={this.handleSnackbarClose}
          key={"bottomcentererror"}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="info"
            icon={<HourglassTopIcon />}
          >
            {t("downloading_document")}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withTranslation()(PredictYourClosing);
