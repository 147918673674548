import React from "react";
import Container from "@mui/material/Container";
import _ from "lodash";
import { Paper, Tab } from "@mui/material";
import { Button } from "@mui/material";
import Title from "../../Title";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { withTranslation } from "react-i18next";
import { authContext } from "../../../contexts/AuthContext";
import {
  LineChart,
  Tooltip,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
} from "recharts";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

class MyMetrics extends React.Component {
  // Pagina de metricas de uso de herramientas varias 
  // cada miembro del equipo PRB puede consultar
  constructor() {
    super();

    let d = new Date();
    this.state = {
      valueDesde: new Date(d.getFullYear() - 1, d.getMonth()),
      valueHasta: new Date(),
      valueOpcionDocumentos: "Todos",
      valueOpcionClausulas: "Todos",
      valueOpcionRevision: "Todos",
      valueOpcionUploads: "Todos",
      dataDocumentos: [],
      filteredDataDocumentos: [],
      dataClausulas: [],
      filteredDataClausulas: [],
      dataRevision: [],
      filteredDataRevision: [],
      dataUploads: [],
      filteredDataUploads: [],
      filteredBarDataUploads: [],
      selectedTabUploads: "1",
    };
  }

  static contextType = authContext;

  async componentDidMount() {
    let dataDocumentos = await this.fetchDetails("Documentos");
    let dataClausulas = await this.fetchDetails("Cláusulas");
    let dataRevision = await this.fetchDetails("Revisión");
    let dataUploads = await this.fetchUploadDetails();

    this.setState(
      {
        dataDocumentos: dataDocumentos,
        dataClausulas: dataClausulas,
        dataRevision: dataRevision,
        dataUploads: dataUploads,
      },
      () => this.updateFilteredData()
    );
  }

  fetchDetails = async (title) => {
    let params = {
      title: title,
      email: this.context.auth.user.email,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetPRBMetricsByUserEmail?code=bbIwTB-xRR3mRQB7ILVgAl9su9wGvXcb9T8LtJ-_tgspAzFut_Z47Q==";

    let result;
    await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        result = data;
      });
    return result;
  };

  fetchUploadDetails = async () => {
    let params = {
      userId: this.context.auth.user.id,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetPRBUploadMetricsByUserId?code=edyiKvKnipp0QCfwQ2oFfcmsbhDTLsh24HXyKOu6hn7TAzFugB-Ayw==";

    let result;
    await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        result = data;
      });
    return result;
  };

  onChangeFromDate = (newValue) => {
    this.setState(
      {
        valueDesde: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  onChangeToDate = (newValue) => {
    this.setState(
      {
        valueHasta: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  onChangeOpcionDocumentos = (newValue) => {
    this.setState(
      {
        valueOpcionDocumentos: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  onChangeOpcionClausulas = (newValue) => {
    this.setState(
      {
        valueOpcionClausulas: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  onChangeOpcionRevision = (newValue) => {
    this.setState(
      {
        valueOpcionRevision: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  onChangeOpcionUploads = (newValue) => {
    this.setState(
      {
        valueOpcionUploads: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  getFormattedMonth = (month) => {
    const { t } = this.props;
    const months = [
      t("m_Jan"),
      "Feb",
      "Mar",
      t("m_Apr"),
      "May",
      "Jun",
      "Jul",
      t("m_Aug"),
      t("m_Sep"),
      "Oct",
      "Nov",
      t("m_Dec"),
    ];

    return months[month];
  };

  colors = ["#17afb6", "#c6053c", "#6471ba", "#ffae02", "#0554f2", "#f24405"];

  updateFilteredData = () => {
    let y = this.state.valueDesde.getFullYear();
    let m = this.state.valueDesde.getMonth();
    let dateKey = y + "-" + (m + 1 < 10 ? "0" : "") + (m + 1);

    let resultDocumentos = [];
    let resultClausulas = [];
    let resultRevision = [];
    let resultUploads = [];
    let resultBarUploads = [];

    if (this.state.valueDesde <= this.state.valueHasta) {
      while (
        y < this.state.valueHasta.getFullYear() ||
        (y === this.state.valueHasta.getFullYear() &&
          m <= this.state.valueHasta.getMonth())
      ) {
        const constDateKey = dateKey;

        // Start Documentos
        if (this.state.valueOpcionDocumentos === "Todos") {
          let stepCounts = _.filter(this.state.dataDocumentos, (row) => {
            return row.mo === constDateKey;
          });
          let stepSum = _.sumBy(stepCounts, (row) => {
            return row.count;
          });

          resultDocumentos.push({
            month: this.getFormattedMonth(m),
            count: stepSum,
          });
        } else {
          let available = _.filter(this.state.dataDocumentos, (row) => {
            return (
              row.mo === constDateKey &&
              row.subtitle === this.state.valueOpcionDocumentos
            );
          });

          resultDocumentos.push({
            month: this.getFormattedMonth(m),
            count: !_.isEmpty(available) ? available[0].count : 0,
          });
        }
        // End Documentos

        // Start Clausulas
        if (this.state.valueOpcionClausulas === "Todos") {
          let stepCounts = _.filter(this.state.dataClausulas, (row) => {
            return row.mo === constDateKey;
          });
          let stepSum = _.sumBy(stepCounts, (row) => {
            return row.count;
          });

          resultClausulas.push({
            month: this.getFormattedMonth(m),
            count: stepSum,
          });
        } else {
          let available = _.filter(this.state.dataClausulas, (row) => {
            return (
              row.mo === constDateKey &&
              row.subtitle === this.state.valueOpcionClausulas
            );
          });

          resultClausulas.push({
            month: this.getFormattedMonth(m),
            count: !_.isEmpty(available) ? available[0].count : 0,
          });
        }
        // End Clausulas

        // Start Revision
        if (this.state.valueOpcionRevision === "Todos") {
          let stepCounts = _.filter(this.state.dataRevision, (row) => {
            return row.mo === constDateKey;
          });
          let stepSum = _.sumBy(stepCounts, (row) => {
            return row.count;
          });

          resultRevision.push({
            month: this.getFormattedMonth(m),
            count: stepSum,
          });
        } else {
          let available = _.filter(this.state.dataRevision, (row) => {
            return (
              row.mo === constDateKey &&
              row.subtitle === this.state.valueOpcionRevision
            );
          });

          resultRevision.push({
            month: this.getFormattedMonth(m),
            count: !_.isEmpty(available) ? available[0].count : 0,
          });
        }
        // End Revision

        // Start Uploads
        if (this.state.valueOpcionUploads === "Todos") {
          let stepCounts = _.filter(this.state.dataUploads, (row) => {
            return row.mo === constDateKey;
          });
          let stepSum = _.sumBy(stepCounts, (row) => {
            return row.count;
          });

          resultUploads.push({
            month: this.getFormattedMonth(m),
            count: stepSum,
          });
        } else {
          let available = _.filter(this.state.dataUploads, (row) => {
            return (
              row.mo === constDateKey &&
              row.subtitle === this.state.valueOpcionUploads
            );
          });

          resultUploads.push({
            month: this.getFormattedMonth(m),
            count: !_.isEmpty(available) ? available[0].count : 0,
          });
        }

        // Bar
        let stepCounts = _.filter(this.state.dataUploads, (row) => {
          return row.mo === constDateKey;
        });
        _.forEach(stepCounts, (record) => {
          if (!resultBarUploads.hasOwnProperty(record.subtitle))
            resultBarUploads[record.subtitle] = 0;
          resultBarUploads[record.subtitle] += record.count;
        });
        // End Uploads

        m = m === 11 ? 0 : m + 1;
        y = m === 0 ? y + 1 : y;
        dateKey = y + "-" + (m + 1 < 10 ? "0" : "") + (m + 1);
      }
    }

    let finalResultBarUploads = [];
    // Parse Uploads Bar Results
    _.keys(resultBarUploads).forEach((key) => {
      finalResultBarUploads.push({
        name: key,
        total: resultBarUploads[key],
      });
    });

    this.setState({
      filteredDataDocumentos: resultDocumentos,
      filteredDataClausulas: resultClausulas,
      filteredDataRevision: resultRevision,
      filteredDataUploads: resultUploads,
      filteredBarDataUploads: finalResultBarUploads,
    });
  };

  render() {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ p: 2, display: "inline-block" }}>
            <DesktopDatePicker
              views={["year", "month"]}
              label="Desde"
              minDate={new Date("2012-03-01")}
              maxDate={new Date("2023-06-01")}
              value={this.state.valueDesde}
              onChange={(newValue) => this.onChangeFromDate(newValue.$d)}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </Box>
          <Box sx={{ p: 2, display: "inline-block" }}>
            <DesktopDatePicker
              views={["year", "month"]}
              label="Hasta"
              minDate={new Date("2012-03-01")}
              maxDate={new Date("2023-06-01")}
              value={this.state.valueHasta}
              onChange={(newValue) => this.onChangeToDate(newValue.$d)}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </Box>
        </LocalizationProvider>

        <Container component={Paper} sx={{ p: 2, mb: 2 }}>
          <React.Fragment>
            <Title>Portal - Documentos cargados</Title>

            <TabContext value={this.state.selectedTabUploads}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={(e, v) => {
                    this.setState({ selectedTabUploads: v });
                  }}
                >
                  <Tab label="Consolidado" value="1" />
                  <Tab label="Categorizado" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <ResponsiveContainer width={600} height={300}>
                  <BarChart
                    width={500}
                    height={300}
                    data={this.state.filteredBarDataUploads}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="total"
                      background={{ fill: "#eee" }}
                      label={({ payload, x, y, width, height, value }) => {
                        return (
                          <text
                            x={x + width / 2}
                            y={y}
                            textAnchor="middle"
                            dy={-6}
                          >
                            {value}
                          </text>
                        );
                      }}
                    >
                      {this.state.filteredBarDataUploads.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={this.colors[index % this.colors.length]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </TabPanel>
              <TabPanel value="2">
                <Box sx={{ p: 2, display: "inline-block" }}>
                  <FormControl style={{ minWidth: 200 }}>
                    <InputLabel id="helper_uploads">Documento</InputLabel>
                    <Select
                      labelId="helper_uploads"
                      id="sel_helper_uploads"
                      label="Form"
                      value={this.state.valueOpcionUploads}
                      onChange={(event) =>
                        this.onChangeOpcionUploads(event.target.value)
                      }
                    >
                      <MenuItem value="Todos">Todos</MenuItem>
                      {_.uniq(_.map(this.state.dataUploads, "subtitle")).map(
                        (row) => (
                          <MenuItem value={row}>{row}</MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>

                <ResponsiveContainer width={"99%"} height={300}>
                  <LineChart
                    data={this.state.filteredDataUploads}
                    plugins={{ datalabels: { display: true } }}
                    margin={{
                      top: 16,
                      right: 16,
                      bottom: 0,
                      left: 24,
                    }}
                  >
                    <XAxis dataKey="month" stroke="blue" />
                    <YAxis stroke="blue">
                      <Label
                        angle={270}
                        position="left"
                        style={{
                          textAnchor: "middle",
                          fill: "black",
                        }}
                      >
                        Documentos mensuales
                      </Label>
                    </YAxis>
                    <Tooltip />
                    <Line
                      isAnimationActive={false}
                      type="monotone"
                      dataKey="count"
                      stroke="green"
                      dot={true}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </TabPanel>
            </TabContext>
          </React.Fragment>
        </Container>

        <Container component={Paper} sx={{ p: 2, mb: 2 }}>
          <React.Fragment>
            <Title>Helper - Borradores de documentos descargados</Title>

            <Box sx={{ p: 2, display: "inline-block" }}>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel id="helper_documentos">Documento</InputLabel>
                <Select
                  labelId="helper_documentos"
                  id="sel_helper_documentos"
                  label="Form"
                  value={this.state.valueOpcionDocumentos}
                  onChange={(event) =>
                    this.onChangeOpcionDocumentos(event.target.value)
                  }
                >
                  <MenuItem value="Todos">Todos</MenuItem>
                  {_.uniq(_.map(this.state.dataDocumentos, "subtitle")).map(
                    (row) => (
                      <MenuItem value={row}>{row}</MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>

            <ResponsiveContainer width={"99%"} height={300}>
              <LineChart
                data={this.state.filteredDataDocumentos}
                plugins={{ datalabels: { display: true } }}
                margin={{
                  top: 16,
                  right: 16,
                  bottom: 0,
                  left: 24,
                }}
              >
                <XAxis dataKey="month" stroke="blue" />
                <YAxis stroke="blue">
                  <Label
                    angle={270}
                    position="left"
                    style={{
                      textAnchor: "middle",
                      fill: "black",
                    }}
                  >
                    Documentos mensuales
                  </Label>
                </YAxis>
                <Tooltip />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="count"
                  stroke="green"
                  dot={true}
                />
              </LineChart>
            </ResponsiveContainer>
          </React.Fragment>
        </Container>

        <Container component={Paper} sx={{ p: 2, mb: 2 }}>
          <React.Fragment>
            <Title>Helper - Cláusulas incluidas</Title>

            <Box sx={{ p: 2, display: "inline-block" }}>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel id="helper_clausulas">Cláusula</InputLabel>
                <Select
                  labelId="helper_clausulas"
                  id="sel_helper_clausulas"
                  label="Form"
                  value={this.state.valueOpcionClausulas}
                  onChange={(event) =>
                    this.onChangeOpcionClausulas(event.target.value)
                  }
                >
                  <MenuItem value="Todos">Todos</MenuItem>
                  {_.uniq(_.map(this.state.dataClausulas, "subtitle")).map(
                    (row) => (
                      <MenuItem value={row}>{row}</MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>

            <ResponsiveContainer width={"99%"} height={300}>
              <LineChart
                data={this.state.filteredDataClausulas}
                plugins={{ datalabels: { display: true } }}
                margin={{
                  top: 16,
                  right: 16,
                  bottom: 0,
                  left: 24,
                }}
              >
                <XAxis dataKey="month" stroke="blue" />
                <YAxis stroke="blue">
                  <Label
                    angle={270}
                    position="left"
                    style={{
                      textAnchor: "middle",
                      fill: "black",
                    }}
                  >
                    Cláusulas mensuales
                  </Label>
                </YAxis>
                <Tooltip />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="count"
                  stroke="green"
                  dot={true}
                />
              </LineChart>
            </ResponsiveContainer>
          </React.Fragment>
        </Container>

        <Container component={Paper} sx={{ p: 2, mb: 2 }}>
          <React.Fragment>
            <Title>Helper - Checklists de revisión completados</Title>

            <Box sx={{ p: 2, display: "inline-block" }}>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel id="helper_revision">Documento</InputLabel>
                <Select
                  labelId="helper_revision"
                  id="sel_helper_revision"
                  label="Form"
                  value={this.state.valueOpcionRevision}
                  onChange={(event) =>
                    this.onChangeOpcionRevision(event.target.value)
                  }
                >
                  <MenuItem value="Todos">Todos</MenuItem>
                  {_.uniq(_.map(this.state.dataRevision, "subtitle")).map(
                    (row) => (
                      <MenuItem value={row}>{row}</MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>

            <ResponsiveContainer width={"99%"} height={300}>
              <LineChart
                data={this.state.filteredDataRevision}
                plugins={{ datalabels: { display: true } }}
                margin={{
                  top: 16,
                  right: 16,
                  bottom: 0,
                  left: 24,
                }}
              >
                <XAxis dataKey="month" stroke="blue" />
                <YAxis stroke="blue">
                  <Label
                    angle={270}
                    position="left"
                    style={{
                      textAnchor: "middle",
                      fill: "black",
                    }}
                  >
                    Cláusulas mensuales
                  </Label>
                </YAxis>
                <Tooltip />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="count"
                  stroke="green"
                  dot={true}
                />
              </LineChart>
            </ResponsiveContainer>
          </React.Fragment>
        </Container>
      </Container>
    );
  }
}

export default withTranslation()(MyMetrics);
