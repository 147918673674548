export async function listAlertsByDocId(docId) {
  let params = {
    documentId: docId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListAlertsByDocumentId?code=UxxAgdWXMLdtnmYVS4AzCUD5U9Wy2XNx8qvxkJ40U-yoAzFuUlnszQ==";

  return sendReq(params, url);
}

export async function listSuggestionsByClientId(clientId) {
  let params = {
    clientId: clientId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListSuggestionsByClient?code=jJwanV5G4vXyl9yMcKi9yX6RC8Kc3tZOwQfPgNR57EL4AzFu2BtgBg==";

  return sendReq(params, url);
}

export async function insertAlert(params) {
  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/InsertAlert?code=sRZbQ1fKQCNBF1C_mMIi885ZDDZJVKD8pkr3IcDRYoCzAzFufsQB2g==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
