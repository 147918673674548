import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import { clientContext } from "../../../contexts/ClientContext";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import "react-block-ui/style.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { listFeatures } from "../../../services/features";
import { Link } from "react-router-dom";
import {
  listWorkspacesByClientId,
  listWorkspaceSubcategories,
} from "../../../services/workspaces";
import { listFeatureLabels } from "../../../services/features";
import UploadTypeDialog from "./UploadDialogs/UploadTypeDialog";
import { FullClientNavigator } from "./FullClientNavigator";
import NavigationDetail from "./NavigationDetail";
import ZapotalNavigationDetail from "./ZapotalNavigationDetail";

export const ClienteDetalleIndex = (props) => {
  // Pagina de navegacion de documentos del cliente, para usuarios internos BLP-PRB
  const { t } = useTranslation();
  const { data, setData, setId, categories, companies } =
    useContext(clientContext);
  const { auth } = useContext(authContext);
  const history = useHistory();

  const { client_id } = useParams();

  const [openUploadTypeDialog, setOpenUploadTypeDialog] = useState(false);
  const [clientFeatures, setClientFeatures] = useState([]);
  const [remountKey, setRemountKey] = useState(0);
  const [workspaces, setWorkspaces] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    setId(client_id);

    const fetchFeatures = async () => {
      let features = await listFeatures(client_id);
      setClientFeatures(features);
    };
    fetchFeatures();

    fetchClientCategories();
  }, []);

  const fetchClientCategories = async () => {
    /* Get subcategories by workspace */
    let workspaces = await listWorkspacesByClientId(client_id);
    let subcategories = [];
    workspaces.map(async (workspace) => {
      let list = await listWorkspaceSubcategories(workspace.workspace_id);
      subcategories.push({
        workspace: workspace,
        subcategories: list,
      });
    });

    /* Get subcategories by feature */
    let courtProceedings = await listFeatureLabels(
      client_id,
      "Court Proceedings"
    );
    if (!_.isEmpty(courtProceedings)) {
      workspaces.push({
        name_en: "Court Proceedings",
        name_es: "Procesos Judiciales",
      });
      let cpSubcategories = [];
      courtProceedings.map((cp) => {
        cpSubcategories.push({
          subcategory_en: cp.label,
          subcategory_es: cp.label,
        });
      });
      subcategories.push({
        workspace: {
          name_en: "Court Proceedings",
          name_es: "Procesos Judiciales",
        },
        subcategories: cpSubcategories,
      });
    }

    let administrativeProcesses = await listFeatureLabels(
      client_id,
      "Administrative Processes"
    );
    if (!_.isEmpty(administrativeProcesses)) {
      workspaces.push({
        name_en: "Administrative Processes",
        name_es: "Procesos Administrativos",
      });
      let apSubcategories = [];
      administrativeProcesses.map((ap) => {
        apSubcategories.push({
          subcategory_en: ap.label,
          subcategory_es: ap.label,
        });
      });
      subcategories.push({
        workspace: {
          name_en: "Administrative Processes",
          name_es: "Procesos Administrativos",
        },
        subcategories: apSubcategories,
      });
    }

    setWorkspaces(workspaces);
    setSubcategories(subcategories);
  };

  const hasFeature = (featureName) => {
    return (
      _.find(clientFeatures, (feature) => {
        return feature.name === featureName;
      }) !== undefined
    );
  };

  const refreshDocumentTable = () => {
    setRemountKey(remountKey + 1);
  };

  if (!data) {
    return <div />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container>
        <Grid item xs={6} container justifyContent="flex-start">
          {hasFeature("Predict Your Closing") && (
            <Link
              to={{
                pathname: "/PredictYourClosing",
                state: { client: data },
              }}
            >
              <Button
                sx={{
                  m: 3,
                  backgroundColor:
                    window.$brand === "PRB" ? "#17afb6" : "#78BE20",
                }}
                variant="contained"
                onClick={() => {}}
              >
                {"Predict Your Closing"}
              </Button>
            </Link>
          )}
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          {(auth.user.userType === "blp_admin" ||
            window.$brand === "PRB" ||
            window.$brand === "BLP" || auth.user.userType === "admin_subway" || auth.user.userType === "admin_kraft") && (
            <Button
              sx={{
                m: 3,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() => {
                history.push("/Administracion", { client: data });
              }}
            >
              {t("go_to_settings")}
            </Button>
          )}
          {auth.user.userType !== "admin_zapotal" &&(
            <Button
              sx={{
                m: 3,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenUploadTypeDialog(true);
              }}
            >
              {t("Assign_document")}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 6, sm: 6, md: 12 }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <FullClientNavigator caption={t("Navigate_the_hierarchy_admin")} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {(auth.user.userType === "admin_zapotal" 
            || auth.user.userType === "admin_IDB"
            || auth.user.userType === "admin_epa"
            || auth.user.userType === "admin_asal"
            || auth.user.userType === "admin_vololoans"
            ) ? (
            <ZapotalNavigationDetail userId={auth.user.id} />
          ) : (
            <NavigationDetail
              userId={auth.user.id}
              subcategories={subcategories}
            />
          )}
        </Grid>
      </Grid>

      <UploadTypeDialog
        data={{
          open: openUploadTypeDialog,
          subcategories: subcategories,
          selectCompanyFor: data,
        }}
        handleDialogClose={() => setOpenUploadTypeDialog(false)}
        refreshFunc={refreshDocumentTable}
      />
    </Container>
  );
};
