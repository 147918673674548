import * as React from "react";
import Typography from "@mui/material/Typography";

const PageTitle = (props) => {
  return (
    <Typography
      style={{ color: "#000", fontSize: "2em", fontWeight:"900" }}
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
};

export default PageTitle;
