import React from "react";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { CardActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteDialog from "../../DeleteDialog";
import "react-block-ui/style.css";
import { authContext } from "../../../contexts/AuthContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { formatDate } from "../../../Utils/Format";
import Permissions from "./Permissions";
import Tooltip from "@mui/material/Tooltip";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import CPSettingsIconButton from "../../Utils/CPSettingsIconButton";
import CPText from "../../Utils/CPText";

class ADUserCard extends React.Component {
  // Card de usuario interno AAD

  constructor() {
    super();

    this.state = {
      settings: {},
      edit: {},
      delete: {},
    };
  }

  static contextType = authContext;

  handleEditOpen = (user) => {
    this.setState({
      edit: user,
    });
  };

  handleEditClose = () => {
    this.setState({
      edit: {},
    });
  };

  handleSettingsOpen = (user) => {
    this.setState({
      settings: user,
    });
  };

  handleSettingsClose = () => {
    this.setState({
      settings: {},
    });
  };

  handleDeleteOpen = (user) => {
    this.setState({
      delete: user,
    });
  };

  handleDialogClose = () => {
    this.setState({
      settings: {},
      edit: {},
      delete: {},
    });
  };

  handleEditUserTypeChange = (event) => {
    let modified = this.state.edit;
    modified.type = event.target.value;
    this.setState({
      edit: modified,
    });
  };

  handleEditRoleChange = (event) => {
    let modified = this.state.edit;
    modified.role = event.target.value;
    this.setState({
      edit: modified,
    });
  };

  update = (user) => {
    user.first_name = document.getElementById("edit_firstName").value;
    user.last_name = document.getElementById("edit_lastName").value;
    user.email = document.getElementById("edit_email").value;
    user.phone = document.getElementById("edit_phone").value;
    user.mobile = document.getElementById("edit_mobile").value;
    user.loggedUserId = this.context.auth.user.id;
    user.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateADUser?code=r7zCg1ufdcE3HlfWAleamZsdHsiAswrmkh30nA76btnfTQT8viMpQA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleEditClose();
  };

  delete = (user) => {
    user.loggedUserId = this.context.auth.user.id;
    user.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteUser?code=FhyC/yldBaINF9fi0CaKkp192uvlRAODGI8O/mxqqrxBaay94/uJXg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");
      })
      .catch((error) => console.log(error));

    this.props.cardDeleteHandler(user);
  };

  render() {
    const { t } = this.props;

    return (
      <Card
        sx={{
          minWidth: 275,
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: window.$brand === "BLP" ? 5 : 0,
          mr: window.$brand === "BLP" ? 10 : 0,
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color="text.primary" variant="body1">
                <strong>
                  {this.props.user.first_name} {this.props.user.last_name}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CPText label={t("Email")} text={this.props.user.email} />
            </Grid>
            <Grid item xs={12}>
              <CPText label={t("Role")} text={t(this.props.user.role)} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: "auto" }}>
          {this.context.auth.user.email !== this.props.user.email && (
            <>
              {window.$brand === "PRB" &&
                this.props.user.type !== "blp_admin" && (
                  <CPSettingsIconButton
                    onClick={() => {
                      this.handleSettingsOpen(this.props.user);
                    }}
                  />
                )}
              <CPEditIconButton
                onClick={() => this.handleEditOpen(this.props.user)}
              />
              <CPDeleteIconButton
                onClick={() => this.handleDeleteOpen(this.props.user)}
              />
            </>
          )}
        </CardActions>
        <DeleteDialog
          item={this.state.delete}
          title={t("delete_user")}
          caption={t("delete_user_caption")}
          targetFunc={this.delete}
          handleDialogClose={this.handleDialogClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.edit)}
          onClose={this.handleEditClose}
        >
          <DialogTitle>{t("edit_user")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.first_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="edit_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.last_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              disabled
              id="edit_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.email}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="edit_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.phone}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="edit_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.mobile}
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <FormControl fullWidth>
              <InputLabel id="edit_type_label">{t("User_type")}</InputLabel>
              <Select
                labelId="edit_type_label"
                id="edit_type"
                label={t("User_type")}
                onChange={this.handleEditUserTypeChange}
                defaultValue={this.state.edit.type}
              >
                <MenuItem value={"blp_admin"}>{t("Administrator")}</MenuItem>
                <MenuItem value={"blp_user"}>
                  {window.$brand + " " + t("User")}
                </MenuItem>
                {window.$brand === "ZAP" && (
                  <MenuItem value="admin_zapotal">Admin Zapotal</MenuItem>
                )}
                {window.$brand === "IDB" && (
                  <MenuItem value="admin_IDB">Admin IDB</MenuItem>
                )}
                {window.$brand === "EPA" && (
                  <MenuItem value="admin_epa">Admin EPA</MenuItem>
                )}
                {window.$brand === "ASAL" && (
                  <MenuItem value="admin_asal">Admin ASAL</MenuItem>
                )}
                {window.$brand === "VOLOLOANS" && (
                  <MenuItem value="admin_vololoans">Admin VOLOLOANS</MenuItem>
                )}
                {window.$brand === "SUBWAY" && (
                  <MenuItem value="admin_subway">Admin SUBWAY</MenuItem>
                )}
                {window.$brand === "KRAFT" && (
                  <MenuItem value="admin_kraft">Admin KRAFT</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="edit_role_label">{t("Role")}</InputLabel>
              <Select
                labelId="edit_role_label"
                id="edit_role"
                label={t("Role")}
                onChange={this.handleEditRoleChange}
                defaultValue={this.state.edit.role}
              >
                <MenuItem value={"Associate"}>{t("Associate")}</MenuItem>
                <MenuItem value={"Partner"}>{t("Partner")}</MenuItem>
                <MenuItem value={"Director"}>{t("Director")}</MenuItem>
                <MenuItem value={"Senior Associate"}>
                  {t("Senior Associate")}
                </MenuItem>
                <MenuItem value={"Special Counsel"}>
                  {t("Special Counsel")}
                </MenuItem>
                <MenuItem value={"Senior Counsel"}>
                  {t("Senior Counsel")}
                </MenuItem>
                <MenuItem value={"Paralegal"}>{t("Paralegal")}</MenuItem>
                <MenuItem value={"Administrative Assistant"}>
                  {t("Administrative Assistant")}
                </MenuItem>
                {window.$brand === "ZAP" && (
                  <MenuItem value="Gerente Zapotal">Gerente Zapotal</MenuItem>
                )}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleEditClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.update(this.state.edit)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.settings)}
          onClose={this.handleSettingsClose}
        >
          <DialogContent>
            <Permissions user={this.props.user} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSettingsClose}>{t("Close")}</Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

export default withTranslation()(ADUserCard);
