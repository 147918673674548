import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import CalculateIcon from "@mui/icons-material/Calculate";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import { authContext } from "../contexts/AuthContext";
import "./ListItems.css";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { getMainClient } from "../services/clients";
import { listFeatures } from "../services/features";
import { listPermissions } from "../services/permissions";
import Box from "@mui/material/Box";
import prbiconMisDocumentos from "../imgs/prbicon_MisDocumentos.svg";
import prbiconClientes from "../imgs/prbicon_Clientes.svg";
import prbiconAdminCenter from "../imgs/prbicon_AdminCenter.svg";
import prbiconEForms from "../imgs/prbicon_eForms.svg";
import prbiconTutorial from "../imgs/prbicon_tutorial.svg";
import prbiconHistorial from "../imgs/prbicon_Historial.svg";
import prbiconLawNuevo from "../imgs/prbicon_LawNuevo.svg";
import prbiconPredictYourClosing from "../imgs/prbicon_PredictYourClosing.svg";
import prbiconPredictYourDeadline from "../imgs/prbicon_PredictYourDeadline.svg";
import prbiconRetainer from "../imgs/prbicon_Retainer.svg";
import prbiconUsuarios from "../imgs/prbicon_Usuarios.svg";

import blpiconCalendar from "../imgs/newblp_calendar.svg";
import blpiconCalendarSel from "../imgs/newblp_calendar_sel.svg";
import blpiconClients from "../imgs/newblp_Clients.svg";
import blpiconClientsSel from "../imgs/newblp_Clients_sel.svg";
import blpiconHistory from "../imgs/newblp_History.svg";
import blpiconHistorySel from "../imgs/newblp_History_sel.svg";
import blpiconUsers from "../imgs/newblp_Users.svg";
import blpiconUsersSel from "../imgs/newblp_Users_sel.svg";
import blpiconWorkspaces from "../imgs/newblp_Workspaces.svg";
import blpiconWorkspacesSel from "../imgs/newblp_Workspaces_sel.svg";

import zapiconCondominios from "../imgs/zapicon_Condominios.svg";
import zapiconCondominiosSel from "../imgs/zapicon_CondominiosSel.svg";

class MainListItems extends React.Component {
  // Side panel para navegacion de paginas principales
  // varia segun tipo de usuario y si es BLP, PRB o ZAP

  constructor() {
    super();

    this.state = {
      clientFeatures: [],
      userPermissions: [],
      videoTutorialOpen: false,
    };
  }

  static contextType = authContext;

  async componentDidMount() {
    if (
    (this.context.auth.type === "client") &&
      (this.context.auth.user.userType !== "admin_zapotal" 
       && this.context.auth.user.userType !== "admin_IDB" 
       && this.context.auth.user.userType !== "admin_epa"
       && this.context.auth.user.userType !== "admin_asal"
       && this.context.auth.user.userType !== "admin_vololoans"
       && this.context.auth.user.userType !== "admin_subway"
       && this.context.auth.user.userType !== "admin_kraft"
       )  
    ) {
      let client = await getMainClient(this.context.auth.user.id);
      let features = await listFeatures(client.id);
      let permissions = await listPermissions(this.context.auth.user.id);

      this.setState({
        clientFeatures: features,
        userPermissions: permissions,
      });
    }
  }

  handleVideoTutorialClose = () => {
    this.setState({
      videoTutorialOpen: false,
    });
  };

  hasFeature = (featureName) => {
    return (
      _.find(this.state.clientFeatures, (feature) => {
        return feature.name === featureName;
      }) !== undefined
    );
  };

  hasPermission = (permissionName) => {
    return (
      _.find(this.state.userPermissions, (permission) => {
        return permission.name === permissionName;
      }) !== undefined
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        {
        <Box
              component="img"
              alignItems="center"
              sx={{
                width:
                  window.$brand === "PRB"
                    ? 0
                    : window.$brand === "ZAP"
                    ? 180
                    : window.$brand === "IDB"
                    ? 180
                    : window.$brand === "EPA"
                    ? 120
                    : window.$brand === "ASAL"
                    ? 180
                    : window.$brand === "VOLOLOANS"
                    ? 180
                    : window.$brand === "SUBWAY"
                    ? 0
                    : window.$brand === "KRAFT"
                    ? 0
                    : 100,
                pl: window.$brand === "PRB" ? 4 : 4,
              }}
              alt="Logo"
              src={
                window.$brand === "PRB"
                  ? "/assets/legally.png"
                  : window.$brand === "ZAP"
                  ? "/assets/zapotal2.png"
                  : window.$brand === "IDB"
                  ? "/assets/idb-invest-color-eng-hor.png"
                  : window.$brand === "EPA"
                  ? "/assets/epa.jpg"
                  : window.$brand === "ASAL"
                  ? "/assets/asal.jpg"
                  : window.$brand === "VOLOLOANS"
                  ? "/assets/vololoans.jpg"
                  : window.$brand === "SUBWAY"
                  ? "/assets/subwaywhite.png"
                  : window.$brand === "KRAFT"
                  ? "/assets/subwaywhite.png"
                  : "/assets/BLP_fullwhite.png"
              }
              
            />}
        {(this.context.auth.user.userType === "blp_admin"  
         || this.context.auth.user.userType === "admin_IDB" 
         || this.context.auth.user.userType === "admin_epa"
         || this.context.auth.user.userType === "admin_asal"
         || this.context.auth.user.userType === "admin_vololoans"
         || this.context.auth.user.userType === "admin_subway"
         || this.context.auth.user.userType === "admin_kraft")
         && (window.$brand === "BLP" 
            || window.$brand === "IDB" 
            || window.$brand === "EPA" 
            || window.$brand === "ASAL" 
            || window.$brand === "VOLOLOANS" 
            || window.$brand === "SUBWAY" 
            || window.$brand === "KRAFT" 
            || this.context.auth.user.email === "rmata@blplegal.com") && (
            <Link to="/Workspaces">
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={
                      window.location.href.indexOf("/Workspaces") > -1
                        ? blpiconWorkspacesSel
                        : blpiconWorkspaces
                    }
                    height="30"
                    alt={t("Workspaces")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("Workspaces")}
                  sx={{
                    color:
                      (window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT")
                        ? "#000"
                        : window.location.href.indexOf("/Workspaces") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
          )
        }
        {(this.context.auth.user.userType === "client_user" ||
          this.context.auth.user.userType === "client_admin") && (
          <>
            <Link to="/MisDocumentos">
              <ListItem button>
                <ListItemIcon>
                  {window.$brand === "PRB" ? (
                    <img
                      src={prbiconMisDocumentos}
                      height="30"
                      alt={t("menu_MyDocuments")}
                    />
                  ) : (
                    <img
                      src={`assets/blpicon_MisDocumentos.svg`}
                      height="30"
                      alt={t("menu_MyDocuments")}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={t("menu_MyDocuments")}
                  sx={{
                    color:
                      window.$brand === "PRB"
                        ? "#000"
                        : window.location.href.indexOf("/MisDocumentos") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
            {window.$brand !== "ZAP" && (
              <Link to="/Lawyer">
                <ListItem button>
                  <ListItemIcon>
                    {window.$brand === "PRB" ? (
                      <img
                        src={prbiconClientes}
                        height="30"
                        alt={t("menu_MyLawyer")}
                      />
                    ) : (
                      <img
                        src={`assets/blpicon_Abogado.svg`}
                        height="30"
                        alt={t("menu_MyLawyer")}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={t("menu_MyLawyer")}
                    sx={{
                      color:
                        window.$brand === "PRB"
                          ? "#000"
                          : window.location.href.indexOf("/Lawyer") > -1
                          ? "#00a1a7"
                          : "#102a46",
                    }}
                  />
                </ListItem>
              </Link>
            )}
          </>
        )}
        {(this.context.auth.user.userType === "blp_admin" 
          || this.context.auth.user.userType === "admin_epa"
          || this.context.auth.user.userType === "admin_asal"
          || this.context.auth.user.userType === "admin_vololoans"
          || this.context.auth.user.userType === "admin_subway"
          || this.context.auth.user.userType === "admin_kraft"
          || this.context.auth.user.userType === "blp_user") &&
          (window.$brand === "PRB" || window.$brand === "BLP"  
            || window.$brand === "EPA" 
            || window.$brand === "ASAL" 
            || window.$brand === "VOLOLOANS" 
            || window.$brand === "SUBWAY" 
            || window.$brand === "KRAFT" 
            ) && (
            <Link to="/Clientes">
              <ListItem button>
                <ListItemIcon>
                  {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT") ? (
                    <img
                      src={prbiconClientes}
                      height="30"
                      alt={t("menu_Clients")}
                    />
                  ) : (
                    <img
                      src={
                        window.location.href.indexOf("/Clientes") > -1
                          ? blpiconClientsSel
                          : blpiconClients
                      }
                      height="30"
                      alt={t("menu_Clients")}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    t("menu_Clients")}
                  sx={{
                    color:
                      window.$brand === "PRB"
                        ? "#000"
                        : window.location.href.indexOf("/Clientes") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
        )}

        {(this.context.auth.user.userType === "blp_admin" 
          || this.context.auth.user.userType === "admin_IDB" 
          || this.context.auth.user.userType === "blp_user") &&
          (window.$brand === "IDB" 
            ) && (
            <Link to="/Clientes">
              <ListItem button>
                <ListItemIcon>
                  {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT") ? (
                    <img
                      src={prbiconClientes}
                      height="30"
                      alt={t("ClientTransactions")}
                    />
                  ) : (
                    <img
                      src={
                        window.location.href.indexOf("/Clientes") > -1
                          ? blpiconClientsSel
                          : blpiconClients
                      }
                      height="30"
                      alt={t("ClientTransactions")}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    t("ClientTransactions")}
                  sx={{
                    color:
                      window.$brand === "PRB"
                        ? "#000"
                        : window.location.href.indexOf("/Clientes") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
        )}


        {(this.context.auth.user.userType === "blp_admin" ||
          this.context.auth.user.userType === "blp_user" ||
          this.context.auth.user.userType === "admin_zapotal" 
          || this.context.auth.user.userType === "admin_IDB"
          || this.context.auth.user.userType === "admin_epa"
          || this.context.auth.user.userType === "admin_asal"
          || this.context.auth.user.userType === "admin_vololoans"
          || this.context.auth.user.userType === "admin_subway"
          || this.context.auth.user.userType === "admin_kraft"
          ) &&
          (window.$brand === "ZAP") && (
            <Link to="/Condominios">
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={
                      window.location.href.indexOf("/Condominios") > -1
                        ? zapiconCondominiosSel
                        : zapiconCondominios
                    }
                    height="30"
                    alt={t("Condominiums")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("Condominiums")}
                  sx={{
                    color:
                      window.location.href.indexOf("/Condominios") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
        )}
        {this.context.auth.user.userType === "client_admin" && (
          <Link to="/Usuarios">
            <ListItem button>
              <ListItemIcon>
                {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT")  ? (
                  <img
                    src={prbiconUsuarios}
                    height="30"
                    alt={t("menu_Users")}
                  />
                ) : (
                  <img
                    src={
                      window.location.href.indexOf("/Usuarios") > -1
                        ? blpiconUsersSel
                        : blpiconUsers
                    }
                    height="30"
                    alt={t("menu_Users")}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={t("menu_Users")}
                sx={{
                  color:
                  (window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT") 
                      ? "#000"
                      : window.location.href.indexOf("/Usuarios") > -1
                      ? "#00a1a7"
                      : "#102a46",
                }}
              />
            </ListItem>
          </Link>
        )}
        {(this.context.auth.user.userType === "blp_admin" 
          || this.context.auth.user.userType === "admin_IDB"
          || this.context.auth.user.userType === "admin_epa"
          || this.context.auth.user.userType === "admin_asal"
          || this.context.auth.user.userType === "admin_vololoans"
          || this.context.auth.user.userType === "admin_subway"
          || this.context.auth.user.userType === "admin_kraft"
          ) && (
          <Link to="/UsuariosAD">
            <ListItem button>
              <ListItemIcon>
                {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT")  ? (
                  <img
                    src={prbiconUsuarios}
                    height="30"
                    alt={t("menu_Users")}
                  />
                ) : (
                  <img
                    src={
                      window.location.href.indexOf("/UsuariosAD") > -1
                        ? blpiconUsersSel
                        : blpiconUsers
                    }
                    height="30"
                    alt={t("menu_Users")}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={t("menu_Users")}
                sx={{
                  color:
                  (window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT") 
                      ? "#000"
                      : window.location.href.indexOf("/UsuariosAD") > -1
                      ? "#00a1a7"
                      : "#102a46",
                }}
              />
            </ListItem>
          </Link>
        )}
        {(this.context.auth.user.userType === "client_admin" ||
          (this.context.auth.user.userType === "client_user" &&
            this.hasPermission("My Fees"))) &&
          this.hasFeature("My Fees") &&
          window.$brand === "PRB" && (
            <Link to="/Retainer">
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={prbiconRetainer}
                    height="30"
                    alt={t("menu_Users")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("My Fees")}
                  sx={{
                    color:
                      window.$brand === "PRB"
                        ? "#000"
                        : window.location.href.indexOf("/Retainer") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
          )}

        {window.$brand === "PRB" &&
          (this.context.auth.user.userType === "blp_admin" ||
            this.context.auth.user.userType === "blp_user") && (
            <Link to="/PRBAdminCenter">
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={prbiconAdminCenter}
                    height="30"
                    alt={"PRB Admin Center"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"PRB Admin Center"}
                  sx={{
                    color:
                      window.$brand === "PRB"
                        ? "#000"
                        : window.location.href.indexOf("/PRBAdminCenter") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
          )}
        {window.$brand === "AAA" &&
          this.context.auth.user.userType === "client_admin" &&
          this.hasFeature("Predict Your Closing") && (
            <Link to="/PredictYourClosing">
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={prbiconPredictYourClosing}
                    height="30"
                    alt={"Predict Your Closing"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Predict Your Closing"}
                  sx={{
                    color:
                      window.$brand === "PRB"
                        ? "#000"
                        : window.location.href.indexOf("/PredictYourClosing") >
                          -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
          )}
        {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT")  && (
          <Link to="/PredictYourDates">
            <ListItem button>
              <ListItemIcon>
                <img
                  src={prbiconPredictYourDeadline}
                  height="30"
                  alt={"Predict Your Dates"}
                />
              </ListItemIcon>
              <ListItemText
                primary={"Predict Your Dates"}
                sx={{
                  color:
                    window.$brand === "PRB"
                      ? "#000"
                      : window.location.href.indexOf("/PredictYourDates") > -1
                      ? "#00a1a7"
                      : "#102a46",
                }}
              />
            </ListItem>
          </Link>
        )}
        {(window.$brand === "BLP" || window.$brand === "ZAP" 
          || window.$brand === "IDB"
          || window.$brand === "EPA" 
          || window.$brand === "ASAL" 
          || window.$brand === "VOLOLOANS" 
          ) &&
          this.context.auth.user.userType !== "admin_zapotal" &&(
            <Link to="/Calendario">
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={
                      window.location.href.indexOf("/Calendario") > -1
                        ? blpiconCalendarSel
                        : blpiconCalendar
                    }
                    height="30"
                    alt={t("menu_History")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("menu_Calendar")}
                  sx={{
                    color:
                      window.$brand === "PRB"
                        ? "#000"
                        : window.location.href.indexOf("/Calendario") > -1
                        ? "#00a1a7"
                        : "#102a46",
                  }}
                />
              </ListItem>
            </Link>
        )}
        {(this.context.auth.user.userType === "blp_admin" 
          || this.context.auth.user.userType === "admin_IDB"
          || this.context.auth.user.userType === "admin_epa"
          || this.context.auth.user.userType === "admin_asal"
          || this.context.auth.user.userType === "admin_vololoans"
          || this.context.auth.user.userType === "admin_subway"
          || this.context.auth.user.userType === "admin_kraft"
          ) && (
          <Link to="/Historial">
            <ListItem button>
              <ListItemIcon>
                {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT")  ? (
                  <img
                    src={prbiconHistorial}
                    height="30"
                    alt={t("menu_History")}
                  />
                ) : (
                  <img
                    src={
                      window.location.href.indexOf("/Historial") > -1
                        ? blpiconHistorySel
                        : blpiconHistory
                    }
                    height="30"
                    alt={t("menu_History")}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={t("menu_History")}
                sx={{
                  color:
                    (window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT") 
                      ? "#000"
                      : window.location.href.indexOf("/Historial") > -1
                      ? "#00a1a7"
                      : "#102a46",
                }}
              />
            </ListItem>
          </Link>
        )}
        <Divider variant="middle" sx={{ mt: 2, mb: 2 }} />
        <Typography sx={{ p: 2, color: "#000" }}>
          {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT") 
            ? t("External_links_PRB")
            : t("External_links_BLP")}
        </Typography>
        {(window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT")  ? (
          <div>
            <a
              rel="noopener noreferrer"
              href="https://legalpredictabill.com/servicios-en-linea/"
              target="_blank"
            >
              <ListItem button>
                <ListItemIcon>
                  <img
                    src={prbiconPredictYourDeadline}
                    height="30"
                    alt={t("Online Services")}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t("Online Services")}
                  sx={{ color: "#000" }}
                />
              </ListItem>
            </a>
            <a
              rel="noopener noreferrer"
              href="https://legalpredictabill.com/law-nuevo-lo-ultimo-en-la-revolucion-legal-en-costa-rica/"
              target="_blank"
            >
              <ListItem button>
                <ListItemIcon>
                  <img src={prbiconLawNuevo} height="30" alt={"LawNuevo"} />
                </ListItemIcon>
                <ListItemText primary={"LawNuevo"} sx={{ color: "#000" }} />
              </ListItem>
            </a>
            <a
              rel="noopener noreferrer"
              href="https://legalpredictabill.com/doing-business-eforms/"
              target="_blank"
            >
              <ListItem button>
                <ListItemIcon>
                  <img src={prbiconEForms} height="30" alt={"eForms"} />
                </ListItemIcon>
                <ListItemText primary={"eForms"} sx={{ color: "#000" }} />
              </ListItem>
            </a>
            <a>
              <ListItem
                button
                onClick={() => this.setState({ videoTutorialOpen: true })}
              >
                <ListItemIcon>
                  <img
                    src={prbiconTutorial}
                    height="25"
                    alt={"Video Tutorial"}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Video Tutorial"}
                  sx={{ color: "#000" }}
                />
              </ListItem>
            </a>
          </div>
        ) : (
          <div>
            <a
              rel="noopener noreferrer"
              href="https://www.blplegal.com/our-people/"
              target="_blank"
            >
              <ListItem button>
                <ListItemIcon>
                  <FiberManualRecordOutlinedIcon sx={{ color: "#102a46" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"Our People"}
                  sx={{ color: "#102a46" }}
                />
              </ListItem>
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.blplegal.com/solutions/"
              target="_blank"
            >
              <ListItem button>
                <ListItemIcon>
                  <FiberManualRecordOutlinedIcon sx={{ color: "#102a46" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"Practice Areas"}
                  sx={{ color: "#102a46" }}
                />
              </ListItem>
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.blplegal.com/blog/"
              target="_blank"
            >
              <ListItem button>
                <ListItemIcon>
                  <FiberManualRecordOutlinedIcon sx={{ color: "#102a46" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"Perspectives"}
                  sx={{ color: "#102a46" }}
                />
              </ListItem>
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.blplegal.com/resources/"
              target="_blank"
            >
              <ListItem button>
                <ListItemIcon>
                  <FiberManualRecordOutlinedIcon sx={{ color: "#102a46" }} />
                </ListItemIcon>
                <ListItemText primary={"Resources"} sx={{ color: "#102a46" }} />
              </ListItem>
            </a>
          </div>
        )}
        <Dialog
          open={this.state.videoTutorialOpen}
          fullScreen
          onClose={this.handleVideoTutorialClose}
        >
          <DialogTitle>
            {"Video Tutorial"}
            <IconButton
              onClick={this.handleVideoTutorialClose}
              style={{ float: "right" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div key={this.childKey} style={{ height: "100%" }}>
              <video
                src="https://blpclientportal.blob.core.windows.net/public/legallyv3.mp4"
                width="100%"
                height="100%"
                controls
              >
                The browser does not support videos.
              </video>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleVideoTutorialClose}>
              {t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(MainListItems);
