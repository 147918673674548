import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

class BLPVersion extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <p>
          <strong>Aceptación de los Términos y Condiciones</strong>
        </p>
        <p>
          Al utilizar el sitio web www.blplegal.com o la plataforma electrónica
          denominada “Portal de Clientes” (el “Portal”) o cualquier aplicación,
          plataforma, o complementos de aplicación de BLP Abogados S.A., una
          sociedad costarricense con cédula de persona jurídica número
          3-101-587195 (“BLP”), o de cualquiera de sus empresas afiliadas (las
          “Aplicaciones”), usted acepta seguir y estar sujeto a estos términos
          de uso (los “Términos y Condiciones”) y acepta cumplir con todas las
          leyes y regulaciones aplicables. Al hacer clic en “
          <strong>Acepto</strong>” en la página de registro de la cuenta, o al
          utilizar el Portal de cualquier modo, usted acepta cumplir y quedar
          obligado por estos Términos y Condiciones y todas las normas,
          políticas y exenciones de responsabilidad que se encuentran publicados
          en el Portal o sobre los que se le notifique (en conjunto, los
          “Términos”).
        </p>
        <p>
          Si no acepta la totalidad de los Términos, no utilice el Portal.
          Revise todos los Términos y Condiciones cuidadosamente antes de
          utilizar el Portal.
        </p>
        <p>
          Usted, como Cliente, reconoce y acepta que al usar este Portal y en
          virtud de que tendrá acceso a ciertos servicios legales, suscribe un
          acuerdo (el “Acuerdo”) legalmente vinculante con BLP.
        </p>
        <p>
          Es su responsabilidad revisar estos Términos y Condiciones
          periódicamente. Si en algún momento considera que estos Términos y
          Condiciones son inaceptables o si no está de acuerdo con estos
          Términos y Condiciones, no utilice este Portal ni ninguna de las
          Aplicaciones. Podemos revisar estos Términos y Condiciones en
          cualquier momento sin notificárselo. Si tiene alguna pregunta sobre
          estos Términos y Condiciones, comuníquese con alguno de nuestros
          asesores al correo info@blplegal.com.
        </p>
        <p>
          Al utilizar el Portal, usted (i) acepta quedar obligado por los
          Términos y Condiciones y (ii) declara que es mayor de 18 años y puede
          celebrar contratos vinculantes en forma legal.
        </p>
        <p>
          <strong>Definiciones</strong>
        </p>
        <p>
          En estos Términos y Condiciones, las palabras “Cliente”, "Usted" y
          "Su" se refieren a cada cliente, visitante del Portal, persona que
          contrata los servicios de BLP mediante el Portal o quien cree una
          Cuenta en el Portal. "BLP", "nosotros", "nos" y "nuestro" se refieren
          a blplegal.com o a BLP Abogados S.A., y "Servicios" se refiere a todos
          los servicios proporcionados por nosotros que se pueden contratar
          mediante el Portal.
        </p>
        <p>
          <strong>Sobre BLP</strong>
        </p>
        <p>
          BLP fue fundada en el año 2003 en Costa Rica por un equipo de abogados
          que compartían la misma visión sobre la práctica jurídica, convencidos
          de que los asesores legales debían convertirse en verdaderos aliados
          de negocios de sus clientes mediante la prestación de asesoría legal
          de alta calidad bajo un enfoque comercial. De ahí nuestro nombre:{" "}
          <strong>Business Law Partners</strong>.
        </p>
        <p>
          La firma se ha mantenido leal a su visión de negocios, brindando a
          nuestros clientes un servicio legal integral, personalizado,
          innovador, con abogados especialistas en las distintas áreas de
          práctica del Derecho. Combinamos una visión de negocios global con la
          cultura local para ofrecer soluciones estratégicas.
        </p>
        <p>
          BLP proporciona un Portal de Clientes en línea para facilitar a sus
          clientes el acceso a ciertos documentos y servicios legales. El
          Cliente no necesita descargar ni licenciar ningún software de BLP. El
          Portal incluye información general sobre asuntos y necesidades legales
          que se encuentran comúnmente. Aunque se brinde alguna información de
          interés, el uso del Portal no consiste en ninguna asesoría legal ni
          constituye una relación abogado-cliente. Esta únicamente se genera al
          momento de contratar los Servicios.
        </p>
        <p>
          Al contratar los Servicios, los mismos serán brindados (cuando así sea
          requerido) por un Profesional con título universitario de Licenciatura
          en Derecho e incorporado al Colegio de Abogados y Abogadas de Costa
          Rica. Asimismo, si los servicios contratados deben ser brindados por
          un Notario Público, entonces un Fedatario (a) público (a) debidamente
          inscrito y habilitado ante la Dirección Nacional de Notariado asistirá
          el Servicio. En algunos casos, ciertas asignaciones específicas
          podrían ser delegadas a otros abogados o colaboradores siempre bajo
          supervisión de los abogados de BLP. Tal asignación puede ser el
          resultado de nuestro interés de participar a otros profesionales o
          asistentes cuya experiencia y especialización esté concentrada en
          áreas específicas aquí no contempladas pero que pueden resultar de
          nuestro trabajo en el Servicio.
        </p>
        <p>
          <strong>Liberación de Responsabilidad</strong>
        </p>
        <p>
          Al utilizar el Portal, usted estará utilizando una plataforma y/o
          página web propiedad de BLP, y usted reconoce y acepta que su uso es
          bajo su propio riesgo y que ninguna de las partes involucradas en
          crear, producir o entregar este Portal es responsable de ningún daño
          directo, incidental, consecuente, indirecto o punitivo ni de otras
          pérdidas, costos o gastos de ningún tipo (incluyendo pero no limitando
          a pago de comisiones por transacciones) que puedan surgir, directa o
          indirectamente, por el acceso a, uso de o búsqueda en este Portal o
          por descargar algún material, dato, texto, imagen, video o audio de
          este Portal, o por utilizar funciones de este Portal, incluyendo pero
          no limitándose a cualquier cosa que ocurra en virtud de un virus,
          errores del sistema (pulgas), procedimiento humano o falta de
          procedimiento humano o mal funcionamiento de algún sistema de cómputo,
          línea telefónica, hardware, software o programa o cualquier otro
          error, falla o retraso en transmisiones electrónicas o conexiones de
          red. BLP no será responsable por cualquier daño, responsabilidad o
          pérdida que derive del uso o dependencia del Portal.
        </p>
        <p>
          Usted acuerda mantener totalmente indemnes y responder frente a BLP y
          sus consejeros, directores, empleados y agentes, empresas y sociedades
          afiliadas o relacionadas, por cualquier reclamación, demanda, pérdida,
          responsabilidad y gasto (incluidos los honorarios de abogados) que
          deriven de: (i) el uso incorrecto del Portal; (ii) incumplimiento o
          violación de cualquiera de estos Términos y Condiciones; (iii) el uso
          por parte de BLP de su Contenido.
        </p>
        <p>
          <strong>Titularidad</strong>
        </p>
        <p>
          La titularidad del Portal y todos los derechos relativos a este son y
          permanecerán en propiedad de BLP. Ninguno de estos Términos y
          Condiciones ni el uso del Portal le transfieren u otorgan ningún
          derecho: (i) sobre o en relación con el Portal; o bien (ii) a utilizar
          o mencionar en cualquier modo los nombres de empresa, logotipos,
          facilidades o la marca de BLP.
        </p>
        <p>
          <strong>Restricciones</strong>
        </p>
        <p>
          Usted no podrá: (i) retirar cualquier nota de derechos de autor, marca
          registrada u otra nota de propiedad de cualquier parte del Portal;
          (ii) reproducir, modificar, preparar obras derivadas sobre el Portal,
          o distribuir, licenciar, arrendar, revender, transferir, exhibir
          públicamente, presentar públicamente, transmitir, retransmitir o
          explotar de otra forma el Portal, excepto como lo autorice
          expresamente BLP; (iii) descompilar, realizar ingeniería inversa o
          desmontar el Portal, excepto como se permita por la ley aplicable;
          (iv) enlazar, reflejar o enmarcar cualquier parte del Portal; (v)
          causar o lanzar cualquier programa o script con el objeto de extraer,
          indexar, analizar o de otro modo realizar prospección de datos de
          cualquier parte de del Portal o sobrecargar o bloquear indebidamente
          la operación y/o funcionalidad de cualquier aspecto del Portal; o (vi)
          intentar obtener un acceso no autorizado o dañar cualquier aspecto de
          la Aplicación o sus sistemas o redes relacionados.
        </p>
        <p>
          <strong>Credenciales para el uso del Portal</strong>
        </p>
        <p>
          Para acceder y utilizar el Portal, usted se deberá registrar y generar
          un usuario y clave de acceso (las “Credenciales"), las cuales deberán
          ser custodiadas por usted.
        </p>
        <p>
          Usted, como Cliente, entiende y acepta que, para garantizar la
          seguridad y eficiencia de las Credenciales, deberá ser diligente a la
          hora de registrar y utilizar las Credenciales. Se recomienda utilizar
          una combinación de símbolos, letras y números, con el fin de
          dificultar el que las Credenciales sean hurtadas o utilizadas por
          terceros.
        </p>
        <p>
          BLP no asume ningún tipo de responsabilidad por el uso y resguardo de
          las Credenciales, cuya protección y resguardo corresponderán
          exclusivamente a usted, el Cliente.
        </p>
        <p>
          <strong>Cuentas de Usuario</strong>
        </p>
        <p>
          Con el fin de utilizar el Portal, usted debe registrarse y mantener
          activa una cuenta personal de usuario del Portal (“Cuenta de Usuario”
          o “Cuenta”). Para obtener una Cuenta de Usuario debe de cumplir con
          los siguientes requisitos: (i) Si es Persona Jurídica: Ser una entidad
          debidamente inscrita y vigente; y, (ii) si es Persona Física: Tener
          como mínimo 18 años de edad. El registro de la Cuenta de Usuario le
          requiere que comunique a BLP determinada información personal, como su
          nombre, dirección, número de teléfono móvil, entre otros datos
          requeridos por las autoridades competentes para la emisión de facturas
          electrónicas. Usted garantiza y está de acuerdo en que, mientras use
          el Portal, usted no: (a) suplantará la identidad de ninguna persona ni
          desvirtuará su afiliación con alguna otra persona o entidad; (b)
          insertará su propio anuncio, posicionamiento de marca o algún otro
          contenido promocional o el de un tercero en cualquiera de los
          contenidos, materiales o servicios o materiales, ni usará,
          redistribuirá, republicará o explotará dichos contenidos o servicios
          con cualquier otro propósito adicional comercial o promocional; (c)
          intentará ganar acceso no autorizado a otros sistemas de cómputo a
          través del Portal; (d) usará el Portal o los servicios puestos a su
          disposición en o a través del Portal de alguna manera con la intención
          de interrumpir, dañar, deshabilitar, sobrecargar o deteriorar el
          Portal o dichos servicios, incluyendo, sin limitación, mandar mensajes
          masivos no solicitados o "inundar" servidores con solicitudes; (e)
          usará el Portal o los servicios o artículos del Portal en violación de
          la propiedad intelectual o de otros derechos legales o patrimoniales
          de BLP o de algún tercero; ni (f) usará el Portal en violación de
          cualquier ley aplicable. Usted se obliga a no intentar (o alentar o
          apoyar el intento de otro) a embaucar, destruir, decodificar, o de
          otro modo alterar o interferir con el Portal o con los servicios del
          Portal, o con cualquier contenido de este, o hacer cualquier uso no
          autorizado del mismo. Usted se obliga a no usar el Portal de alguna
          manera que pudiera dañar, deshabilitar, sobrecargar o deteriorar el
          Portal o interferir con que cualquier otra persona pueda usar o
          disfrutar del Portal o de cualquiera de sus servicios. Usted no
          obtendrá ni intentará obtener algún material o información a través de
          cualquier medio que no haya sido estipulado o puesto a la disposición
          del público intencionalmente a través del Portal.
        </p>
        <p>
          <strong>Requisitos &amp; Conducta del Usuario</strong>
        </p>
        <p>
          El Portal no está disponible para el uso de personas menores de 18
          años o para quienes no tengan plena capacidad legal para obligarse.
          Usted no podrá autorizar a terceros a utilizar su Cuenta. No podrá
          ceder o transferir de otro modo su Cuenta a cualquier otra persona o
          entidad. Usted acuerda cumplir con todas las leyes aplicables al
          utilizar el Portal y solo podrá utilizar el Portal con fines
          legítimos.
        </p>
        <p>
          En algunos casos, se le podrá requerir que facilite un documento de
          identidad para el acceso o uso del Portal, y usted acepta que se le
          podrá denegar el acceso o uso del Portal si se niega a facilitar el
          documento de identidad.
        </p>
        <p>
          <strong>Protección de Datos Personales</strong>
        </p>
        <p>
          Usted, como Cliente y al usar el Portal, se obliga a cumplir con la
          legislación vigente en Costa Rica y/o las políticas de BLP en materia
          de protección de datos personales, por lo que garantiza que tratará
          como confidenciales los datos personales que reciba o se transmitan en
          virtud del uso del Portal o Aplicaciones de BLP, incluyendo los datos
          personales de sus compañías, socios, empleados y/o representantes
          legales (“Datos Personales”). Asimismo, se obliga a dar a conocer a
          los titulares de los datos personales los avisos de privacidad que
          correspondan y a mantener las medidas de seguridad, administrativas,
          técnicas y físicas que permitan proteger los datos personales, contra
          daño, pérdida, alteración, destrucción, uso, acceso o tratamiento no
          autorizado. Lo anterior, con el fin de garantizar el cabal
          cumplimiento con la legislación de Costa Rica, sus reglamentos y los
          lineamientos que en su caso publiquen las autoridades
          correspondientes.
        </p>
        <p>
          <strong>
            Derechos de Propiedad Intelectual del Portal o Aplicaciones
          </strong>
        </p>
        <p>
          El sonido, gráficos, cuadros, texto, video, juegos, formulas, datos,
          información o imágenes de cualquier tipo que se encuentren en el
          Portal, son propiedad de BLP, o son usadas con la correspondiente
          autorización. Ninguna porción de este Portal o alguna Aplicación,
          incluyendo, pero sin limitarse al texto, imágenes, audio, juegos,
          aplicaciones o video, puede ser usada de ninguna forma o para ningún
          propósito, sin una autorización escrita de BLP, excepto si se indica
          aquí. El nombre “BLP”, y sus logotipos y marcas pertenecen a BLP. Al
          utilizar el Portal usted reconoce y acepta que cualquier nombre,
          logotipo, marca registrada, o marca de servicio, que aparezca en este
          Portal es propiedad exclusiva, o está siendo utilizada con la debida
          autorización por BLP y no puede ser usada y/o reproducida en todo o en
          parte por usted, sin la previa autorización escrita de BLP.
        </p>
        <p>
          BLP hará cumplir sus derechos de propiedad intelectual con toda la
          fuerza de la ley. El uso de cualquiera de estos materiales es
          prohibido a menos que aquí se especifique expresamente lo contrario.
          Modificar, publicar, copiar o usar el material de este Portal viola
          los derechos legales de BLP. Cualquier uso no autorizado de estos
          materiales puede estar sujeto a penalidades o daños, incluyendo, pero
          no limitándose a aquellos relacionados con la violación de derechos de
          autor, de marcas registradas, de privacidad y de publicidad.
        </p>
        <p>
          No promocione ni transfiera por medio de este Portal ningún material
          pornográfico, obsceno, inmoral, difamatorio, calumnioso, amenazador,
          discriminatorio, ofensivo, ilegal u otro material que pudiera
          constituir o motivar una conducta que pudiera ser considerada una
          ofensa criminal, crear una responsabilidad civil o violar alguna ley o
          regulación.
        </p>
        <p>
          Al ingresar en este Portal usted reconoce y acepta que cualquier
          comunicación o material que usted transfiera, de cualquier forma y por
          cualquier razón, no necesariamente será tratado como confidencial, ni
          de su propiedad, a no ser que el mismo se encuentre resguardado por el
          Secreto Profesional de la relación Abogado-Cliente.
        </p>
        <p>
          En algún momento podría ser necesario que usted suministre sus datos
          personales, incluyendo su nombre completo, edad, ocupación, genero,
          número de cédula, dirección exacta de su residencia, lugar de trabajo
          y contactos de referencia (los “Datos Personales”). Estos Datos
          Personales podrán utilizados por BLP para llevar control de las
          Cuentas que utilicen el Portal y los servicios contratados por estas.
          BLP podrá custodiar, tratar y almacenar los Datos Personales,
          asimismo, esta información podrá ser utilizada en caso de ser
          requerido para prospección comercial y usar además la información de
          contacto de la Cuenta.
        </p>
        <p>
          En lo relativo al respeto del derecho de autodeterminación informativa
          que asiste a las personas, BLP podría tener una base de datos donde se
          recopilarán los datos personales que sean solicitados en cualquier
          formulario, o documento que se utilice para el acceso a la Aplicación.
          La información contenida en la base de datos será de uso de BLP y este
          queda autorizado a traspasar dichos datos a la(s) empresa(s) que
          considere(n) pertinentes, incluyendo sus asesores externos y/o
          Afiliados comerciales. Usted, declara haber sido informado de las
          condiciones sobre protección de Datos Personales anteriormente
          descritas, aceptando y consintiendo el tratamiento automatizado de los
          mismos por parte de BLP, en la forma y para las finalidades indicadas
          en estos Términos y Condiciones y en los documentos que suscriba,
          durante el proceso de formalización de los servicios que contrate a
          BLP.
        </p>
        <p>
          En relación con la información que puede ser almacenada y utilizada en
          este Portal, esta además la información de carácter “no personal”, la
          cual puede (no exclusivamente), ser información técnica o información
          demográfica, como su edad, o intereses. A continuación, encontrará
          algunos ejemplos de Información que no es de identificación personal
          que puede ser recolectada por esta Aplicación y una descripción de
          cómo esta información puede llegar a ser usada:
        </p>
        <ol>
          <li>
            <strong>Identificación de Dispositivo</strong>. BLP podrá recopilar
            la información relativa al dispositivo que se utiliza ingresar al
            Portal.
          </li>
          <li>
            <strong>Información Demográfica</strong>. La “Información
            Demográfica” como su género, edad, código postal, e intereses, que
            usted voluntariamente nos suministra, puede ser usada para analizar
            tendencias, para asegurar que la información de la Aplicación llene
            sus necesidades.
          </li>
          <li>
            <strong>Incumplimiento Material</strong>. Una Parte puede rescindir
            este Acuerdo en cualquier momento por un incumplimiento importante o
            persistente por parte de la otra Parte. Cuando dicha infracción
            pueda remediarse, una Parte solo podrá rescindirla si la Parte
            infractora no ha subsanado dicha infracción dentro de los noventa
            días naturales posteriores a la notificación por escrito de dicha
            infracción.
          </li>
        </ol>
        <p>
          <strong>Duración</strong>
        </p>
        <p>
          El Acuerdo entre usted, el Cliente y BLP, entrará en vigor a partir de
          que usted se registre como usuario del Portal y continuará a menos que
          se cancele de conformidad con algún acuerdo paralelo a éste, o que
          usted deje de utilizar el Portal de forma permanente. Asimismo, estos
          Términos y Condiciones podrán ser modificadas por BLP en cualquier
          momento. Cada cambio entrará en vigor un día hábil después de haberse
          publicado y es responsabilidad suya revisar estos Términos y
          Condiciones cada vez que utiliza el Portal.
        </p>
        <p>
          <strong>Relación</strong>
        </p>
        <p>
          Los presentes Términos y Condiciones regulan el acceso o uso que usted
          haga, como persona, desde Costa Rica, o desde cualquier jurisdicción,
          de aplicaciones, páginas web, contenido, productos y servicios en
          relación con el Portal puesto a disposición por BLP.
        </p>
        <p>
          Mediante su acceso y uso del Portal, usted acuerda vincularse
          jurídicamente por estos Términos y Condiciones, que establecen una
          relación entre usted y BLP exclusivamente para efectos del uso del
          Portal y la eventual compra de Servicios.
        </p>
        <p>
          Si usted no acepta estos Términos y Condiciones, no podrá acceder o
          usar el Portal ni contratar Servicios por medio del mismo. Estos
          Términos y Condiciones sustituyen expresamente los acuerdos o
          compromisos previos con usted. BLP podrá poner fin de inmediato a
          estos Términos y Condiciones o cualquiera de las facilidades asociadas
          al Portal con respecto a usted o, en general, dejar de ofrecer o
          denegar el acceso al Portal o cualquier parte de este, en cualquier
          momento y por cualquier motivo. Se podrán aplicar condiciones
          suplementarias a determinadas facilidades suministrados por el Portal.
        </p>
        <p>
          No existe entre usted y BLP, una relación de empresa conjunta o
          relación de afiliados, empleo o agencia como resultado del contrato
          entre usted y BLP o del uso del Portal; y la única relación existente
          existirá si usted contrata los Servicios de BLP. Dichos Servicios se
          regularán por lo dispuesto en la LEY ORGÁNICA DEL COLEGIO DE ABOGADOS
          Y ABOGADAS DE COSTA RICA, y EL CÓDIGO DE DEBERES JURÍDICOS, MORALES Y
          ÉTICOS DEL PROFESIONAL EN DERECHO.
        </p>
        <p>
          <strong>Precios y Pago</strong>
        </p>
        <p>
          Es posible que usted pueda contratar y pagar ciertos servicios de BLP
          a través del Portal. También, BLP se reserva el derecho de cobrar por
          el uso de este Portal. En ambos supuestos, usted, como Cliente, podrá
          realizar el pago de Servicios mediante transferencia a las cuentas
          bancarias de BLP cuya información consta en este Portal , una vez
          aceptada la cotización por los servicios solicitados. Los precios
          aplicables a cada servicio son los indicados en la cotización
          remitida. Los honorarios establecidos se basan en las tarifas mínimas
          establecidas en el ARANCEL DE HONORARIOS POR SERVICIOS PROFESIONALES
          DE ABOGACÍA Y NOTARIADO (el “Arancel”).
        </p>
        <p>
          Los precios son estimados con base en parámetros razonables del
          mercado, por lo que podrían variar dependiendo de las particularidades
          de cada caso. Por favor comuníquese con el equipo de BLP para
          información adicional. Los montos señalados han sido calculados,
          asumiendo que el Cliente proporcionará oportunamente a BLP, toda la
          información y documentación de soporte requerida por BLP, para prestar
          los servicios legales correspondientes. La información de soporte
          entregada por el cliente, a solicitud de BLP, deberá de estar vigente,
          legible y preferiblemente en formato digital. Salvo en los casos
          señalados, ninguno de los montos indicados incluye gastos como timbres
          y cobros administrativos de entidades locales como el Registro
          Público. Por favor contacte a nuestro equipo para información. Salvo
          en los casos señalados, ninguno de los montos indicados incluye gastos
          notariales, los cuales se cobrarán con base en el Arancel vigente.
        </p>
        <p>
          El Cliente está de acuerdo en pagar los honorarios de BLP y los gastos
          en que se incurran, vinculados con el servicio que usted adquiere. No
          se procederá con la realización de los Servicios hasta que se haya
          confirmado el pago por parte de usted y se cuente con la información
          necesaria para la ejecución de estos. Una vez confirmado lo anterior,
          recibirá el comprobante de la transacción.
        </p>
        <p>
          Una vez realizado el pago y se cuente con la información necesaria
          para la ejecución del Servicios, el plazo de duración dependerá del
          Servicio adquirido. Cada servicio tiene referencia al plazo estimado
          para su ejecución. No existe responsabilidad en caso de que el
          servicio adquirido se atrase por culpa del cliente, instituciones
          públicas o de terceros. En caso de ser necesario la entrega de alguna
          documentación, el Cliente deberá ajustarse a los horarios y el lugar
          señalado por la persona a cargo del Servicio.
        </p>
        <p>
          El Cliente reconoce que las tarifas horarias podrán incrementar, según
          lo determine BLP. Sin embargo, antes de realizar cualquier aumento en
          nuestras tarifas, se solicitará su autorización previa y pago por la
          pasarela de pago de BLP.
        </p>
        <p>
          BLP se reserva el derecho de suspender al Cliente, el acceso al
          Portal, en caso de que rechace pagar las tarifas que BLP defina para
          su uso.
        </p>
        <p>
          <strong>Gastos e Impuestos</strong>
        </p>
        <p>
          No se incurrirá en ningún otro cargo sin su acuerdo previo. Cualquier
          honorario o gasto adicional que pueda generarse, fuera del alcance del
          trabajo acordado, será cotizado por BLP con base en el Arancel. Todos
          los gastos relacionados con los servicios prestados (copias,
          traducciones, legalizaciones, timbres, impuesto, especies fiscales,
          Courier, etc.) no forman parte de esta cotización a no ser que en su
          descripción haya sido expresamente incluido, por ende, correrán por
          cuenta exclusiva suya y serán facturados antes de incurrirse en dicho
          gasto, previa notificación al Cliente.
        </p>
        <p>
          De conformidad con lo dispuesto en la Ley 9635, de Fortalecimiento de
          las Finanzas Públicas, los servicios legales se entenderán localizados
          en el territorio de aplicación del Impuesto de Valor Agregado
          costarricense. El tipo de Impuesto de Valor Agregado aplicable será el
          legalmente vigente en cada momento en función del servicio concreto de
          que se trate.
        </p>
        <p>
          <strong>Confidencialidad y Secreto Profesional</strong>
        </p>
        <p>
          BLP garantiza que toda la información que sea provista por el Cliente
          será utilizada para cumplir los fines del Servicio y será tratada como
          información confidencial. En caso de que una autoridad gubernamental
          solicite información privilegiada/confidencial del cliente, BLP le
          informará al Cliente inmediatamente, salvo orden judicial en contra.
        </p>
        <p>
          El Cliente acepta revelar de manera completa y precisa todos los
          hechos e información que puedan ser relevantes para proveer el
          Servicio de manera integral, o bien, toda aquella información que BLP
          le pueda solicitar. Asimismo, el Cliente acuerda participar de
          reuniones y conferencias telefónicas, que se consideren indispensables
          para lograr entregar los Servicios.
        </p>
        <p>
          Durante la prestación de servicios legales, BLP podrá expresar
          opiniones o predicciones sobre asuntos presentes o contingencias
          futuras. Dichas expresiones o manifestaciones realizadas por cualquier
          abogado de BLP, debe considerarse como una recomendación o
          apreciación, basada en información que tuvimos a la mano en dicho
          momento y en ninguna circunstancia debe ser interpretado como una
          promesa o garantía.
        </p>
        <p>
          <strong>Derecho de Retracto y Garantía</strong>
        </p>
        <p>
          El servicio está implícitamente garantizado, en cuanto al cumplimiento
          de los estándares de calidad y los requerimientos técnicos que, por
          razones de legalidad, salud, medio ambiente, seguridad; establezcan
          las leyes, los reglamentos y las normas respectivas dictadas por la
          Administración Pública. Esta garantía implícita se refiere a la
          naturaleza, características, condiciones, calidad y utilidad o
          finalidad previsible para el cual normalmente es adquirido.
        </p>
        <p>
          Además, el Cliente, amparado al derecho de retracto, puede rescindir,
          sin su responsabilidad, la compra de los Servicios en un plazo de ocho
          días contados a partir de su perfeccionamiento. El retracto sólo
          procederá en el tanto los Servicios no se hubieren prestado
          efectivamente de manera parcial o total.
        </p>
        <p>
          En caso de aplicar el derecho de retracto y el mismo sea ejercido por
          el Cliente dentro del plazo de ocho (8) días luego de realizar la
          compra, se reintegrará los importes recibidos por el mismo medio
          utilizado para el pago. No obstante, el rubro correspondiente al costo
          de la transferencia bancaria, de ser necesaria, no será reintegrado.
        </p>
        <p>
          El reintegro del dinero se hará dentro del plazo de ocho días hábiles
          contados a partir de la notificación de su devolución.
        </p>
        <p>
          <strong>Derecho Mutuo para Rescindir la Relación Profesional</strong>
        </p>
        <p>
          Cualquier Cliente tiene el irrestricto derecho de dar por concluida su
          relación con BLP en cualquier momento y por cualquier razón. Además,
          podrá dejar de utilizar el Portal en el momento en que estime
          oportuno.
        </p>
        <p>
          BLP se reserva este derecho siempre que cumplamos con ciertos
          requisitos básicos como son una notificación oportuna y la posibilidad
          de trasladar toda la información pertinente a otro profesional. Dentro
          de las razones típicas por las que podemos dar por terminada nuestra
          representación están: (1) el no pago de los honorarios, gastos o
          costos relacionados; (2) la negativa por parte del Cliente de dirigir,
          apoyar o cooperar con los esfuerzos desplegados por BLP en su favor;
          (3) la falta de veracidad o el no revelar hechos relevantes en torno
          al servicio legal prestado; (4) la negativa por parte del Cliente en
          aceptar nuestros servicios o consejos; (5) el advenimiento o
          desarrollo de un conflicto de intereses, (6) uso abusivo, irregular o
          ilegal del Portal, o, (6) cualquier otra razón aceptada por el Código
          de Ética Profesional del Colegio de Abogados de la República de Costa
          Rica. Al darse la terminación, sea por voluntad del Cliente o de BLP,
          el Cliente asume la obligación de firmar, en caso de ser requerido,
          todos los documentos necesarios para dar por concluida la relación.
        </p>
        <p>
          <strong>Notificaciones</strong>
        </p>
        <p>
          BLP podrá realizar notificaciones por medio de una notificación en el
          Portal, o mediante una comunicación enviada al correo electrónico o
          por comunicación escrita enviada a su dirección, según lo dispuesto en
          su Cuenta.
        </p>
        <p>
          <strong>Nulidad</strong>
        </p>
        <p>
          Si cualquier disposición de estos Términos y Condiciones se
          considerara ilegal, nula o inexigible, ya sea en su totalidad o en
          parte, de conformidad con cualquier legislación, dicha disposición o
          parte de esta se considerará que no forma parte de estos Términos y
          Condiciones, aunque la legalidad, validez y exigibilidad del resto de
          las disposiciones de estos Términos y Condiciones no se verá afectada.
          En ese caso, BLP buscará reemplazar dicha disposición ilegal, nula o
          inexigible, en todo o en parte por una disposición legal, válida y
          exigible que tenga, en la medida de lo posible, un efecto similar al
          que tenía la disposición ilegal, nula o inexigible, dados los
          contenidos y el propósito de estos Términos y Condiciones.
        </p>
        <p>
          <strong>Ley Aplicable</strong>
        </p>
        <p>
          Al ingresar al Portal usted reconoce y acepta que su uso y estos
          Términos y Condiciones serán regidos, interpretados y evaluados de
          acuerdo con las leyes de la República de Costa Rica. Todas las
          controversias, diferencias, disputas o reclamos que pudieran derivarse
          de estos Términos y Condiciones, o el negocio y la materia a la que
          este se refiere, su ejecución, incumplimiento, liquidación,
          interpretación o validez, se resolverán por medio de arbitraje de
          conformidad con los reglamentos del Centro Internacional de
          Conciliación y Arbitraje de la Cámara Costarricense-Norteamericana de
          Comercio ("CICA"), a cuyas normas las Partes se someten en forma
          voluntaria e incondicional. La sede del arbitraje será la ciudad de
          San José, Costa Rica. El laudo arbitral se dictará por escrito, será
          definitivo, vinculante para las Partes e inapelable, salvo el recurso
          de revisión o de nulidad. Una vez que el laudo se haya dictado y se
          encuentre firme, producirá los efectos de cosa juzgada material y las
          Partes deberán cumplirlo sin demora. Los gastos relacionados con el
          arbitraje y los honorarios de los árbitros serán asumidos por las
          Partes en igual proporción conforme el procedimiento avance, salvo que
          el Tribunal Arbitral decidiere otra cosa. Los honorarios de los
          respectivos asesores y abogados serán asumidos por cada parte que los
          contrate, sin perjuicio que el laudo condene a la parte perdidosa a su
          pago.
        </p>
      </div>
    );
  }
}

export default withTranslation()(BLPVersion);
