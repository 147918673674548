import React from "react";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import CPText from "../../Utils/CPText";
import { authContext } from "../../../contexts/AuthContext";
import {
  listEventsByClient,
  listEventsByBrandLabel,
  listExpirationsByClient,
  listExpirationsByBrandLabel,
} from "../../../services/calendar";
import {
  listClientsByUser,
  getMainClient,
  listAuthorizedClientsByClientUser,
} from "../../../services/clients";

class Calendario extends React.Component {
  //Calendario para portal BLP
  constructor() {
    super();

    this.state = {
      date: {},
      events: [],
      selectedEvent: {},
      client: {},
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.setState({
      date: Date.now(),
    });
    if (this.context.auth.type === "blp") {
      this.fetchAlertsAndExpirations();
    } else if (this.context.auth.user.userType === "admin_IDB" || this.context.auth.user.userType === "admin_epa"
    || this.context.auth.user.userType === "admin_asal"
    || this.context.auth.user.userType === "admin_vololoans"
    || this.context.auth.user.userType === "admin_subway"
    || this.context.auth.user.userType === "admin_kraft") {
      this.fetchAlertsAndExpirations();
    } else if (this.context.auth.type === "client") {
      this.fetchClientExpirations();
    }
  }

  fetchAlertsAndExpirations = async () => {
    let data = [];
    if (
      this.context.auth.user.userType === "blp_admin" ||
      this.context.auth.user.userType === "admin_IDB"
      || this.context.auth.user.userType === "admin_epa"
      || this.context.auth.user.userType === "admin_asal"
      || this.context.auth.user.userType === "admin_vololoans"
      || this.context.auth.user.userType === "admin_subway"
      || this.context.auth.user.userType === "admin_kraft"
      || this.context.auth.user.role === "Director" ||
      this.context.auth.user.role === "Partner"
    ) {
      let alerts = await listEventsByBrandLabel();
      data = data.concat(alerts);
      let expirations = await listExpirationsByBrandLabel();
      data = data.concat(expirations);
    } else {
      let clientList = await listClientsByUser(this.context.auth.user.id);
      _.forEach(clientList, async (client) => {
        let res = await listEventsByClient(client.id);
        data = data.concat(res);
      });
      _.forEach(clientList, async (client) => {
        let res = await listExpirationsByClient(client.id, "1");
        data = data.concat(res);
      });
    }

    let events = data.map((alert) => {
      return {
        start: new Date(alert.expiration),
        end: new Date(alert.expiration),
        title: alert.filename,
        clientName: alert.name,
        documentName: alert.filename,
        message: alert.message,
        type: alert.type,
      };
    });

    this.setState({
      events: events,
    });
  };

  fetchClientExpirations = async () => {
    let mainClient = await getMainClient(this.context.auth.user.id);

    let data = [];
    if (this.context.auth.user.userType === "client_admin") {
      let res = await listExpirationsByClient(mainClient.id, "0");
      data = data.concat(res);
    } else if (this.context.auth.user.userType === "client_user") {
      let clientList = await listAuthorizedClientsByClientUser(
        this.context.auth.user.id
      );
      if (_.find(clientList, (client) => client.id === mainClient.id)) {
        // If the user has authorization on the main client, use that one
        let res = await listExpirationsByClient(mainClient.id, "0");
        data = data.concat(res);
      } else {
        // If not, use each authorized client
        _.forEach(clientList, async (client) => {
          let res = await listExpirationsByClient(client.id, "0");
          data = data.concat(res);
        });
      }
    }

    let events = data.map((alert) => {
      return {
        start: new Date(alert.expiration),
        end: new Date(alert.expiration),
        title: alert.filename,
        clientName: alert.name,
        documentName: alert.filename,
        message: alert.message,
        type: alert.type,
      };
    });

    this.setState({
      events: events,
    });
  };

  onNavigate = (newDate) => {
    this.setState({
      date: newDate,
    });
    if (this.context.auth.type === "blp") {
      this.fetchAlertsAndExpirations();
    } else if (this.context.auth.user.userType === "admin_IDB" || this.context.auth.user.userType === "admin_epa"
    || this.context.auth.user.userType === "admin_asal"
    || this.context.auth.user.userType === "admin_vololoans"
    || this.context.auth.user.userType === "admin_subway"
    || this.context.auth.user.userType === "admin_kraft") {
      this.fetchAlertsAndExpirations();
    } else if (this.context.auth.type === "client") {
      this.fetchClientExpirations();
    }
  };

  onSelectEvent = (event) => {
    this.setState({
      selectedEvent: event,
    });
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = window.$brand === "PRB" ? "#17afb6" : "#102a46";
    if (event.type === "expiration") backgroundColor = "#d32f2f";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  render() {
    const { t } = this.props;
    const localizer = momentLocalizer(moment);

    return (
      <Container component={Paper}>
        <Calendar
          localizer={localizer}
          date={this.state.date}
          onNavigate={this.onNavigate}
          events={this.state.events}
          style={{ height: "100vh" }}
          onSelectEvent={this.onSelectEvent}
          eventPropGetter={this.eventStyleGetter}
        />
        {/* <Fab
          color="primary"
          sx={{ position: "fixed", bottom: "40px", cursor: "pointer" }}
          onClick={() => alert("!!")}
        >
          <AddIcon />
        </Fab> */}
        <Dialog
          open={!_.isEmpty(this.state.selectedEvent)}
          onClose={() => this.setState({ selectedEvent: {} })}
        >
          <DialogTitle>
            {this.state.selectedEvent.type === "alert"
              ? t("Alert")
              : t("Expiration")}
          </DialogTitle>
          <DialogContent sx={{ mt: 2, p: 2, minWidth: 400 }}>
            <CPText
              label={t("Client")}
              text={this.state.selectedEvent.clientName}
            />
            <CPText
              label={t("Document")}
              text={this.state.selectedEvent.documentName}
            />
            {this.state.selectedEvent.type === "alert" && (
              <>
                <CPText label={t("Message")} />
                <CPText text={this.state.selectedEvent.message} />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ selectedEvent: {} })}>
              {t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(Calendario);
