import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ImageViewer from "react-simple-image-viewer";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Container, Grid, Paper, Radio } from "@mui/material";
import { withTranslation } from "react-i18next";
import CPText from "../../../Utils/CPText";
import CPButton from "../../../Utils/CPButton";
import { formatDate } from "../../../../Utils/Format";
import BlockUi from "react-block-ui";
import DocuWareUploadDialog from "./DocuWareUploadDialog";

class DocuWareSelectDialog extends React.Component {
  // Modal para elegir documento entre resultados de busqueda de DW
  constructor() {
    super();

    this.state = {
      open: false,
      blocking: false,
      results: [],
      selectedDoc: {},
      item: {},
      autocomplete: {
        CLIENTE: [],
        C_DULA_JUR_DICA: [],
        IDENTIFICACION: [],
      },
      openDocuWareUpload: false,
      isViewerOpen: false,
      viewerImages: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        open: this.props.data.open,
        results: this.props.data.results,
        item: this.props.data.item,
        subcategories: this.props.data.subcategories,
        selectedClientId: this.props.data.selectedClientId,
        preselectedCustomFile: this.props.data.preselectedCustomFile,
        selectCompanyFor: this.props.data.selectCompanyFor,
      });
    }
  }

  previewDocument = (id, cabinet) => {
    this.setState({
      blocking: true,
    });

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetDocuwareLowQualityImage?code=hFthtkMKEC3PyY03W9kA0X84xDWWyI7HSXzf83hoNS/HFpeCy3GssA==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          id: id,
          cabinet: cabinet,
        }),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {
        if (done) {
          const blob = new Blob([chunks], { type: "image/png" });
          parentComponentInReadClientModal.openImageViewer(
            URL.createObjectURL(blob)
          );
          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  openImageViewer = (image) => {
    this.setState({
      viewerImages: [image],
      isViewerOpen: true,
      blocking: false,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Dialog open={this.state.open} onClose={this.props.handleDialogClose}>
          <BlockUi tag="div" blocking={this.state.blocking}>
            <DialogTitle>{t("select_document")}</DialogTitle>
            <DialogContent>
              <div style={{ overflowY: "auto", height: "500px" }}>
                {this.state.results.map(
                  (row) =>
                    row.client !== "BLP ABOGADOS" && (
                      <Container sx={{ p: 2, minWidth: 400 }}>
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          <Radio
                            value={row.id}
                            name="radio-documents"
                            checked={this.state.selectedDoc.id === row.id}
                            onChange={() => {
                              let modified = this.state.item;
                              modified.extension = row.extension;
                              modified.url = `${row.cabinet}_${row.id}`;
                              modified.cabinet = row.cabinet;
                              this.setState({
                                item: modified,
                                selectedDoc: row,
                              });
                            }}
                          />
                          <Paper elevation={3} sx={{ p: 2 }}>
                            <CPText label={t("Client")} text={row.client} />
                            <CPText label={t("Case")} text={row.case} />
                            <CPText
                              label={t("Razón social")}
                              text={row.razonSocial}
                            />
                            <CPText
                              label={t("Expediente")}
                              text={row.expediente}
                            />
                            <CPText label={t("Type")} text={row.type} />
                            <CPText label={t("Document")} text={row.name} />
                            <CPText
                              label={t("Date")}
                              text={formatDate(t, row.date)}
                            />
                            <CPText
                              label={t("Remitido por")}
                              text={row.remitidoPor}
                            />
                            {row.ubicacion && (
                              <CPText
                                label={t("Ubicación física")}
                                text={row.ubicacion}
                              />
                            )}
                            <CPButton
                              onClick={() =>
                                this.previewDocument(row.id, row.cabinet)
                              }
                              icon={<RemoveRedEyeOutlinedIcon />}
                              tooltip={t("Preview")}
                            />
                          </Paper>
                        </Grid>
                      </Container>
                    )
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleDialogClose}>
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => {
                  this.setState({ openDocuWareUpload: true });
                  this.props.handleDialogClose();
                }}
              >
                {t("Select")}
              </Button>
            </DialogActions>
          </BlockUi>
        </Dialog>

        <DocuWareUploadDialog
          data={{
            open: this.state.openDocuWareUpload,
            item: this.props.data.item,
            subcategories: this.props.data.subcategories,
            selectedClientId: this.props.data.selectedClientId,
            results: this.state.results,
            preselectedCustomFile: this.state.preselectedCustomFile,
            selectCompanyFor: this.state.selectCompanyFor,
          }}
          handleDialogClose={() => this.setState({ openDocuWareUpload: false })}
          refreshFunc={this.props.refreshFunc}
        />

        {this.state.isViewerOpen && (
          <ImageViewer
            src={this.state.viewerImages}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={() =>
              this.setState({
                isViewerOpen: false,
              })
            }
          />
        )}
      </>
    );
  }
}

export default withTranslation()(DocuWareSelectDialog);
