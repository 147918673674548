import React from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { withTranslation } from "react-i18next";
import { misDocumentosContext } from "../../../contexts/MisDocumentosContext";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Alerts from "../../Alerts";
import DeleteDialog from "../../DeleteDialog";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatDate } from "../../../Utils/Format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CPText from "../../Utils/CPText";

class MisDocumentosNavigationDetail extends React.Component {
  // Card de detalle segun seleccion en el FullMisDocumentosNavigator
  // Render dependiendo de si se selecciona client, company, workspace, subcategory, document
  constructor() {
    super();

    this.state = {
      snackbarDownloadingOpen: false,
    };
  }

  static contextType = misDocumentosContext;

  componentDidMount() {}

  downloadStorageDocument = (doc) => {
    let client = this.context.data;
    let clientOrCompanyId =
      this.context.selectionChain[1] === -1
        ? client.id
        : this.context.selectionChain[1];

    this.setState({
      snackbarDownloadingOpen: true,
    });

    let filename = /[^/]*$/.exec(doc.url)[0];
    let rqData = {
      container:
        doc.source === "docusign"
          ? "prbdocusign"
          : "client-" + clientOrCompanyId,
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.props.userId,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      let chunks = [];

      reader.read().then(function processText({ done, value }) {
        if (done) {
          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension.toLowerCase()]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  renderClient = (props) => {
    let client = this.context.data;

    return (
      <div>
        {client.license && (
          <Typography variant="subtitle2" sx={{ pt: 1, pb: 1 }}>
            ID: {client.license}
          </Typography>
        )}
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{client.name}</strong>
        </Typography>
      </div>
    );
  };

  renderCompany = (props) => {
    let client = this.context.data;
    let company = _.find(client.companies, (c) => {
      return c.id === this.context.selectionChain[1];
    });

    if (!company) return <></>;
    return (
      <div>
        {company.license && (
          <Typography variant="subtitle2" sx={{ pt: 1, pb: 1 }}>
            ID: {company.license}
          </Typography>
        )}
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{company.name}</strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {client.name}
        </Typography>
      </div>
    );
  };

  renderWorkspace = (props) => {
    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }

    if (!workspace) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>
            {window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en}
          </strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""}`}
        </Typography>
      </div>
    );
  };

  renderSubcategory = (props) => {
    const { t } = this.props;

    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }
    if (!workspace) return <></>;

    let subcategory = _.find(workspace.subcategories, (s) => {
      return s.subcategory_en === this.context.selectionChain[3];
    });

    if (!subcategory) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>
            {window.$lang === "en"
              ? subcategory.subcategory_en
              : subcategory.subcategory_es
              ? subcategory.subcategory_es
              : subcategory.subcategory_en}
          </strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""} > ${
            window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en
          }`}
        </Typography>
      </div>
    );
  };

  renderFile = (props) => {
    const { t } = this.props;

    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }
    if (!workspace) return <></>;
    let subcategory = _.find(workspace.subcategories, (s) => {
      return s.subcategory_en === this.context.selectionChain[3];
    });
    let file =
      this.context.selectionChain[4] === -1
        ? null
        : _.find(subcategory.files, (f) => {
            return f.id === this.context.selectionChain[4];
          });

    if (!file) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{file.name}</strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""} > ${
            window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en
          } > ${
            window.$lang === "en"
              ? subcategory.subcategory_en
              : subcategory.subcategory_es
              ? subcategory.subcategory_es
              : subcategory.subcategory_en
          }`}
        </Typography>
      </div>
    );
  };

  renderDocument = (props) => {
    const { t } = this.props;

    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }
    if (!workspace) return <></>;
    let subcategory = _.find(workspace.subcategories, (s) => {
      return s.subcategory_en === this.context.selectionChain[3];
    });
    let file =
      this.context.selectionChain[4] === -1
        ? null
        : _.find(subcategory.files, (f) => {
            return f.id === this.context.selectionChain[4];
          });
    let document;
    if (!file) {
      document = _.find(subcategory.docs, (d) => {
        return d.id === this.context.selectionChain[5];
      });
    } else {
      document = _.find(file.docs, (d) => {
        return d.id === this.context.selectionChain[5];
      });
    }

    if (!document) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{document.filename}</strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""} > ${
            window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en
          } > ${
            window.$lang === "en"
              ? subcategory.subcategory_en
              : subcategory.subcategory_es
              ? subcategory.subcategory_es
              : subcategory.subcategory_en
          }${file !== null ? " > " + file.name : ""}`}
        </Typography>
        <CPText
          label={document.source !== "dwcopy" ? t("Uploaded") : t("Updated")}
          text={formatDate(t, document.modified)}
        />
        {document.expiration && (
          <CPText
            label={t("Expires")}
            text={formatDate(t, document.expiration)}
          />
        )}
        {document.notes && <CPText text={document.notes} />}
        <Grid container>
          <Grid item xs={6} container justifyContent="flex-start">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => this.downloadStorageDocument(document)}
            >
              {t("Download")}
            </Button>
          </Grid>
        </Grid>
        {!_.isEmpty(this.context.currentViewerDoc) && (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[this.context.currentViewerDoc]}
            config={{
              header: {
                disableFileName: true,
              },
            }}
            style={{ width: "100%", minHeight: 500, height: "100%" }}
          />
        )}
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Container
        component={Paper}
        sx={{
          minWidth: 275,
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: window.$brand === "BLP" ? 5 : 0,
          p: 2,
          mb: 2,
          mr: window.$brand === "BLP" ? 10 : 0,
        }}
      >
        {this.context.selectionChain.length === 1 ? (
          <this.renderClient />
        ) : this.context.selectionChain.length === 2 ? (
          <this.renderCompany />
        ) : this.context.selectionChain.length === 3 ? (
          <this.renderWorkspace />
        ) : this.context.selectionChain.length === 4 ? (
          <this.renderSubcategory />
        ) : this.context.selectionChain.length === 5 ? (
          <this.renderFile />
        ) : this.context.selectionChain.length === 6 ? (
          <this.renderDocument />
        ) : (
          <></>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarDownloadingOpen}
          onClose={() =>
            this.setState({
              snackbarDownloadingOpen: false,
            })
          }
          key={"bottomcentererror"}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="info"
            icon={<HourglassTopIcon />}
          >
            {t("downloading_document")}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withTranslation()(MisDocumentosNavigationDetail);
