export async function listEventsByClient(clientId) {
  let params = {
    clientId: clientId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListMonthEventsForCalendarByClient?code=VaeIT6fmnEoB7Q3O-62G-6A8pSPrjWkLkBfIkE2C7waxAzFuO4lLsA==";

  return sendReq(params, url);
}

export async function listExpirationsByClient(clientId, includeNotVisible) {
  let params = {
    clientId: clientId,
    includeNotVisible: includeNotVisible,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListMonthExpirationsForCalendarByClient?code=9FUtQmA-eG38zvpMatCX2oXhaBG14p_T6Z0jMh6iYbDBAzFuIBLqkg==";

  return sendReq(params, url);
}

export async function listExpirationsByBrandLabel() {
  let params = {
    brandLabel: window.$brand,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListMonthExpirationsForCalendar?code=nKzRe50Ir9h0o3FQI4VH9prTe_v7hSmpV31msW6Uu8eSAzFuyA6gOg==";

  return sendReq(params, url);
}

export async function listEventsByBrandLabel() {
  let params = {
    brandLabel: window.$brand,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListMonthEventsForCalendar?code=bqNe8KuRwUXkFd3YaLzEvxsi6TflN_UnQSF7BnKb9NdBAzFuaoS-UQ==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
