import React, { createContext, useState, useEffect } from "react";
import {
  listWorkspacesByClientId,
  listWorkspaceSubcategories,
  listFilesByClient,
} from "../services/workspaces";
import { listClientsByParent, getClientDataById } from "../services/clients";
import { listFeatureLabels } from "../services/features";
import { listClientDocuments } from "../services/documents";
import _, { forEach } from "lodash";
import { authContext } from "./AuthContext";
import { useContext } from "react";

export const clientContext = createContext({});

const ClientProvider = ({ children }) => {
  const { auth } = useContext(authContext);

  const [id, setId] = useState(-1);
  const [data, setData] = useState({
    id: -1,
    name: "",
    license: "",
    type: "",
    workspaces: [],
    companies: [],
  });
  const [workspaces, setWorkspaces] = useState({});
  const [selectionChain, setSelectionChain] = useState([]);
  const [currentViewerDoc, setCurrentViewerDoc] = useState({});

  const initContext = async () => {
    if (id === -1) return;

    let clientData = await getClientDataById(id);

    let data = {
      id: clientData.id,
      name: clientData.name,
      license: clientData.license,
      type: clientData.type,
    };

    let workspaces = await listWorkspacesByClientId(id);
    await Promise.all(
      workspaces.map(async (workspace) => {
        let list = await listWorkspaceSubcategories(workspace.workspace_id);
        workspace.subcategories = list;
      })
    );

    let courtProceedings = await listFeatureLabels(id, "Court Proceedings");
    if (!_.isEmpty(courtProceedings)) {
      let cpWorkspace = {
        name_en: "Court Proceedings",
        name_es: "Procesos Judiciales",
      };
      let cpSubcategories = [];
      courtProceedings.forEach((cp) => {
        cpSubcategories.push({
          subcategory_en: cp.label,
          subcategory_es: cp.label,
        });
      });
      cpWorkspace.subcategories = cpSubcategories;
      workspaces.push(cpWorkspace);
    }

    let administrativeProcesses = await listFeatureLabels(
      id,
      "Administrative Processes"
    );
    if (!_.isEmpty(administrativeProcesses)) {
      let apWorkspace = {
        name_en: "Administrative Processes",
        name_es: "Procesos Administrativos",
      };
      let apSubcategories = [];
      administrativeProcesses.forEach((ap) => {
        apSubcategories.push({
          subcategory_en: ap.label,
          subcategory_es: ap.label,
        });
      });
      apWorkspace.subcategories = apSubcategories;
      workspaces.push(apWorkspace);
    }

    data.workspaces = JSON.parse(JSON.stringify(workspaces));
    getClientFiles(id, data.workspaces);
    getDocuments(id, data.workspaces);

    //repeat call for each company
    let companies = await listClientsByParent(id);
    companies.forEach((c) => {
      c.workspaces = JSON.parse(JSON.stringify(workspaces));
      getClientFiles(c.id, c.workspaces);
      getDocuments(c.id, c.workspaces);
    });
    data.companies = companies;

    setWorkspaces(workspaces);

    setData(data);
  };

  const getDocuments = async (clientId, clientWorkspaces) => {
    let documents = await listClientDocuments(
      clientId,
      auth.type === "blp" ? "1" : "0"
    );
    
    documents.forEach((d) => {
      let ws = _.find(clientWorkspaces, (w) => {
        return w.name_en === d.category_en && w.name_es === d.category_es;
      });
      if (ws) {
        let wsSubcategories = ws.subcategories;
        let dSubcategory = _.find(wsSubcategories, (s) => {
          return (
            s.subcategory_en === d.subcategory_en &&
            s.subcategory_es === d.subcategory_es
          );
        });

        if (d.file_id === null) {
          //document directly in subcategory
          if (!dSubcategory.docs) dSubcategory.docs = [];
          dSubcategory.docs.push(d);
        } else {
          //document inside specific file
          let dFile = _.find(dSubcategory.files, (f) => {
            return f.id === d.file_id;
          });
          if (dFile) {
            if (!dFile.docs) dFile.docs = [];
            dFile.docs.push(d);
          }
        }
      }
    });
  };

  const getClientFiles = async (clientId, clientWorkspaces) => {
    let files = await listFilesByClient(clientId);
    files.forEach((f) => {
      let wsSubcategories = _.find(clientWorkspaces, (w) => {
        return w.workspace_id === f.category_id;
      }).subcategories;
      let fSubcategory = _.find(wsSubcategories, (s) => {
        return s.id === f.subcategory_id;
      });
      if (!fSubcategory.files) fSubcategory.files = [];
      fSubcategory.files.push(f);
    });
  };

  const refreshDeletedDoc = async (docId) => {
    _.forEach(data.workspaces, (w) => {
      _.forEach(w.subcategories, (s) => {
        _.forEach(s.files, (f) => {
          let idx = _.findIndex(f.docs, (d) => d.id === docId);
          if (idx > -1) {
            _.remove(f.docs, (d) => d.id === docId);
            return;
          }
        });

        let idx = _.findIndex(s.docs, (d) => d.id === docId);
        if (idx > -1) {
          _.remove(s.docs, (d) => d.id === docId);
          return;
        }
      });
    });

    _.forEach(data.companies, (c) => {
      _.forEach(c.workspaces, (w) => {
        _.forEach(w.subcategories, (s) => {
          _.forEach(s.files, (f) => {
            let idx = _.findIndex(f.docs, (d) => d.id === docId);
            if (idx > -1) {
              _.remove(f.docs, (d) => d.id === docId);
              return;
            }
          });

          let idx = _.findIndex(s.docs, (d) => d.id === docId);
          if (idx > -1) {
            _.remove(s.docs, (d) => d.id === docId);
            return;
          }
        });
      });
    });
  };

  const refreshUpdatedDoc = async (doc) => {
    _.forEach(data.workspaces, (w) => {
      _.forEach(w.subcategories, (s) => {
        _.forEach(s.files, (f) => {
          let idx = _.findIndex(f.docs, (d) => d.id === doc.id);
          if (idx > -1) {
            f.docs.splice(idx, 1, doc);
            return;
          }
        });

        let idx = _.findIndex(s.docs, (d) => d.id === doc.id);
        if (idx > -1) {
          s.docs.splice(idx, 1, doc);
          return;
        }
      });
    });

    _.forEach(data.companies, (c) => {
      _.forEach(c.workspaces, (w) => {
        _.forEach(w.subcategories, (s) => {
          _.forEach(s.files, (f) => {
            let idx = _.findIndex(f.docs, (d) => d.id === doc.id);
            if (idx > -1) {
              f.docs.splice(idx, 1, doc);
              return;
            }
          });

          let idx = _.findIndex(s.docs, (d) => d.id === doc.id);
          if (idx > -1) {
            s.docs.splice(idx, 1, doc);
            return;
          }
        });
      });
    });
  };

  const refreshAddedDoc = async () => {
    initContext();
  };

  useEffect(() => {
    initContext();
  }, [id]);

  return (
    <clientContext.Provider
      value={{
        id,
        setId,
        data,
        setData,
        workspaces,
        selectionChain,
        setSelectionChain,
        currentViewerDoc,
        setCurrentViewerDoc,
        refreshDeletedDoc,
        refreshUpdatedDoc,
        refreshAddedDoc,
      }}
    >
      {children}
    </clientContext.Provider>
  );
};

export default ClientProvider;
