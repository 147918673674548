export async function saveRetainer(params) {
  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/UpdateRetainer?code=bGw-RdCGK3DcydkKXIYKqDQrBwHmYBXF9i6Aoiwk7aMFAzFumCERgg==";

  await fetch(url, { method: "POST", body: JSON.stringify(params) });
}

export async function getRetainer(clientId) {
  let params = {
    clientId: clientId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/GetRetainerByClientId?code=KGAZtyojj4ewpZtLFkaTIyKNUaay4Xh3Prz6Js1BucDoAzFu8uBoPg==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
