import React from "react";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { withTranslation } from "react-i18next";

class ClientTitle extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Container component={Paper} sx={{ p: 2, mb: 2 }}>
        {this.props.titleCaption && (
          <>
            <Typography variant="subtitle2" sx={{ pt: 1, pb: 1 }}>
              {this.props.titleCaption}
            </Typography>
            <Typography component="h4" variant="h4" style={{ color: "#000" }}>
              <strong>{this.props.client.name}</strong>
            </Typography>
            <Typography component="h6" variant="h6" style={{ color: "#000" }}>
              {this.props.client.license}
            </Typography>
          </>
        )}
      </Container>
    );
  }
}

export default withTranslation()(ClientTitle);
