import "./App.css";
import "./fonts/MyriadPro-Light.otf";
import "./fonts/MYRIADPRO-REGULAR.OTF";
import "./fonts/MYRIADPRO-SEMIBOLD.OTF";
import Main from "./components/Main";
import { BrowserRouter } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import SignIn from "./components/Pages/SignIn/SignIn";
import SignInAD from "./components/Pages/SignIn/SignInAD";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/SignIn" component={SignIn} />
          <Route path="/SignInAD" component={SignInAD} />
          <PrivateRoute path="/" component={Main} />
        </Switch>
      </BrowserRouter>
      <Helmet>
        <title>
          {t("Client_Portal")} -{" "}
          {window.$brand === "PRB"
            ? "PredictaBill"
            : window.$brand === "ZAP"
            ? "Zapotal"
            : window.$brand === "IDB"
            ? "IDB"
            : window.$brand === "EPA"
            ? "EPA"
            : window.$brand === "ASAL"
            ? "ASAL"
            : window.$brand === "VOLOLOANS"
            ? "VOLOLOANS"
            : window.$brand === "SUBWAY"
            ? "SUBWAY"
            : window.$brand === "KRAFT"
            ? "KRAFT"
            : "ZAP"}
        </title>
      </Helmet>
    </div>
  );
}

export default App;
