import React from "react";
import Container from "@mui/material/Container";
import _ from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import Title from "../../Title";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { withTranslation } from "react-i18next";
import { authContext } from "../../../contexts/AuthContext";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPText from "../../Utils/CPText";
import DeleteDialog from "../../DeleteDialog";

class ScheduledReminders extends React.Component {
  // Pagina para agendar recordatorios de obligaciones y envio programado de noticias
  constructor() {
    super();

    this.state = {
      list: [],
      valueFechaEdit: null,
      valueFechaAdd: null,
      addReminder: {},
      editReminder: {},
      deleteReminder: {},
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchList();
  }

  handleAddReminderOpen = (reminder) => {
    this.setState({
      addReminder: reminder,
    });
  };

  handleAddReminderClose = () => {
    this.state.list.push(this.state.addReminder);
    this.handleDialogClose();
  };

  handleEditReminderOpen = (reminder) => {
    let d = new Date(reminder.date);
    if (reminder.date.length <= 10) d.setDate(d.getDate() + 1);
    this.setState({
      editReminder: reminder,
      valueFechaEdit: d,
    });
  };

  handleEditReminderClose = (reminder) => {
    reminder.title = this.state.editReminder.title;
    reminder.message = this.state.editReminder.message;
    reminder.date = this.state.editReminder.date;

    this.handleDialogClose();
  };

  handleDeleteReminderClose = () => {
    this.setState({
      deleteReminder: {},
    });
  };

  handleDialogClose = () => {
    this.setState({
      addReminder: {},
      editReminder: {},
      deleteReminder: {},
    });
  };

  insertReminder = (reminder) => {
    reminder.title = document.getElementById("insert_reminder_title").value;
    reminder.message = document.getElementById("insert_reminder_message").value;

    let month = this.state.valueFechaAdd.getMonth() + 1;
    let monthStr = month < 10 ? `0${month}` : month;
    let day = this.state.valueFechaAdd.getDate();
    let dayStr = day < 10 ? `0${day}` : day;
    reminder.date = `${this.state.valueFechaAdd.getFullYear()}-${monthStr}-${dayStr}`;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertScheduledReminder?code=mSE9U0G6oA0dw-nxh-SYfZAkkNBMj82rihb_rQhHo3VWAzFuz_L_Dg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(reminder),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          addReminder: data,
        });
        this.handleAddReminderClose();
      })
      .catch((error) => console.log(error));
  };

  updateReminder = (reminder) => {
    reminder.title = document.getElementById("edit_reminder_title").value;
    reminder.message = document.getElementById("edit_reminder_message").value;

    let month = this.state.valueFechaEdit.getMonth() + 1;
    let monthStr = month < 10 ? `0${month}` : month;
    let day = this.state.valueFechaEdit.getDate();
    let dayStr = day < 10 ? `0${day}` : day;
    reminder.date = `${this.state.valueFechaEdit.getFullYear()}-${monthStr}-${dayStr}`;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateScheduledReminder?code=_faYP0pSgvZfE_x5vbBm2IZAmEc8vbQvQeGmb9m1bG6KAzFu_RoILg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(reminder),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleEditReminderClose(reminder);
  };

  deleteReminder = (reminder) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteScheduledReminder?code=yWA-0y_5ZeQ30RVMil7JB-4BxkREEICSKB7MLFyyHkFuAzFuwu03wA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(reminder),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        _.remove(this.state.list, (r) => {
          return r.id === reminder.id;
        });
        this.forceUpdate();
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  fetchList = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListScheduledReminders?code=FvmHnUNzR_BwWHdbfxd_JMqlwC2h1kJ9_Nvmof0Y0orsAzFuJJb5xQ==";

    fetch(url, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        data.map((reminder) => {
          let d = new Date(reminder.date);
          let month = d.getMonth() + 1;
          let monthStr = month < 10 ? `0${month}` : month;
          let day = d.getDate();
          let dayStr = day < 10 ? `0${day}` : day;
          reminder.date = `${d.getFullYear()}-${monthStr}-${dayStr}`;
          return reminder;
        });
        this.setState({
          list: data,
        });
      });
  };

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Container component={Paper} sx={{ p: 2, mb: 2 }}>
          <Title>
            {t("Scheduled Reminders")}
            <Button
              sx={{
                m: 3,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() => this.handleAddReminderOpen({ id: -1 })}
            >
              {t("Add")}
            </Button>
          </Title>
          <List dense sx={{ maxWidth: 360 }}>
            {this.state.list.map((reminder) => (
              <ListItem
                key={reminder.id}
                secondaryAction={
                  <>
                    <CPEditIconButton
                      onClick={() => this.handleEditReminderOpen(reminder)}
                    />
                    <CPDeleteIconButton
                      onClick={() =>
                        this.setState({ deleteReminder: reminder })
                      }
                    />
                  </>
                }
                disablePadding
              >
                <ListItemButton>
                  <CPText label={reminder.date} text={reminder.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <DeleteDialog
            item={this.state.deleteReminder}
            title={t("Delete_reminder")}
            caption={t("delete_reminder_full_caption")}
            targetFunc={this.deleteReminder}
            handleDialogClose={this.handleDeleteReminderClose}
          />
        </Container>
        <Dialog
          open={!_.isEmpty(this.state.addReminder)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("create_reminder_title")}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mt: 2,
                width: "400px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Date")}
                  value={this.state.valueFechaAdd}
                  onChange={(newValue) => {
                    this.setState({
                      valueFechaAdd: newValue.$d,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              required
              id="insert_reminder_title"
              label={t("Subject")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 64 }}
            />
            <TextField
              sx={{ mt: 2 }}
              required
              multiline
              id="insert_reminder_message"
              label={t("Message")}
              fullWidth
              minRows={6}
              inputProps={{ maxLength: 2048 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.insertReminder(this.state.addReminder)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.editReminder)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("edit_reminder_title")}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mt: 2,
                width: "400px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Date")}
                  value={this.state.valueFechaEdit}
                  onChange={(newValue) => {
                    this.setState({
                      valueFechaEdit: newValue.$d,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              required
              id="edit_reminder_title"
              label={t("Subject")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editReminder.title}
              inputProps={{ maxLength: 64 }}
            />
            <TextField
              sx={{ mt: 2 }}
              required
              multiline
              id="edit_reminder_message"
              label={t("Message")}
              fullWidth
              minRows={6}
              defaultValue={this.state.editReminder.message}
              inputProps={{ maxLength: 2048 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => this.updateReminder(this.state.editReminder)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(ScheduledReminders);
