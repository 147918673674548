import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function DragDropFile(props) {
  const [dragActive, setDragActive] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") setDragActive(true);
    else if (e.type === "dragleave") setDragActive(false);
  };

  const handleDragOver = function (e) {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.fileChange(e);
      setLoaded(true);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.fileChange(e);
      setLoaded(true);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
      style={{
        textAlign: "center",
        position: "relative",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleChange}
        multiple={true}
        style={{ display: "none" }}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderWidth: "2px",
          borderStyle: "dashed",
          borderColor: "rgb(156 163 175 / 1)",
          backgroundColor: loaded ? "#77ff77" : dragActive ? "#fff" : "rgb(226 232 240 / 1)",
        }}
      >
        <div>
          <p>Drag and drop your file here or</p>
          <button
            onClick={onButtonClick}
            style={{
              cursor: "pointer",
              padding: "0.25rem",
              borderStyle: "none",
              backgroundColor: "transparent",
              textDecorationLine: "underline",
              marginBottom: "1rem",
            }}
          >
            Upload a file
          </button>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        ></div>
      )}
    </form>
  );
}

export default DragDropFile;
