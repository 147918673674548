import * as React from "react";
import Typography from "@mui/material/Typography";

const SubcategoryTitle = (props) => {
  return (
    <Typography
      style={{ color: window.$brand === "PRB" ? "#17afb6" : "#09a1b9", fontSize: "1.5em", fontWeight:"900" }}
      component="div"
      variant="h6"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
};

export default SubcategoryTitle;
