import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
  },
}));

class TableHeader extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    return (
      <TableHead>
        <TableRow>
          {this.props.cols.map((col) => (
            <StyledTableHeaderCell><strong>{col}</strong></StyledTableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default TableHeader;
