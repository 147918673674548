import React from "react";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import "react-block-ui/style.css";
import Box from "@mui/material/Box";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

class Permissions extends React.Component {
  // Modal de permisos, actualmente solo para PRB (Legally)
  constructor() {
    super();

    this.state = {
      permissions: undefined,
      filteredPermissions: [],
      assignedPermissions: [],
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchAssignedPermissions(this.props.user.id);
    this.fetchPermissionList();
  }

  fetchPermissionList = () => {
    let params = { category: "PRB_internal" };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListPermissions?code=UkLVJITpjrwRofv2onD6gcQAjMj_Wd5aw_nzojIlKMLoAzFuiGVUdA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          permissions: data,
          filteredPermissions: data,
        });
      });
  };

  fetchAssignedPermissions = (userId) => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedPermissionsByUser?code=dxk3aVcfAH_PQ7ApqpRB9D90bIab7ZJh-RyLp_RSATMAAzFuKHSGoQ==&userId=" +
        userId
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          assignedPermissions: _.map(data, "id"),
        });
      });
  };

  toggleAssignPermission = (event, permissionId) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignUserPermission?code=VljY-_czlNS1u8IpMfSSkBVPUl6hsCMR1THAtkVFUzWxAzFuI3L7Jg==";

    let assignedPermissions = this.state.assignedPermissions;
    if (event.target.checked) {
      assignedPermissions.push(permissionId);
    } else {
      _.remove(assignedPermissions, (pid) => {
        return pid == permissionId;
      });
    }
    this.setState({
      assignedPermissions: assignedPermissions,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        permissionId: permissionId,
        userId: this.props.user.id,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const res = this.state.permissions.filter((permission) => {
        return permission.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.setState({
        filteredPermissions: res,
      });
    } else {
      this.setState({
        filteredPermissions: this.state.permissions,
      });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Box sx={{ flexGrow: 1, mb: 4, p: 2, maxWidth: 400 }} component={Paper}>
        <SectionTitle>{t("Permissions")}</SectionTitle>
        <List dense sx={{ maxWidth: 360 }}>
          {this.state.filteredPermissions.map((permission) => (
            <ListItem key={permission.id} disablePadding>
              <ListItemButton>
                <Checkbox
                  edge="start"
                  onChange={(e) =>
                    this.toggleAssignPermission(e, permission.id)
                  }
                  checked={this.state.assignedPermissions.includes(
                    permission.id
                  )}
                />
                <ListItemText primary={permission.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }
}

export default withTranslation()(Permissions);
