import React from "react";
import "../fonts/MyriadPro-Light.otf";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MainListItems from "./ListItems";
import "./Main.css";
import Routes from "../Routes";
import i18next from "../i18nextConf";
import { SignOutButton } from "./SignOutButton";
import CPButton from "./Utils/CPButton";
import { NotificationBellButton } from "./NotificationBellButton";

// Estructura principal del sitio, Header, SideBar, Drawer

const drawerWidth = window.innerWidth <= 768 ? window.innerWidth : 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(0),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      main: window.$brand === "PRB" ? "#017cbc" : "#102a46",
    },
  },
  typography: {
    fontFamily: window.$brand === "PRB" ? "Montserrat" : "MyriadProRegular",
  },
});

function MainContent() {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [lang, setLang] = React.useState(true);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const toggleLang = () => {
    setLang(lang === "en" ? "es" : "en");
  };

  window.$lang = i18next.language;

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }} className="main-box">
        <CssBaseline />
        <AppBar position="absolute" open={drawerOpen} sx={{ boxShadow: 0 }}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              bgcolor: window.$brand === "PRB" ? "#1c213c" : "#102a46",
            }}
          >
            <CPButton
              icon={<MenuIcon />}
              onClick={toggleDrawer}
              edge="start"
              sx={{
                color: "#fff",
                marginRight: "36px",
                ...(drawerOpen && { display: "none" }),
              }}
            />
            <Typography sx={{ flexGrow: 1 }} />
            <SignOutButton />
            <a
              id="language-anchor"
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                cursor: "pointer",
                color: "#fff",
              }}
              onClick={() => {
                i18next.changeLanguage(i18next.language === "en" ? "es" : "en");
                window.$lang = i18next.language;
                toggleLang();
              }}
            >
              {i18next.language === "en" ? "ESP" : "ENG"}
            </a>
            <NotificationBellButton />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={drawerOpen}
          sx={{
            "& .MuiDrawer-paper": {
              borderWidth: 0,
              bgcolor: "#fff",
            },
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              px: [1],
              bgcolor: window.$brand === "PRB" ? "#1c213c" : "#102a46",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
            <Box
              component="img"
              alignItems="center"
              sx={{
                width:
                  window.$brand === "PRB"
                    ? 140
                    : window.$brand === "ZAP"
                    ? 180
                    : window.$brand === "IDB"
                    ? 180
                    : window.$brand === "EPA"
                    ? 180
                    : window.$brand === "ASAL"
                    ? 180
                    : window.$brand === "VOLOLOANS"
                    ? 180
                    : window.$brand === "SUBWAY"
                    ? 180
                    : window.$brand === "KRAFT"
                    ? 180
                    : 180,
                pl: window.$brand === "PRB" ? 4 : 4,
              }}
              alt="Logo"
              src={
                window.$brand === "PRB"
                  ? "/assets/legally_white.png"
                  : window.$brand === "ZAP"
                  ? "/assets/Microsite Blanco.png"
                  : window.$brand === "IDB"
                  ? "/assets/Microsite Blanco.png"
                  : window.$brand === "EPA"
                  ? "/assets/Microsite Blanco.png"
                  : window.$brand === "ASAL"
                  ? "/assets/Microsite Blanco.png"
                  : window.$brand === "VOLOLOANS"
                  ? "/assets/Microsite Blanco.png"
                  : window.$brand === "SUBWAY"
                  ? "/assets/legally_white.png"
                  : window.$brand === "KRAFT"
                  ? "/assets/legally_white.png"
                  : "/assets/Microsite Blanco.png"
              }
            />
          </Toolbar>
          <Divider />
          <List
            onClick={() => {
              if (window.innerWidth <= 768) toggleDrawer();
            }}
          >
            <MainListItems />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            backgroundImage: `url(${
              window.$brand === "PRB"
                ? "/assets/bgBrush.png"
                : "/assets/newblp_fondo.svg"
            })`,
            backgroundSize: window.$brand === "PRB" ? "auto" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Routes />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Main() {
  return <MainContent />;
}
