import React from "react";
import Container from "@mui/material/Container";
import { authContext } from "../../../contexts/AuthContext";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { withTranslation } from "react-i18next";
import { listPermissions } from "../../../services/permissions";
import "./PRBAdminCenter.css";
import _ from "lodash";

class PRBAdminCenter extends React.Component {
  // Dashboard de administracion para PredictaBill
  // Todo excepto "My metrics" y "Repositorio DocuSign" esta limitado a usuarios blp_admin
  constructor() {
    super();

    this.state = {
      metrics: {
        woodpecker_lastmonth: 0,
        docusign_lastmonth: 0,
        docusign_unassigned: 0,
        calculator_lastmonth: 0,
        helper_documentos: 0,
        helper_revision: 0,
        helper_clausulas: 0,
        helper_eforms: 0,
      },
      userPermissions: [],
    };
  }

  static contextType = authContext;

  async componentDidMount() {
    this.fetchAdminMetrics();

    let permissions = await listPermissions(this.context.auth.user.id);
    this.setState({
      userPermissions: permissions,
    });
  }

  hasPermission = (permissionName) => {
    return (
      _.find(this.state.userPermissions, (permission) => {
        return permission.name === permissionName;
      }) !== undefined
    );
  };

  fetchAdminMetrics = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetPRBAdminMetrics?code=HV3A1eUa26S87uYL4ssJLZJ443VeXlUEnUjmJ/Cq/SKP7pRjYg2hkA==";

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          metrics: data,
        });
      });
  };

  getCurrentMonth = () => {
    let d = new Date();

    const { t } = this.props;
    const months = [
      t("m_January"),
      t("m_February"),
      t("m_March"),
      t("m_April"),
      t("m_May"),
      t("m_June"),
      t("m_July"),
      t("m_August"),
      t("m_September"),
      t("m_October"),
      t("m_November"),
      t("m_December"),
    ];

    return months[d.getMonth()];
  };

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={1} sx={{ pl: 4 }}>
          {this.context.auth.user.userType === "blp_admin" && (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                <CardHeader
                  className="dashboard_card_header"
                  title={t("Scheduled Reminders")}
                />
                <CardContent></CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/ScheduledReminders",
                      });
                    }}
                  >
                    Ver detalles
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          <Grid item xs={4}>
            <Card sx={{ maxWidth: 250 }} className="dashboard_card">
              <CardHeader
                className="dashboard_card_header"
                title={t("My metrics")}
              />
              <CardContent></CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/MyMetrics",
                    });
                  }}
                >
                  Ver detalles
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {this.context.auth.user.userType === "blp_admin" && (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                <CardHeader
                  className="dashboard_card_header"
                  title="Mastercase"
                />
                <CardContent>
                  {/* <Typography
                    className="dashboard_card_title"
                    variant="body2"
                    color="text.secondary"
                  >
                    Registros de {this.getCurrentMonth()}
                  </Typography>
                  <Typography variant="h3" color="text.primary">
                    31
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/DetalleMastercase",
                      });
                    }}
                  >
                    Ver detalles
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          {(this.context.auth.user.userType === "blp_admin" ||
            this.hasPermission("DocuSign Repository")) && (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                <CardHeader
                  className="dashboard_card_header"
                  title="Repositorio DocuSign"
                />
                <CardContent>
                  <Typography
                    className="dashboard_card_title"
                    variant="body2"
                    color="text.secondary"
                  >
                    Documentos sin asignar
                  </Typography>
                  <Typography variant="h3" color="text.primary">
                    {this.state.metrics.docusign_unassigned}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/RepositorioDocuSign",
                      });
                    }}
                  >
                    Ver detalles
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          {this.context.auth.user.userType === "blp_admin" && (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                <CardHeader
                  avatar={
                    <Avatar
                      className="card_avatar"
                      src="/assets/calc_icon.png"
                      variant="rounded"
                    />
                  }
                  className="dashboard_card_header"
                  title="Calculadora de timbre fiscal"
                />
                <CardContent>
                  <Typography
                    className="dashboard_card_title"
                    variant="body2"
                    color="text.secondary"
                  >
                    Cálculos de {this.getCurrentMonth()}
                  </Typography>
                  <Typography variant="h3" color="text.primary">
                    {this.state.metrics.calculator_lastmonth}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/DetalleCalculadoraTimbreFiscal",
                      });
                    }}
                  >
                    Ver detalles
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          {this.context.auth.user.userType === "blp_admin" && (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                <CardHeader
                  avatar={
                    <Avatar
                      className="card_avatar"
                      src="/assets/woodpecker.png"
                      variant="rounded"
                    />
                  }
                  className="dashboard_card_header"
                  title="Woodpecker Forms"
                />
                <CardContent>
                  <Typography
                    className="dashboard_card_title"
                    variant="body2"
                    color="text.secondary"
                  >
                    Documentos de {this.getCurrentMonth()}
                  </Typography>
                  <Typography variant="h3" color="text.primary">
                    {this.state.metrics.woodpecker_lastmonth}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/DetalleWoodpeckerForms",
                      });
                    }}
                  >
                    Ver detalles
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          {this.context.auth.user.userType === "blp_admin" && (
            <Grid item xs={4}>
              <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                <CardHeader
                  avatar={
                    <Avatar
                      className="card_avatar"
                      src="/assets/docusign.png"
                      variant="rounded"
                    />
                  }
                  className="dashboard_card_header"
                  title="DocuSign"
                />
                <CardContent>
                  <div>
                    <Typography
                      className="dashboard_card_title"
                      variant="body2"
                      color="text.secondary"
                    >
                      Documentos firmados en {this.getCurrentMonth()}
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {this.state.metrics.docusign_lastmonth}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/DetalleDocuSign",
                      });
                    }}
                  >
                    Ver detalles
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          {this.context.auth.user.userType === "blp_admin" && (
            <>
              <Grid item xs={4}>
                <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                  <CardHeader
                    className="dashboard_card_header"
                    title="Helper - Documentos"
                  />
                  <CardContent>
                    <Typography
                      className="dashboard_card_title"
                      variant="body2"
                      color="text.secondary"
                    >
                      Documentos de {this.getCurrentMonth()}
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {this.state.metrics.helper_documentos}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/DetalleDocumentosHelper",
                        });
                      }}
                    >
                      Ver detalles
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                  <CardHeader
                    className="dashboard_card_header"
                    title="Helper - Cláusulas"
                  />
                  <CardContent>
                    <Typography
                      className="dashboard_card_title"
                      variant="body2"
                      color="text.secondary"
                    >
                      Cláusulas de {this.getCurrentMonth()}
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {this.state.metrics.helper_clausulas}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/DetalleClausulasHelper",
                        });
                      }}
                    >
                      Ver detalles
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                  <CardHeader
                    className="dashboard_card_header"
                    title="Helper - Checklists"
                  />
                  <CardContent>
                    <Typography
                      className="dashboard_card_title"
                      variant="body2"
                      color="text.secondary"
                    >
                      Checklists de {this.getCurrentMonth()}
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {this.state.metrics.helper_revision}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/DetalleRevisionHelper",
                        });
                      }}
                    >
                      Ver detalles
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ maxWidth: 250 }} className="dashboard_card">
                  <CardHeader
                    className="dashboard_card_header"
                    title="Helper - eForms"
                  />
                  <CardContent>
                    <Typography
                      className="dashboard_card_title"
                      variant="body2"
                      color="text.secondary"
                    >
                      eForms de {this.getCurrentMonth()}
                    </Typography>
                    <Typography variant="h3" color="text.primary">
                      {this.state.metrics.helper_eforms}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/DetalleDocumentosHelper",
                        });
                      }}
                    >
                      Ver detalles
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    );
  }
}

export default withTranslation()(PRBAdminCenter);
