import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  TextField,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import "react-block-ui/style.css";
import Box from "@mui/material/Box";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import CPText from "../../Utils/CPText";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import DeleteDialog from "../../DeleteDialog";
import { listWorkspaceSubcategories } from "../../../services/workspaces";

class WorkspaceSubcategories extends React.Component {
  // Administracion de subcategorias de cada workspace
  
  constructor() {
    super();

    this.state = {
      snackbarInfoOpen: false,
      subcategories: [],
      addSubcategory: {},
      editSubcategory: {},
      deleteSubcategory: {},
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchWorkspaceSubcategories(this.props.workspace.id);
  }

  handleAddSubcategoryOpen = (subcategory) => {
    this.setState({
      addSubcategory: subcategory,
    });
  };

  handleAddSubcategoryClose = () => {
    this.state.subcategories.push(this.state.addSubcategory);

    this.handleDialogClose();
  };

  handleEditSubcategoryOpen = (subcategory) => {
    this.setState({
      editSubcategory: subcategory,
    });
  };

  handleEditSubcategoryClose = (subcategory) => {
    subcategory.subcategory = this.state.editSubcategory.subcategory;

    this.handleDialogClose();
  };

  handleDeleteSubcategoryClose = () => {
    this.setState({
      deleteSubcategory: {},
    });
  };

  handleDialogClose = () => {
    this.setState({
      addSubcategory: {},
      editSubcategory: {},
      deleteSubcategory: {},
    });
  };

  insertSubcategory = (subcategory) => {
    subcategory.subcategory_en = document.getElementById(
      "insert_subcategory_en"
    ).value;
    subcategory.subcategory_es = document.getElementById(
      "insert_subcategory_es"
    ).value;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertWorkspaceSubcategory?code=K3bFqGi-XLEG6mAdxui67Mmts0yiVws5IaZBseaHp0-kAzFuHyYdVw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(subcategory),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          subcategory: data,
        });
        this.handleAddSubcategoryClose();
      })
      .catch((error) => console.log(error));
  };

  updateSubcategory = (subcategory) => {
    subcategory.subcategory_en = document.getElementById(
      "edit_subcategory_en"
    ).value;
    subcategory.subcategory_es = document.getElementById(
      "edit_subcategory_es"
    ).value;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateWorkspaceSubcategory?code=xoGgpH8h4CfCXsSVQN_7sDhknxY0q9u0bRxt5wUhcziAAzFuSopfQQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(subcategory),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleEditSubcategoryClose(subcategory);
  };

  deleteSubcategory = (subcategory) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteWorkspaceSubcategory?code=W3GGmusG3yNE4LN-Wi5IPlBBR-YGpZ4YxsFRH9cCKC2IAzFuzcGCwQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(subcategory),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        _.remove(this.state.subcategories, (s) => {
          return s.id === subcategory.id;
        });
        this.forceUpdate();
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  fetchWorkspaceSubcategories = async (workspaceId) => {
    let list = await listWorkspaceSubcategories(workspaceId);
    this.setState({
      subcategories: list,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Box sx={{ flexGrow: 1, mb: 4, p: 2, width: 400 }} component={Paper}>
        <SectionTitle>
          {this.props.workspace.name}
          <Button
            sx={{
              m: 3,
              backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#102a46",
              borderRadius: window.$brand === "PRB" ? 0 : 5,
            }}
            variant="contained"
            onClick={() =>
              this.handleAddSubcategoryOpen({
                workspaceId: this.props.workspace.id,
              })
            }
          >
            {t("Add")}
          </Button>
        </SectionTitle>
        <Box sx={{ pb: 4, pt: 4 }}>
          <List dense sx={{ maxWidth: 360 }}>
            {this.state.subcategories.map((subcategory) => (
              <ListItem
                key={subcategory.id}
                secondaryAction={
                  <>
                    <CPEditIconButton
                      onClick={() =>
                        this.handleEditSubcategoryOpen(subcategory)
                      }
                    />
                    <CPDeleteIconButton
                      onClick={() =>
                        this.setState({ deleteSubcategory: subcategory })
                      }
                    />
                  </>
                }
                disablePadding
              >
                <ListItemButton>
                  <CPText
                    text={
                      subcategory.subcategory_en +
                      " / " +
                      (subcategory.subcategory_es
                        ? subcategory.subcategory_es
                        : "")
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <DeleteDialog
          item={this.state.deleteSubcategory}
          title={t("Delete_subcategory")}
          caption={t("delete_subcategory_full_caption")}
          targetFunc={this.deleteSubcategory}
          handleDialogClose={this.handleDeleteSubcategoryClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.addSubcategory)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("add_subcategory_title")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="insert_subcategory_en"
              label={t("Subcategory_en")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 40 }}
              sx={{ pb: 3 }}
            />
            <TextField
              id="insert_subcategory_es"
              label={t("Subcategory_es")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 40 }}
              sx={{ pb: 3 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => this.insertSubcategory(this.state.addSubcategory)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.editSubcategory)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("edit_subcategory")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_subcategory_en"
              label={t("Subcategory_en")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editSubcategory.subcategory_en}
              inputProps={{ maxLength: 40 }}
              sx={{ pb: 3 }}
            />
            <TextField
              id="edit_subcategory_es"
              label={t("Subcategory_es")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editSubcategory.subcategory_es}
              inputProps={{ maxLength: 40 }}
              sx={{ pb: 3 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => this.updateSubcategory(this.state.editSubcategory)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default withTranslation()(WorkspaceSubcategories);
