export async function listFeatures(clientId) {
  let params = {
    clientId: clientId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedFeaturesByClient?code=4KAgASbEWhpY-Ld_vZHfIdJ9UXKLjC9B70n4msZKX2lxAzFu1AWsNg==";

  return sendReq(params, url);
}

export async function listFeatureLabels(clientId, featureName) {
  let params = {
    clientId: clientId,
    featureName: featureName,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListFeatureLabels?code=YQjXUcW-kuMK6ZrQb4eyXBbdtdeFy5cxSsbb3IOGAEa0AzFuSAmEaw==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
