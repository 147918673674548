import React from "react";
import { TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import "react-block-ui/style.css";
import Box from "@mui/material/Box";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { listWorkspacesByClientId } from "../../../services/workspaces";
import CPTip from "../../Utils/CPTip";
import { listWorkspacesByBrandLabel } from "../../../services/workspaces";

class AssignedWorkspacesCard extends React.Component {
  //Asignacion de workspaces en la config del cliente
  constructor() {
    super();

    this.state = {
      snackbarInfoOpen: false,
      workspaces: [],
      assignedWorkspaces: [],
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchAssignedWorkspaces(this.props.client.id);
    this.fetchWorkspaceList();
  }

  fetchWorkspaceList = async () => {
    let list = await listWorkspacesByBrandLabel();
    this.setState({
      workspaces: list,
    });
  };

  fetchAssignedWorkspaces = async (clientId) => {
    let list = await listWorkspacesByClientId(clientId);
    this.setState({
      assignedWorkspaces: _.map(list, "workspace_id"),
    });
  };

  toggleAssignWorkspace = (event, workspaceId) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignClientWorkspace?code=Bi4qfAAhADeHlh7RPJPEh4NuD10gPRmvLNX_cDrgqZrUAzFu2TnvXA==";

    let assignedWorkspaces = this.state.assignedWorkspaces;
    if (event.target.checked) {
      assignedWorkspaces.push(workspaceId);
    } else {
      _.remove(assignedWorkspaces, (wid) => {
        return wid == workspaceId;
      });
    }
    this.setState({
      assignedWorkspaces: assignedWorkspaces,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        workspaceId: workspaceId,
        clientId: this.props.client.id,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { t } = this.props;

    return (
      <Box
        sx={{
          flexGrow: 1,
          mb: 4,
          p: 2,
          maxWidth: 400,
          borderRadius: window.$brand === "PRB" ? 0 : 5,
        }}
        component={Paper}
      >
        <SectionTitle>{t("Workspaces")}</SectionTitle>
        <Box sx={{ pb: 2 }}>
          <CPTip
            text={
              window.$brand === "ZAP"
                ? t("Workspaces_assigned_to_condo")
                : t("Workspaces_assigned_to_client")
            }
          />
        </Box>
        <Autocomplete
          multiple
          options={this.state.workspaces}
          disableCloseOnSelect
          disableClearable
          getOptionLabel={(w) =>
            /* EN is required, ES is optional, default to EN */
            window.$lang === "en"
              ? w.name_en
              : w.name_es
              ? w.name_es
              : w.name_en
          }
          value={
            this.state.workspaces.filter((w) =>
              this.state.assignedWorkspaces.includes(w.id)
            ) || null
          }
          ChipProps={{
            onDelete: null,
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                onChange={(e) => this.toggleAssignWorkspace(e, option.id)}
                checked={this.state.assignedWorkspaces.includes(option.id)}
              />
              {/* EN is required, ES is optional, default to EN */}
              {window.$lang === "en"
                ? option.name_en
                : option.name_es
                ? option.name_es
                : option.name_en}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label={t("Workspaces")} />
          )}
        />
      </Box>
    );
  }
}

export default withTranslation()(AssignedWorkspacesCard);
