export async function savePredictYourClosingEnabled(params) {
  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/UpdatePredictYourClosingEnabled?code=_tT7BSlbrnikTdMFL9XgmEZPmcd29nuQEG7CD-7J06RrAzFuuRN4FA==";

  await fetch(url, { method: "POST", body: JSON.stringify(params) });
}

export async function getPredictYourClosingEnabled(clientId) {
  let params = {
    clientId: clientId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/GetPredictYourClosingEnabledByClientId?code=4akidaduesepdY4H9TwNv_hMMvwfXS_MdT-t9jYrO9jfAzFuvqpt2w==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
