import React from "react";
import { authContext } from "../../../contexts/AuthContext";
import { getMainClient } from "../../../services/clients";
import { withTranslation } from "react-i18next";
import RetainerCard from "./RetainerCard";
import { Grid } from "@mui/material";
import ProposalCard from "./ProposalCard";

class Retainer extends React.Component {
  // Pagina de funcionalidad renombrada "My Fees" o "Mis Honorarios"
  // Carga las horas facturadas al cliente en BillQuick en el case asignado en su config
  constructor() {
    super();

    let d = new Date();
    d.setDate(1);
    this.state = {
      client: {},
      retainerSettings: {},
      hoursSpent: 0,
      dateValue: d,
      minDate: new Date(d.getFullYear() - 1, d.getMonth(), 1),
      maxDate: new Date(d.getFullYear(), d.getMonth(), 1),
    };
  }

  static contextType = authContext;

  getMainClient = async () => {
    let data = await getMainClient(this.context.auth.user.id);
    this.setState({
      client: data,
    });
  };

  async componentDidMount() {
    await this.getMainClient();
  }

  render() {
    const { t } = this.props;

    if (!this.state.client.id) return <></>;
    else
      return (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 6, sm: 6, md: 12 }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <RetainerCard clientId={this.state.client.id} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ProposalCard clientId={this.state.client.id} />
          </Grid>
        </Grid>
      );
  }
}

export default withTranslation()(Retainer);
