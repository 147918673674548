import React from "react";
import Container from "@mui/material/Container";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { withTranslation } from "react-i18next";
import ClientCard from "./ClientCard";
import { Typography } from "@mui/material";
import PageTitle from "../../PageTitle";
import {
  insertClient,
  listClientsByUser,
  assignClientToWorkspace,
  assignLawyerToClient,
} from "../../../services/clients";

class Clientes extends React.Component {
  // Pagina con lista de clientes
  constructor() {
    super();

    this.state = {
      clients: undefined,
      filteredClients: undefined,
      addClient: {},
      searchClient: {},
      openDictionary: {},
      snackbarInfoOpen: false,
      selectedClientAdmin: {},
      selectedClientDetails: {},
      addClientError: {},
      workspaces: [],
      selectedWorkspace: null,
      selectedClient: {},
      clientSearchResults: [],
    };
  }

  static contextType = authContext;

  handleAddClientOpen = (client) => {
    this.setState({
      addClient: client,
    });
  };

  handleAddClientClose = () => {
    if (this.state.addClient.id && this.state.addClient.id > 0) {
      this.setState({
        clients: _.concat(this.state.clients, this.state.addClient),
        filteredClients: _.concat(
          this.state.filteredClients,
          this.state.addClient
        ),
      });
    }

    this.handleDialogClose();
  };

  handleAssignClientClose = () => {
    if (this.state.searchClient.id && this.state.searchClient.id > 0) {
      this.setState({
        clients: _.concat(this.state.clients, this.state.searchClient),
        filteredClients: _.concat(
          this.state.filteredClients,
          this.state.searchClient
        ),
      });
    }

    this.handleDialogClose();
  };

  handleSelectWorkspaceChange = (event) => {
    let modified = this.state.selectedWorkspace;
    modified = this.state.workspaces.find(
      (workspace) => workspace.id === event.target.value
    );
    this.setState(
      {
        selectedWorkspace: modified,
      },
      () => this.fetchClientList()
    );
  };

  handleAddClientTypeChange = (event) => {
    let modified = this.state.addClient;
    modified.type = event.target.value;
    this.setState({
      addClient: modified,
    });
  };

  handleDialogClose = () => {
    this.setState({
      addClient: {},
      addClientError: {},
      searchClient: {},
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarInfoOpen: false,
    });
  };

  componentDidMount() {
    this.fetchClientList();
  }

  fetchClientList = async () => {
    let clientList = await listClientsByUser(this.context.auth.user.id);
    this.setState({
      clients: clientList,
      filteredClients: clientList,
    });
  };

  insertClient = async (params) => {
    params.name = document.getElementById("insert_name").value;
    params.license = document.getElementById("insert_license").value;
    params.loggedUserId = this.context.auth.user.id;

    let client = await insertClient(params);
    let assignmentParams = {
      userId: this.context.auth.user.id,
      clientId: parseInt(client.id, 10),
      value: 1,
    };
    await assignLawyerToClient(assignmentParams);

    // this.setState({
    //   addClient: client,
    // });
    // this.handleAddClientClose();
    this.props.history.push({
      pathname: "/Administracion",
      state: { client },
    });
  };

  assignClient = async () => {
    let params = {
      clientId: this.state.searchClient.id,
      workspaceId: this.state.selectedWorkspace.id,
    };

    await assignClientToWorkspace(params);
    this.handleAssignClientClose();
  };

  validAddClient = () => {
    let nameEl = document.getElementById("insert_name");

    this.setState({
      addClientError: {
        // type: _.isEmpty(this.state.addClient.type),
        name: _.isEmpty(nameEl.value),
      },
    });

    return _.isEmpty(this.state.addClientError);
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const res = this.state.clients.filter((client) => {
        return (
          client.name.toLowerCase().includes(keyword.toLowerCase()) ||
          client.license.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      this.setState({
        filteredClients: res,
      });
    } else {
      this.setState({
        filteredClients: this.state.clients,
      });
    }
  };

  selectedClientAdminHandler = (e) => {
    this.setState({
      selectedClientAdmin: e,
    });
  };

  selectedClientDetailsHandler = (e) => {
    this.setState({
      selectedClientDetails: e,
    });
  };

  cardDeleteHandler = (e) => {
    _.remove(this.state.clients, (c) => {
      return c.id === e.id;
    });
    _.remove(this.state.filteredClients, (c) => {
      return c.id === e.id;
    });
    this.forceUpdate();
  };

  searchClients = () => {
    let params = {
      name: document.getElementById("search_name").value,
      brandLabel: window.$brand,
    };

    let searchUrl =
      "https://blpclientportalfunctions.azurewebsites.net/api/SearchClientsAndWorkspaces?code=1YUmW1QC68idRxeQZUcXbCIvmnRpBPFsrCINmldL61t6AzFuMGUntA==";

    fetch(searchUrl, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        //fix workspaces format

        this.setState({
          clientSearchResults: data,
        });
      });
  };

  render() {
    if (
      this.context.auth.user.userType === "client_admin" ||
      this.context.auth.user.userType === "client_user" ||
      !this.state.clients
    ) {
      return <div />;
    }

    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={6}>
            <PageTitle>
              {window.$brand === "ZAP" ? "Condominios" : window.$brand === "IDB" ? t("ClientTransactions") : t("Clients")}
            </PageTitle>
          </Grid>
          {this.context.auth.user.userType !== "admin_zapotal" && (
            <Grid item xs={6} container justifyContent="flex-end">
              <Button
                sx={{
                  m: 3,
                  backgroundColor:
                    window.$brand === "PRB" ? "#17afb6" : "#102a46",
                  borderRadius: window.$brand === "PRB" ? 0 : 5,
                }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                  this.handleAddClientOpen({ id: -1, type: "client" })
                }
              >
                {window.$brand === "ZAP"
                  ? t("Add_condominium")
                  : window.$brand === "IDB" 
                  ? t("add_clientTransactions")
                  : t("Add_client")}
              </Button>
            </Grid>
          )}
        </Grid>

        <TextField
          id="client-search"
          label={t("Client_filter")}
          onChange={this.filter}
          type="text"
          fullWidth
          variant="standard"
          sx={{ mb: 4, mt: 1 }}
        />

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {this.state.filteredClients.map((client) => (
            <Grid item xs={12} sm={8} md={4} key={client.id}>
              <ClientCard
                client={client}
                selectedClientAdminHandler={this.selectedClientAdminHandler}
                selectedClientDetailsHandler={this.selectedClientDetailsHandler}
                cardDeleteHandler={this.cardDeleteHandler}
              />
            </Grid>
          ))}
        </Grid>
        <Dialog
          open={!_.isEmpty(this.state.addClient)}
          onClose={this.handleAddClientClose}
        >
          <DialogTitle>
            {window.$brand === "ZAP" ? t("Add_condominium") : window.$brand === "IDB" ? t("ClientTransactions") : t("add_client")}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{ pb: 4 }}>
              {t("Remember_to_configure")}{" "}
              <SettingsOutlinedIcon fontSize="small" />{" "}
              {t("complete_client_process")}
            </Typography>

            <TextField
              required
              id="insert_name"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 50 }}
              error={this.state.addClientError.name}
            />
            <TextField
              id="insert_license"
              label={t("ID")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => {
                if (this.validAddClient())
                  this.insertClient(this.state.addClient);
              }}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarInfoOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          key={"bottomcentererror"}
        >
          <Alert elevation={6} variant="filled" severity="info">
            {t("password_sent")}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withTranslation()(Clientes);
