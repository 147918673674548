import * as React from "react";
import Typography from "@mui/material/Typography";

const Title = (props) => {
  return (
    <Typography
      style={{
        color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
        fontSize: "2em",
      }}
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
};

export default Title;
