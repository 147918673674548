import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { withTranslation } from "react-i18next";

class DeleteDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      item: {},
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.setState({
        item: this.props.item,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={!_.isEmpty(this.state.item)}
        onClose={this.props.handleDialogClose}
      >
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <p>{this.props.caption}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleDialogClose}>{t("Cancel")}</Button>
          <Button
            onClick={() => {
              this.props.targetFunc(this.state.item);
              this.props.handleDialogClose();
            }}
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(DeleteDialog);
