import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { authContext } from "../../../contexts/AuthContext";
import "./SignIn.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { verifyTOTP } from "../../../Utils/MFA";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";

const SignIn = (props) => {
  // Pagina de login para clientes (usuarios externos)

  const [snackbarErrorOpen, setSnackbarErrorOpen] = React.useState(false);
  const [snackbarTokenErrorOpen, setSnackbarTokenErrorOpen] =
    React.useState(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = React.useState(false);
  const [showToken, setShowToken] = React.useState(false);
  const [blockingLogin, setBlockingLogin] = React.useState(false);
  const { setAuthData } = useContext(authContext);
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = React.useState();
  const [currentData, setCurrentData] = React.useState();
  const [currentToken, setCurrentToken] = React.useState();
  const [termsUser, setTermsUser] = React.useState();

  const handleSnackbarClose = () => {
    setSnackbarErrorOpen(false);
    setSnackbarSuccessOpen(false);
  };

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    setBlockingLogin(true);

    let creds = {};
    creds.email = document.getElementById("login_email").value;
    creds.password = document.getElementById("login_password").value;
    creds.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/CredentialsLogin?code=VU5WpE98kpbnTZxIkPoITlk/kQWzcG6WEVj7fwy5Hi0eEaHoUzYG2w==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(creds),
    })
      .then((res) => {
        if (res.status === 401) {
          setSnackbarErrorOpen(true);
        } else if (res.status === 200) {
          let data = res.json();
          data.then((token) => {
            let id = _.find(token.claims, (claim) => claim.type === "id").value;
            let first_name = _.find(
              token.claims,
              (claim) => claim.type === "first_name"
            ).value;
            let last_name = _.find(
              token.claims,
              (claim) => claim.type === "last_name"
            ).value;
            let userType = _.find(
              token.claims,
              (claim) => claim.type === "user_type"
            ).value;
            let email = _.find(
              token.claims,
              (claim) => claim.type === "email"
            ).value;
            let imageURL = _.find(
              token.claims,
              (claim) => claim.type === "image_url"
            ).value;
            let phone = _.find(
              token.claims,
              (claim) => claim.type === "phone"
            ).value;
            let mobile = _.find(
              token.claims,
              (claim) => claim.type === "mobile"
            ).value;
            let mfaEnabled = _.find(
              token.claims,
              (claim) => claim.type === "mfa_enabled"
            ).value;
            let mfaSecret = _.find(
              token.claims,
              (claim) => claim.type === "mfa_secret"
            ).value;
            let acceptedTerms = _.find(
              token.claims,
              (claim) => claim.type === "accepted_terms"
            ).value;
            let user = {
              id: id,
              first_name: first_name,
              last_name: last_name,
              userType: userType,
              email: email,
              image_url: imageURL,
              phone: phone,
              mobile: mobile,
              mfaEnabled: mfaEnabled,
              mfaSecret: mfaSecret,
              acceptedTerms: acceptedTerms,
            };
            setCurrentUser(user);
            setCurrentData(data);
            setCurrentToken(token);
            if (user.mfaEnabled === "0") {
              if (_.isEmpty(user.acceptedTerms)) {
                setTermsUser({ acceptedTerms: true });
              } else {
                setAuthData("client", user, data, token);
                if (userType === "admin_IDB"
                || userType === "admin_epa"
                || userType === "admin_asal"
                || userType === "admin_vololoans"
                || userType === "admin_subway"
                || userType === "admin_kraft"
                ) {
                  props.history.push("/Clientes");
                }else if (userType === "admin_zapotal") {
                  props.history.push("/Condominios");
                }else {
                  props.history.push("/MisDocumentos");
                }
                setSnackbarSuccessOpen(true);
              }
              // setEnableMFAUser(user);
            } else if (user.mfaEnabled === "1") {
              setShowToken(true);
              document.getElementById("login_token").focus();
            }
          });
        }
        setBlockingLogin(false);
      })
      .catch((error) => {
        console.log(error);
        setBlockingLogin(false);
      });
  };

  const onLoginTokenSubmit = () => {
    let token = document.getElementById("login_token").value;
    let result = verifyTOTP(token, currentUser.mfaSecret);
    if (result) {
      if (_.isEmpty(currentUser.acceptedTerms)) {
        setTermsUser({ acceptedTerms: true });
      } else {
        setAuthData("client", currentUser, currentData, currentToken);
        props.history.push("/MisDocumentos");
        setSnackbarSuccessOpen(true);
      }
    } else {
      setSnackbarTokenErrorOpen(true);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (!showToken) {
        onLoginFormSubmit(e);
      } else {
        onLoginTokenSubmit();
      }
    }
  };

  const handleDialogClose = () => {
    setTermsUser({});
    // setEnableMFAUser({});
  };

  const acceptTermsAndConditions = () => {
    let params = { userId: currentUser.id };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/AcceptTermsAndConditions?code=kyS3EKxwpwilekWwB3QYiHvEEoJcH4g7sDf_MAnlax6XAzFuALhBqg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Accepted");

        setAuthData("client", currentUser, currentData, currentToken);
        props.history.push("/MisDocumentos");
        setSnackbarSuccessOpen(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Box className="login-box">
      <div
        className="login-wrap"
        style={{
          backgroundImage: `url(${
            window.$brand === "PRB"
              ? "/assets/bg_PRB.png"
              : "/assets/bg_BLP.png"
          })`,
        }}
      >
        <BlockUi tag="div" blocking={blockingLogin}>
          <form className="login-form" action="">
            <div className="login-form-header">
              <Box
                component="img"
                alignItems="center"
                sx={{
                  height: window.$brand === "PRB" ? 80 : 60,
                }}
                alt="Logo"
                src={
                  (window.$brand === "PRB" || window.$brand === "SUBWAY" || window.$brand === "KRAFT")
                    ? "/assets/legally.png"
                    : "/assets/BLP Micrositeazulverde.png"
                }
              />
              {window.$brand === "BLP" && <h3>{t("Client_Portal")}</h3>}
            </div>
            <div className="login-form-group">
              <TextField
                disabled={showToken}
                required
                id="login_email"
                label={t("Email")}
                type="text"
                fullWidth
                variant="standard"
                onKeyDown={handleEnter}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div className="login-form-group">
              <TextField
                disabled={showToken}
                required
                id="login_password"
                label={t("Password")}
                type="password"
                fullWidth
                variant="standard"
                onKeyDown={handleEnter}
                inputProps={{ maxLength: 20 }}
              />
            </div>
            {showToken && (
              <div className="login-form-group">
                <TextField
                  required
                  id="login_token"
                  label={"Token"}
                  type="text"
                  fullWidth
                  variant="standard"
                  onKeyDown={handleEnter}
                  inputProps={{ maxLength: 6 }}
                />
              </div>
            )}
            <div className="login-form-group">
              <Button
                onClick={(e) => {
                  if (!showToken) {
                    onLoginFormSubmit(e);
                  } else {
                    onLoginTokenSubmit();
                  }
                }}
                style={{
                  color: `${window.$brand === "PRB" ? "#cc0033" : "#05539c"}`,
                }}
              >
                {t("Login")}
              </Button>
            </div>
            {/* <div className="login-form-footer">
              <SignInButton history={props.history} />
            </div> */}
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbarErrorOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              key={"bottomcentererror"}
            >
              <Alert elevation={6} variant="filled" severity="error">
                {t("Invalid_credentials")}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbarTokenErrorOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              key={"bottomcentererror"}
            >
              <Alert elevation={6} variant="filled" severity="error">
                {t("Invalid_token")}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbarSuccessOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              key={"bottomcentersuccess"}
            >
              <Alert elevation={6} variant="filled" severity="success">
                {t("Welcome")}
              </Alert>
            </Snackbar>
          </form>
        </BlockUi>
      </div>
      <TermsAndConditions
        user={termsUser}
        handleDialogClose={handleDialogClose}
        acceptTermsAndConditions={acceptTermsAndConditions}
      />
    </Box>
  );
};

export default SignIn;
