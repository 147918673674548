import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BlockUi from "react-block-ui";
import { formatDate } from "../../../../Utils/Format";
import DragDropFile from "../../../Utils/drag-drop-file";
import {
  getClientDataById,
  listClientsByParent,
} from "../../../../services/clients";
import { clientContext } from "../../../../contexts/ClientContext";

class LocalUploadDialog extends React.Component {
  // Modal para carga de documentos desde maquina local
  constructor() {
    super();

    this.state = {
      open: false,
      item: {},
      inputFile: null,
      selectedFile: null,
      vencimiento: null,
      error: {},
      blocking: false,
      preselectedCustomFile: null,
      selectCompanyFor: null,
      filename: "",
      tipo_libro: "",
      numero_acta: 0,
      numero_tomo: 0,
      fecha_libro: null,
      clientOrCompanies: [],
      selectedClientOrCompany: -1,
    };
  }

  static contextType = clientContext;

  fileinputAcceptProps = {};

  componentDidMount() {
    if (window.$brand === "BLP")
      this.fileinputAcceptProps.accept = "application/pdf";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState(
        {
          open: this.props.data.open,
          item: this.props.data.item,
          subcategories: this.props.data.subcategories,
          selectedClientId: this.props.data.selectedClientId,
          preselectedCustomFile: this.props.data.preselectedCustomFile,
          selectCompanyFor: this.props.data.selectCompanyFor,
        },
        () => {
          if (
            !_.isEmpty(this.props.data.selectCompanyFor) &&
            this.props.data.selectCompanyFor.id >= 0
          )
            this.fetchClientOrCompanies(this.props.data.selectCompanyFor.id);
        }
      );
    }
  }

  fetchClientOrCompanies = async (clientId) => {
    let clientData = await getClientDataById(clientId);
    clientData.type = "client";
    let companies = await listClientsByParent(clientId);

    let clientOrCompanies = [];
    clientOrCompanies.push(clientData);
    _.forEach(companies, (c) => clientOrCompanies.push(c));
    this.setState({
      clientOrCompanies: clientOrCompanies,
    });
  };

  onFileChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files && event.target.files[0]) {
      this.setState({
        selectedFile: event.target.files[0],
        filename: event.target.files[0].name.split(".")[0],
      });
    } else if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      this.setState({
        selectedFile: event.dataTransfer.files[0],
        filename: event.dataTransfer.files[0].name.split(".")[0],
      });
    }

    document.getElementById("input_upload_filename").focus();
  };

  handleCategoryChange = (event) => {
    let modified = this.state.item;
    let selectedValue = event.target.value;

    let workspace =
      this.state.subcategories[parseInt(selectedValue / 1000)].workspace;
    let subcategory =
      this.state.subcategories[parseInt(selectedValue / 1000)].subcategories[
        parseInt(selectedValue % 1000)
      ];

    if (workspace.workspace_id) {
      modified.category_id = workspace.workspace_id;
      modified.subcategory_id = subcategory.id;
    } else {
      modified.category_en = workspace.name_en;
      modified.category_es = workspace.name_es;
      modified.subcategory_en = subcategory.subcategory_en;
      modified.subcategory_es = subcategory.subcategory_es;
    }

    this.setState({
      item: modified,
    });
  };

  handleClientOrCompanyChange = (event) => {
    let modified = this.state.item;
    let selectedValue = event.target.value;

    modified.assignedTo = selectedValue;

    this.setState({
      item: modified,
      selectedClientOrCompany: selectedValue,
    });
  };

  handleBookTypeChange = (event) => {
    this.setState({
      tipo_libro: event.target.value,
    });
  };

  valid = () => {
    let filenameEl = document.getElementById("input_upload_filename");
    //let fileEl = document.getElementById("input_upload_file");

    let error = {};
    if (
      !this.state.item.category_en &&
      !this.state.item.category_id &&
      !this.state.preselectedCustomFile
    )
      error.category = true;
    //if (_.isEmpty(fileEl.value)) error.file = true;
    if (_.isEmpty(filenameEl.value)) error.filename = true;

    if (this.state.selectCompanyFor && this.state.item.assignedTo < 0)
      error.clientOrCompany = true;

    if (!this.state.selectedFile) {
      error.file = true;
    }

    // if (
    //   window.$brand === "BLP" &&
    //   this.state.selectedFile &&
    //   this.state.selectedFile.type !== "application/pdf"
    // ) {
    //   error.notpdf = true;
    // }

    if (this.showDatosLibro()) {
      if (_.isEmpty(this.state.tipo_libro)) error.tipo_libro = true;
      if (_.isEmpty(this.state.numero_acta)) error.numero_acta = true;
      if (_.isEmpty(this.state.numero_tomo)) error.numero_tomo = true;
      if (this.state.fecha_libro === null) error.fecha_libro = true;
    }

    this.setState({
      error: error,
    });
    if (!_.isEmpty(error)) this.setState({ blocking: false });

    return _.isEmpty(error);
  };

  uploadFile = () => {
    let formData = new FormData();
    formData.append("file", this.state.selectedFile);
    formData.append("clientId", this.state.item.assignedTo);

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UploadFileToStorage?code=ZDKuIDH83i4Yd5Z1BloqtKL8C2kKUWIfhba0b5OA5CnacQhh18OXjA==";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          selectedFile: null,
        });

        let uploadData = this.state.item;
        uploadData.url = data.url;
        let extension = data.url.substring(data.url.lastIndexOf("."));
        uploadData.extension = extension;
        this.insertDocumentToDB(uploadData);
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error);
      });
  };

  insertDocumentToDB = (uploadData) => {
    uploadData.filename = document.getElementById(
      "input_upload_filename"
    ).value;
    uploadData.notes = document.getElementById("input_upload_notes").value;
    uploadData.visible = document.getElementById("input_upload_visible_cb")
      .checked
      ? "1"
      : "0";
    if (this.state.preselectedCustomFile) {
      uploadData.file_id = this.state.preselectedCustomFile.id;
    }

    if (document.getElementById("input_upload_expiration_cb").checked) {
      let month = this.state.vencimiento.$M + 1;
      let monthStr = month < 10 ? `0${month}` : month;
      let day = this.state.vencimiento.$D;
      let dayStr = day < 10 ? `0${day}` : day;
      uploadData.expiration = `${this.state.vencimiento.$y}-${monthStr}-${dayStr}`;
    }

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertDocument?code=74hsURB0abOx7ak88UW/Y/Q2TPDe1QuNunuduLXWfq2SB6J/orepdw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(uploadData),
    })
      .then((res) => res.json())
      .then((data) => {
        this.props.refreshFunc();
        this.setState({
          blocking: false,
          tipo_libro: "",
          numero_acta: 0,
          numero_tomo: 0,
          fecha_libro: null,
          filename: "",
          selectedClientOrCompany: -1,
        });

        this.context.refreshAddedDoc();
      })
      .catch((error) => {
        this.setState({
          blocking: false,
          tipo_libro: "",
          numero_acta: 0,
          numero_tomo: 0,
          fecha_libro: null,
          filename: "",
          selectedClientOrCompany: -1,
        });
        console.log(error);
      });
    this.props.handleDialogClose();
  };

  renderSelectGroup = (workspace, idxWorkspace) => {
    const items = workspace.subcategories.map((subcategory, idxSubcategory) => {
      return (
        <MenuItem
          key={idxWorkspace * 1000 + idxSubcategory}
          value={idxWorkspace * 1000 + idxSubcategory}
        >
          {/* EN is required, ES is optional, default to EN */}
          {window.$lang === "en"
            ? subcategory.subcategory_en
            : subcategory.subcategory_es
            ? subcategory.subcategory_es
            : subcategory.subcategory_en}
        </MenuItem>
      );
    });

    return [
      <ListSubheader>
        {/* EN is required, ES is optional, default to EN */}
        {window.$lang === "en"
          ? workspace.workspace.name_en
          : workspace.workspace.name_es
          ? workspace.workspace.name_es
          : workspace.workspace.name_en}
      </ListSubheader>,
      items,
    ];
  };

  showDatosLibro = () => {
    return this.state.item.subcategory_id === 140;
  };

  filename = () => {
    const { t } = this.props;

    if (this.showDatosLibro()) {
      return `${this.state.tipo_libro}, acta ${this.state.numero_acta}, tomo ${
        this.state.numero_tomo
      }, ${
        this.state.fecha_libro === null
          ? ""
          : formatDate(t, this.state.fecha_libro.$d)
      }`;
    } else {
      return this.state.filename;
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Dialog open={this.state.open} onClose={this.props.handleDialogClose}>
        <BlockUi tag="div" blocking={this.state.blocking}>
          <input
            id="fileInput"
            type="file"
            ref={(ref) => (this.state.inputFile = ref)}
            style={{ display: "none" }}
            {...this.fileinputAcceptProps}
            onChange={this.onFileChange}
          ></input>
          <DialogTitle>{t("upload_document")}</DialogTitle>
          <DialogContent>
            {this.state.selectCompanyFor && (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <FormControl
                  sx={{ m: 1, minWidth: 250 }}
                  error={this.state.error.clientOrCompany}
                  disabled={!this.state.selectCompanyFor}
                >
                  <InputLabel htmlFor="grouped-select-client">
                    {"Client or company"}
                  </InputLabel>
                  <Select
                    value={this.state.selectedClientOrCompany}
                    id="grouped-select-client"
                    label="Grouping"
                    onChange={this.handleClientOrCompanyChange}
                  >
                    <ListSubheader>Client</ListSubheader>
                    {_.filter(
                      this.state.clientOrCompanies,
                      (c) => c.type === "client"
                    ).map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                    <ListSubheader>Companies</ListSubheader>
                    {_.filter(
                      this.state.clientOrCompanies,
                      (c) => c.type !== "client"
                    ).map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <FormControl
                sx={{ m: 1, minWidth: 250 }}
                error={this.state.error.category}
                disabled={this.state.preselectedCustomFile}
              >
                <InputLabel htmlFor="grouped-select">
                  {t("Category")}
                </InputLabel>
                <Select
                  defaultValue={this.state.preselectedCustomFile ? "-1" : ""}
                  id="grouped-select"
                  label="Grouping"
                  onChange={this.handleCategoryChange}
                >
                  {this.state.preselectedCustomFile && (
                    <MenuItem value={-1}>
                      {window.$lang === "en"
                        ? this.state.preselectedCustomFile.subcategory_en
                        : this.state.preselectedCustomFile.subcategory_es
                        ? this.state.preselectedCustomFile.subcategory_es
                        : this.state.preselectedCustomFile.subcategory_en}
                    </MenuItem>
                  )}
                  {this.state.subcategories?.map((workspace, idx) =>
                    this.renderSelectGroup(workspace, idx)
                  )}
                </Select>
              </FormControl>
            </Box>
            {this.showDatosLibro() && ( //Legal books
              <Box
                id="fields-libros"
                sx={{ border: "1px solid grey", pb: 2, pl: 2, pr: 2 }}
              >
                <span style={{ fontSize: "0.8em", paddingBottom: "16px" }}>
                  Datos del libro
                </span>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <FormControl
                    sx={{ m: 1, minWidth: 250 }}
                    error={this.state.error.tipo_libro}
                  >
                    <InputLabel htmlFor="grouped-select">{"Tipo"}</InputLabel>
                    <Select
                      id="grouped-select"
                      label="Grouping"
                      onChange={this.handleBookTypeChange}
                    >
                      <ListSubheader>Sociedades Anónimas</ListSubheader>
                      <MenuItem value={"Libro de Actas Junta Directiva"}>
                        Libro de Actas Junta Directiva
                      </MenuItem>
                      <MenuItem value={"Libro de Actas de Asamblea de Socios"}>
                        Libro de Actas de Asamblea de Socios
                      </MenuItem>
                      <MenuItem value={"Libro de Registro de Accionistas"}>
                        Libro de Registro de Accionistas
                      </MenuItem>
                      <ListSubheader>
                        Sociedades de Responsabilidad Limitada
                      </ListSubheader>
                      <MenuItem
                        value={"Libro de Actas de Asamblea de Cuotistas"}
                      >
                        Libro de Actas de Asamblea de Cuotistas
                      </MenuItem>
                      <MenuItem value={"Libro de Registro de Cuotistas"}>
                        Libro de Registro de Cuotistas
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    required
                    id="input_libros_numero_acta"
                    label={"Número de acta"}
                    type="number"
                    fullWidth
                    variant="standard"
                    value={this.state.numero_acta}
                    error={this.state.error.numero_acta}
                    onChange={(e) =>
                      this.setState({ numero_acta: e.target.value })
                    }
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    required
                    id="input_libros_numero_tomo"
                    label={"Número de tomo"}
                    type="number"
                    fullWidth
                    variant="standard"
                    value={this.state.numero_tomo}
                    error={this.state.error.numero_tomo}
                    onChange={(e) =>
                      this.setState({ numero_tomo: e.target.value })
                    }
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label={t("Date")}
                      value={this.state.fecha_libro}
                      onChange={(newValue) => {
                        this.setState({
                          fecha_libro: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={this.state.error.fecha_libro}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            )}
            {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <FileUploadIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                onClick={() => this.state.inputFile.click()}
              />
              <TextField
                id="input_upload_file"
                label={window.$brand === "BLP" ? t("PDFFile") : t("File")}
                variant="standard"
                onClick={() => this.state.inputFile.click()}
                disabled
                value={
                  this.state.selectedFile ? this.state.selectedFile.name : ""
                }
                error={this.state.error.file || this.state.error.notpdf}
              />
            </Box> */}
            <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
              <DragDropFile fileChange={this.onFileChange} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="input_upload_filename"
                disabled={this.showDatosLibro()}
                label={t("Filename")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 100 }}
                value={this.filename()}
                error={this.state.error.filename || this.state.error.notpdf}
                onChange={(e) => this.setState({ filename: e.target.value })}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="input_upload_notes"
                multiline
                label={t("Notes")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 255 }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3 }}>
              <Checkbox id="input_upload_expiration_cb"></Checkbox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Expires")}
                  value={this.state.vencimiento}
                  onChange={(newValue) => {
                    this.setState({
                      vencimiento: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3, ml: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked id="input_upload_visible_cb" />
                }
                label={t("Visible_for_clients")}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleDialogClose}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                this.setState({ blocking: true });
                if (this.valid()) this.uploadFile();
              }}
            >
              {t("Upload")}
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    );
  }
}

export default withTranslation()(LocalUploadDialog);
