export async function listWorkspaceClients(workspaceId, userId) {
  let params = {
    workspaceId: workspaceId,
    userId: userId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByWorkspaceAndUserIds?code=dhyVBCd9vTSTrsaOQlPwUJ9MRC4WDnf2o60Aj-CU0kAvAzFurvG3aw==";

  return sendReq(params, url);
}

export async function listClientsByUser(userId) {
  let params = {
    userId: userId,
    brandLabel: window.$brand,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByUserId?code=bVShitJReoKs3fLiqpjeyDeeNuHeTm1TNFQeWMJGX2qMAzFuA7p6AA==";

  return sendReq(params, url);
}

export async function listClientsByParent(clientId) {
  let params = {
    parent: clientId,
    brandLabel: window.$brand,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByParent?code=ABu6YYdXe9PQSfpvdSG9slk9rJqSCLMmHxXbZ1rk4NzY6yyrfGviOg==";

  return sendReq(params, url);
}

export async function listAuthorizedClientsByClientUser(userId) {
  let params = {
    userId: userId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListAuthorizedClientsByUser?code=v1c8akCxuMaoZZBSp1HY6GU1tylrr3sH9EawaqupECxUjysiNRmxJQ==";

  return sendReq(params, url);
}

export async function listClients(parent) {
  let params = {
    parent: parent,
    brandLabel: window.$brand,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByParent?code=ABu6YYdXe9PQSfpvdSG9slk9rJqSCLMmHxXbZ1rk4NzY6yyrfGviOg==";

  return sendReq(params, url);
}

export async function insertClient(params) {
  params.brandLabel = window.$brand;

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/InsertClient?code=2YQQ0zi/p9FpHduvTiMjtJdFgGZpSTycjLHhx7PwcInYyFU5BmDXnw==";

  return sendReq(params, url);
}

export async function assignLawyerToClient(params) {
  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignClientLawyer?code=fDYO6JMK6htJZsn-0uPaAsLckpBpaTL-c_RdgtWwfRHxAzFueemZSw==";

  await fetch(url, { method: "POST", body: JSON.stringify(params) });
}

export async function assignClientToWorkspace(params) {
  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/AssignClientToWorkspace?code=tBx4iQzCNT2w-dyf2-kD7EEz2c3bfCtM7tDaYI2zBVQ8AzFuKwu8lg==";

  await fetch(url, { method: "POST", body: JSON.stringify(params) });
}

export async function getClientDataById(clientId) {
  let params = { clientId };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/GetClientDataById?code=ikkyzG9Jpo1O_x_GE1Pj9LbFaVjXffk6R1GmempUtoIgAzFurxrxvg==";

  return sendReq(params, url);
}

export async function getMainClient(userId) {
  let params = { userId };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/GetClientOrPersonByUser?code=kqDgy6GbOa8BuRiZlmMWlbyeZuqLOYm3XEGw0bBYz9QqrzSa5sVpeA==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, {
    method: "POST",
    body: JSON.stringify(params),
  }).catch((error) => console.log(error));
  return res.json();
}
