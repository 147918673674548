import React from "react";
import { withTranslation } from "react-i18next";
import { Paper, Tooltip } from "@mui/material";
import "react-block-ui/style.css";
import Box from "@mui/material/Box";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CPButton from "../../Utils/CPButton";
import Checkbox from "@mui/material/Checkbox";
import { getRetainer } from "../../../services/retainers";
import { getPredictYourClosingEnabled } from "../../../services/closing";
import { listFeatureLabels } from "../../../services/features";
import PredictYourClosingSettings from "./FeatureSettings/PredictYourClosingSettings";
import CPSettingsIconButton from "../../Utils/CPSettingsIconButton";
import FeatureLabelSettings from "./FeatureSettings/FeatureLabelSettings";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RetainerPreview from "./FeatureSettings/RetainerPreview";
import RetainerSettings from "./FeatureSettings/RetainerSettings";

class FeaturesCard extends React.Component {
  // Tarjeta de features opcionales en la config de cada cliente
  constructor() {
    super();

    this.state = {
      features: undefined,
      filteredFeatures: [],
      assignedFeatures: [],
      retainerSettings: {},
      retainerPreview: {},
      predictYourClosingSettings: {},
      openPredictYourClosingSettings: false,
      courtProceedingsSettings: {},
      administrativeProcessesSettings: {},
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchAssignedFeatures(this.props.client.id);
    this.fetchFeatureList();
  }

  fetchFeatureList = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListFeatures?code=D7zCbqki_q2tEVKo7nUwhgfM3DIr-_vYm1mJN3HsDfLdAzFukavy3g==";

    fetch(url, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          features: data,
          filteredFeatures: data,
        });
      });
  };

  fetchAssignedFeatures = (clientId) => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedFeaturesByClient?code=4KAgASbEWhpY-Ld_vZHfIdJ9UXKLjC9B70n4msZKX2lxAzFu1AWsNg==&clientId=" +
        clientId
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          assignedFeatures: _.map(data, "id"),
        });
      });
  };

  toggleAssignFeature = (event, featureId) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignClientFeature?code=NCP-v5LdayswPSYtXIFE6AbElDVhnAr7naC6D7fmTdn-AzFu87Z-tw==";

    let assignedFeatures = this.state.assignedFeatures;
    if (event.target.checked) {
      assignedFeatures.push(featureId);
    } else {
      _.remove(assignedFeatures, (fid) => {
        return fid == featureId;
      });
    }
    this.setState({
      assignedFeatures: assignedFeatures,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        featureId: featureId,
        clientId: this.props.client.id,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const res = this.state.features.filter((feature) => {
        return feature.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.setState({
        filteredFeatures: res,
      });
    } else {
      this.setState({
        filteredFeatures: this.state.features,
      });
    }
  };

  openFeatureSettings = async (feature) => {
    if (feature.name === "My Fees") {
      let data = await getRetainer(this.props.client.id);
      this.setState({
        retainerSettings: {
          hours: data.hours ? data.hours : 0,
          caseId: data.bq_case_id,
          proposalUrl: data.proposal_url,
        },
      });
    } else if (feature.name === "Predict Your Closing") {
      let data = await getPredictYourClosingEnabled(this.props.client.id);
      this.setState({
        predictYourClosingSettings: data,
        openPredictYourClosingSettings: true,
      });
    } else if (feature.name === "Court Proceedings") {
      let list = await listFeatureLabels(this.props.client.id, feature.id);
      let settings = {
        labels: list,
        feature: feature,
      };
      this.setState({ courtProceedingsSettings: settings });
    } else if (feature.name === "Administrative Processes") {
      let list = await listFeatureLabels(this.props.client.id, feature.id);
      let settings = {
        labels: list,
        feature: feature,
      };
      this.setState({ administrativeProcessesSettings: settings });
    }
  };

  handleFeatureSettingsClose = () => {
    this.setState({
      retainerSettings: {},
      retainerPreview: {},
      openPredictYourClosingSettings: false,
      administrativeProcessesSettings: {},
      courtProceedingsSettings: {},
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Box sx={{ flexGrow: 1, mb: 4, p: 2, maxWidth: 400 }} component={Paper}>
        <SectionTitle>{t("Features")}</SectionTitle>
        <List dense sx={{ maxWidth: 360 }}>
          {this.state.filteredFeatures.map((feature) => (
            <ListItem
              key={feature.id}
              secondaryAction={
                this.state.assignedFeatures.includes(feature.id) && (
                  <CPSettingsIconButton
                    onClick={() => this.openFeatureSettings(feature)}
                  />
                )
              }
              disablePadding
            >
              <ListItemButton>
                <Checkbox
                  edge="start"
                  onChange={(e) => this.toggleAssignFeature(e, feature.id)}
                  checked={this.state.assignedFeatures.includes(feature.id)}
                />
                <ListItemText primary={t(feature.name)} />
                {!_.isEmpty(feature.description) && (
                  <Tooltip title={t(feature.description)}>
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                )}
                {feature.name === "My Fees" &&
                  this.state.assignedFeatures.includes(feature.id) && (
                    <CPButton
                      icon={<SearchOutlinedIcon />}
                      tooltip={t("Preview")}
                      onClick={() =>
                        this.setState({
                          retainerPreview: { clientId: this.props.client.id },
                        })
                      }
                    />
                  )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <RetainerSettings
          settings={this.state.retainerSettings}
          clientId={this.props.client.id}
          handleDialogClose={this.handleFeatureSettingsClose}
        />
        <RetainerPreview
          openPreview={this.state.retainerPreview}
          clientId={this.props.client.id}
          handleDialogClose={this.handleFeatureSettingsClose}
        />
        <PredictYourClosingSettings
          settings={this.state.predictYourClosingSettings}
          open={this.state.openPredictYourClosingSettings}
          clientId={this.props.client.id}
          handleDialogClose={this.handleFeatureSettingsClose}
        />
        <FeatureLabelSettings
          settings={this.state.courtProceedingsSettings}
          open={this.state.openCourtProceedings}
          client={this.props.client}
          feature={{ name: "Court Proceedings" }}
          handleDialogClose={this.handleFeatureSettingsClose}
        />
        <FeatureLabelSettings
          settings={this.state.administrativeProcessesSettings}
          open={this.state.openAdministrativeProcesses}
          client={this.props.client}
          feature={{ name: "Administrative Processes" }}
          handleDialogClose={this.handleFeatureSettingsClose}
        />
      </Box>
    );
  }
}

export default withTranslation()(FeaturesCard);
