export async function listPermissions(userId) {
  let params = {
    userId: userId,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedPermissionsByUser?code=dxk3aVcfAH_PQ7ApqpRB9D90bIab7ZJh-RyLp_RSATMAAzFuKHSGoQ==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
