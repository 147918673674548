import React from "react";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import Button from "@mui/material/Button";
import { CardActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DeleteDialog from "../../DeleteDialog";
import "react-block-ui/style.css";
import { authContext } from "../../../contexts/AuthContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { formatDate } from "../../../Utils/Format";
import Tooltip from "@mui/material/Tooltip";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import CPText from "../../Utils/CPText";
import CPSettingsIconButton from "../../Utils/CPSettingsIconButton";
import Permissions from "./Permissions";

class UserCard extends React.Component {
  // Card de usuario para el admin cliente
  
  constructor() {
    super();

    this.state = {
      settings: {},
      edit: {},
      delete: {},
      reset: {},
      snackbarInfoOpen: false,
    };
  }

  static contextType = authContext;

  handleSettingsOpen = (user) => {
    this.setState({
      settings: user,
    });
  };

  handleSettingsClose = () => {
    this.setState({
      settings: {},
    });
  };

  handleEditOpen = (user) => {
    this.setState({
      edit: user,
    });
  };

  handleEditClose = () => {
    this.setState({
      edit: {},
    });
  };

  handleDeleteOpen = (user) => {
    this.setState({
      delete: user,
    });
  };

  handleResetOpen = (user) => {
    this.setState({
      reset: user,
    });
  };

  handleResetClose = () => {
    this.setState({
      reset: {},
    });
  };

  handleDialogClose = () => {
    this.setState({
      edit: {},
      delete: {},
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarInfoOpen: false,
    });
  };

  update = (user) => {
    user.first_name = document.getElementById("edit_firstName").value;
    user.last_name = document.getElementById("edit_lastName").value;
    user.email = document.getElementById("edit_email").value;
    user.phone = document.getElementById("edit_phone").value;
    user.mobile = document.getElementById("edit_mobile").value;
    user.loggedUserId = this.context.auth.user.id;
    user.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateUser?code=AYcWxCsFFGpiced2Hm57ZTcPSshn99vArFV6w3fsJ97lFp3mkfMrnw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleEditClose();
  };

  delete = (user) => {
    user.loggedUserId = this.context.auth.user.id;
    user.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteUser?code=FhyC/yldBaINF9fi0CaKkp192uvlRAODGI8O/mxqqrxBaay94/uJXg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");
      })
      .catch((error) => console.log(error));

    this.props.cardDeleteHandler(user);
  };

  resetPassword = (user) => {
    user.brandLabel = window.$brand;
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ResetPassword?code=mMv/ro86SAchaxEmEsxzoMvavartQnot3XQ3Ul3bYV4cP1AXR/STuA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Reset successful");
        this.setState({
          snackbarInfoOpen: true,
        });
      })
      .catch((error) => console.log(error));

    this.handleResetClose();
  };

  render() {
    const { t } = this.props;

    return (
      <Card
        sx={{
          minWidth: 275,
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: window.$brand === "BLP" ? 5 : 0,
          mr: window.$brand === "BLP" ? 10 : 0,
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color="text.primary" variant="body1">
                <strong>
                  {this.props.user.first_name} {this.props.user.last_name}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CPText label={t("Email")} text={this.props.user.email} />
            </Grid>
            <Grid item xs={12}>
              <CPText
                label={t("User_type")}
                text={
                  this.props.user.type === "client_admin"
                    ? t("Administrator")
                    : t("User")
                }
              />
            </Grid>
            {/* <Grid item xs={12}>
              <CPText
                label={t("Created")}
                text={formatDate(t, this.props.user.created)}
              />
            </Grid> */}
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: "auto" }}>
          {this.props.user.type !== "client_admin" && (
            <>
              <CPSettingsIconButton
                onClick={() => {
                  this.handleSettingsOpen(this.props.user);
                }}
              />
              <CPEditIconButton
                onClick={() => this.handleEditOpen(this.props.user)}
              />
              <CPDeleteIconButton
                onClick={() => this.handleDeleteOpen(this.props.user)}
              />
            </>
          )}
        </CardActions>
        <Dialog
          open={!_.isEmpty(this.state.reset)}
          onClose={this.handleResetClose}
        >
          <DialogTitle>{t("reset_password")}</DialogTitle>
          <DialogContent>
            <p>{t("reset_password_caption")}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleResetClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.resetPassword(this.state.reset)}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <DeleteDialog
          item={this.state.delete}
          title={t("delete_user")}
          caption={t("delete_user_caption")}
          targetFunc={this.delete}
          handleDialogClose={this.handleDialogClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.edit)}
          onClose={this.handleEditClose}
        >
          <DialogTitle>{t("edit_user")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.first_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="edit_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.last_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              disabled
              id="edit_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.email}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="edit_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.phone}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="edit_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.mobile}
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <Button
              variant="contained"
              sx={{ m: 2 }}
              size="small"
              onClick={() => this.handleResetOpen(this.props.user)}
            >
              {t("reset_password")}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleEditClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.update(this.state.edit)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.settings)}
          onClose={this.handleSettingsClose}
        >
          <DialogContent>
            <Permissions user={this.props.user} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSettingsClose}>{t("Close")}</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarInfoOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          key={"bottomcentererror"}
        >
          <Alert elevation={6} variant="filled" severity="info">
            {t("password_sent")}
          </Alert>
        </Snackbar>
      </Card>
    );
  }
}

export default withTranslation()(UserCard);
