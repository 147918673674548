export async function listClientDocuments(clientId, includeNotVisible) {
  let params = {
    clientId: clientId,
    includeNotVisible: includeNotVisible,
  };

  let url =
    "https://blpclientportalfunctions.azurewebsites.net/api/GetClientDocuments?code=wdbPh7A/5DUKNw3AM3sYPMeRd0/wgCMa3f2fvT/Z53oOeZhDqMLyow==";

  return sendReq(params, url);
}

async function sendReq(params, url) {
  let res = await fetch(url, { method: "POST", body: JSON.stringify(params) });
  return res.json();
}
