import React from "react";
import Box from "@mui/material/Box";
import { authContext } from "../../../contexts/AuthContext";
import LawyerInformationCard from "./LawyerInformationCard";

class LawyerInformation extends React.Component {
  // Pagina para clientes, muestra los abogados asignados en la config del cliente
  constructor() {
    super();

    this.state = {
      client: {},
      list: [],
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchClientOrPersonByUser();
  }

  fetchClientOrPersonByUser = () => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/GetClientOrPersonByUser?code=kqDgy6GbOa8BuRiZlmMWlbyeZuqLOYm3XEGw0bBYz9QqrzSa5sVpeA==&userId=" +
        this.context.auth.user.id
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          client: data,
        });
        this.fetchAssignedUsers(data.id);
      });
  };

  fetchAssignedUsers = (clientId) => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedLawyersByClient?code=LA-UtbtsN-EHNQCA_h_b5vg3uBXqvJlwHFziSrvWkUVcAzFu1y1gzg==&clientId=" +
        clientId
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          list: data,
        });
      });
  };

  render() {
    return (
      <Box sx={{ mt: 8, ml: 8 }}>
        {this.state.list.map((lawyer) => (
          <LawyerInformationCard lawyer={lawyer} key={lawyer.id} />
        ))}
      </Box>
    );
  }
}

export default LawyerInformation;
