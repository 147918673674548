import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { withTranslation } from "react-i18next";

class CPEditIconButton extends React.Component {
  render() {
    const { t, onClick, edge, sx } = this.props;

    return (
      <IconButton
        onClick={onClick}
        sx={
          sx
            ? sx
            : {
                color: window.$brand === "PRB" ? "#17afb6" : "#017cbc",
              }
        }
        edge={edge ? edge : "end"}
      >
        <Tooltip title={t("Edit")}>
          <EditOutlinedIcon />
        </Tooltip>
      </IconButton>
    );
  }
}

export default withTranslation()(CPEditIconButton);
