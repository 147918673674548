import React from "react";
import Container from "@mui/material/Container";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import CPButton from "../../Utils/CPButton";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PageTitle from "../../PageTitle";
import _ from "lodash";
import { Paper } from "@mui/material";
import { authContext } from "../../../contexts/AuthContext";
import { withTranslation } from "react-i18next";
import TableHeader from "../../Utils/TableHeader";
import { formatDateTime } from "../../../Utils/Format";

class Auditoria extends React.Component {
  // Pagina de "Historial" donde se consulta la tabla "audits" de la base de datos
  constructor() {
    super();

    this.state = {
      valueDesde: new Date(),
      valueHasta: new Date(),
      actionSearch: "Todas",
      auditRows: [],
      showChanges: undefined,
    };
  }

  static contextType = authContext;

  searchAudit = () => {
    let monthDesde = this.state.valueDesde.getMonth() + 1;
    let monthDesdeStr = monthDesde < 10 ? `0${monthDesde}` : monthDesde;
    let dayDesde = this.state.valueDesde.getDate();
    let dayDesdeStr = dayDesde < 10 ? `0${dayDesde}` : dayDesde;

    let monthHasta = this.state.valueHasta.getMonth() + 1;
    let monthHastaStr = monthHasta < 10 ? `0${monthHasta}` : monthHasta;
    let dayHasta = this.state.valueHasta.getDate();
    let dayHastaStr = dayHasta < 10 ? `0${dayHasta}` : dayHasta;

    let searchParams = {
      fromDate: `${this.state.valueDesde.getFullYear()}-${monthDesdeStr}-${dayDesdeStr} 00:00:00`,
      toDate: `${this.state.valueHasta.getFullYear()}-${monthHastaStr}-${dayHastaStr} 23:59:59`,
      brandLabel: window.$brand,
    };

    let userEmail = document.getElementById("audit_search_user").value;
    if (!_.isEmpty(userEmail)) searchParams.userEmail = userEmail;
    if (!_.isEqual(this.state.actionSearch, "Todas"))
      searchParams.action = this.state.actionSearch;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/SearchAudit?code=RHfIOquwX1EggUmcSmYEpu2NRKGu5mjapOIsE8CUE2m0RO8A64noxA==";
    fetch(url, {
      method: "POST",
      body: JSON.stringify(searchParams),
    })
      .then((response) => response.json())
      .then((data) => {
        _.forEach(data, (item) => {
          let changes = JSON.parse(item.changes);
          item.changes = changes;
        });
        this.setState({
          auditRows: data,
        });
      });
  };

  handleActionSearchChange = (event) => {
    this.setState({
      actionSearch: event.target.value,
    });
  };

  handleDialogClose = () => {
    this.setState({
      showChanges: undefined,
    });
  };

  buildChangesTableBody = (changes) => {
    let fields = [];
    if (_.isEmpty(changes.oldValue)) {
      fields = _.keys(changes.newValue);
    } else {
      fields = _.keys(changes.oldValue);
    }

    let res = [];
    for (let i = 0; i < fields.length; i++) {
      res.push(
        <TableRow
          key={fields[i]}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell scope="fields[i]">{fields[i]}</TableCell>
          <TableCell>
            {this.state.showChanges.changes.oldValue[fields[i]]}
          </TableCell>
          <TableCell>
            {this.state.showChanges.changes.newValue[fields[i]]}
          </TableCell>
        </TableRow>
      );
    }

    return res;
  };

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <PageTitle>{t("menu_History")}</PageTitle>
        <Container component={Paper} sx={{ p: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Desde"
              value={this.state.valueDesde}
              onChange={(newValue) => {
                this.setState({
                  valueDesde: newValue.$d,
                });
              }}
              renderInput={(params) => <TextField {...params} sx={{ m: 2 }} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Hasta"
              value={this.state.valueHasta}
              onChange={(newValue) => {
                this.setState({
                  valueHasta: newValue.$d,
                });
              }}
              renderInput={(params) => <TextField {...params} sx={{ m: 2 }} />}
            />
          </LocalizationProvider>
          <FormControl sx={{ m: 2 }}>
            <InputLabel id="audit_search_action_label">Acción</InputLabel>
            <Select
              labelId="audit_search_action_label"
              id="audit_search_action"
              label="Acción"
              onChange={this.handleActionSearchChange}
              defaultValue={this.state.actionSearch}
            >
              <MenuItem value={"Todas"}>Todas</MenuItem>
              <MenuItem value={"INSERT"}>INSERT</MenuItem>
              <MenuItem value={"UPDATE"}>UPDATE</MenuItem>
              <MenuItem value={"DELETE"}>DELETE</MenuItem>
              <MenuItem value={"LOGIN"}>LOGIN</MenuItem>
              <MenuItem value={"LOGOUT"}>LOGOUT</MenuItem>
              <MenuItem value={"UPLOAD"}>UPLOAD</MenuItem>
              <MenuItem value={"DOWNLOAD"}>DOWNLOAD</MenuItem>
              <MenuItem value={"CHANGEPSWD"}>CHANGEPSWD</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ m: 2 }}
            id="audit_search_user"
            label="Email de usuario"
            type="text"
            variant="standard"
            inputProps={{ maxLength: 50 }}
          />
          <Button
            sx={{
              m: 3,
              backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#78BE20",
            }}
            variant="contained"
            onClick={() => this.searchAudit()}
          >
            Buscar
          </Button>
        </Container>
        {!_.isEmpty(this.state.auditRows) && (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHeader
                cols={["Fecha y hora", "Acción", "Entidad", "Responsable", ""]}
              />
              <TableBody>
                {this.state.auditRows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">
                      {formatDateTime(t, row.mo)}
                    </TableCell>
                    <TableCell>{row.action}</TableCell>
                    <TableCell>{row.changes.entity}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell align="right">
                      <CPButton
                        onClick={() => this.setState({ showChanges: row })}
                        icon={<RemoveRedEyeOutlinedIcon />}
                        tooltip={t("Details")}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {this.state.showChanges && (
          <Dialog
            open={this.state.showChanges}
            onClose={this.handleDialogClose}
          >
            <DialogTitle>
              {this.state.showChanges.action}{" "}
              {this.state.showChanges.changes.entity}
            </DialogTitle>
            <DialogContent>
              <p>
                <strong>Fecha y hora:</strong>{" "}
                {formatDateTime(t, this.state.showChanges.mo)}
              </p>
              <p>
                <strong>Usuario responsable:</strong>{" "}
                {this.state.showChanges.email}
              </p>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHeader
                    cols={["Propiedad", "Valor anterior", "Valor posterior"]}
                  />
                  <TableBody>
                    {this.buildChangesTableBody(this.state.showChanges.changes)}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose}>Cerrar</Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    );
  }
}

export default withTranslation()(Auditoria);
