import React from "react";
import { authContext } from "../../../contexts/AuthContext";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import SectionTitle from "../../SectionTitle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Button } from "@mui/material";
import DeleteDialog from "../../DeleteDialog";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPText from "../../Utils/CPText";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CPTip from "../../Utils/CPTip";

class UserAdmins extends React.Component {
  //Creacion de user admins para cada cliente (full access al cliente sin permisos)
  constructor() {
    super();

    this.state = {
      list: [],
      addUserAdmin: {},
      editUserAdmin: {},
      deleteUserAdmin: {},
      reset: {},
      snackbarInfoOpen: false,
      currPassword: "",
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchList();
  }

  handleAddUserAdminOpen = (userAdmin) => {
    this.setState({
      addUserAdmin: userAdmin,
    });
    this.generatePassword();
  };

  handleAddPreferredLangChange = (event) => {
    let modified = this.state.addUserAdmin;
    modified.preferredLang = event.target.value;
    this.setState({
      addUserAdmin: modified,
    });
  };

  handleAddUserAdminClose = () => {
    this.state.list.push(this.state.addUserAdmin);

    this.handleDialogClose();
  };

  handleEditUserAdminOpen = (userAdmin) => {
    this.setState({
      editUserAdmin: userAdmin,
    });
  };

  handleEditUserAdminClose = (userAdmin) => {
    userAdmin.first_name = this.state.editUserAdmin.first_name;
    userAdmin.last_name = this.state.editUserAdmin.last_name;
    userAdmin.email = this.state.editUserAdmin.email;
    userAdmin.phone = this.state.editUserAdmin.phone;
    userAdmin.mobile = this.state.editUserAdmin.mobile;

    this.handleDialogClose();
  };

  handleResetClose = () => {
    this.setState({
      reset: {},
    });
  };

  handleDeleteUserAdminClose = () => {
    this.setState({
      deleteUserAdmin: {},
    });
  };

  handleDialogClose = () => {
    this.setState({
      addUserAdmin: {},
      editUserAdmin: {},
      deleteUserAdmin: {},
      reset: {},
      currPassword: "",
    });
  };

  generatePassword = () => {
    const valid =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result = result.concat(valid[Math.floor(Math.random() * valid.length)]);
    }

    this.setState({
      currPassword: result,
    });
  };

  insertUserAdmin = (userAdmin) => {
    userAdmin.first_name = document.getElementById(
      "insert_admin_firstName"
    ).value;
    userAdmin.last_name = document.getElementById(
      "insert_admin_lastName"
    ).value;
    userAdmin.email = document.getElementById("insert_admin_email").value;
    userAdmin.password = document.getElementById("insert_admin_password").value;
    userAdmin.phone = document.getElementById("insert_admin_phone").value;
    userAdmin.mobile = document.getElementById("insert_admin_mobile").value;
    userAdmin.preferredLang = this.state.addUserAdmin.preferredLang;
    userAdmin.brandLabel = window.$brand;
    userAdmin.loggedUserId = this.context.auth.user.id;
    userAdmin.loggedUserEmail = this.context.auth.user.email;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertClientUserAdmin?code=otKVBPc0YQRNpVcXQskvvnWS/EerUr4horE405oMMciua97YlGax2A==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(userAdmin),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          addUserAdmin: data,
        });
        this.handleAddUserAdminClose();
      })
      .catch((error) => console.log(error));
  };

  updateUserAdmin = (userAdmin) => {
    userAdmin.first_name = document.getElementById(
      "edit_admin_firstName"
    ).value;
    userAdmin.last_name = document.getElementById("edit_admin_lastName").value;
    userAdmin.email = document.getElementById("edit_admin_email").value;
    userAdmin.phone = document.getElementById("edit_admin_phone").value;
    userAdmin.mobile = document.getElementById("edit_admin_mobile").value;
    userAdmin.loggedUserId = this.context.auth.user.id;
    userAdmin.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateUser?code=AYcWxCsFFGpiced2Hm57ZTcPSshn99vArFV6w3fsJ97lFp3mkfMrnw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(userAdmin),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleEditUserAdminClose(userAdmin);
  };

  resetPassword = (user) => {
    user.brandLabel = window.$brand;
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ResetPassword?code=mMv/ro86SAchaxEmEsxzoMvavartQnot3XQ3Ul3bYV4cP1AXR/STuA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Reset successful");
        this.setState({
          snackbarInfoOpen: true,
        });
      })
      .catch((error) => console.log(error));

    this.handleResetClose();
  };

  deleteUserAdmin = (user) => {
    user.loggedUserId = this.context.auth.user.id;
    user.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteUserAdmin?code=K9Ks8MgT_22RK_-QqqeRBcaTow1p9oBO_pVHzUMBw60kAzFu5h3vGA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        _.remove(this.state.list, (u) => {
          return u.id === user.id;
        });
        this.forceUpdate();
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  fetchList = () => {
    let params = {
      clientId: this.props.clientId,
      brandLabel: window.$brand,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListUserAdminsByClient?code=t4v13jojZr2szjo94nkPcSpz9H_oltaUNt5igbEhGUI0AzFutiiIJQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          list: data,
        });
      });
  };

  render() {
    const { t } = this.props;

    return (
      <Box
        sx={{
          flexGrow: 1,
          mb: 4,
          p: 2,
          maxWidth: 400,
          borderRadius: window.$brand === "PRB" ? 0 : 5,
        }}
        component={Paper}
      >
        <SectionTitle>
          {window.$brand === "ZAP"
            ? t("Condo_Administrators")
            : t("Client_Administrators")}
          <Button
            sx={{
              m: 3,
              backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#102a46",
              borderRadius: window.$brand === "PRB" ? 0 : 5,
            }}
            variant="contained"
            onClick={() =>
              this.handleAddUserAdminOpen({
                type: "client_admin",
                client: this.props.clientId,
                preferredLang: "es",
              })
            }
          >
            {t("Add")}
          </Button>
        </SectionTitle>
        <List dense sx={{ maxWidth: 360 }}>
          {this.state.list.map((user) => (
            <ListItem
              key={user.id}
              secondaryAction={
                <>
                  <CPEditIconButton
                    onClick={() => this.handleEditUserAdminOpen(user)}
                  />
                  <CPDeleteIconButton
                    onClick={() => this.setState({ deleteUserAdmin: user })}
                  />
                </>
              }
              disablePadding
            >
              <ListItemButton>
                <CPText text={user.first_name + " " + user.last_name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <DeleteDialog
          item={this.state.deleteUserAdmin}
          title={t("Delete_admin")}
          caption={t("delete_admin_full_caption")}
          targetFunc={this.deleteUserAdmin}
          handleDialogClose={this.handleDeleteUserAdminClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.addUserAdmin)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("assign_user_admin_title")}</DialogTitle>
          <DialogContent>
            {window.$brand === "PRB" && (
              <Box sx={{ pb: 2 }}>
                <CPTip text={t("Sending_new_user_email")} />
              </Box>
            )}
            <TextField
              required
              id="insert_admin_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_admin_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_admin_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              required
              id="insert_admin_password"
              label={t("Password")}
              type="text"
              value={this.state.currPassword}
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
              style={{ display: `${window.$brand === "PRB" ? "none" : ""}` }}
            />
            <TextField
              id="insert_admin_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="insert_admin_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <FormControl fullWidth>
              <InputLabel id="insert_preferred_lang_label">
                {t("Preferred_lang")}
              </InputLabel>
              <Select
                labelId="insert_preferred_lang_label"
                id="insert_preferred_lang"
                label={t("Preferred_lang")}
                onChange={this.handleAddPreferredLangChange}
                defaultValue={"es"}
              >
                <MenuItem value={"es"}>{t("Spanish")}</MenuItem>
                <MenuItem value={"en"}>{t("English")}</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => this.insertUserAdmin(this.state.addUserAdmin)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.editUserAdmin)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("edit_user_admin_title")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_admin_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editUserAdmin.first_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="edit_admin_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editUserAdmin.last_name}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="edit_admin_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editUserAdmin.email}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="edit_admin_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editUserAdmin.phone}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="edit_admin_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editUserAdmin.mobile}
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <Button
              onClick={() => this.setState({ reset: this.state.editUserAdmin })}
              sx={{ m: 4 }}
              variant="contained"
              size="small"
            >
              {t("reset_password")}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => this.updateUserAdmin(this.state.editUserAdmin)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.reset)}
          onClose={this.handleResetClose}
        >
          <DialogTitle>{t("reset_password")}</DialogTitle>
          <DialogContent>
            <p>{t("reset_password_caption")}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleResetClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.resetPassword(this.state.reset)}>
              {t("Accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarInfoOpen}
          autoHideDuration={4000}
          onClose={() =>
            this.setState({
              snackbarInfoOpen: false,
            })
          }
          key={"bottomcentererror"}
        >
          <Alert elevation={6} variant="filled" severity="info">
            {t("password_sent")}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default withTranslation()(UserAdmins);
