import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { saveRetainer } from "../../../../services/retainers";
import SectionTitle from "../../../SectionTitle";
import CPButton from "../../../Utils/CPButton";
import { authContext } from "../../../../contexts/AuthContext";

class RetainerSettings extends React.Component {
  //Configuracion de My Fees o Mis Honorarios en la config del cliente
  constructor() {
    super();

    this.state = {
      settings: {},
      invalidErrors: {},
      inputFile: null,
    };
  }

  static contextType = authContext;

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings) {
      this.setState({
        settings: this.props.settings,
      });
    }
  }

  save = async () => {
    let params = {
      clientId: this.props.clientId,
      bqCaseId: document.getElementById("input_case_id").value,
      hours: document.getElementById("input_hours").value,
    };

    await saveRetainer(params);
  };

  valid = () => {
    let bqCaseId = document.getElementById("input_case_id").value;
    let hours = document.getElementById("input_hours").value;

    let invalidErrors = {};
    if (_.isEmpty(bqCaseId)) invalidErrors.bqCaseId = true;
    if (_.isEmpty(hours) || isNaN(hours)) invalidErrors.hours = true;

    this.setState({
      invalidErrors: invalidErrors,
    });

    return _.isEmpty(invalidErrors);
  };

  onFileChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files && event.target.files[0]) {
      let formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("clientId", this.props.clientId);

      let url =
        "https://blpclientportalfunctions.azurewebsites.net/api/UploadProposalToRetainer?code=x58mW4heuxA1jnGLjFXMZEx-1FBHZO5RVWje8JOb9jxLAzFu6gLrqA==";

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          let modified = this.state.settings;
          modified.proposalUrl = data.url;
          this.setState({
            settings: modified,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  downloadStorageDocument = (docUrl) => {
    let filename = /[^/]*$/.exec(docUrl)[0];
    let rqData = {
      container: "client-" + this.props.clientId,
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.context.auth.user.id,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      let chunks = [];

      reader.read().then(function processText({ done, value }) {
        if (done) {
          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let extension = docUrl.substring(docUrl.lastIndexOf("."));
          let mime = extToMimes.hasOwnProperty(extension.toLowerCase())
            ? extToMimes[extension.toLowerCase()]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = "Proposal.pdf";
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  deleteProposal = () => {
    let params = {
      clientId: this.props.clientId,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteProposalFromRetainer?code=vKew2tX9g9gU3FQGZGX09ugy2jLOirRt5w5CGMjIeDzqAzFuX08wyQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => {
        let modified = this.state.settings;
        modified.proposalUrl = null;
        this.setState({
          settings: modified,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={!_.isEmpty(this.state.settings)}
        onClose={this.props.handleDialogClose}
      >
        <input
          id="fileInput"
          type="file"
          ref={(ref) => (this.state.inputFile = ref)}
          style={{ display: "none" }}
          onChange={this.onFileChange}
        ></input>
        <DialogContent>
          <SectionTitle>{t("My Fees")}</SectionTitle>
          <Typography variant="body2" sx={{ pb: 4 }}>
            {
              "Select the BillQuick Case ID for this client's retainer and the amount of hours assigned per month."
            }
          </Typography>

          <TextField
            required
            id="input_case_id"
            label={t("BQ Case ID")}
            defaultValue={this.state.settings.caseId}
            type="text"
            fullWidth
            variant="standard"
            inputProps={{ maxLength: 100 }}
            error={this.state.invalidErrors.bqCaseId}
          />
          <TextField
            required
            id="input_hours"
            label={t("Monthly Hours")}
            defaultValue={this.state.settings.hours}
            type="text"
            fullWidth
            variant="standard"
            inputProps={{ maxLength: 3 }}
            error={this.state.invalidErrors.hours}
          />
          <Button
            sx={{
              mt: 4,
              backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#102a46",
              borderRadius: window.$brand === "PRB" ? 0 : 5,
            }}
            variant="contained"
            onClick={() => this.state.inputFile.click()}
          >
            {t("Upload proposal")}
          </Button>
          {this.state.settings.proposalUrl &&
            this.state.settings.proposalUrl !== null && (
              <>
                <Button
                  sx={{
                    mt: 4,
                    ml: 2,
                    backgroundColor:
                      window.$brand === "PRB" ? "#17afb6" : "#102a46",
                    borderRadius: window.$brand === "PRB" ? 0 : 5,
                  }}
                  variant="contained"
                  onClick={() =>
                    this.downloadStorageDocument(
                      this.state.settings.proposalUrl
                    )
                  }
                >
                  {t("Donwload proposal")}
                </Button>
                <Button
                  sx={{
                    mt: 4,
                    ml: 2,
                    borderRadius: window.$brand === "PRB" ? 0 : 5,
                  }}
                  color="error"
                  variant="contained"
                  onClick={() => this.deleteProposal()}
                >
                  {t("Delete proposal")}
                </Button>
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleDialogClose}>{t("Cancel")}</Button>
          <Button
            onClick={() => {
              if (this.valid()) {
                this.save();
                this.props.handleDialogClose();
              }
            }}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(RetainerSettings);
