import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import BlockUi from "react-block-ui";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getClientDataById,
  listClientsByParent,
} from "../../../../services/clients";

class DocuWareUploadDialog extends React.Component {
  // Modal para finalizar la carga de documentos de DW
  // ingreso de informacion para la tabla "documents" en la DB
  constructor() {
    super();

    this.state = {
      open: false,
      blocking: false,
      results: [],
      selectedDoc: {},
      item: {},
      autocomplete: {
        CLIENTE: [],
        C_DULA_JUR_DICA: [],
        IDENTIFICACION: [],
      },
      openDocuWareUpload: false,
      isViewerOpen: false,
      viewerImages: [],
      subcategories: [],
      vencimiento: null,
      error: {},
      preselectedCustomFile: null,
      clientOrCompanies: [],
      selectedClientOrCompany: -1,
      selectCompanyFor: null,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState(
        {
          open: this.props.data.open,
          results: this.props.data.results,
          item: this.props.data.item,
          subcategories: this.props.data.subcategories,
          selectedClientId: this.props.data.selectedClientId,
          preselectedCustomFile: this.props.data.preselectedCustomFile,
          selectCompanyFor: this.props.data.selectCompanyFor,
        },
        () => {
          if (
            !_.isEmpty(this.props.data.selectCompanyFor) &&
            this.props.data.selectCompanyFor.id >= 0
          )
            this.fetchClientOrCompanies(this.props.data.selectCompanyFor.id);
        }
      );
    }
  }

  fetchClientOrCompanies = async (clientId) => {
    let clientData = await getClientDataById(clientId);
    clientData.type = "client";
    let companies = await listClientsByParent(clientId);

    let clientOrCompanies = [];
    clientOrCompanies.push(clientData);
    _.forEach(companies, (c) => clientOrCompanies.push(c));
    this.setState({
      clientOrCompanies: clientOrCompanies,
    });
  };

  handleCategoryChange = (event) => {
    let modified = this.state.item;
    let selectedValue = event.target.value;

    let workspace =
      this.state.subcategories[parseInt(selectedValue / 1000)].workspace;
    let subcategory =
      this.state.subcategories[parseInt(selectedValue / 1000)].subcategories[
        parseInt(selectedValue % 1000)
      ];

    if (workspace.workspace_id) {
      modified.category_id = workspace.workspace_id;
      modified.subcategory_id = subcategory.id;
    } else {
      modified.category_en = workspace.name_en;
      modified.category_es = workspace.name_es;
      modified.subcategory_en = subcategory.subcategory_en;
      modified.subcategory_es = subcategory.subcategory_es;
    }

    this.setState({
      item: modified,
    });
  };

  uploadCopy = () => {
    let params = {
      clientId: this.state.item.assignedTo,
      url: this.state.item.url,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UploadDocuwareCopy?code=obKKTveBWLGNKD-tkNw_CM5N1jtxy3g0XzTzpDjyvquVAzFulEtR8w==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        let uploadData = this.state.item;
        uploadData.dw_url = uploadData.url; //save for updating to latest version later
        uploadData.url = data.url;
        let extension = data.url.substring(data.url.lastIndexOf("."));
        uploadData.extension = extension;
        this.setState({ item: uploadData }, () => this.insertDocumentToDB());
      })
      .catch((error) => {
        this.setState({ blocking: false });
        console.log(error);
      });
  };

  valid = () => {
    let filenameEl = document.getElementById("input_filename");

    let error = {};
    if (
      !this.state.item.category_en &&
      !this.state.item.category_id &&
      !this.state.preselectedCustomFile
    )
      error.category = true;
    if (_.isEmpty(filenameEl.value)) error.filename = true;

    if (this.state.selectCompanyFor && this.state.item.assignedTo < 0)
      error.clientOrCompany = true;

    this.setState({
      error: error,
    });
    if (!_.isEmpty(error)) this.setState({ blocking: false });

    return _.isEmpty(error);
  };

  insertDocumentToDB = () => {
    let uploadData = this.state.item;
    uploadData.filename = document.getElementById("input_filename").value;
    uploadData.notes = document.getElementById("input_notes").value;
    uploadData.visible = document.getElementById("input_visible_cb").checked
      ? "1"
      : "0";

    if (document.getElementById("input_expiration_cb").checked) {
      let month = this.state.vencimiento.$M + 1;
      let monthStr = month < 10 ? `0${month}` : month;
      let day = this.state.vencimiento.$D;
      let dayStr = day < 10 ? `0${day}` : day;
      uploadData.expiration = `${this.state.vencimiento.$y}-${monthStr}-${dayStr}`;
    }
    if (this.state.preselectedCustomFile) {
      uploadData.file_id = this.state.preselectedCustomFile.id;
    }

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertDocument?code=74hsURB0abOx7ak88UW/Y/Q2TPDe1QuNunuduLXWfq2SB6J/orepdw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(uploadData),
    })
      .then((res) => res.json())
      .then((data) => {
        this.props.refreshFunc();
        this.setState({ blocking: false, selectedClientOrCompany: -1 });
      })
      .catch((error) => {
        this.setState({ blocking: false, selectedClientOrCompany: -1 });
        console.log(error);
      });
    this.props.handleDialogClose();
  };

  renderSelectGroup = (workspace, idxWorkspace) => {
    const { t } = this.props;

    const items = workspace.subcategories.map((subcategory, idxSubcategory) => {
      return (
        <MenuItem
          key={idxWorkspace * 1000 + idxSubcategory}
          value={idxWorkspace * 1000 + idxSubcategory}
        >
          {/* EN is required, ES is optional, default to EN */}
          {window.$lang === "en"
            ? subcategory.subcategory_en
            : subcategory.subcategory_es
            ? subcategory.subcategory_es
            : subcategory.subcategory_en}
        </MenuItem>
      );
    });

    return [
      <ListSubheader>
        {/* EN is required, ES is optional, default to EN */}
        {window.$lang === "en"
          ? workspace.workspace.name_en
          : workspace.workspace.name_es
          ? workspace.workspace.name_es
          : workspace.workspace.name_en}
      </ListSubheader>,
      items,
    ];
  };

  handleClientOrCompanyChange = (event) => {
    let modified = this.state.item;
    let selectedValue = event.target.value;

    modified.assignedTo = selectedValue;

    this.setState({
      item: modified,
      selectedClientOrCompany: selectedValue,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Dialog open={this.state.open} onClose={this.props.handleDialogClose}>
          <BlockUi tag="div" blocking={this.state.blocking}>
            <DialogTitle>{t("upload_document")}</DialogTitle>
            <DialogContent>
              {this.state.selectCompanyFor && (
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <FormControl
                    sx={{ m: 1, minWidth: 250 }}
                    error={this.state.error.clientOrCompany}
                    disabled={!this.state.selectCompanyFor}
                  >
                    <InputLabel htmlFor="grouped-select-client">
                      {"Client or company"}
                    </InputLabel>
                    <Select
                      value={this.state.selectedClientOrCompany}
                      id="grouped-select-client"
                      label="Grouping"
                      onChange={this.handleClientOrCompanyChange}
                    >
                      <ListSubheader>Client</ListSubheader>
                      {_.filter(
                        this.state.clientOrCompanies,
                        (c) => c.type === "client"
                      ).map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                      <ListSubheader>Companies</ListSubheader>
                      {_.filter(
                        this.state.clientOrCompanies,
                        (c) => c.type !== "client"
                      ).map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <FormControl
                  sx={{ m: 1, minWidth: 250 }}
                  error={this.state.error.category}
                  disabled={this.state.preselectedCustomFile}
                >
                  <InputLabel htmlFor="grouped-select">
                    {t("Category")}
                  </InputLabel>
                  <Select
                    defaultValue={this.state.preselectedCustomFile ? "-1" : ""}
                    id="grouped-select"
                    label="Grouping"
                    onChange={this.handleCategoryChange}
                  >
                    {this.state.preselectedCustomFile && (
                      <MenuItem value={-1}>
                        {window.$lang === "en"
                          ? this.state.preselectedCustomFile.subcategory_en
                          : this.state.preselectedCustomFile.subcategory_es
                          ? this.state.preselectedCustomFile.subcategory_es
                          : this.state.preselectedCustomFile.subcategory_en}
                      </MenuItem>
                    )}
                    {this.state.subcategories?.map((workspace, idx) =>
                      this.renderSelectGroup(workspace, idx)
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  required
                  id="input_filename"
                  label={t("Filename")}
                  type="text"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 100 }}
                  error={this.state.error.filename}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  id="input_notes"
                  multiline
                  label={t("Notes")}
                  type="text"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 255 }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3 }}>
                <Checkbox id="input_expiration_cb"></Checkbox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={t("Expires")}
                    value={this.state.vencimiento}
                    onChange={(newValue) => {
                      this.setState({
                        vencimiento: newValue,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "flex-end", pt: 3, ml: 1 }}
              >
                <FormControlLabel
                  control={<Checkbox defaultChecked id="input_visible_cb" />}
                  label={t("Visible_for_clients")}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleDialogClose}>
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => {
                  this.setState({ blocking: true });
                  if (this.valid()) this.uploadCopy();
                }}
              >
                {t("Upload")}
              </Button>
            </DialogActions>
          </BlockUi>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(DocuWareUploadDialog);
