import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import PRBVersion from "./PRBVersion";
import BLPVersion from "./BLPVersion";

class TermsAndConditions extends React.Component {
  // Modal de terminos y condiciones
  
  constructor() {
    super();

    this.state = {
      user: {},
      readOnly: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user,
      });
    }
    if (prevProps.readOnly !== this.props.readOnly) {
      this.setState({
        readOnly: this.props.readOnly,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={!_.isEmpty(this.state.user) || this.state.readOnly}
        onClose={this.props.handleDialogClose}
      >
        <DialogTitle>{"Términos y condiciones"}</DialogTitle>
        <DialogContent>
          {window.$brand === "BLP" ? <BLPVersion /> : <PRBVersion />}
        </DialogContent>
        {!this.state.readOnly && (
          <DialogActions sx={{ p: 3 }}>
            <strong>
              Al hacer clic en Aceptar a continuación hago constar mi aceptación
              expresa de los términos que aquí se detallan.
            </strong>
          </DialogActions>
        )}
        <DialogActions>
          {this.state.readOnly ? (
            <Button onClick={this.props.handleDialogClose}>{t("Close")}</Button>
          ) : (
            <>
              <Button onClick={this.props.handleDialogClose}>
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => {
                  this.props.acceptTermsAndConditions();
                  this.props.handleDialogClose();
                }}
              >
                {t("Accept")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(TermsAndConditions);
