import React from "react";
import Container from "@mui/material/Container";
import PageTitle from "../../PageTitle";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import { authContext } from "../../../contexts/AuthContext";
import _ from "lodash";
import UserCard from "./UserCard";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import CPTip from "../../Utils/CPTip";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

class Usuarios extends React.Component {
  // Pagina de administracion de usuarios para el admin del cliente

  constructor() {
    super();

    this.state = {
      client: {},
      users: undefined,
      filteredUsers: undefined,
      add: {},
    };
  }

  static contextType = authContext;

  handleAddOpen = (user) => {
    this.setState({
      add: user,
    });
  };

  handleAddPreferredLangChange = (event) => {
    let modified = this.state.add;
    modified.preferredLang = event.target.value;
    this.setState({
      add: modified,
    });
  };

  handleAddClose = () => {
    if (this.state.add.id) {
      this.setState({
        users: _.concat(this.state.users, this.state.add),
        filteredUsers: _.concat(this.state.filteredUsers, this.state.add),
        add: {},
      });
    } else {
      this.setState({
        add: {},
      });
    }
  };

  handleDialogClose = () => {
    this.setState({
      add: {},
    });
  };

  componentDidMount() {
    this.fetchClientByUserAdmin();
  }

  fetchClientByUserAdmin = () => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/GetClientByUserAdmin?code=fsxVj5CIgudIwqfXHDgL0Mu1ZV/aD4eHgEI7Qu5ehtOuSpjNJ/iaRw==&userId=" +
        this.context.auth.user.id
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          client: data,
        });
        this.fetchUserListByMasterClient();
      });
  };

  fetchUserListByMasterClient = () => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/ListUsersByMasterClient?code=1_2rxiMdihDe9NAebp46OvzqAM_-ZRYNO-jru6AbVAY5AzFu8oxfGw==&client_id=" +
        this.state.client.id
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          users: data,
          filteredUsers: data,
        });
      });
  };

  insert = (user) => {
    user.first_name = document.getElementById("insert_firstName").value;
    user.last_name = document.getElementById("insert_lastName").value;
    user.email = document.getElementById("insert_email").value;
    user.phone = document.getElementById("insert_phone").value;
    user.mobile = document.getElementById("insert_mobile").value;
    user.preferredLang = this.state.add.preferredLang;
    user.loggedUserId = this.context.auth.user.id;
    user.loggedUserEmail = this.context.auth.user.email;
    user.brandLabel = window.$brand;
    user.clientId = this.state.client.id;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertUser?code=1rzrTmgTR3ilqULbUXsCq9DUUAz4Wr2PlB886sk3XY5cMg894SjilA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          add: data,
        });
        this.handleAddClose();
      })
      .catch((error) => console.log(error));
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const res = this.state.users.filter((user) => {
        return (
          user.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          user.last_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (
            user.first_name.toLowerCase() +
            " " +
            user.last_name.toLowerCase()
          ).includes(keyword.toLowerCase()) ||
          user.email.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      this.setState({
        filteredUsers: res,
      });
    } else {
      this.setState({
        filteredUsers: this.state.users,
      });
    }
  };

  cardDeleteHandler = (e) => {
    _.remove(this.state.users, (c) => {
      return c.id === e.id;
    });
    _.remove(this.state.filteredUsers, (c) => {
      return c.id === e.id;
    });
    this.forceUpdate();
  };

  render() {
    if (!this.state.users) {
      return <div />;
    }

    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={6}>
            <PageTitle>{t("Users")}</PageTitle>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button
              sx={{
                m: 3,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() =>
                this.handleAddOpen({
                  created_by: this.context.auth.user.id,
                  type: "client_user",
                  preferredLang: "es",
                })
              }
            >
              {t("Add_user")}
            </Button>
          </Grid>
        </Grid>
        <TextField
          id="user-search"
          label={t("User_filter")}
          onChange={this.filter}
          type="text"
          fullWidth
          variant="standard"
          sx={{ mb: 4, mt: 2 }}
        />

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {this.state.filteredUsers.map((user) => (
            <Grid item xs={12} sm={8} md={4} key={user.id}>
              <UserCard
                user={user}
                cardDeleteHandler={this.cardDeleteHandler}
              />
            </Grid>
          ))}
        </Grid>
        <Dialog open={!_.isEmpty(this.state.add)} onClose={this.handleAddClose}>
          <DialogTitle>{t("add_user")}</DialogTitle>
          <DialogContent>
            {window.$brand === "PRB" && (
              <Box sx={{ pb: 2 }}>
                <CPTip text={t("Sending_new_user_email")} />
              </Box>
            )}
            <Typography variant="body2" sx={{ pb: 4 }}>
              {t("Remember_to_configure")}{" "}
              <SettingsOutlinedIcon fontSize="small" />{" "}
              {t("complete_user_permissions")}
            </Typography>

            <TextField
              required
              id="insert_firstName"
              label={t("Name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_lastName"
              label={t("Last_name")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="insert_email"
              label={t("Email")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="insert_phone"
              label={t("Phone")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="insert_mobile"
              label={t("Mobile")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
              sx={{ pb: 3 }}
            />
            <FormControl fullWidth>
              <InputLabel id="insert_preferred_lang_label">
                {t("Preferred_lang")}
              </InputLabel>
              <Select
                labelId="insert_preferred_lang_label"
                id="insert_preferred_lang"
                label={t("Preferred_lang")}
                onChange={this.handleAddPreferredLangChange}
                defaultValue={"es"}
              >
                <MenuItem value={"es"}>{t("Spanish")}</MenuItem>
                <MenuItem value={"en"}>{t("English")}</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAddClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.insert(this.state.add)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(Usuarios);
