import React from "react";
import { CardActions, IconButton, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import { withTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import DeleteDialog from "../../DeleteDialog";
import "react-block-ui/style.css";
import { authContext } from "../../../contexts/AuthContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import CPButton from "../../Utils/CPButton";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPSettingsIconButton from "../../Utils/CPSettingsIconButton";
import OpenInBrowserOutlinedIcon from "@mui/icons-material/OpenInBrowserOutlined";
import CPText from "../../Utils/CPText";

class ClientCard extends React.Component {
  // Card para cada cliente en Clientes.js
  constructor() {
    super();

    this.state = {
      deleteClient: {},
    };
  }

  static contextType = authContext;

  handleDeleteClientOpen = (client) => {
    this.setState({
      deleteClient: client,
    });
  };

  handleDialogClose = () => {
    this.setState({
      deleteClient: {},
    });
  };

  updateClient = (client) => {
    client.name = document.getElementById("edit_name").value;
    client.license = document.getElementById("edit_license").value;
    client.loggedUserId = this.context.auth.user.id;
    client.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateClient?code=WaCj6aKC1GXugrtEQfzMaFwXaRJGtTiVH3I/qpalcLen1or7KFRVXQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(client),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  deleteClient = (client) => {
    client.loggedUserId = this.context.auth.user.id;
    client.brandLabel = window.$brand;
    client.workspaceId = this.props.workspaceId;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteClient?code=30WVuEccojkUfLbHaxmEjP82anP1WUcAbo2bPNB6pWlsR4yzunPi/g==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(client),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");
      })
      .catch((error) => console.log(error));

    this.props.cardDeleteHandler(client);
  };

  render() {
    const { t } = this.props;

    return (
      <Card
        sx={{
          minWidth: 275,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: window.$brand === "BLP" ? 5 : 0,
          mr: window.$brand === "BLP" ? 10 : 0,
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color="text.primary" variant="body1">
                <strong>{this.props.client.name}</strong>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: "auto" }}>
          <Link
            to={{
              pathname: `/client/${this.props.client.id}`,
              state: { client: this.props.client },
            }}
          >
            <IconButton
              sx={{
                color: "#78BE20",
              }}
              edge="end"
            >
              <Tooltip title={t("Open")}>
                <OpenInBrowserOutlinedIcon />
              </Tooltip>
            </IconButton>
          </Link>
          {(this.context.auth.user.userType === "blp_admin" 
            || this.context.auth.user.userType === "admin_IDB" 
            || this.context.auth.user.userType === "admin_epa"
            || this.context.auth.user.userType === "admin_asal"
            || this.context.auth.user.userType === "admin_vololoans"
            || this.context.auth.user.userType === "admin_subway"
            || this.context.auth.user.userType === "admin_kraft"
            || window.$brand === "PRB" ||
            window.$brand === "BLP") && (
            <>
              <Link
                to={{
                  pathname: "/Administracion",
                  state: { client: this.props.client },
                }}
              >
                <CPSettingsIconButton />
              </Link>
              <CPDeleteIconButton
                onClick={() => this.handleDeleteClientOpen(this.props.client)}
              />
            </>
          )}
        </CardActions>
        <DeleteDialog
          item={this.state.deleteClient}
          title={
            window.$brand === "ZAP" ? t("delete_condo") : t("delete_client")
          }
          caption={
            window.$brand === "ZAP"
              ? t("delete_condo_full_caption")
              : t("delete_client_full_caption")
          }
          targetFunc={this.deleteClient}
          handleDialogClose={this.handleDialogClose}
        />
      </Card>
    );
  }
}

export default withTranslation()(ClientCard);
