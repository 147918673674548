import React from "react";
import Container from "@mui/material/Container";
import PageTitle from "../../PageTitle";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import { authContext } from "../../../contexts/AuthContext";
import _ from "lodash";
import WorkspaceCard from "./WorkspaceCard";
import { withTranslation } from "react-i18next";
import { listWorkspacesByBrandLabel } from "../../../services/workspaces";

class Workspaces extends React.Component {
  // Pagina para administrar los espacios de trabajo
  
  constructor() {
    super();

    this.state = {
      workspaces: undefined,
      filteredWorkspaces: undefined,
      add: {},
    };
  }

  static contextType = authContext;

  handleAddOpen = (workspace) => {
    this.setState({
      add: workspace,
    });
  };

  handleAddClose = () => {
    if (this.state.add.id) {
      this.setState({
        workspaces: _.concat(this.state.workspaces, this.state.add),
        filteredWorkspaces: _.concat(
          this.state.filteredWorkspaces,
          this.state.add
        ),
        add: {},
      });
    } else {
      this.setState({
        add: {},
      });
    }
  };

  handleDialogClose = () => {
    this.setState({
      add: {},
    });
  };

  componentDidMount() {
    this.fetchWorkspaces();
  }

  fetchWorkspaces = async () => {
    let list = await listWorkspacesByBrandLabel();
    this.setState({
      workspaces: list,
      filteredWorkspaces: list,
    });
  };

  insert = (workspace) => {
    workspace.name_en = document.getElementById("insert_name_en").value;
    workspace.name_es = document.getElementById("insert_name_es").value;
    workspace.description = document.getElementById("insert_description").value;
    workspace.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertWorkspace?code=_FoChSRg1Ug1E0HouteOr7oaLHehmE-7CQbiN5Zbsk_MAzFufIRfIA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(workspace),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.setState({
          add: data,
        });
        this.handleAddClose();
      })
      .catch((error) => console.log(error));
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const res = this.state.workspaces.filter((workspace) => {
        return workspace.name.toLowerCase().includes(keyword.toLowerCase());
      });
      this.setState({
        filteredWorkspaces: res,
      });
    } else {
      this.setState({
        filteredWorkspaces: this.state.workspaces,
      });
    }
  };

  cardDeleteHandler = (e) => {
    _.remove(this.state.workspaces, (c) => {
      return c.id === e.id;
    });
    _.remove(this.state.filteredWorkspaces, (c) => {
      return c.id === e.id;
    });
    this.forceUpdate();
  };

  render() {
    if (!this.state.workspaces) {
      return <div />;
    }

    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={6}>
            <PageTitle>{t("Workspaces")}</PageTitle>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button
              sx={{
                m: 3,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() =>
                this.handleAddOpen({
                  label: window.$brand,
                })
              }
            >
              {t("Add_workspace")}
            </Button>
          </Grid>
        </Grid>
        <TextField
          id="workspace-search"
          label={t("Workspace_filter")}
          onChange={this.filter}
          type="text"
          fullWidth
          variant="standard"
          sx={{ mb: 4, mt: 2 }}
        />

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {this.state.filteredWorkspaces.map((workspace) => (
            <Grid item xs={12} sm={8} md={4}>
              <WorkspaceCard
                workspace={workspace}
                cardDeleteHandler={this.cardDeleteHandler}
              />
            </Grid>
          ))}
        </Grid>
        <Dialog open={!_.isEmpty(this.state.add)} onClose={this.handleAddClose}>
          <DialogTitle>{t("Add_workspace")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="insert_name_en"
              label={t("Name_en")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 40 }}
            />
            <TextField
              id="insert_name_es"
              label={t("Name_es")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 40 }}
            />
            <TextField
              id="insert_description"
              label={t("Description")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 100 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAddClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.insert(this.state.add)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(Workspaces);
