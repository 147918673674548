import React from "react";
import { Typography } from "@mui/material";

class CPText extends React.Component {
  render() {
    const { label, text } = this.props;

    return (
      <Typography variant="subtitle2">
        {label && <strong>{label}: </strong>}
        {text}
      </Typography>
    );
  }
}

export default CPText;
