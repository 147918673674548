import React from "react";
import _ from "lodash";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { withTranslation } from "react-i18next";
import CPTip from "./Utils/CPTip";

class ClientNavigation extends React.Component {
  constructor() {
    super();

    this.state = {
      client: {},
      companies: [],
      selectedClientDetail: {},
      selectedCompany: null,
      selectedArea: null,
      anchorCompanyEl: false,
      anchorAreaEl: false,
    };
  }

  componentDidMount() {
    this.setState({
      client: this.props.client,
      selectedClientDetail: this.props.client,
    });
    this.fetchClientList();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.updateTo !== null &&
      this.props.updateTo !== prevProps.updateTo
    ) {
      if (this.props.updateTo.type === "company") {
        let company = this.props.updateTo;
        if (!company.hasOwnProperty("areas")) company.areas = [];
        this.setState({
          selectedArea: null,
          selectedCompany: company,
          selectedClientDetail: company,
        });
      } else if (this.props.updateTo.type === "area") {
        let url =
          "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByParent?code=ABu6YYdXe9PQSfpvdSG9slk9rJqSCLMmHxXbZ1rk4NzY6yyrfGviOg==";

        fetch(url, {
          method: "POST",
          body: JSON.stringify({
            parent: this.props.client.id,
            brandLabel: window.$brand,
          }),
        })
          .then((response) => response.json())
          .then((companies) => {
            let company = _.find(companies, (company) => {
              return company.id === area.parent;
            });
            company.areas = [];
            this.setState({
              selectedCompany: company,
            });
          });
        let area = this.props.updateTo;
        this.setState({
          selectedArea: area,
          selectedClientDetail: area,
        });
      }
    }
  }

  fetchClientList = () => {
    let params = {
      parent: this.props.client.id,
      brandLabel: window.$brand,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByParent?code=ABu6YYdXe9PQSfpvdSG9slk9rJqSCLMmHxXbZ1rk4NzY6yyrfGviOg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((companies) => {
        _.forEach(companies, (company) => {
          params.parent = company.id;

          fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((areas) => {
              _.find(companies, { id: company.id }).areas = areas;
              company.areas = areas;
              this.forceUpdate();
            });
        });
        this.setState({
          companies: companies,
        });
      });
  };

  handleCompanyMenuClick = (event) => {
    this.setState({
      anchorCompanyEl: event.currentTarget,
    });
  };

  handleAreaMenuClick = (event) => {
    if (this.state.selectedCompany == null) return;
    this.setState({
      anchorAreaEl: event.currentTarget,
    });
  };

  handleCompanyMenuClose = (event) => {
    let selectedCompanyName = _.isEmpty(event.target.innerText)
      ? this.state.selectedCompany == null
        ? ""
        : this.state.selectedCompany.name
      : event.target.innerText;
    let selected = _.find(
      this.state.companies,
      (company) => company.name === selectedCompanyName
    );
    let clearArea =
      this.state.selectedCompany == null ||
      selectedCompanyName !== this.state.selectedClientDetail.name;
    this.setState({
      anchorCompanyEl: null,
      selectedCompany: selected ? selected : null,
      selectedArea: clearArea ? null : this.state.selectedArea,
      selectedClientDetail: selected
        ? selected
        : this.state.selectedClientDetail,
    });
    this.props.updateClientNavigation(
      selected ? selected : this.state.selectedClientDetail
    );
  };

  handleAreaMenuClose = (event) => {
    let selectedAreaName = _.isEmpty(event.target.innerText)
      ? this.state.selectedArea == null
        ? ""
        : this.state.selectedArea.name
      : event.target.innerText;
    let selected = _.find(
      this.state.selectedCompany.areas,
      (area) => area.name === selectedAreaName
    );
    this.setState({
      anchorAreaEl: null,
      selectedArea: selected ? selected : null,
      selectedClientDetail: selected
        ? selected
        : this.state.selectedClientDetail,
    });
    this.props.updateClientNavigation(
      selected ? selected : this.state.selectedClientDetail
    );
  };

  handleClientClick = (event) => {
    this.setState({
      selectedArea: null,
      selectedCompany: null,
      selectedClientDetail: this.state.client,
    });
    this.props.updateClientNavigation(this.state.client);
  };

  SideBar = (props) => {
    return (
      <span
        style={{
          color: "#fff",
          backgroundImage: props.selected
            ? window.$brand === "PRB"
              ? 'url("/assets/PRBSideBar.png")'
              : 'url("/assets/BLPSideBar.png")'
            : 'url("/assets/greySideBar.png")',
          backgroundSize: "contain",
          backgroundRepeat: "repeat",
          margin: 0,
          paddingBottom: "100px",
        }}
      >
        &nbsp;&nbsp;
      </span>
    );
  };

  selectClient = (client) => {
    this.setState({
      selectedClientDetail: client,
    });
    this.props.updateClientNavigation(client);
  };

  render() {
    const { t } = this.props;

    return (
      <Container
        component={Paper}
        sx={{ p: 2, mb: 2, borderRadius: window.$brand === "PRB" ? 0 : 5 }}
      >
        {this.props.caption && <CPTip text={this.props.caption} />}
        {this.props.titleCaption && (
          <>
            <Typography variant="subtitle2">
              {this.props.titleCaption}
            </Typography>
            <Typography component="h4" variant="h4" style={{ color: "#000" }}>
              <strong>{this.state.selectedClientDetail.name}</strong>
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              style={{ color: "#000" }}
              sx={{ pb: 4 }}
            >
              {this.state.selectedClientDetail.license}
            </Typography>
          </>
        )}
        <Typography component="p" variant="body" style={{ fontSize: "0.8em" }}>
          {window.$brand === "ZAP" ? t("Condominium") : t("Client")}
        </Typography>
        <Typography
          component="p"
          variant="body"
          style={{
            marginBottom: "10px",
            cursor: "pointer",
          }}
          onClick={() => this.selectClient(this.state.client)}
        >
          {this.state.client.id === this.state.selectedClientDetail.id ? (
            <strong
              style={{
                background: window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
                color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
                borderRadius: "10px",
                padding: "6px",
              }}
            >
              <FolderOpenOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
              {this.state.client.name}
            </strong>
          ) : (
            <>
              <FolderOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
              {this.state.client.name}
            </>
          )}
        </Typography>
        {!_.isEmpty(this.state.companies) && (
          <Typography
            component="p"
            variant="body"
            style={{ fontSize: "0.8em" }}
          >
            {window.$brand === "ZAP" ? t("Properties") : window.$brand === "IDB" ? t("Financings") : t("Companies")}
          </Typography>
        )}
        <div style={{ margin: 0, padding: 0, overflow: "hidden" }}>
          {this.state.companies.map((company) => (
            <div key={company.id}>
              <this.SideBar
                selected={company.id === this.state.selectedClientDetail.id}
              />
              {company.id === this.state.selectedClientDetail.id ? (
                <Typography
                  component="span"
                  variant="body"
                  style={{
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                >
                  <strong
                    style={{
                      background:
                        window.$brand === "PRB" ? "#41bcfc44" : "#4593db44",
                      color: window.$brand === "PRB" ? "#017cbc" : "#05539b",
                      borderRadius: "10px",
                      padding: "6px",
                    }}
                  >
                    <FolderOpenOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                    {company.name}
                  </strong>
                </Typography>
              ) : (
                <Typography
                  component="span"
                  variant="body"
                  style={{
                    color: "#000",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.selectClient(company)}
                >
                  <FolderOutlinedIcon sx={{ pt: 0.5 }} fontSize="small" />
                  {company.name}
                </Typography>
              )}
            </div>
          ))}
        </div>
      </Container>
    );
  }
}

export default withTranslation()(ClientNavigation);
