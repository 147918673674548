import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { authContext } from "../contexts/AuthContext";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

export const SignOutButton = (props) => {
  const { instance } = useMsal();
  const { setAuthData, auth } = useContext(authContext);

  const [anchorAccountEl, setAnchorAccountEl] = React.useState(false);
  const { t } = useTranslation();

  function handleLogout(instance) {
    if (auth.type === "client") {
      setAuthData(null, null, null, null);
    } else if (auth.type === "blp") {
      instance
        .logoutPopup()
        .then((d) => {
          setAuthData(null, null, null, null);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  function handleAccountMenuClick(event) {
    setAnchorAccountEl(event.currentTarget);
  }

  function handleAccountMenuClose(event) {
    setAnchorAccountEl(null);
  }

  return (
    <>
      {auth.user.image_url ? (
        <Avatar src={auth.user.image_url}></Avatar>
      ) : (
        <Avatar>{auth.user.first_name[0]}</Avatar>
      )}
      <Button
        color="inherit"
        onClick={handleAccountMenuClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ color: "#fff" }}
      >
        <Stack sx={{ textAlign: "end" }}>
          <Typography>
            {auth.user.first_name} {auth.user.last_name}
          </Typography>
          <Typography sx={{ fontSize: "0.75em" }}>{auth.user.email}</Typography>
        </Stack>
      </Button>
      <Menu
        id="account-menu"
        anchorEl={anchorAccountEl}
        open={anchorAccountEl}
        onClose={handleAccountMenuClose}
        MenuListProps={{
          "aria-labelledby": "btn-nav-area",
        }}
      >
        <MenuItem onClick={handleAccountMenuClose}>
          <Link to="/Perfil">{t("Profile")}</Link>
        </MenuItem>
        <MenuItem onClick={() => handleLogout(instance)}>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </>
  );
};
