import React from "react";
import { TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import "react-block-ui/style.css";
import Box from "@mui/material/Box";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import SectionTitle from "../../SectionTitle";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CPTip from "../../Utils/CPTip";

class AssignedLawyersCard extends React.Component {
  // Abogados asignados al cliente, el cliente los ve en la pagina de LawyerInformation
  
  constructor() {
    super();

    this.state = {
      snackbarInfoOpen: false,
      users: undefined,
      filteredUsers: [],
      assignedUsers: [],
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchAssignedUsers(this.props.client.id);
    this.fetchADUserList();
  }

  fetchADUserList = () => {
    let params = {
      brandLabel: window.$brand,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListADUsers?code=sHDaeBFa7a2FeY5Kb3YmJU0iobSmkySTIkg7Bkjw8BauaL2He9hO5g==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          users: data,
          filteredUsers: data,
        });
      });
  };

  fetchAssignedUsers = (clientId) => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/ListAssignedLawyersByClient?code=LA-UtbtsN-EHNQCA_h_b5vg3uBXqvJlwHFziSrvWkUVcAzFu1y1gzg==&clientId=" +
        clientId
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          assignedUsers: _.map(data, "user_id"),
        });
      });
  };

  toggleAssignLawyer = (event, userId) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ToggleAssignClientLawyer?code=fDYO6JMK6htJZsn-0uPaAsLckpBpaTL-c_RdgtWwfRHxAzFueemZSw==";

    let assignedUsers = this.state.assignedUsers;
    if (event.target.checked) {
      assignedUsers.push(userId);
    } else {
      _.remove(assignedUsers, (uid) => {
        return uid == userId;
      });
    }
    this.setState({
      assignedUsers: assignedUsers,
    });

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        userId: userId,
        clientId: this.props.client.id,
        value: event.target.checked ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Toggle successful");
      })
      .catch((error) => console.log(error));
  };

  filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const res = this.state.users.filter((user) => {
        return (
          user.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          user.last_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (
            user.first_name.toLowerCase() +
            " " +
            user.last_name.toLowerCase()
          ).includes(keyword.toLowerCase()) ||
          user.email.toLowerCase().includes(keyword.toLowerCase())
        );
      });
      this.setState({
        filteredUsers: res,
      });
    } else {
      this.setState({
        filteredUsers: this.state.users,
      });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Box
        sx={{
          flexGrow: 1,
          mb: 4,
          p: 2,
          maxWidth: 400,
          borderRadius: window.$brand === "PRB" ? 0 : 5,
        }}
        component={Paper}
      >
        <SectionTitle>{t("Assigned_lawyers")}</SectionTitle>
        <Box sx={{ pb: 2 }}>
          <CPTip text={t("Client_will_see_this_info")} />
        </Box>
        {/* <TextField
          label={t("User_filter")}
          onChange={this.filter}
          type="text"
          fullWidth
          variant="standard"
          sx={{ mb: 4 }}
        /> */}
        <Autocomplete
          multiple
          options={this.state.filteredUsers}
          disableCloseOnSelect
          disableClearable
          getOptionLabel={(u) => u.first_name + " " + u.last_name}
          value={
            this.state.filteredUsers.filter((u) =>
              this.state.assignedUsers.includes(u.id)
            ) || null
          }
          ChipProps={{
            onDelete: null,
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                onChange={(e) => this.toggleAssignLawyer(e, option.id)}
                checked={this.state.assignedUsers.includes(option.id)}
              />
              <ListItemAvatar>
                {option.image_url ? (
                  <Avatar src={option.image_url} />
                ) : (
                  <Avatar>{option.first_name[0]}</Avatar>
                )}
              </ListItemAvatar>
              {option.first_name + " " + option.last_name}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Usuarios" />}
        />
        {/* <List dense sx={{ maxWidth: 360 }}>
          {this.state.filteredUsers.map((user) => (
            <ListItem
              key={user.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={(e) => this.toggleAssignLawyer(e, user.id)}
                  checked={this.state.assignedUsers.includes(user.id)}
                />
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  {user.image_url ? (
                    <Avatar src={user.image_url} />
                  ) : (
                    <Avatar>{user.first_name[0]}</Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={user.first_name + " " + user.last_name}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Box>
    );
  }
}

export default withTranslation()(AssignedLawyersCard);
