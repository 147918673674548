import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import { clientContext } from "../../../contexts/ClientContext";
import Grid from "@mui/material/Grid";
import "react-block-ui/style.css";
import { useTranslation } from "react-i18next";
import {
  listWorkspacesByClientId,
  listWorkspaceSubcategories,
} from "../../../services/workspaces";
import { listFeatureLabels } from "../../../services/features";
import { FullMisDocumentosNavigator } from "./FullMisDocumentosNavigator";
import MisDocumentosProvider from "../../../contexts/MisDocumentosContext";
import MisDocumentosNavigationDetail from "./MisDocumentosNavigationDetail";

export const MisDocumentosIndex = (props) => {
  // Pagina de navegacion de documentos del cliente, pagina para usuarios clientes
  const { t } = useTranslation();
  const { auth } = useContext(authContext);

  const [client, setClient] = useState({});
  const [remountKey, setRemountKey] = useState(0);
  const [workspaces, setWorkspaces] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    fetchClientOrPersonByUser();
  }, []);

  const fetchClientOrPersonByUser = async () => {
    fetch(
      "https://blpclientportalfunctions.azurewebsites.net/api/GetClientOrPersonByUser?code=kqDgy6GbOa8BuRiZlmMWlbyeZuqLOYm3XEGw0bBYz9QqrzSa5sVpeA==&userId=" +
        auth.user.id
    )
      .then((response) => response.json())
      .then((data) => {
        setClient(data);
        fetchClientCategories(data.id);
      });
  };

  const fetchClientCategories = async (clientId) => {
    /* Get subcategories by workspace */
    let workspaces = await listWorkspacesByClientId(clientId);
    let subcategories = [];
    workspaces.map(async (workspace) => {
      let list = await listWorkspaceSubcategories(workspace.workspace_id);
      subcategories.push({
        workspace: workspace,
        subcategories: list,
      });
    });

    /* Get subcategories by feature */
    let courtProceedings = await listFeatureLabels(
      clientId,
      "Court Proceedings"
    );
    if (!_.isEmpty(courtProceedings)) {
      workspaces.push({
        name_en: "Court Proceedings",
        name_es: "Procesos Judiciales",
      });
      let cpSubcategories = [];
      courtProceedings.map((cp) => {
        cpSubcategories.push({
          subcategory_en: cp.label,
          subcategory_es: cp.label,
        });
      });
      subcategories.push({
        workspace: {
          name_en: "Court Proceedings",
          name_es: "Procesos Judiciales",
        },
        subcategories: cpSubcategories,
      });
    }

    let administrativeProcesses = await listFeatureLabels(
      clientId,
      "Administrative Processes"
    );
    if (!_.isEmpty(administrativeProcesses)) {
      workspaces.push({
        name_en: "Administrative Processes",
        name_es: "Procesos Administrativos",
      });
      let apSubcategories = [];
      administrativeProcesses.map((ap) => {
        apSubcategories.push({
          subcategory_en: ap.label,
          subcategory_es: ap.label,
        });
      });
      subcategories.push({
        workspace: {
          name_en: "Administrative Processes",
          name_es: "Procesos Administrativos",
        },
        subcategories: apSubcategories,
      });
    }

    setWorkspaces(workspaces);
    setSubcategories(subcategories);
  };

  if (_.isEmpty(client)) {
    return <div />;
  }

  return (
    <MisDocumentosProvider>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 6, sm: 6, md: 12 }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <FullMisDocumentosNavigator
              client={client}
              caption={t("Navigate_the_hierarchy_client")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <MisDocumentosNavigationDetail userId={auth.user.id} />
          </Grid>
        </Grid>
      </Container>
    </MisDocumentosProvider>
  );
};
