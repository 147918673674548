import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import RetainerCard from "../../Retainer/RetainerCard";
import ProposalCard from "../../Retainer/ProposalCard";

class RetainerSettings extends React.Component {
  //Preview de My Fees o Mis Honorarios en la config del cliente
  constructor() {
    super();

    this.state = {
      openPreview: {},
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.openPreview !== this.props.openPreview) {
      this.setState({
        openPreview: this.props.openPreview,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={!_.isEmpty(this.state.openPreview)}
        onClose={this.props.handleDialogClose}
      >
        <DialogContent>
          <Grid item xs={12} sm={6} md={6}>
            <RetainerCard clientId={this.props.clientId} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ProposalCard clientId={this.props.clientId} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleDialogClose}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(RetainerSettings);
