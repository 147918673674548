import React from "react";
import Container from "@mui/material/Container";
import _ from "lodash";
import { authContext } from "../../../contexts/AuthContext";
import Button from "@mui/material/Button";
import DocumentTable from "../../DocumentTable";
import Grid from "@mui/material/Grid";
import ClientNavigation from "../../ClientNavigation";
import AddIcon from "@mui/icons-material/Add";
import "react-block-ui/style.css";
import { withTranslation } from "react-i18next";
import ClientTitle from "../../ClientTitle";
import { listFeatures } from "../../../services/features";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import {
  listWorkspacesByClientId,
  listWorkspaceSubcategories,
} from "../../../services/workspaces";
import { listFeatureLabels } from "../../../services/features";
import UploadTypeDialog from "./UploadDialogs/UploadTypeDialog";

class ClienteDetalleOld extends React.Component {
  //Antigua pagina de navegacion de documentos del cliente, usuarios internos BLP-PRB
  constructor() {
    super();

    this.state = {
      openUploadTypeDialog: false,
      clientFeatures: [],
      docs: undefined,
      client: {},
      selectedClientDetail: {},
      remountKey: 0,
      workspaces: [],
      subcategories: [],
    };
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static contextType = authContext;

  async componentDidMount() {
    let features = await listFeatures(this.props.client.id);

    this.setState(
      {
        client: this.props.client,
        selectedClientDetail: this.props.client,
        clientFeatures: features,
      },
      () => this.fetchClientCategories()
    );
  }

  fetchClientCategories = async () => {
    /* Get subcategories by workspace */
    let workspaces = await listWorkspacesByClientId(this.state.client.id);
    let subcategories = [];
    workspaces.map(async (workspace) => {
      let list = await listWorkspaceSubcategories(workspace.workspace_id);
      subcategories.push({
        workspace: workspace,
        subcategories: list,
      });
    });

    /* Get subcategories by feature */
    let courtProceedings = await listFeatureLabels(
      this.state.client.id,
      "Court Proceedings"
    );
    if (!_.isEmpty(courtProceedings)) {
      workspaces.push({
        name_en: "Court Proceedings",
        name_es: "Procesos Judiciales",
      });
      let cpSubcategories = [];
      courtProceedings.map((cp) => {
        cpSubcategories.push({
          subcategory_en: cp.label,
          subcategory_es: cp.label,
        });
      });
      subcategories.push({
        workspace: {
          name_en: "Court Proceedings",
          name_es: "Procesos Judiciales",
        },
        subcategories: cpSubcategories,
      });
    }

    let administrativeProcesses = await listFeatureLabels(
      this.state.client.id,
      "Administrative Processes"
    );
    if (!_.isEmpty(administrativeProcesses)) {
      workspaces.push({
        name_en: "Administrative Processes",
        name_es: "Procesos Administrativos",
      });
      let apSubcategories = [];
      administrativeProcesses.map((ap) => {
        apSubcategories.push({
          subcategory_en: ap.label,
          subcategory_es: ap.label,
        });
      });
      subcategories.push({
        workspace: {
          name_en: "Administrative Processes",
          name_es: "Procesos Administrativos",
        },
        subcategories: apSubcategories,
      });
    }

    this.setState({
      workspaces: workspaces,
      subcategories: subcategories,
    });
  };

  hasFeature = (featureName) => {
    return (
      _.find(this.state.clientFeatures, (feature) => {
        return feature.name === featureName;
      }) !== undefined
    );
  };

  updateClientNavigation = (scd) => {
    this.setState({
      selectedClientDetail: scd,
    });
  };

  refreshDocumentTable = () => {
    this.setState({ remountKey: this.state.remountKey + 1 });
  };

  render() {
    const { t } = this.props;

    if (!this.state.selectedClientDetail) {
      return <div />;
    }

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {this.state.client.type === "client" ? (
          <ClientNavigation
            client={this.state.client}
            updateClientNavigation={this.updateClientNavigation}
            caption={t("Navigate_the_hierarchy_admin")}
            titleCaption={t("You_are_assigning_docs")}
          ></ClientNavigation>
        ) : (
          <ClientTitle
            titleCaption={t("You_are_assigning_docs")}
            client={this.state.client}
          />
        )}
        <Grid container>
          <Grid item xs={6} container justifyContent="flex-start">
            {this.hasFeature("Predict Your Closing") && (
              <Link
                to={{
                  pathname: "/PredictYourClosing",
                  state: { client: this.state.client },
                }}
              >
                <Button
                  sx={{
                    m: 3,
                    backgroundColor:
                      window.$brand === "PRB" ? "#17afb6" : "#78BE20",
                  }}
                  variant="contained"
                  onClick={() => {}}
                >
                  {"Predict Your Closing"}
                </Button>
              </Link>
            )}
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            {this.context.auth.user.userType === "blp_admin" && (
              <Button
                sx={{
                  m: 3,
                  backgroundColor:
                    window.$brand === "PRB" ? "#17afb6" : "#102a46",
                  borderRadius: window.$brand === "PRB" ? 0 : 5,
                }}
                variant="contained"
                onClick={() => {
                  let client = this.state.client;
                  this.props.history.push({
                    pathname: "/Administracion",
                    state: { client },
                  });
                }}
              >
                {t("go_to_settings")}
              </Button>
            )}
            <Button
              sx={{
                m: 3,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                this.setState({
                  openUploadTypeDialog: true,
                });
              }}
            >
              {t("Assign_document")}
            </Button>
          </Grid>
        </Grid>

        <DocumentTable
          key={this.state.remountKey}
          client={this.state.selectedClientDetail}
          subcategories={this.state.subcategories}
        ></DocumentTable>

        <UploadTypeDialog
          data={{
            open: this.state.openUploadTypeDialog,
            subcategories: this.state.subcategories,
            selectedClientId: this.state.selectedClientDetail.id,
          }}
          handleDialogClose={() =>
            this.setState({ openUploadTypeDialog: false })
          }
          refreshFunc={this.refreshDocumentTable}
        />
      </Container>
    );
  }
}

export default withTranslation()(ClienteDetalleOld);
