import React from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { SignInButton } from "../../SignInButton";
import "./SignIn.css";
import { useTranslation } from "react-i18next";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

const SignInAD = (props) => {
  // Pagina de login para usuarios internos (AAD)
  
  const [snackbarErrorOpen, setSnackbarErrorOpen] = React.useState(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = React.useState(false);
  const [blockingLogin, setBlockingLogin] = React.useState(false);
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbarErrorOpen(false);
    setSnackbarSuccessOpen(false);
  };

  return (
    <Box className="login-box">
      <div
        className="login-wrap"
        style={{
          backgroundImage: `url(${
            window.$brand === "PRB"
              ? "/assets/bg_PRB.png"
              : "/assets/bg_BLP.png"
          })`,
        }}
      >
        <BlockUi tag="div" blocking={blockingLogin}>
          <form className="login-form" action="">
            <div className="login-form-header">
              <Box
                component="img"
                alignItems="center"
                sx={{
                  height: window.$brand === "PRB"
                  ? 80 : 60,
                }}
                alt="Logo"
                src={
                  window.$brand === "PRB"
                    ? "/assets/legally.png"
                    : "/assets/BLP Micrositeazulverde.png"
                }
              />
              {window.$brand === "BLP" && <h3>{t("Client_Portal")}</h3>}
            </div>
            <div className="login-form-footer">
              <SignInButton history={props.history} />
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbarErrorOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              key={"bottomcentererror"}
            >
              <Alert elevation={6} variant="filled" severity="error">
                {t("Invalid_credentials")}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={snackbarSuccessOpen}
              autoHideDuration={4000}
              onClose={handleSnackbarClose}
              key={"bottomcentersuccess"}
            >
              <Alert elevation={6} variant="filled" severity="success">
                {t("Welcome")}
              </Alert>
            </Snackbar>
          </form>
        </BlockUi>
      </div>
    </Box>
  );
};

export default SignInAD;
