import React from "react";
import Container from "@mui/material/Container";
import { authContext } from "../../../contexts/AuthContext";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import LawyerInformationCard from "../LawyerInformation/LawyerInformationCard";
import EnableMFA from "./EnableMFA";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import NotificationsCard from "./NotificationsCard";
import CPButton from "../../Utils/CPButton";

class Perfil extends React.Component {
  // Pagina de perfil de usuario
  constructor() {
    super();

    this.state = {
      client: {},
      companies: [],
      changePassword: {},
      snackbarSuccessOpen: false,
      snackbarErrorOpen: false,
      openTermsAndConditions: false,
    };
  }

  static contextType = authContext;

  componentDidMount() {}

  handleChangePasswordOpen = (credentials) => {
    this.setState({
      changePassword: credentials,
    });
  };

  handleChangePasswordClose = () => {
    this.setState({
      changePassword: {},
    });
    this.handleSnackbarClose();
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarSuccessOpen: false,
      snackbarErrorOpen: false,
    });
  };

  changePassword = (credentials) => {
    credentials.oldPassword = document.getElementById(
      "changePassword_oldPassword"
    ).value;
    credentials.newPassword = document.getElementById(
      "changePassword_newPassword"
    ).value;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateUserPassword?code=6mtKqfPVEkC9FO3NlrZwNSduYSyJ9wh8FWXLS8T4qEcvCW00OxBveA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(credentials),
    })
      .then((res) => {
        if (res.status === 401) {
          this.setState({
            snackbarErrorOpen: true,
          });
        } else {
          this.handleChangePasswordClose();
          this.setState({
            snackbarSuccessOpen: true,
          });
        }
      })
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <LawyerInformationCard lawyer={this.context.auth.user} />
        {this.context.auth.type === "client" && (
          <EnableMFA user={this.context.auth.user} />
        )}
        {this.context.auth.type === "client" && (
          <>
            {(this.context.auth.user.userType !== "admin_zapotal" 
              || this.context.auth.user.userType !== "admin_IDB"
              || this.context.auth.user.userType !== "admin_epa"
              || this.context.auth.user.userType !== "admin_asal"
              || this.context.auth.user.userType !== "admin_vololoans"
              || this.context.auth.user.userType !== "admin_subway"
              || this.context.auth.user.userType !== "admin_kraft"
              ) && (
              <NotificationsCard />
            )}
            <Button
              sx={{
                m: 3,
                p: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() =>
                this.handleChangePasswordOpen({
                  id: this.context.auth.user.id,
                })
              }
            >
              {t("change_password")}
            </Button>
            <Button
              sx={{
                m: 3,
                p: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() => this.setState({ openTermsAndConditions: true })}
            >
              {"Terms & Conditions"}
            </Button>
          </>
        )}
        <Dialog
          open={!_.isEmpty(this.state.changePassword)}
          onClose={this.handleChangePasswordClose}
        >
          <DialogTitle>{t("change_password")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="changePassword_oldPassword"
              label={t("current_password")}
              type="password"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              required
              id="changePassword_newPassword"
              label={t("new_password")}
              type="password"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleChangePasswordClose}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => this.changePassword(this.state.changePassword)}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <TermsAndConditions
          readOnly={this.state.openTermsAndConditions}
          handleDialogClose={() =>
            this.setState({ openTermsAndConditions: false })
          }
          acceptTermsAndConditions={() => {}}
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarErrorOpen}
          autoHideDuration={3000}
          onClose={this.state.handleSnackbarClose}
          key={"bottomcentererror"}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {t("invalid_password")}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarSuccessOpen}
          autoHideDuration={4000}
          onClose={this.state.handleSnackbarClose}
          key={"bottomcentersuccess"}
        >
          <Alert elevation={6} variant="filled" severity="success">
            {t("password_change_successful")}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withTranslation()(Perfil);
