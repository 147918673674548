import React from "react";
import { Switch } from "react-router-dom";
import Clientes from "./components/Pages/Clientes/Clientes";
import Usuarios from "./components/Pages/Usuarios/Usuarios";
import UsuariosAD from "./components/Pages/UsuariosAD/UsuariosAD";
import Calendario from "./components/Pages/Calendario/Calendario";
import Perfil from "./components/Pages/Perfil/Perfil";
import PrivateRoute from "./components/PrivateRoute";
import Retainer from "./components/Pages/Retainer/Retainer";
import PRBAdminCenter from "./components/Pages/PRBAdminCenter/PRBAdminCenter";
import Auditoria from "./components/Pages/Auditoria/Auditoria";
import LawyerInformation from "./components/Pages/LawyerInformation/LawyerInformation";
import PredictYourDates from "./components/Pages/PRBSites/PredictYourDates";
import PredictYourClosing from "./components/Pages/PRBSites/PredictYourClosing";
import Workspaces from "./components/Pages/Workspaces/Workspaces";
import Administracion from "./components/Pages/Administracion/Administracion";
import PYCExternal from "./components/Pages/PRBSites/PYCExternal";
import DetalleDocuSign from "./components/Pages/PRBAdminCenter/DetalleDocuSign";
import RepositorioDocuSign from "./components/Pages/PRBAdminCenter/RepositorioDocuSign/RepositorioDocuSign";
import DetalleMastercase from "./components/Pages/PRBAdminCenter/DetalleMastercase";
import MyMetrics from "./components/Pages/PRBAdminCenter/MyMetrics";
import DetalleWoodpeckerForms from "./components/Pages/PRBAdminCenter/DetalleWoodpeckerForms";
import DetalleCalculadoraTimbreFiscal from "./components/Pages/PRBAdminCenter/DetalleCalculadoraTimbreFiscal";
import DetalleDocumentosHelper from "./components/Pages/PRBAdminCenter/DetalleDocumentosHelper";
import DetalleClausulasHelper from "./components/Pages/PRBAdminCenter/DetalleClausulasHelper";
import DetalleRevisionHelper from "./components/Pages/PRBAdminCenter/DetalleRevisionHelper";
import DetalleEFormsHelper from "./components/Pages/PRBAdminCenter/DetalleEFormsHelper";
import { ClienteDetalle } from "./components/Pages/ClienteDetalle/ClienteDetalle";
import { MisDocumentosIndex } from "./components/Pages/MisDocumentos/MisDocumentosIndex";
import ScheduledReminders from "./components/Pages/PRBAdminCenter/ScheduledReminders";

const Routes = () => {
  return (
    <div>
      <Switch>
        <PrivateRoute
          exact
          path="/MisDocumentos"
          component={MisDocumentosIndex}
        />
        <PrivateRoute
          exact
          path="/client/:client_id"
          component={ClienteDetalle}
        />
        <PrivateRoute exact path="/Retainer" component={Retainer} />
        <PrivateRoute exact path="/Workspaces" component={Workspaces} />
        <PrivateRoute exact path="/Administracion" component={Administracion} />
        <PrivateRoute exact path="/Clientes" component={Clientes} />
        <PrivateRoute exact path="/Condominios" component={Clientes} />
        <PrivateRoute exact path="/Usuarios" component={Usuarios} />
        <PrivateRoute exact path="/UsuariosAD" component={UsuariosAD} />
        <PrivateRoute exact path="/Perfil" component={Perfil} />
        <PrivateRoute exact path="/PRBAdminCenter" component={PRBAdminCenter} />
        <PrivateRoute exact path="/Calendario" component={Calendario} />
        <PrivateRoute exact path="/Historial" component={Auditoria} />
        <PrivateRoute exact path="/Lawyer" component={LawyerInformation} />
        <PrivateRoute exact path="/MyMetrics" component={MyMetrics} />
        <PrivateRoute
          exact
          path="/ScheduledReminders"
          component={ScheduledReminders}
        />
        <PrivateRoute
          exact
          path="/DetalleDocuSign"
          component={DetalleDocuSign}
        />
        <PrivateRoute
          exact
          path="/RepositorioDocuSign"
          component={RepositorioDocuSign}
        />
        <PrivateRoute
          exact
          path="/DetalleMastercase"
          component={DetalleMastercase}
        />
        <PrivateRoute
          exact
          path="/DetalleWoodpeckerForms"
          component={DetalleWoodpeckerForms}
        />
        <PrivateRoute
          exact
          path="/DetalleDocumentosHelper"
          component={DetalleDocumentosHelper}
        />
        <PrivateRoute
          exact
          path="/DetalleClausulasHelper"
          component={DetalleClausulasHelper}
        />
        <PrivateRoute
          exact
          path="/DetalleRevisionHelper"
          component={DetalleRevisionHelper}
        />
        <PrivateRoute
          exact
          path="/DetalleEFormsHelper"
          component={DetalleEFormsHelper}
        />
        <PrivateRoute
          exact
          path="/DetalleCalculadoraTimbreFiscal"
          component={DetalleCalculadoraTimbreFiscal}
        />
        <PrivateRoute
          exact
          path="/PredictYourDates"
          component={PredictYourDates}
        />
        <PrivateRoute
          exact
          path="/PredictYourClosing"
          component={PredictYourClosing}
        />
        <PrivateRoute path="/PredictYourClosing/:id/" component={PYCExternal} />
      </Switch>
    </div>
  );
};

export default Routes;
