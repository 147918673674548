import React from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import _ from "lodash";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { authContext } from "../contexts/AuthContext";
import Alerts from "./Alerts";
import { CardActions, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import { TextField } from "@mui/material";
import DeleteDialog from "./DeleteDialog";
import { withTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "react-block-ui/style.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { formatDate } from "../Utils/Format";
import CPButton from "./Utils/CPButton";
import CPDeleteIconButton from "./Utils/CPDeleteIconButton";
import CPEditIconButton from "./Utils/CPEditIconButton";
import CPText from "./Utils/CPText";
import {
  listWorkspacesByClientId,
  listWorkspaceSubcategories,
} from "../services/workspaces";
import DocumentViewer from "./Utils/DocumentViewer";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";

class DocumentCard extends React.Component {
  constructor() {
    super();

    this.state = {
      document: {},
      editDocument: {},
      deleteDocument: {},
      snackbarDownloadingOpen: false,
      valueVencimientoEdit: undefined,
      editError: {},
      alerts: {},
      workspaces: [],
      subcategories: [],
      viewerDoc: {},
    };
  }

  static contextType = authContext;

  async componentDidMount() {
    this.setState({ document: this.props.document });
    this.fetchClientCategories();
  }

  fetchClientCategories = async () => {
    let workspaces = await listWorkspacesByClientId(this.props.clientId);
    let subcategories = [];
    workspaces.map(async (workspace) => {
      let list = await listWorkspaceSubcategories(workspace.workspace_id);
      subcategories.push({
        workspace: workspace.name,
        subcategories: _.map(list, "subcategory"),
      });
    });
    this.setState({
      workspaces: workspaces,
      subcategories: subcategories,
    });
  };

  downloadDocuwareDocument2 = (doc) => {
    this.setState({
      snackbarDownloadingOpen: true,
    });

    let rqData = {
      url: doc.url,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadDocuware2?code=QqEqj/xEWeIym9ZtLILp8gZ9UJSq9AoK0zHgvW53aGfWumbCo1dMFg==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {

        if (done) {

          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  downloadStorageDocument = (doc) => {
    this.setState({
      snackbarDownloadingOpen: true,
    });

    let filename = /[^/]*$/.exec(doc.url)[0];
    let rqData = {
      container:
        doc.source === "docusign"
          ? "prbdocusign"
          : "client-" + this.props.clientId,
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.context.auth.user.id,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];

      reader.read().then(function processText({ done, value }) {

        if (done) {

          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension.toLowerCase()]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarDownloadingOpen: false,
    });
  };

  handleDocViewerClose = () => {
    this.setState({
      viewerDoc: {},
    });
  };

  handleDeleteDocumentOpen = () => {
    this.setState({
      deleteDocument: this.state.document,
    });
  };

  handleEditOpen = (doc) => {
    doc.category = this.state.document.category;
    doc.subcategory = this.state.document.subcategory;
    this.setState({
      editDocument: doc,
      valueVencimientoEdit: this.state.document.expiration
        ? new Date(this.state.document.expiration)
        : new Date(),
    });
  };

  handleDialogClose = () => {
    this.setState({
      editDocument: {},
      deleteDocument: {},
      editError: {},
    });
  };

  handleOpenAlerts = () => {
    this.setState({
      alerts: { docId: this.props.document.id },
    });
  };

  updateDocument = (doc) => {
    doc.filename = document.getElementById("edit_filename").value;
    doc.notes = document.getElementById("edit_notes").value;
    doc.visible = document.getElementById("edit_visible_cb").checked ? 1 : 0;
    if (document.getElementById("edit_expiration_cb").checked) {
      let month = this.state.valueVencimientoEdit.getMonth() + 1;
      let monthStr = month < 10 ? `0${month}` : month;
      let day = this.state.valueVencimientoEdit.getDate();
      let dayStr = day < 10 ? `0${day}` : day;
      doc.expiration = `${this.state.valueVencimientoEdit.getFullYear()}-${monthStr}-${dayStr}`;
    }

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateDocument?code=G1D6YKLhNNKXMfWSFGfokSe-3bmx17fYYHh8Kgw_3aJqAzFumrYmsg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(doc),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
        this.setState({ document: doc });
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  deleteDocument = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteDocument?code=maatAGlqaso51CmMTzUHx6ewyogzQkA/hFpTTKdoe3QokjZOgAI3Hg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(this.state.document),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");
      })
      .catch((error) => console.log(error));

    this.props.cardDeleteHandler(this.state.document);
  };

  validEdit = () => {
    let filenameEl = document.getElementById("edit_filename");

    let editError = {
      //category: _.isEmpty(this.state.editDocument.category),
      filename: _.isEmpty(filenameEl.value),
    };

    this.setState({
      editError: editError,
    });

    return !editError.filename;
  };

  closeAlerts = () => {
    this.setState({ alerts: {} });
  };

  render() {
    const { t } = this.props;

    return (
      <Card
        sx={{
          minWidth: 275,
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: window.$brand === "BLP" ? 5 : 0,
          mr: window.$brand === "BLP" ? 10 : 0,
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container>
            <Grid item xs={6} sx={{ mb: 1.2 }}>
              <Typography color="text.primary" sx={{ fontWeight: 800 }}>
                {this.state.document.filename}
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              {this.context.auth.type === "blp" &&
                (this.state.document.visible === 1 ||
                  this.state.document.visible === true) && (
                  <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <Tooltip title="Visible for clients">
                      <RemoveRedEyeIcon fontSize="small" color="success" />
                    </Tooltip>
                  </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
              <CPText
                label={
                  this.state.document.source !== "dwcopy"
                    ? t("Uploaded")
                    : t("Updated")
                }
                text={formatDate(t, this.state.document.modified)}
              />
            </Grid>
            <Grid item xs={12}>
              {this.state.document.expiration && (
                <CPText
                  label={t("Expires")}
                  text={formatDate(t, this.state.document.expiration)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {this.state.document.notes && (
                <CPText text={this.state.document.notes} />
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: "auto" }}>
          {this.state.document.source !== "docuware" && (
            <CPButton
              icon={<PlagiarismOutlinedIcon />}
              tooltip={t("Open")}
              onClick={() =>
                this.setState({
                  viewerDoc: this.state.document,
                })
              }
            />
          )}
          {this.state.document.source === "docuware" ? (
            <CPButton
              icon={<FileDownloadOutlinedIcon />}
              tooltip={t("Download")}
              onClick={() => {
                this.downloadDocuwareDocument2(this.state.document);
              }}
            />
          ) : (
            <CPButton
              icon={<FileDownloadOutlinedIcon />}
              tooltip={t("Download")}
              onClick={() => {
                this.downloadStorageDocument(this.state.document);
              }}
            />
          )}
          {this.context.auth.type === "blp" && (
            <>
              <CPButton
                icon={<NotificationsOutlinedIcon />}
                tooltip={t("Alerts")}
                onClick={() => {
                  this.handleOpenAlerts();
                }}
              />
              <CPEditIconButton
                onClick={() => {
                  this.handleEditOpen(this.state.document);
                }}
              />
              <CPDeleteIconButton
                onClick={() => {
                  this.handleDeleteDocumentOpen(this.state.document);
                }}
              />
            </>
          )}
        </CardActions>
        <Alerts
          alerts={this.state.alerts}
          clientId={this.props.clientId}
          docId={this.props.document.id}
          closeHandler={this.closeAlerts}
        />
        <DeleteDialog
          item={this.state.deleteDocument}
          title={t("delete_document_title")}
          caption={t("delete_document_caption")}
          targetFunc={this.deleteDocument}
          handleDialogClose={this.handleDialogClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.editDocument)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("edit_document")}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                disabled
                label={t("Category")}
                defaultValue={
                  /* EN is required, ES is optional, default to EN */
                  window.$lang === "en"
                    ? this.state.document.subcategory_en
                    : this.state.document.subcategory_es
                    ? this.state.document.subcategory_es
                    : this.state.document.subcategory_en
                }
                type="text"
                fullWidth
                variant="standard"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="edit_filename"
                label={t("Filename")}
                defaultValue={this.state.document.filename}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 100 }}
                error={this.state.editError.filename}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="edit_notes"
                multiline
                label={t("Notes")}
                defaultValue={this.state.document.notes}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 255 }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3 }}>
              <Checkbox
                id="edit_expiration_cb"
                defaultChecked={this.state.document.hasOwnProperty(
                  "expiration"
                )}
              ></Checkbox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Expires")}
                  value={this.state.valueVencimientoEdit}
                  onChange={(newValue) => {
                    this.setState({
                      valueVencimientoEdit: newValue.$d,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3, ml: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={this.state.document.visible}
                    id="edit_visible_cb"
                  />
                }
                label="Visible for clients"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button
              onClick={() => {
                if (this.validEdit())
                  this.updateDocument(this.state.editDocument);
              }}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <DocumentViewer
          document={this.state.viewerDoc}
          handleDialogClose={this.handleDocViewerClose}
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarDownloadingOpen}
          onClose={this.handleSnackbarClose}
          key={"bottomcentererror"}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="info"
            icon={<HourglassTopIcon />}
          >
            {t("downloading_document")}
          </Alert>
        </Snackbar>
      </Card>
    );
  }
}

export default withTranslation()(DocumentCard);
