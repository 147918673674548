import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, IconButton } from "@mui/material";
import { withTranslation } from "react-i18next";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import CloseIcon from "@mui/icons-material/Close";

class DocumentViewer extends React.Component {
  constructor() {
    super();

    this.state = {
      document: {},
      docs: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.document !== this.props.document) {
      let docs = this.state.docs;
      if (_.isEmpty(this.props.document)) docs = [];

      this.setState(
        {
          document: this.props.document,
          docs: docs,
        },
        () => {
          if (!_.isEmpty(this.props.document)) this.getSASToken();
        }
      );
    }
  }

  getSASToken = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetDocumentSASToken?code=60cfPysOUg_RthP_VGzenaYx6E3JEiTCJnKWTAoc7pJEAzFuJb6uDg==";
    let params = { documentUrl: this.state.document.url };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          docs: [
            {
              uri: data.uri,
            },
          ],
        });
      });
  };

  render() {
    const { t } = this.props;
    ++this.childKey;

    return (
      <Dialog
        open={!_.isEmpty(this.state.document)}
        onClose={this.props.handleDialogClose}
        fullScreen
      >
        <DialogTitle>
          {this.state.document.filename}
          <IconButton
            onClick={this.props.handleDialogClose}
            style={{ float: "right" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div key={this.childKey} style={{ height: "100%" }}>
            {!_.isEmpty(this.state.docs) && (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={this.state.docs}
                config={{
                  header: {
                    disableFileName: true,
                  },
                }}
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleDialogClose}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTranslation()(DocumentViewer);
