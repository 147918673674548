import React from "react";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { withTranslation } from "react-i18next";
import { authContext } from "../../../contexts/AuthContext";

class LawyerInformationCard extends React.Component {
  // Card con la informacion de contacto del abogado
  constructor() {
    super();

    this.state = {};
  }

  static contextType = authContext;

  componentDidMount() {}

  render() {
    const { t } = this.props;

    return (
      <Box
        sx={{
          flexGrow: 1,
          mt: 12,
          mb: 4,
          p: 2,
          maxWidth: 600,
          position: "relative",
        }}
        component={Paper}
      >
        {this.props.lawyer.image_url ? (
          <Avatar
            src={this.props.lawyer.image_url}
            sx={{
              width: 150,
              height: 150,
              position: "absolute",
              left: "35%",
              top: -70,
            }}
          />
        ) : (
          <Avatar
            sx={{
              width: 150,
              height: 150,
              position: "absolute",
              left: "35%",
              top: -70,
              fontSize: 60,
            }}
          >
            {this.props.lawyer.first_name[0]}
          </Avatar>
        )}
        <Box sx={{ mt: 8, ml: 4 }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "2em",
              fontWeight: "900",
              m: 0,
            }}
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
          >
            {this.props.lawyer.first_name + " " + this.props.lawyer.last_name}
          </Typography>
          <Typography sx={{ m: 0 }} variant="h6">
            {this.props.lawyer.role}
          </Typography>
          {(this.props.lawyer.phone || this.props.lawyer.mobile) && (
            <Typography variant="subtitle2">
              {this.props.lawyer.phone && (
                <>
                  {t("Phone") + ": "}
                  <strong>{this.props.lawyer.phone}</strong>
                </>
              )}
              {this.props.lawyer.phone && this.props.lawyer.mobile && (
                <>{" | "}</>
              )}
              {this.props.lawyer.mobile && (
                <>
                  {t("Mobile") + ": "}
                  <strong>{this.props.lawyer.mobile}</strong>
                </>
              )}
            </Typography>
          )}
          <Typography variant="subtitle2" sx={{ pb: 2 }}>
            {t("Email") + ": "}
            <strong>
              <a href={"mailto:" + this.props.lawyer.email}>
                {this.props.lawyer.email}
              </a>
            </strong>
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default withTranslation()(LawyerInformationCard);
