import React, { useContext } from "react";
import Box from "@mui/material/Box";
import "./PYCExternal.css";
import _ from "lodash";
import PredictYourClosing from "./PredictYourClosing";
import { useParams } from "react-router-dom";

const PYCExternal = (props) => {
  // Acceso directo a Predict Your Closing por ID
  const { id } = useParams();
  return (
    <Box className="pyc-box">
      <div
        className="pyc-wrap"
        style={{
          backgroundImage: `url(/assets/bglight_PRB.png)`,
          overflowY: "scroll",
        }}
      >
        <Box sx={{ height: "100%" }}>
          <PredictYourClosing clientId={id} />
        </Box>
      </div>
    </Box>
  );
};

export default PYCExternal;
