import React, { createContext, useState, useEffect } from "react";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    type: null,
    user: null,
    data: null,
    token: null,
  });

  const setAuthData = (type, user, data, token) => {
    setAuth({ type: type, user: user, data: data, token: token });
  };

  const replaceToken = (token) => {
    setAuth({
      type: auth.type,
      user: auth.user,
      data: auth.data,
      token: token,
    });
  };

  useEffect(() => {
    setAuth({
      loading: false,
      type: window.localStorage.getItem("authType"),
      user: JSON.parse(window.localStorage.getItem("authUser")),
      data: JSON.parse(window.localStorage.getItem("authData")),
      token: JSON.parse(window.localStorage.getItem("authToken")),
    });
  }, []);

  useEffect(() => {
    window.localStorage.setItem("authType", auth.type);
    window.localStorage.setItem("authUser", JSON.stringify(auth.user));
    window.localStorage.setItem("authData", JSON.stringify(auth.data));
    window.localStorage.setItem("authToken", JSON.stringify(auth.token));
  }, [auth.type, auth.user, auth.data, auth.token]);

  return (
    <authContext.Provider value={{ auth, setAuthData, replaceToken }}>
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
