import React, { PureComponent } from "react";
import Container from "@mui/material/Container";
import _ from "lodash";
import { Paper, Tab } from "@mui/material";
import { Button } from "@mui/material";
import Title from "../../Title";
import SectionTitle from "../../SectionTitle";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHeader from "../../Utils/TableHeader";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../../Utils/Format";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  PolarAngleAxis,
} from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  RadialBarChart,
  RadialBar,
  Legend,
  Tooltip,
} from "recharts";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box } from "@mui/system";

class DetalleMastercase extends React.Component {
  // Pagina de informacion de trabajo cargado por BLP a PRB
  // Informacion obtenida de BillQuick
  constructor() {
    super();

    let d = new Date();
    this.state = {
      valueDesde: new Date(d.getFullYear(), d.getMonth(), 1),
      valueHasta: d,
      timeEntryRows: {},
      total: 0,
      radialBarData: [],
      stackedAreaData: [],
      selectedTab: "1",
    };
  }

  colors = ["#17afb6", "#c6053c", "#6471ba", "#ffae02", "#0554f2", "#f24405"];

  radialBarLegendStyle = {
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };

  search = () => {
    let monthDesde = this.state.valueDesde.getMonth() + 1;
    let monthDesdeStr = monthDesde < 10 ? `0${monthDesde}` : monthDesde;
    let dayDesde = this.state.valueDesde.getDate();
    let dayDesdeStr = dayDesde < 10 ? `0${dayDesde}` : dayDesde;

    let monthHasta = this.state.valueHasta.getMonth() + 1;
    let monthHastaStr = monthHasta < 10 ? `0${monthHasta}` : monthHasta;
    let dayHasta = this.state.valueHasta.getDate();
    let dayHastaStr = dayHasta < 10 ? `0${dayHasta}` : dayHasta;

    let searchParams = {
      fromDate: `${this.state.valueDesde.getFullYear()}-${monthDesdeStr}-${dayDesdeStr} 00:00:00`,
      toDate: `${this.state.valueHasta.getFullYear()}-${monthHastaStr}-${dayHastaStr} 23:59:59`,
    };

    this.callMastercaseSP(searchParams);
  };

  convertRTFToPlain = (rtf) => {
    rtf = rtf.replace(/\\par[d]?/g, "");
    rtf = rtf.replace(
      /\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g,
      ""
    );
    rtf = rtf.replace(/\\'e1/g, "á");
    rtf = rtf.replace(/\\'e9/g, "é");
    rtf = rtf.replace(/\\'ed/g, "í");
    rtf = rtf.replace(/\\'f3/g, "ó");
    rtf = rtf.replace(/\\'fa/g, "ú");
    rtf = rtf.replace(/\\'c1/g, "Á");
    rtf = rtf.replace(/\\'c9/g, "É");
    rtf = rtf.replace(/\\'cd/g, "Í");
    rtf = rtf.replace(/\\'d3/g, "Ó");
    rtf = rtf.replace(/\\'da/g, "Ú");
    rtf = rtf.replace(/\\'f1/g, "ñ");
    rtf = rtf.replace(/\\'d1/g, "Ñ");
    return rtf.replace(/\\'[0-9a-zA-Z]{2}/g, "").trim();
  };

  handleEmployeeIDSearchChange = (event) => {
    this.setState({
      employeeIDSearch: event.target.value,
    });
  };

  callMastercaseSP = (params) => {
    let url =
      "https://clientportalbqconnector20230425154757.azurewebsites.net/api/PRBMastercase" +
      "?fromDate=" +
      params.fromDate +
      "&toDate=" +
      params.toDate;

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        _.map(data, (row) => {
          row.totalAmount =
            row.TEBill === "0"
              ? 0 /* non-billable */
              : parseFloat(row.TEHours) * parseFloat(row.TEBillRate);
        });
        let groupedRows = _.groupBy(data, "EmployeeID");

        let tot = _.map(data, "totalAmount").reduce((r, n) => {
          return r + parseFloat(n);
        }, 0);

        let barData = [];
        _.keys(groupedRows).forEach((key) => {
          barData.push({
            name: key,
            total: _.map(groupedRows[key], "totalAmount").reduce((r, n) => {
              return r + parseFloat(n);
            }, 0),
          });
        });

        let radialBarData = [];
        _.keys(groupedRows).forEach((key) => {
          let reducedTotal = _.map(groupedRows[key], "totalAmount").reduce(
            (r, n) => {
              return r + parseFloat(n);
            },
            0
          );
          radialBarData.push({
            name: `${key} $${reducedTotal}`,
            total: reducedTotal,
            fill:
              "hsl(" +
              360 * Math.random() +
              "," +
              (25 + 70 * Math.random()) +
              "%," +
              (65 + 10 * Math.random()) +
              "%)",
          });
        });

        this.setState({
          timeEntryRows: groupedRows,
          total: tot,
          radialBarData: radialBarData,
          stackedAreaData: barData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  RadialBarCustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "#ffffffaa", padding: "0.1em 0.8em" }}
        >
          <p className="label">{`${payload[0].payload.name}`}</p>
        </div>
      );
    }

    return null;
  };

  render() {
    const { t } = this.props;

    let renderLabel = function (entry) {
      return entry.name + " $" + entry.total;
    };

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Title>Mastercase Horas PredictaBill</Title>
        <Container component={Paper} sx={{ p: 2, mb: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Desde"
              value={this.state.valueDesde}
              onChange={(newValue) => {
                this.setState({
                  valueDesde: newValue.$d,
                });
              }}
              renderInput={(params) => <TextField {...params} sx={{ m: 2 }} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Hasta"
              value={this.state.valueHasta}
              onChange={(newValue) => {
                this.setState({
                  valueHasta: newValue.$d,
                });
              }}
              renderInput={(params) => <TextField {...params} sx={{ m: 2 }} />}
            />
          </LocalizationProvider>
          <Button
            sx={{ m: 3 }}
            variant="contained"
            onClick={() => this.search()}
          >
            Buscar
          </Button>
        </Container>
        <Title>Total: ${this.state.total}</Title>
        <Box component={Paper} sx={{ mb: 4 }}>
          <TabContext value={this.state.selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(e, v) => {
                  this.setState({ selectedTab: v });
                }}
              >
                <Tab label="Bar Chart" value="1" />
                {/* <Tab label="Radial Chart" value="2" /> */}
              </TabList>
            </Box>
            {/* <TabPanel value="2">
              <ResponsiveContainer width={600} height={300}>
                <RadialBarChart
                  cx="50%"
                  cy="50%"
                  innerRadius="10%"
                  outerRadius="100%"
                  data={this.state.radialBarData}
                >
                  {this.state.radialBarData.map((bar) => {
                    return (
                      <>
                        <PolarAngleAxis
                          type="number"
                          domain={[0, this.state.total]}
                          angleAxisId={bar.name}
                          tick={false}
                        />
                        <RadialBar
                          background
                          dataKey={"total"}
                          angleAxisId={bar.name}
                          data={[bar]}
                        />
                      </>
                    );
                  })}
                  <Legend
                    iconSize={10}
                    layout="vertical"
                    verticalAlign="middle"
                    wrapperStyle={this.radialBarLegendStyle}
                  />
                  <Tooltip content={<this.RadialBarCustomTooltip />} />
                </RadialBarChart>
              </ResponsiveContainer>
            </TabPanel> */}
            <TabPanel value="1">
              <ResponsiveContainer width={600} height={300}>
                <BarChart
                  width={500}
                  height={300}
                  data={this.state.stackedAreaData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="total"
                    background={{ fill: "#eee" }}
                    label={({ payload, x, y, width, height, value }) => {
                      return (
                        <text
                          x={x + width / 2}
                          y={y}
                          textAnchor="middle"
                          dy={-6}
                        >
                          ${value}
                        </text>
                      );
                    }}
                  >
                    {this.state.stackedAreaData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={this.colors[index % this.colors.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </TabPanel>
          </TabContext>
        </Box>
        {!_.isEmpty(this.state.timeEntryRows) &&
          _.keys(this.state.timeEntryRows).map((key) => (
            <div>
              <SectionTitle>{key}</SectionTitle>
              <p>
                Total: $
                {_.map(this.state.timeEntryRows[key], "totalAmount").reduce(
                  (r, n) => {
                    return r + parseFloat(n);
                  },
                  0
                )}
              </p>
              <TableContainer component={Paper} sx={{ mt: 2, mb: 4 }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHeader
                    cols={[
                      "Date",
                      "Staff ID",
                      "Description",
                      "Hrs",
                      "Cost Amt",
                      "Amount",
                    ]}
                  />
                  <TableBody>
                    {this.state.timeEntryRows[key].map((row) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell scope="row">
                          {formatDate(t, row.TEDate)}
                        </TableCell>
                        <TableCell>{row.EmployeeID}</TableCell>
                        {row.TEMemo ? (
                          <TableCell
                            style={{
                              color: row.TEBill === "0" ? "#ff0000" : "#000",
                            }}
                          >
                            {row.TEBill === "0" ? "[NON-BILLABLE] " : ""}
                            {this.convertRTFToPlain(row.TEMemo)}
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                        <TableCell>{row.TEHours}</TableCell>
                        <TableCell>${row.TECostRate}</TableCell>
                        <TableCell>${row.totalAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ))}
      </Container>
    );
  }
}

export default withTranslation()(DetalleMastercase);
