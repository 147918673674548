import React from "react";
import Container from "@mui/material/Container";
import { authContext } from "../../../contexts/AuthContext";
import { Paper } from "@mui/material";
import { getRetainer } from "../../../services/retainers";
import { getMainClient } from "../../../services/clients";
import Button from "@mui/material/Button";
import SectionTitle from "../../SectionTitle";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { withTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import { IconButton } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { formatMonthYear } from "../../../Utils/Format";

class RetainerCard extends React.Component {
  // Card para mostrar horas facturadas en BQ para el case del cliente
  constructor() {
    super();

    let d = new Date();
    d.setDate(1);
    this.state = {
      retainerSettings: {},
      hoursSpent: 0,
      dateValue: d,
      minDate: new Date(d.getFullYear() - 1, d.getMonth(), 1),
      maxDate: new Date(d.getFullYear(), d.getMonth(), 1),
    };
  }

  static contextType = authContext;

  getRetainerSettings = async () => {
    let data = await getRetainer(this.props.clientId);
    this.setState({
      retainerSettings: {
        hours: data.hours,
        caseId: data.bq_case_id,
      },
    });
  };

  getHoursByMonth = async () => {
    let y = this.state.dateValue.getFullYear();
    let m = this.state.dateValue.getMonth();
    let lastDayOfMonth = new Date(y, m + 1, 0);

    let fromDate = y + "-" + String(m + 1).padStart(2, "0") + "-01";
    let toDate =
      y +
      "-" +
      String(m + 1).padStart(2, "0") +
      "-" +
      String(lastDayOfMonth.getDate()).padStart(2, "0");

    let url =
      "https://clientportalbqconnector20230425154757.azurewebsites.net/api/hours" +
      "?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&caseId=" +
      this.state.retainerSettings.caseId;

    await fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          hoursSpent: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  prevMonth = () => {
    let d = new Date(
      this.state.dateValue.getFullYear(),
      this.state.dateValue.getMonth(),
      1
    );
    if (d === 0) {
      d.setMonth(11);
      d.setFullYear(d.getFullYear() - 1);
    } else {
      d.setMonth(d.getMonth() - 1);
    }
    this.setDateValue(d);
  };

  nextMonth = () => {
    let d = new Date(
      this.state.dateValue.getFullYear(),
      this.state.dateValue.getMonth(),
      1
    );
    if (d === 11) {
      d.setMonth(0);
      d.setFullYear(d.getFullYear() + 1);
    } else {
      d.setMonth(d.getMonth() + 1);
    }
    this.setDateValue(d);
  };

  setDateValue = (d) => {
    if (
      d.getTime() <= this.state.maxDate.getTime() &&
      d.getTime() >= this.state.minDate.getTime()
    ) {
      this.setState(
        {
          dateValue: d,
        },
        () => this.getHoursByMonth()
      );
    }
  };

  async componentDidMount() {
    await this.getRetainerSettings();
    await this.getHoursByMonth();
  }

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Container component={Paper} sx={{ p: 2 }}>
          <SectionTitle>{t("My Fees")}</SectionTitle>
          <Box sx={{ pb: 2 }}>
            <Button
              sx={{
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#2e7d32",
              }}
              variant="contained"
              onClick={() => this.setDateValue(this.state.maxDate)}
            >
              {t("Current month")}
            </Button>
            <IconButton edge="end" onClick={() => this.prevMonth()}>
              <ArrowBackOutlinedIcon />
            </IconButton>
            <IconButton edge="end" onClick={() => this.nextMonth()}>
              <ArrowForwardOutlinedIcon />
            </IconButton>
          </Box>
          <SectionTitle>
            {formatMonthYear(t, this.state.dateValue)}
          </SectionTitle>
          <BarChart
            width={400}
            height={300}
            data={[
              {
                name: "Total",
                Hours: this.state.retainerSettings.hours,
              },
              {
                name: "Used",
                Hours: this.state.hoursSpent,
              },
            ]}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="Hours"
              fill="#8884d8"
              background={{ fill: "#eee" }}
              label={({ payload, x, y, width, height, value }) => {
                return (
                  <text x={x + width / 2} y={y} textAnchor="middle" dy={-6}>
                    {value}
                  </text>
                );
              }}
            />
          </BarChart>
        </Container>
      </Container>
    );
  }
}

export default withTranslation()(RetainerCard);
