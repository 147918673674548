import React from "react";
import Container from "@mui/material/Container";
import _ from "lodash";
import { Paper } from "@mui/material";
import { Button } from "@mui/material";
import Title from "../../Title";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import {
  LineChart,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
} from "recharts";

class DetalleDocuSign extends React.Component {
  // Pagina de metricas de uso de DocuSign
  constructor() {
    super();

    let d = new Date();
    this.state = {
      valueDesde: new Date(d.getFullYear() - 1, d.getMonth()),
      valueHasta: new Date(),
      data: [],
      filteredData: [],
    };
  }

  componentDidMount() {
    this.fetchDetails();
  }

  fetchDetails = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetPRBAdminDocuSignDetails?code=9o4AgVueS0ToUS7NKRoeEqzidLZVP/QCsyrkKkKT61uWIJmjA8kj5Q==";

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          data: data,
        });
        this.updateFilteredData();
      });
  };

  onChangeFromDate = (newValue) => {
    this.setState(
      {
        valueDesde: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  onChangeToDate = (newValue) => {
    this.setState(
      {
        valueHasta: newValue,
      },
      () => {
        this.updateFilteredData();
      }
    );
  };

  getFormattedMonth = (month) => {
    const { t } = this.props;
    const months = [
      t("m_Jan"),
      "Feb",
      "Mar",
      t("m_Apr"),
      "May",
      "Jun",
      "Jul",
      t("m_Aug"),
      t("m_Sep"),
      "Oct",
      "Nov",
      t("m_Dec"),
    ];

    return months[month];
  };

  updateFilteredData = () => {
    let y = this.state.valueDesde.getFullYear();
    let m = this.state.valueDesde.getMonth();
    let dateKey = y + "-" + (m + 1 < 10 ? "0" : "") + (m + 1);

    let result = [];

    if (this.state.valueDesde <= this.state.valueHasta) {
      while (
        y < this.state.valueHasta.getFullYear() ||
        (y === this.state.valueHasta.getFullYear() &&
          m <= this.state.valueHasta.getMonth())
      ) {
        const constDateKey = dateKey;
        let available = _.filter(this.state.data, (row) => {
          return row.mo === constDateKey;
        });

        result.push({
          month: this.getFormattedMonth(m),
          count: !_.isEmpty(available) ? available[0].count : 0,
        });

        m = m === 11 ? 0 : m + 1;
        y = m === 0 ? y + 1 : y;
        dateKey = y + "-" + (m + 1 < 10 ? "0" : "") + (m + 1);
      }
    }

    this.setState({
      filteredData: result,
    });
  };

  render() {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Container component={Paper} sx={{ p: 2 }}>
          <React.Fragment>
            <Title>Documentos firmados a través de DocuSign</Title>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ p: 2, display: "inline-block" }}>
                <DesktopDatePicker
                  views={["year", "month"]}
                  label="Desde"
                  minDate={new Date("2012-03-01")}
                  maxDate={new Date()}
                  value={this.state.valueDesde}
                  onChange={(newValue) => this.onChangeFromDate(newValue.$d)}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Box>
              <Box sx={{ p: 2, display: "inline-block" }}>
                <DesktopDatePicker
                  views={["year", "month"]}
                  label="Hasta"
                  minDate={new Date("2012-03-01")}
                  maxDate={new Date()}
                  value={this.state.valueHasta}
                  onChange={(newValue) => this.onChangeToDate(newValue.$d)}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Box>
            </LocalizationProvider>

            <ResponsiveContainer width={"99%"} height={300}>
              <LineChart
                data={this.state.filteredData}
                plugins={{ datalabels: { display: true } }}
                margin={{
                  top: 16,
                  right: 16,
                  bottom: 0,
                  left: 24,
                }}
              >
                <XAxis dataKey="month" stroke="blue" />
                <YAxis stroke="blue">
                  <Label
                    angle={270}
                    position="left"
                    style={{
                      textAnchor: "middle",
                      fill: "black",
                    }}
                  >
                    Documentos mensuales
                  </Label>
                </YAxis>
                <Tooltip />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="count"
                  stroke="green"
                  dot={true}
                />
              </LineChart>
            </ResponsiveContainer>
          </React.Fragment>
        </Container>
      </Container>
    );
  }
}

export default withTranslation()(DetalleDocuSign);
