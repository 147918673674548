export async function refreshToken(currentToken) {
  let url = "https://blpclientportalfunctions.azurewebsites.net/api/RefreshToken?code=W8qsJEgYcQt2YhQAPz1nCy13cLanRAl0-5Z62mj8hKndAzFuoJSsAg==";

  return sendReq(currentToken, url);
}

async function sendReq(currentToken, url) {
  let res = await fetch(url, {
    method: "POST",
    headers: { Authorization: `Bearer ${currentToken}` },
  });
  return res.json();
}
