import React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

class CPButton extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    const { icon, onClick, tooltip, text, color, edge, sx } = this.props;

    return (
      <>
        {icon ? (
          <IconButton
            onClick={onClick}
            sx={
              sx
                ? sx
                : {
                    color: color
                      ? color
                      : window.$brand === "PRB"
                      ? "#17afb6"
                      : "#017cbc",
                  }
            }
            edge={edge ? edge : "end"}
          >
            {tooltip ? <Tooltip title={tooltip}>{icon}</Tooltip> : icon}
          </IconButton>
        ) : (
          <Button onClick={onClick}>{text}</Button>
        )}
      </>
    );
  }
}

export default CPButton;
