import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { withTranslation } from "react-i18next";

class CPDeleteIconButton extends React.Component {
  render() {
    const { t, onClick, edge, sx } = this.props;

    return (
      <IconButton
        onClick={onClick}
        color="error"
        sx={sx ? sx : {}}
        edge={edge ? edge : "end"}
      >
        <Tooltip title={t("Delete")}>
          <DeleteOutlinedIcon />
        </Tooltip>
      </IconButton>
    );
  }
}

export default withTranslation()(CPDeleteIconButton);
