import React from "react";
import Container from "@mui/material/Container";
import { authContext } from "../../../contexts/AuthContext";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import SectionTitle from "../../SectionTitle";
import { withTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { getRetainer } from "../../../services/retainers";

class ProposalCard extends React.Component {
  // Card de propuesta de trabajo hecha al cliente, documento ligado a "My Fees"
  constructor() {
    super();

    this.state = {
      currentViewerDoc: null,
      docUrl: "",
    };
  }

  static contextType = authContext;

  getRetainerSettings = async () => {
    let data = await getRetainer(this.props.clientId);
    if (data.proposal_url && data.proposal_url.length > 0) {
      this.setState({
        docUrl: data.proposal_url,
      });
      this.getSASToken(data.proposal_url);
    }
  };

  async componentDidMount() {
    await this.getRetainerSettings();
  }

  downloadStorageDocument = (docUrl) => {
    let filename = /[^/]*$/.exec(docUrl)[0];
    let rqData = {
      container: "client-" + this.props.clientId,
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.context.auth.user.id,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      let chunks = [];

      reader.read().then(function processText({ done, value }) {
        if (done) {
          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let extension = docUrl.substring(docUrl.lastIndexOf("."));
          let mime = extToMimes.hasOwnProperty(extension.toLowerCase())
            ? extToMimes[extension.toLowerCase()]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = "Proposal.pdf";
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  getSASToken = (docUrl) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetDocumentSASToken?code=60cfPysOUg_RthP_VGzenaYx6E3JEiTCJnKWTAoc7pJEAzFuJb6uDg==";
    let params = { documentUrl: docUrl };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          currentViewerDoc: {
            uri: data.uri,
          },
        });
      });
  };

  render() {
    const { t } = this.props;

    if (this.state.docUrl && this.state.docUrl !== "") {
      return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Container component={Paper} sx={{ p: 2 }}>
            <SectionTitle>{t("My Proposal")}</SectionTitle>
            <Box sx={{ pb: 2 }}>
              <Button
                sx={{
                  backgroundColor:
                    window.$brand === "PRB" ? "#17afb6" : "#2e7d32",
                }}
                variant="contained"
                onClick={() => this.downloadStorageDocument(this.state.docUrl)}
              >
                {t("Download proposal")}
              </Button>
            </Box>
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={[this.state.currentViewerDoc]}
              config={{
                header: {
                  disableFileName: true,
                },
              }}
              style={{ width: "100%", minHeight: 500, height: "100%" }}
            />
          </Container>
        </Container>
      );
    } else {
      return <></>;
    }
  }
}

export default withTranslation()(ProposalCard);
