import * as React from "react";
import Typography from "@mui/material/Typography";

const SectionTitle = (props) => {
  return (
    <Typography
      style={{
        color: window.$brand === "PRB" ? "#230871" : "#102a46",
        fontSize: "1.5em",
        fontWeight: "900",
      }}
      component="div"
      variant="h6"
      gutterBottom
    >
      {props.children}
    </Typography>
  );
};

export default SectionTitle;
