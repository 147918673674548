import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, List, ListItem, ListItemButton } from "@mui/material";
import { withTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import SectionTitle from "../../../SectionTitle";
import CPEditIconButton from "../../../Utils/CPEditIconButton";
import CPDeleteIconButton from "../../../Utils/CPDeleteIconButton";
import CPText from "../../../Utils/CPText";
import DeleteDialog from "../../../DeleteDialog";
import { listFeatureLabels } from "../../../../services/features";
import { authContext } from "../../../../contexts/AuthContext";

class FeatureLabelSettings extends React.Component {
  //Configuracion de Court Proceedings y Administrative Processes en la config del cliente
  constructor() {
    super();

    this.state = {
      labels: [],
      addLabel: {},
      editLabel: {},
      deleteLabel: {},
      invalidErrors: {},
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchFeatureLabels(this.props.client.id, this.props.feature.name);
  }

  handleAddLabelOpen = (label) => {
    this.setState({
      addLabel: label,
    });
  };

  handleAddLabelClose = (label) => {
    this.state.labels.push(label);

    this.setState({
      addLabel: label,
    });
    this.handleDialogClose();
  };

  handleEditLabelOpen = (label) => {
    this.setState({
      editLabel: label,
    });
  };

  handleDeleteLabelClose = () => {
    this.setState({
      deleteLabel: {},
    });
  };

  handleDialogClose = () => {
    this.setState({
      addLabel: {},
      editLabel: {},
      deleteLabel: {},
    });
  };

  insertLabel = () => {
    let label = this.state.addLabel;
    label.label = document.getElementById("insert_label").value;
    label.clientId = this.props.client.id;
    label.featureName = this.props.feature.name;
    this.setState({ addLabel: label });

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertFeatureLabel?code=p0n18iM0QQQAlt1hpKrC_jcpiI8A_TUJnTuO5EV1orT8AzFuh8u2_Q==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(label),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert successful");
        this.handleAddLabelClose(data);
      })
      .catch((error) => console.log(error));
  };

  updateLabel = () => {
    let label = this.state.editLabel;
    label.label = document.getElementById("edit_label").value;
    this.setState({ editLabel: label });

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateFeatureLabel?code=3SYtFmYuqxalHmZmsrnUBIwiWEN9mk8WbmkEm_KrpTv2AzFu57eDxA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(label),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  deleteLabel = (label) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteFeatureLabel?code=QUtowykJjzqaETN7K-FOXtqoehsMtHgtHD_AZfRHbXvkAzFuGaHoTg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(label),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        _.remove(this.state.labels, (l) => {
          return l.id === label.id;
        });
        this.forceUpdate();
      })
      .catch((error) => console.log(error));

    this.handleDialogClose();
  };

  fetchFeatureLabels = async (clientId, featureName) => {
    let list = await listFeatureLabels(clientId, featureName);
    this.setState({
      labels: list,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={!_.isEmpty(this.props.settings)}
        onClose={() => {
          this.props.handleDialogClose();
        }}
      >
        <DialogContent>
          <SectionTitle>
            {t(this.props.feature.name)}
            <Button
              sx={{
                ml: 4,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#78BE20",
              }}
              variant="contained"
              onClick={() =>
                this.handleAddLabelOpen({
                  clientId: this.props.client.id,
                  featureName: this.props.feature.name,
                })
              }
            >
              {t("Add")}
            </Button>
          </SectionTitle>
          <Box sx={{ pb: 4, pt: 4 }}>
            <List dense sx={{ maxWidth: 360 }}>
              {this.state.labels.map((label) => (
                <ListItem
                  key={label.id}
                  secondaryAction={
                    <>
                      <CPEditIconButton
                        onClick={() => this.handleEditLabelOpen(label)}
                      />
                      <CPDeleteIconButton
                        onClick={() => this.setState({ deleteLabel: label })}
                      />
                    </>
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <CPText text={label.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
        <DeleteDialog
          item={this.state.deleteLabel}
          title={t("Delete_feature_label")}
          caption={t("delete_feature_label_full_caption")}
          targetFunc={this.deleteLabel}
          handleDialogClose={this.handleDeleteLabelClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.addLabel)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("add_feature_label_title")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="insert_label"
              label={t("Label")}
              type="text"
              fullWidth
              variant="standard"
              inputProps={{ maxLength: 40 }}
              sx={{ pb: 3 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.insertLabel(this.state.addLabel)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.editLabel)}
          onClose={this.handleDialogClose}
        >
          <DialogTitle>{t("edit_feature_label")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_label"
              label={t("Label")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.editLabel.label}
              inputProps={{ maxLength: 40 }}
              sx={{ pb: 3 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.updateLabel(this.state.editLabel)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

export default withTranslation()(FeatureLabelSettings);
