import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { authContext } from "../contexts/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export const SignInButton = (props) => {
  // Boton de ingreso para login AAD
  
  const { instance } = useMsal();
  const { setAuthData } = useContext(authContext);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = React.useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbarSuccessOpen(false);
    setSnackbarErrorOpen(false);
  };

  function handleLogin(instance) {
    instance
      .loginPopup(loginRequest)
      .then((d) => {
        let creds = {};
        creds.msalToken = d.accessToken;
        creds.brandLabel = window.$brand;

        let url =
          "https://blpclientportalfunctions.azurewebsites.net/api/ADLogin?code=P7GrAyHakVrmWxqmn1NNJkCeE9Mq4neFM/w3cQqdff8muENyA3zGwA==";

        fetch(url, {
          method: "POST",
          body: JSON.stringify(creds),
        })
          .then((res) => {
            if (res.status === 401) {
              setSnackbarErrorOpen(true);
              instance.logout();
            } else if (res.status === 200) {
              setSnackbarSuccessOpen(true);
              let data = res.json();
              data.then((token) => {
                let id = _.find(
                  token.claims,
                  (claim) => claim.type === "id"
                ).value;
                let first_name = _.find(
                  token.claims,
                  (claim) => claim.type === "first_name"
                ).value;
                let last_name = _.find(
                  token.claims,
                  (claim) => claim.type === "last_name"
                ).value;
                let userType = _.find(
                  token.claims,
                  (claim) => claim.type === "user_type"
                ).value;
                let email = _.find(
                  token.claims,
                  (claim) => claim.type === "email"
                ).value;
                let imageURL = _.find(
                  token.claims,
                  (claim) => claim.type === "image_url"
                ).value;
                let phone = _.find(
                  token.claims,
                  (claim) => claim.type === "phone"
                ).value;
                let mobile = _.find(
                  token.claims,
                  (claim) => claim.type === "mobile"
                ).value;
                let role = _.find(
                  token.claims,
                  (claim) => claim.type === "role"
                ).value;
                let user = {
                  id: id,
                  first_name: first_name,
                  last_name: last_name,
                  userType: userType,
                  email: email,
                  image_url: imageURL,
                  phone: phone,
                  mobile: mobile,
                  role: role,
                };
                setAuthData("blp", user, data, token);
                props.history.push("/Clientes");
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  return (
    <span>
      <Button
        sx={{
          backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#78BE20",
        }}
        variant="contained"
        onClick={() => handleLogin(instance)}
      >
        Ingreso de usuarios {window.$brand}
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarErrorOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        key={"bottomcentererror"}
      >
        <Alert elevation={6} variant="filled" severity="error">
          {t("User_not_authorized")}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarSuccessOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        key={"bottomcentersuccess"}
      >
        <Alert elevation={6} variant="filled" severity="success">
          {t("Welcome")}
        </Alert>
      </Snackbar>
    </span>
  );
};
