import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { withTranslation } from "react-i18next";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { savePredictYourClosingEnabled } from "../../../../services/closing";
import DeleteDialog from "../../../DeleteDialog";
import CPText from "../../../Utils/CPText";
import SectionTitle from "../../../SectionTitle";

class PredictYourClosingSettings extends React.Component {
  //Configuracion de Predict Your Closing en la config del cliente
  constructor() {
    super();

    this.state = {
      open: false,
      invalidErrors: {},
      deleteProject: {},
      disableSelect: false,
      settings: [],
      selectedProjectName: "New",
      selectedProjectSettings: {
        letter_of_intent_enabled: true,
        right_to_purchase_enabled: true,
        escrow_agreement_enabled: true,
        due_diligence_enabled: true,
        closing_conditions_enabled: true,
        closing_enabled: true,
        postclosing_enabled: true,
      },
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings) {
      this.setState({
        settings: this.props.settings,
        selectedProjectName: !_.isEmpty(this.props.settings)
          ? _.last(this.props.settings).project_name
          : "New",
        selectedProjectSettings: !_.isEmpty(this.props.settings)
          ? _.last(this.props.settings)
          : {
              letter_of_intent_enabled: true,
              right_to_purchase_enabled: true,
              escrow_agreement_enabled: true,
              due_diligence_enabled: true,
              closing_conditions_enabled: true,
              closing_enabled: true,
              postclosing_enabled: true,
            },
      });
    }
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  save = async () => {
    let params = {
      clientId: this.props.clientId,
      project_name:
        this.state.selectedProjectName !== "New"
          ? this.state.selectedProjectName
          : document.getElementById("input_project_name").value,
      letter_of_intent_enabled: document.getElementById(
        "input_letter_of_intent_enabled"
      ).checked,
      right_to_purchase_enabled: document.getElementById(
        "input_right_to_purchase_enabled"
      ).checked,
      escrow_agreement_enabled: document.getElementById(
        "input_escrow_agreement_enabled"
      ).checked,
      due_diligence_enabled: document.getElementById(
        "input_due_diligence_enabled"
      ).checked,
      closing_conditions_enabled: document.getElementById(
        "input_closing_conditions_enabled"
      ).checked,
      closing_enabled: document.getElementById("input_closing_enabled").checked,
      postclosing_enabled: document.getElementById("input_postclosing_enabled")
        .checked,
    };

    await savePredictYourClosingEnabled(params);
  };

  valid = () => {
    if (document.getElementById("input_project_name") === null) return true; // Updating

    let projectName = document.getElementById("input_project_name").value;

    let invalidErrors = {};
    if (_.isEmpty(projectName) || projectName === "New")
      invalidErrors.projectName = true;

    this.setState({
      invalidErrors: invalidErrors,
    });

    return _.isEmpty(invalidErrors);
  };

  handleSelectProjectChange = (event) => {
    this.setState({
      selectedProjectName: event.target.value,
    });

    if (event.target.value === "New") {
      this.setState({
        selectedProjectSettings: {
          letter_of_intent_enabled: true,
          right_to_purchase_enabled: true,
          escrow_agreement_enabled: true,
          due_diligence_enabled: true,
          closing_conditions_enabled: true,
          closing_enabled: true,
          postclosing_enabled: true,
        },
      });
    } else {
      this.setState({
        selectedProjectSettings: _.find(
          this.state.settings,
          (project) => project.project_name === event.target.value
        ),
      });
    }
  };

  handleDeleteDialogClose = () => {
    this.setState({
      deleteProject: {},
    });
    this.props.handleDialogClose();
  };

  deleteProject = () => {
    let project = this.state.selectedProjectSettings;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeletePredictYourClosing?code=p1vsLXcifwiajpe21soR4o3QJtzliUtrNbqMtgfsi_U-AzFu4pazig==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(project),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={this.state.open}
        onClose={() => {
          this.setState({ disableSelect: false });
          this.props.handleDialogClose();
        }}
      >
        <DialogContent>
          <SectionTitle>{"Predict Your Closing"}</SectionTitle>
          <FormControl sx={{ pr: 2, mt: 1, mb: 2 }} fullWidth>
            <InputLabel id="project_select_label">Closing Project</InputLabel>
            <Select
              labelId="project_select_label"
              disabled={this.state.disableSelect}
              id="project_select"
              label="Closing Project"
              onChange={(event) => this.handleSelectProjectChange(event)}
              value={this.state.selectedProjectName}
            >
              {!_.isEmpty(this.state.settings) &&
                this.state.settings.map((project) => (
                  <MenuItem value={project.project_name}>
                    {project.project_name}
                  </MenuItem>
                ))}
              <MenuItem value="New">+ New project</MenuItem>
            </Select>
          </FormControl>
          <CPText
            text={"Select which steps will be enabled for this project."}
          />
          {this.state.selectedProjectName === "New" && (
            <TextField
              sx={{ pb: 1 }}
              id="input_project_name"
              label="Project name"
              onChange={() => this.setState({ disableSelect: true })}
              error={this.state.invalidErrors.projectName}
              fullWidth
              type="text"
              required
              variant="standard"
              inputProps={{ maxLength: 50 }}
            />
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    this.state.selectedProjectSettings.letter_of_intent_enabled
                  }
                  onChange={(event) => {
                    let newSettings = this.state.selectedProjectSettings;
                    newSettings.letter_of_intent_enabled = event.target.checked;
                    this.setState({
                      selectedProjectSettings: newSettings,
                      disableSelect: true,
                    });
                  }}
                  id="input_letter_of_intent_enabled"
                />
              }
              label="Letter of Intent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    this.state.selectedProjectSettings.right_to_purchase_enabled
                  }
                  onChange={(event) => {
                    let newSettings = this.state.selectedProjectSettings;
                    newSettings.right_to_purchase_enabled =
                      event.target.checked;
                    this.setState({
                      selectedProjectSettings: newSettings,
                      disableSelect: true,
                    });
                  }}
                  id="input_right_to_purchase_enabled"
                />
              }
              label="Right to Purchase &amp; Sale Agreement"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    this.state.selectedProjectSettings.escrow_agreement_enabled
                  }
                  onChange={(event) => {
                    let newSettings = this.state.selectedProjectSettings;
                    newSettings.escrow_agreement_enabled = event.target.checked;
                    this.setState({
                      selectedProjectSettings: newSettings,
                      disableSelect: true,
                    });
                  }}
                  id="input_escrow_agreement_enabled"
                />
              }
              label="Escrow Agreement"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    this.state.selectedProjectSettings.due_diligence_enabled
                  }
                  onChange={(event) => {
                    let newSettings = this.state.selectedProjectSettings;
                    newSettings.due_diligence_enabled = event.target.checked;
                    this.setState({
                      selectedProjectSettings: newSettings,
                      disableSelect: true,
                    });
                  }}
                  id="input_due_diligence_enabled"
                />
              }
              label="Due Diligence"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    this.state.selectedProjectSettings
                      .closing_conditions_enabled
                  }
                  onChange={(event) => {
                    let newSettings = this.state.selectedProjectSettings;
                    newSettings.closing_conditions_enabled =
                      event.target.checked;
                    this.setState({
                      selectedProjectSettings: newSettings,
                      disableSelect: true,
                    });
                  }}
                  id="input_closing_conditions_enabled"
                />
              }
              label="Closing Conditions Confirmation"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.selectedProjectSettings.closing_enabled}
                  onChange={(event) => {
                    let newSettings = this.state.selectedProjectSettings;
                    newSettings.closing_enabled = event.target.checked;
                    this.setState({
                      selectedProjectSettings: newSettings,
                      disableSelect: true,
                    });
                  }}
                  id="input_closing_enabled"
                />
              }
              label="Closing"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    this.state.selectedProjectSettings.postclosing_enabled
                  }
                  onChange={(event) => {
                    let newSettings = this.state.selectedProjectSettings;
                    newSettings.postclosing_enabled = event.target.checked;
                    this.setState({
                      selectedProjectSettings: newSettings,
                      disableSelect: true,
                    });
                  }}
                  id="input_postclosing_enabled"
                />
              }
              label="Postclosing"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          {this.state.selectedProjectName !== "New" && (
            <Button
              onClick={() => {
                this.setState({
                  deleteProject: this.state.selectedProjectSettings,
                });
              }}
              color="error"
            >
              {t("Delete")}
            </Button>
          )}
          <Button
            onClick={() => {
              this.setState({ disableSelect: false });
              this.props.handleDialogClose();
            }}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              if (this.valid()) {
                this.save();
                this.setState({ disableSelect: false });
                this.props.handleDialogClose();
              }
            }}
          >
            {t("Save")}
          </Button>
        </DialogActions>
        <DeleteDialog
          item={this.state.deleteProject}
          title={t("delete_project")}
          caption={t("delete_project_full_caption")}
          targetFunc={this.deleteProject}
          handleDialogClose={this.handleDeleteDialogClose}
        />
      </Dialog>
    );
  }
}

export default withTranslation()(PredictYourClosingSettings);
