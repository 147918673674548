import React from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { withTranslation } from "react-i18next";
import { clientContext } from "../../../contexts/ClientContext";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Alerts from "../../Alerts";
import DeleteDialog from "../../DeleteDialog";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatDate } from "../../../Utils/Format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CPText from "../../Utils/CPText";
import UploadTypeDialog from "./UploadDialogs/UploadTypeDialog";

class NavigationDetail extends React.Component {
  // Card de detalle segun seleccion en el FullClientNavigator
  // Render dependiendo de si se selecciona client, company, workspace, subcategory, document
  constructor() {
    super();

    this.state = {
      snackbarDownloadingOpen: false,
      alerts: {},
      editDocument: {},
      editError: {},
      valueVencimientoEdit: undefined,
      deleteDocument: {},
      customDirectory: {},
      customDirectoryError: {},
      editCustomDirectory: {},
      editCustomDirectoryError: {},
      deleteCustomDirectory: {},
      openUploadToFileTypeDialog: false,
    };
  }

  static contextType = clientContext;

  componentDidMount() {}

  downloadStorageDocument = (doc) => {
    let client = this.context.data;
    let clientOrCompanyId =
      this.context.selectionChain[1] === -1
        ? client.id
        : this.context.selectionChain[1];

    this.setState({
      snackbarDownloadingOpen: true,
    });

    let filename = /[^/]*$/.exec(doc.url)[0];
    let rqData = {
      container:
        doc.source === "docusign"
          ? "prbdocusign"
          : "client-" + clientOrCompanyId,
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.props.userId,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      let chunks = [];

      reader.read().then(function processText({ done, value }) {
        if (done) {
          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension.toLowerCase()]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  updateDocument = (doc) => {
    doc.filename = document.getElementById("edit_filename").value;
    doc.notes = document.getElementById("edit_notes").value;
    doc.visible = document.getElementById("edit_visible_cb").checked ? 1 : 0;
    if (document.getElementById("edit_expiration_cb").checked) {
      let month = this.state.valueVencimientoEdit.getMonth() + 1;
      let monthStr = month < 10 ? `0${month}` : month;
      let day = this.state.valueVencimientoEdit.getDate();
      let dayStr = day < 10 ? `0${day}` : day;
      doc.expiration = `${this.state.valueVencimientoEdit.getFullYear()}-${monthStr}-${dayStr}`;
    } else {
      doc.expiration = null;
    }

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateDocument?code=G1D6YKLhNNKXMfWSFGfokSe-3bmx17fYYHh8Kgw_3aJqAzFumrYmsg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(doc),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");

        this.context.refreshUpdatedDoc(doc);
      })
      .catch((error) => console.log(error));

    this.setState({
      editDocument: {},
      editError: {},
    });
  };

  deleteDocument = (doc) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteDocument?code=maatAGlqaso51CmMTzUHx6ewyogzQkA/hFpTTKdoe3QokjZOgAI3Hg==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(doc),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        window.location.reload(true);
      })
      .catch((error) => console.log(error));

    //this.props.cardDeleteHandler(this.state.document);
  };

  validEdit = () => {
    let filenameEl = document.getElementById("edit_filename");

    let editError = {
      //category: _.isEmpty(this.state.editDocument.category),
      filename: _.isEmpty(filenameEl.value),
    };

    this.setState({
      editError: editError,
    });

    return !editError.filename;
  };

  renderClient = (props) => {
    let client = this.context.data;

    return (
      <div>
        {client.license && (
          <Typography variant="subtitle2" sx={{ pt: 1, pb: 1 }}>
            ID: {client.license}
          </Typography>
        )}
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{client.name}</strong>
        </Typography>
      </div>
    );
  };

  renderCompany = (props) => {
    let client = this.context.data;
    let company = _.find(client.companies, (c) => {
      return c.id === this.context.selectionChain[1];
    });

    if (!company) return <></>;
    return (
      <div>
        {company.license && (
          <Typography variant="subtitle2" sx={{ pt: 1, pb: 1 }}>
            ID: {company.license}
          </Typography>
        )}
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{company.name}</strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {client.name}
        </Typography>
      </div>
    );
  };

  renderWorkspace = (props) => {
    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }

    if (!workspace) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>
            {window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en}
          </strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""}`}
        </Typography>
      </div>
    );
  };

  validCustomDirectory = () => {
    let nameEl = document.getElementById("input_customDirectory_name");

    let customDirectoryError = {};
    if (_.isEmpty(nameEl.value)) customDirectoryError.name = true;

    this.setState({
      customDirectoryError: customDirectoryError,
    });

    return _.isEmpty(customDirectoryError);
  };

  enableCustomDirectory = (clientId, workspaceId, subcategoryId) => {
    let params = this.state.customDirectory;
    params.category_id = workspaceId;
    params.subcategory_id = subcategoryId;
    params.name = document.getElementById("input_customDirectory_name").value;
    params.client_id = clientId;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertFile?code=mwIauyOLC3pjU8IwQOYXwdQkHY5qZszioxQDwnmsrlvtAzFukc_fTw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Custom directory enabled");

        this.context.refreshAddedDoc();
      })
      .catch((error) => console.log(error));

    this.setState({
      customDirectory: {},
      customDirectoryError: {},
    });
  };

  renderSubcategory = (props) => {
    const { t } = this.props;

    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }
    if (!workspace) return <></>;

    let subcategory = _.find(workspace.subcategories, (s) => {
      return s.subcategory_en === this.context.selectionChain[3];
    });

    if (!subcategory) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>
            {window.$lang === "en"
              ? subcategory.subcategory_en
              : subcategory.subcategory_es
              ? subcategory.subcategory_es
              : subcategory.subcategory_en}
          </strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""} > ${
            window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en
          }`}
        </Typography>
        <Grid container>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() =>
                this.setState({
                  customDirectory: { id: -1 },
                })
              }
            >
              {"Enable custom directory"}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={!_.isEmpty(this.state.customDirectory)}
          onClose={() => {
            this.setState({
              customDirectory: {},
              customDirectoryError: {},
            });
          }}
        >
          <DialogTitle>{"Enable custom directory"}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                disabled
                id="input_customDirectory_workspace"
                label={t("Workspace")}
                defaultValue={
                  window.$lang === "en"
                    ? workspace.name_en
                    : workspace.name_es
                    ? workspace.name_es
                    : workspace.name_en
                }
                type="text"
                fullWidth
                variant="standard"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                disabled
                id="input_customDirectory_subcategory"
                label={t("Subcategory")}
                defaultValue={
                  window.$lang === "en"
                    ? subcategory.subcategory_en
                    : subcategory.subcategory_es
                    ? subcategory.subcategory_es
                    : subcategory.subcategory_en
                }
                type="text"
                fullWidth
                variant="standard"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="input_customDirectory_name"
                label={t("Name")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 40 }}
                error={this.state.customDirectoryError.name}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  customDirectory: {},
                  customDirectoryError: {},
                });
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                if (this.validCustomDirectory())
                  this.enableCustomDirectory(
                    this.context.selectionChain[1] === -1
                      ? client.id
                      : company.id,
                    _.find(this.context.workspaces, (w) => {
                      return w.name_en === workspace.name_en;
                    }).workspace_id,
                    subcategory.id
                  );
              }}
            >
              {t("Enable")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  validEditCustomDirectory = () => {
    let nameEl = document.getElementById("input_editCustomDirectory_name");

    let editCustomDirectoryError = {};
    if (_.isEmpty(nameEl.value)) editCustomDirectoryError.name = true;

    this.setState({
      editCustomDirectoryError: editCustomDirectoryError,
    });

    return _.isEmpty(editCustomDirectoryError);
  };

  updateCustomDirectory = () => {
    let params = this.state.editCustomDirectory;
    params.name = document.getElementById(
      "input_editCustomDirectory_name"
    ).value;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateFile?code=fGyBqm_nGsaxaBsbwdOS5Wp1yRTaDTN15vKFuHJ3_RfEAzFuD-fxug==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Custom directory enabled");

        this.context.refreshAddedDoc();
      })
      .catch((error) => console.log(error));

    this.setState({
      editCustomDirectory: {},
      editCustomDirectoryError: {},
    });
  };

  deleteCustomDirectory = (doc) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteFile?code=XxdvjNnamPbgE959qY58Vj38QxCK614jtnNfMEGhtkfyAzFunMahaA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(doc),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");

        window.location.reload(true);
      })
      .catch((error) => console.log(error));

    //this.props.cardDeleteHandler(this.state.document);
  };

  renderFile = (props) => {
    const { t } = this.props;

    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }
    if (!workspace) return <></>;
    let subcategory = _.find(workspace.subcategories, (s) => {
      return s.subcategory_en === this.context.selectionChain[3];
    });
    let file =
      this.context.selectionChain[4] === -1
        ? null
        : _.find(subcategory.files, (f) => {
            return f.id === this.context.selectionChain[4];
          });

    if (!file) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{file.name}</strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""} > ${
            window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en
          } > ${
            window.$lang === "en"
              ? subcategory.subcategory_en
              : subcategory.subcategory_es
              ? subcategory.subcategory_es
              : subcategory.subcategory_en
          }`}
        </Typography>
        <Grid container>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() =>
                this.setState({
                  editCustomDirectory: file,
                })
              }
            >
              {"Edit custom directory"}
            </Button>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-start">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor: "#d32f2f",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<DeleteOutlinedIcon />}
              onClick={() => this.setState({ deleteCustomDirectory: file })}
            >
              {t("Delete custom directory")}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              onClick={() =>
                this.setState({
                  openUploadToFileTypeDialog: true,
                })
              }
            >
              {t("Assign document")}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={!_.isEmpty(this.state.editCustomDirectory)}
          onClose={this.handleCustomDirectoryClose}
        >
          <DialogTitle>{"Edit custom directory"}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="input_editCustomDirectory_name"
                label={t("Name")}
                type="text"
                fullWidth
                variant="standard"
                defaultValue={this.state.editCustomDirectory.name}
                inputProps={{ maxLength: 40 }}
                error={this.state.editCustomDirectoryError.name}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCustomDirectoryClose}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                if (this.validEditCustomDirectory())
                  this.updateCustomDirectory();
              }}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <DeleteDialog
          item={this.state.deleteCustomDirectory}
          title={t("delete_custom_directory")}
          caption={t("delete_custom_directory_full_caption")}
          targetFunc={this.deleteCustomDirectory}
          handleDialogClose={() => this.setState({ deleteCustomDirectory: {} })}
        />
        <UploadTypeDialog
          data={{
            open: this.state.openUploadToFileTypeDialog,
            subcategories: this.props.subcategories,
            selectedClientId:
              this.context.selectionChain[1] === -1
                ? this.context.data.id
                : this.context.selectionChain[1],
            preselectedCustomFile: file,
          }}
          handleDialogClose={() =>
            this.setState({ openUploadToFileTypeDialog: false })
          }
          refreshFunc={this.context.refreshAddedDoc}
        />
      </div>
    );
  };

  renderDocument = (props) => {
    const { t } = this.props;

    let client = this.context.data;
    let company =
      this.context.selectionChain[1] === -1
        ? null
        : _.find(client.companies, (c) => {
            return c.id === this.context.selectionChain[1];
          });
    let workspace;
    if (!company) {
      workspace = _.find(client.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    } else {
      workspace = _.find(company.workspaces, (w) => {
        return w.name_en === this.context.selectionChain[2];
      });
    }
    if (!workspace) return <></>;
    let subcategory = _.find(workspace.subcategories, (s) => {
      return s.subcategory_en === this.context.selectionChain[3];
    });
    let file =
      this.context.selectionChain[4] === -1
        ? null
        : _.find(subcategory.files, (f) => {
            return f.id === this.context.selectionChain[4];
          });
    let document;
    if (!file) {
      document = _.find(subcategory.docs, (d) => {
        return d.id === this.context.selectionChain[5];
      });
    } else {
      document = _.find(file.docs, (d) => {
        return d.id === this.context.selectionChain[5];
      });
    }

    if (!document) return <></>;
    return (
      <div>
        <Typography component="h4" variant="h4" style={{ color: "#000" }}>
          <strong>{document.filename}</strong>
        </Typography>
        <Typography component="h6" variant="h6" style={{ color: "#000" }}>
          {`${client.name}${company ? " > " + company.name : ""} > ${
            window.$lang === "en"
              ? workspace.name_en
              : workspace.name_es
              ? workspace.name_es
              : workspace.name_en
          } > ${
            window.$lang === "en"
              ? subcategory.subcategory_en
              : subcategory.subcategory_es
              ? subcategory.subcategory_es
              : subcategory.subcategory_en
          }${file !== null ? " > " + file.name : ""}`}
        </Typography>
        {(document.visible === 1 || document.visible === true) && (
          <Tooltip title="Visible for clients">
            <RemoveRedEyeIcon fontSize="small" color="success" />
          </Tooltip>
        )}
        <CPText
          label={document.source !== "dwcopy" ? t("Uploaded") : t("Updated")}
          text={formatDate(t, document.modified)}
        />
        {document.expiration && (
          <CPText
            label={t("Expires")}
            text={formatDate(t, document.expiration)}
          />
        )}
        {document.notes && <CPText text={document.notes} />}
        <Grid container>
          <Grid item xs={6} container justifyContent="flex-start">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => this.downloadStorageDocument(document)}
            >
              {t("Download")}
            </Button>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-start">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<NotificationsOutlinedIcon />}
              onClick={() =>
                this.setState({
                  alerts: { docId: document.id },
                })
              }
            >
              {t("Alerts")}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} container justifyContent="flex-start">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor:
                  window.$brand === "PRB" ? "#17afb6" : "#102a46",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              onClick={() => {
                let editDoc = document;
                this.setState({
                  editDocument: editDoc,
                  valueVencimientoEdit: document.expiration
                    ? new Date(document.expiration)
                    : new Date(),
                });
              }}
            >
              {t("Edit")}
            </Button>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-start">
            <Button
              fullWidth
              sx={{
                m: 2,
                backgroundColor: "#d32f2f",
                borderRadius: window.$brand === "PRB" ? 0 : 5,
              }}
              variant="contained"
              startIcon={<DeleteOutlinedIcon />}
              onClick={() => this.setState({ deleteDocument: document })}
            >
              {t("Delete")}
            </Button>
          </Grid>
        </Grid>
        {!_.isEmpty(this.context.currentViewerDoc) && (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={[this.context.currentViewerDoc]}
            config={{
              header: {
                disableFileName: true,
              },
            }}
            style={{ width: "100%", minHeight: 500, height: "100%" }}
          />
        )}
        <Alerts
          alerts={this.state.alerts}
          clientId={client.id}
          docId={document.id}
          closeHandler={() =>
            this.setState({
              alerts: {},
            })
          }
        />
        <DeleteDialog
          item={this.state.deleteDocument}
          title={t("delete_document_title")}
          caption={t("delete_document_caption")}
          targetFunc={() => this.deleteDocument(document)}
          handleDialogClose={() => this.setState({ deleteDocument: {} })}
        />
        <Dialog
          open={!_.isEmpty(this.state.editDocument)}
          onClose={() => this.setState({ editDocument: {}, editError: {} })}
        >
          <DialogTitle>{t("edit_document")}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                disabled
                label={t("Category")}
                defaultValue={
                  /* EN is required, ES is optional, default to EN */
                  window.$lang === "en"
                    ? document.subcategory_en
                    : document.subcategory_es
                    ? document.subcategory_es
                    : document.subcategory_en
                }
                type="text"
                fullWidth
                variant="standard"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                required
                id="edit_filename"
                label={t("Filename")}
                defaultValue={document.filename}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 100 }}
                error={this.state.editError.filename}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="edit_notes"
                multiline
                label={t("Notes")}
                defaultValue={document.notes}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 255 }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3 }}>
              <Checkbox
                id="edit_expiration_cb"
                defaultChecked={document.expiration !== null}
              ></Checkbox>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Expires")}
                  value={this.state.valueVencimientoEdit}
                  onChange={(newValue) => {
                    this.setState({
                      valueVencimientoEdit: newValue.$d,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", pt: 3, ml: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={document.visible}
                    id="edit_visible_cb"
                  />
                }
                label="Visible for clients"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ editDocument: {}, editError: {} })}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                if (this.validEdit())
                  this.updateDocument(this.state.editDocument);
              }}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Container
        component={Paper}
        sx={{
          minWidth: 275,
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: window.$brand === "BLP" ? 5 : 0,
          p: 2,
          mb: 2,
          mr: window.$brand === "BLP" ? 10 : 0,
        }}
      >
        {this.context.selectionChain.length === 1 ? (
          <this.renderClient />
        ) : this.context.selectionChain.length === 2 ? (
          <this.renderCompany />
        ) : this.context.selectionChain.length === 3 ? (
          <this.renderWorkspace />
        ) : this.context.selectionChain.length === 4 ? (
          <this.renderSubcategory />
        ) : this.context.selectionChain.length === 5 ? (
          <this.renderFile />
        ) : this.context.selectionChain.length === 6 ? (
          <this.renderDocument />
        ) : (
          <></>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarDownloadingOpen}
          onClose={() =>
            this.setState({
              snackbarDownloadingOpen: false,
            })
          }
          key={"bottomcentererror"}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="info"
            icon={<HourglassTopIcon />}
          >
            {t("downloading_document")}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withTranslation()(NavigationDetail);
