export function formatDate(t, dateStr) {
  if (!dateStr) return "";

  let d = new Date(dateStr);
  const months = [
    t("m_jan"),
    "feb",
    "mar",
    t("m_apr"),
    "may",
    "jun",
    "jul",
    t("m_aug"),
    t("m_sep"),
    "oct",
    "nov",
    t("m_dec"),
  ];

  return `${d.getDate()}-${months[d.getMonth()]}-${d.getFullYear()}`;
}

export function formatDateTime(t, datetimeStr) {
  if (!datetimeStr) return "";

  let d = new Date(datetimeStr);
  const months = [
    t("m_jan"),
    "feb",
    "mar",
    t("m_apr"),
    "may",
    "jun",
    "jul",
    t("m_aug"),
    t("m_sep"),
    "oct",
    "nov",
    t("m_dec"),
  ];

  return `${d.getDate()}-${
    months[d.getMonth()]
  }-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
}

export function formatMonthYear(t, dateStr) {
  if (!dateStr) return "";

  let d = new Date(dateStr);
  const months = [
    t("m_January"),
    "February",
    "March",
    t("m_April"),
    "May",
    "June",
    "July",
    t("m_August"),
    t("m_September"),
    "October",
    "November",
    t("m_December"),
  ];

  return `${months[d.getMonth()]} ${d.getFullYear()}`;
}