import React from "react";
import Container from "@mui/material/Container";
import { authContext } from "../../../contexts/AuthContext";
import { Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Box } from "@mui/system";
import Alert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SectionTitle from "../../SectionTitle";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { withTranslation } from "react-i18next";
import base32Encode from "base32-encode";
import qrcode from "qrcode";
import crypto from "crypto";
import { verifyTOTP } from "../../../Utils/MFA";

class EnableMFA extends React.Component {
  // Habilitacion de doble factor de auth para clientes, usando Google Authenticator
  constructor() {
    super();

    this.state = {
      snackbarSuccessOpen: false,
      snackbarErrorOpen: false,
      enableMFAChecked: false,
      mfaSecret: "",
      user: {},
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchUser(this.props.user.id);
  }

  fetchUser = (id) => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetUserById?code=OY5Vxl1YXxv6k8oSym2EC5sQI5zfrw-7sqJ3Y0108apCAzFuTSqjuA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({ id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Fetch successful");
        this.setState({
          user: data,
        });
      })
      .catch((error) => console.log(error));
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarSuccessOpen: false,
      snackbarErrorOpen: false,
    });
  };

  toggleEnableMFA = (event) => {
    this.setState({
      enableMFAChecked: event.target.checked,
    });
    this.mfaQRcode(this.props.user);
  };

  mfaQRcode = (user) => {
    const buffer = crypto.randomBytes(14);
    let mfaSecret = base32Encode(buffer, "RFC4648", {
      padding: false,
    });

    const issuer = `${window.$brand}ClientPortal`;
    const algorithm = "SHA1";
    const digits = "6";
    const period = "30";
    const otpType = "totp";
    const configUri = `otpauth://${otpType}/${issuer}:${user.email}?algorithm=${algorithm}&digits=${digits}&period=${period}&issuer=${issuer}&secret=${mfaSecret}`;

    this.setState({
      mfaSecret: mfaSecret,
    });

    let canvas = document.getElementById("qrcanvas");
    qrcode.toCanvas(canvas, configUri, (error) => {
      if (error) console.log(error);
      console.log("Success QR");
    });
  };

  registerMFASecret = () => {
    let token = document.getElementById("enable_token").value;
    let result = verifyTOTP(token, this.state.mfaSecret);

    if (result) {
      let params = {
        id: this.props.user.id,
        mfa_secret: this.state.mfaSecret,
      };
      let url =
        "https://blpclientportalfunctions.azurewebsites.net/api/RegisterMFA?code=pCN_eq55hfYE_VIxdxsUh-fSvqvmv7AQuGZ9mYi_vzU8AzFu2_laXg==";

      fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
      })
        .then((res) => console.log(res))
        .then((success) => {
          console.log("Registration successful");

          this.setState({
            snackbarSuccessOpen: true,
          });
          this.fetchUser(this.props.user.id);
        })
        .catch((error) => console.log(error));
    } else {
      this.setState({
        snackbarErrorOpen: true,
      });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Box
        sx={{
          flexGrow: 1,
          mt: 12,
          mb: 4,
          p: 2,
          maxWidth: 600,
          position: "relative",
        }}
        component={Paper}
      >
        <SectionTitle>Multi-factor de autenticación</SectionTitle>
        {this.state.user.mfa_enabled ? (
          <FormGroup>
            <FormControlLabel
              disabled
              control={<Checkbox defaultChecked />}
              label="Habilitado"
            />
          </FormGroup>
        ) : (
          <FormGroup>
            <Typography sx={{ mb: 2 }} variant="subtitle">
              Habilita el multi-factor de autenticación para aumentar la
              seguridad de tu cuenta.
            </Typography>
            <FormControlLabel
              control={<Checkbox onChange={(e) => this.toggleEnableMFA(e)} />}
              label="Habilitar"
            />
            <Container
              style={{
                display: this.state.enableMFAChecked ? "block" : "none",
              }}
            >
              <Typography sx={{ m: 0 }} variant="subtitle">
                1. Descarga la app de Google Authenticator en su dispositivo
                móvil.
              </Typography>
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={"assets/google_play.png"}
                  height="50px"
                  alt="Google Play"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src={"assets/app_store.png"}
                  height="50px"
                  alt="App Store"
                />
              </a>
              <Typography sx={{ m: 0, display: "block" }} variant="subtitle">
                2. Escanea el código QR.
              </Typography>
              <canvas id="qrcanvas"></canvas>
              <Typography sx={{ m: 0, display: "block" }} variant="subtitle">
                3. Digita el token generado por Google Authenticator.
              </Typography>
              <TextField
                required
                id="enable_token"
                label={"Token"}
                type="text"
                variant="standard"
                inputProps={{ maxLength: 6 }}
              />
              <Button
                sx={{
                  m: 3,
                  display: "block",
                  backgroundColor:
                    window.$brand === "PRB" ? "#17afb6" : "#78BE20",
                }}
                variant="contained"
                onClick={() => this.registerMFASecret()}
              >
                {t("Register")}
              </Button>
            </Container>
          </FormGroup>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarErrorOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          key={"bottomcentererror"}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {t("Invalid_token")}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbarSuccessOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
          key={"bottomcentersuccess"}
        >
          <Alert elevation={6} variant="filled" severity="success">
            Registro exitoso
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default withTranslation()(EnableMFA);