import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18nextConf";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import AuthProvider from "./contexts/AuthContext";
import ClientProvider from "./contexts/ClientContext";

const msalInstance = new PublicClientApplication(msalConfig);

// zapotal.blplegal.com
// portal.blplegal.com
// legally.legalpredictabill.com
window.$brand = window.location.href.includes("zapotal")
  ? "ZAP"
  : window.location.href.includes("idbinvest")
  ? "IDB"
  : window.location.href.includes("epa")
  ? "EPA"
  : window.location.href.includes("asal")
  ? "ASAL"
  : window.location.href.includes("vololoans")
  ? "VOLOLOANS"
  : window.location.href.includes("subway")
  ? "SUBWAY"
  : window.location.href.includes("kraft")
  ? "KRAFT"
  : window.location.href.includes("blplegal")
  ? "BLP"
  : window.location.href.includes("legalpredictabill")
  ? "PRB"
  : "PRB";

document.getElementById("favicon").href =
  window.$brand === "PRB"
    ? "/assets/prb_favicon.ico"
    : "/assets/100x100 Logo white.png";

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <AuthProvider>
      <ClientProvider>
        <App />
      </ClientProvider>
    </AuthProvider>
  </MsalProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
