import React from "react";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { authContext } from "../../../contexts/AuthContext";
import {
  listEventsByClient,
  listEventsByBrandLabel,
  listExpirationsByClient,
  listExpirationsByBrandLabel,
} from "../../../services/calendar";
import {
  listClientsByUser,
  getMainClient,
  listAuthorizedClientsByClientUser,
} from "../../../services/clients";
import _ from "lodash";
import CPText from "../../Utils/CPText";
import { createEvent } from "ics";
import { saveAs } from "file-saver";
import CPButton from "../../Utils/CPButton";
import { refreshToken } from "../../../services/tokens";
import { Link } from "react-router-dom";

class PredictYourDates extends React.Component {
  // Pagina de Predict Your Dates (Calendario para Legally PRB)
  constructor() {
    super();

    this.state = {
      date: {},
      events: [],
      selectedEvent: {},
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.setState({
      date: Date.now(),
    });
    if (this.context.auth.type === "blp") {
      this.fetchAlertsAndExpirations();
    } else if (this.context.auth.user.userType === "admin_IDB" || this.context.auth.user.userType === "admin_epa"
    || this.context.auth.user.userType === "admin_asal"
    || this.context.auth.user.userType === "admin_vololoans"
    || this.context.auth.user.userType === "admin_subway"
    || this.context.auth.user.userType === "admin_kraft") {
      this.fetchAlertsAndExpirations();
    }else if (this.context.auth.type === "client") {
      this.fetchClientExpirationsAndAlerts();
    }
  }

  fetchAlertsAndExpirations = async () => {
    let data = [];
    if (
      this.context.auth.user.userType === "blp_admin" ||
      this.context.auth.user.userType === "admin_IDB"
      || this.context.auth.user.userType === "admin_epa"
      || this.context.auth.user.userType === "admin_asal"
      || this.context.auth.user.userType === "admin_vololoans"
      || this.context.auth.user.userType === "admin_subway"
      || this.context.auth.user.userType === "admin_kraft"
      || this.context.auth.user.role === "Director" ||
      this.context.auth.user.role === "Partner"
    ) {
      let alerts = await listEventsByBrandLabel();
      data = data.concat(alerts);
      let expirations = await listExpirationsByBrandLabel();
      data = data.concat(expirations);
    } else {
      let clientList = await listClientsByUser(this.context.auth.user.id);
      _.forEach(clientList, async (client) => {
        let res = await listEventsByClient(client.id);
        data = data.concat(res);
      });
      _.forEach(clientList, async (client) => {
        let res = await listExpirationsByClient(client.id, "1");
        data = data.concat(res);
      });
    }

    let events = data.map((alert) => {
      return {
        start: new Date(alert.expiration),
        end: new Date(alert.expiration),
        title: alert.filename,
        clientName: alert.name,
        documentName: alert.filename,
        message: alert.message,
        type: alert.type,
        docId: alert.type === "expiration" ? alert.id : alert.doc_id,
        clientId: alert.client_id,
      };
    });

    this.setState({
      events: events,
    });
  };

  fetchClientExpirationsAndAlerts = async () => {
    let mainClient = await getMainClient(this.context.auth.user.id);
    let fulldata = [];
    let clientList = [];

    if (this.context.auth.user.userType === "client_admin") {
      let url =
        "https://blpclientportalfunctions.azurewebsites.net/api/ListClientAndCompaniesByUserId?code=XUHjiLhavoUBVWWXdeFfJ4Lb5YEFRSquI9efJOeCl3ONAzFudZyoFg==&userId=" +
        this.context.auth.user.id;

      await fetch(url, {
        method: "POST",
      })
        .then((response) => response.json())
        .then((data) => {
          clientList = data;
        });
    } else {
      clientList = await listAuthorizedClientsByClientUser(
        this.context.auth.user.id
      );
    }

    let events_params = {
      clientId: mainClient.id,
    };
    let events_url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetMonthEventsByClient?code=HEVrF0fHmVo-wYHo1hY0ynUL8L0EhQuYKOiJTZBkvCIHAzFuN-DQ6g==";

    await fetch(events_url, {
      method: "POST",
      body: JSON.stringify(events_params),
      headers: {
        Authorization: `Bearer ${this.context.auth.token.rawData}`,
      },
    })
      .then(async (res) => {
        if (res.status === 401) {
          console.log("refresh token");
          let token = await refreshToken(this.context.auth.token.rawData);
          this.context.replaceToken(token);

          window.location.reload(false);
          return;
        }

        return res.json();
      })
      .then((data) => {
        fulldata = fulldata.concat(data);
      })
      .catch((error) => console.log(error));

    await Promise.all(
      clientList.map(async (client) => {
        let expirations_url =
          "https://blpclientportalfunctions.azurewebsites.net/api/GetMonthExpirationsByClient?code=yQ_8yVorrht3ZKmRgnjcq7rp5nwq-mATUOf6MbxHd0w7AzFunxgfnA==";

        let params = {
          clientId: client.id,
        };

        await fetch(expirations_url, {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            Authorization: `Bearer ${this.context.auth.token.rawData}`,
          },
        })
          .then(async (res) => {
            if (res.status === 401) {
              console.log("refresh token");
              let token = await refreshToken(this.context.auth.token.rawData);
              this.context.replaceToken(token);

              window.location.reload(false);
              return;
            }

            return res.json();
          })
          .then((data) => {
            fulldata = fulldata.concat(data);
          })
          .catch((error) => console.log(error));
      })
    );

    let events = fulldata.map((alert) => {
      return {
        start: new Date(alert.expiration),
        end: new Date(alert.expiration),
        title: alert.filename,
        clientName: alert.name,
        documentName: alert.filename,
        message: alert.message,
        type: alert.type,
        docId: alert.type === "expiration" ? alert.id : alert.doc_id,
      };
    });

    this.setState({
      events: events,
    });
  };

  onNavigate = (newDate) => {
    this.setState({
      date: newDate,
    });
    if (this.context.auth.type === "blp") {
      this.fetchAlertsAndExpirations();
    } else if (this.context.auth.user.userType === "admin_IDB" || this.context.auth.user.userType === "admin_epa"
    || this.context.auth.user.userType === "admin_asal"
    || this.context.auth.user.userType === "admin_vololoans"
    || this.context.auth.user.userType === "admin_subway"
    || this.context.auth.user.userType === "admin_kraft") {
      this.fetchAlertsAndExpirations();
    } else if (this.context.auth.type === "client") {
      this.fetchClientExpirationsAndAlerts();
    }
  };

  onSelectEvent = (event) => {
    this.setState({
      selectedEvent: event,
    });
  };

  downloadICS = () => {
    const { t } = this.props;

    let sel = this.state.selectedEvent;

    const event = {
      start: [
        sel.start.getFullYear(),
        sel.start.getMonth() + 1,
        sel.start.getDate(),
        0,
        0,
      ],
      duration: { hours: 24 },
      title: sel.title,
      description: `${sel.type === "alert" ? t("Alert") : t("Expiration")} ${
        sel.message ? "- " + sel.message : ""
      }`,
      organizer: { name: `${window.$brand} Client Portal` },
      busyStatus: "FREE",
    };

    createEvent(event, (error, value) => {
      const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `${sel.title}.ics`);
    });
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = window.$brand === "PRB" ? "#17afb6" : "#102a46";
    if (event.type === "expiration") backgroundColor = "#d32f2f";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  render() {
    const { t } = this.props;
    const localizer = momentLocalizer(moment);

    return (
      <Container component={Paper}>
        <Calendar
          localizer={localizer}
          date={this.state.date}
          onNavigate={this.onNavigate}
          events={this.state.events}
          style={{ height: "100vh" }}
          onSelectEvent={this.onSelectEvent}
          eventPropGetter={this.eventStyleGetter}
        />

        <Dialog
          open={!_.isEmpty(this.state.selectedEvent)}
          onClose={() => this.setState({ selectedEvent: {} })}
        >
          <DialogTitle>
            {this.state.selectedEvent.type === "alert"
              ? t("Alert")
              : t("Expiration")}
          </DialogTitle>
          <DialogContent sx={{ mt: 2, p: 2, minWidth: 400 }}>
            <CPText
              label={t("Client")}
              text={this.state.selectedEvent.clientName}
            />
            <CPText
              label={t("Document")}
              text={this.state.selectedEvent.documentName}
            />
            {this.state.selectedEvent.type === "alert" && (
              <>
                <CPText label={t("Message")} />
                <CPText text={this.state.selectedEvent.message} />
              </>
            )}
            <Container sx={{ pt: 4 }}>
              <CPButton
                text={t("Go to document")}
                onClick={() => {
                  if (this.context.auth.type === "blp" || this.context.auth.user.userType === "admin_subway" || this.context.auth.user.userType === "admin_kraft") {
                    window.open(
                      `/client/${this.state.selectedEvent.clientId}?d=${this.state.selectedEvent.docId}`,
                      "_self"
                    );
                  } else {
                    window.open(
                      `/MisDocumentos?d=${this.state.selectedEvent.docId}`,
                      "_self"
                    );
                  }
                }}
              />
            </Container>
            <Container sx={{ pt: 4 }}>
              <CPButton
                text={t("Download to Outlook")}
                onClick={() => {
                  this.downloadICS();
                }}
              />
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ selectedEvent: {} })}>
              {t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(PredictYourDates);
