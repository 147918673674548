import React from "react";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import Button from "@mui/material/Button";
import { CardActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import DeleteDialog from "../../DeleteDialog";
import Tooltip from "@mui/material/Tooltip";
import "react-block-ui/style.css";
import { authContext } from "../../../contexts/AuthContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CPDeleteIconButton from "../../Utils/CPDeleteIconButton";
import CPEditIconButton from "../../Utils/CPEditIconButton";
import CPSettingsIconButton from "../../Utils/CPSettingsIconButton";
import WorkspaceSubcategories from "./WorkspaceSubcategories";

class WorkspaceCard extends React.Component {
  // Card de workspace

  constructor() {
    super();

    this.state = {
      edit: {},
      delete: {},
      subcategories: {},
    };
  }

  static contextType = authContext;

  handleEditOpen = (workspace) => {
    this.setState({
      edit: workspace,
    });
  };

  handleEditClose = () => {
    this.setState({
      edit: {},
    });
  };

  handleSubcategoriesOpen = (user) => {
    this.setState({
      subcategories: user,
    });
  };

  handleSubcategoriesClose = () => {
    this.setState({
      subcategories: {},
    });
  };

  handleDeleteOpen = (workspace) => {
    this.setState({
      delete: workspace,
    });
  };

  handleDialogClose = () => {
    this.setState({
      edit: {},
      delete: {},
    });
  };

  update = (workspace) => {
    workspace.name_en = document.getElementById("edit_name_en").value;
    workspace.name_es = document.getElementById("edit_name_es").value;
    workspace.description = document.getElementById("edit_description").value;
    workspace.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/UpdateWorkspace?code=Zw9YGM4ntQNh5HmRexNP93_z-Ji2mwe6X13li-8GBtpBAzFuqOEKGQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(workspace),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Update successful");
      })
      .catch((error) => console.log(error));

    this.handleEditClose();
  };

  delete = (workspace) => {
    workspace.brandLabel = window.$brand;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteWorkspace?code=E2IXkv9WkjkeEgS8-YWK-mXbnU3C-qQt8qNq8p5vkDr0AzFuRhZhxQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(workspace),
    })
      .then((res) => console.log(res))
      .then((success) => {
        console.log("Delete successful");
      })
      .catch((error) => console.log(error));

    this.props.cardDeleteHandler(workspace);
  };

  render() {
    const { t } = this.props;

    return (
      <Card
        sx={{
          minWidth: 275,
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: window.$brand === "BLP" ? 5 : 0,
          mr: window.$brand === "BLP" ? 10 : 0,
        }}
      >
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color="text.primary" variant="body1">
                <strong>{this.props.workspace.name_en}</strong>{" / "}
                <strong>{this.props.workspace.name_es}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {this.props.workspace.description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginTop: "auto" }}>
          <CPSettingsIconButton
            onClick={() => {
              this.handleSubcategoriesOpen(this.props.workspace);
            }}
          />
          <CPEditIconButton
            onClick={() => this.handleEditOpen(this.props.workspace)}
          />
          <CPDeleteIconButton
            onClick={() => this.handleDeleteOpen(this.props.workspace)}
          />
        </CardActions>
        <DeleteDialog
          item={this.state.delete}
          title={t("delete_workspace")}
          caption={t("delete_workspace_caption")}
          targetFunc={this.delete}
          handleDialogClose={this.handleDialogClose}
        />
        <Dialog
          open={!_.isEmpty(this.state.edit)}
          onClose={this.handleEditClose}
        >
          <DialogTitle>{t("edit_workspace")}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="edit_name_en"
              label={t("Name_en")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.name_en}
              inputProps={{ maxLength: 40 }}
            />
            <TextField
              id="edit_name_es"
              label={t("Name_es")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.name_es}
              inputProps={{ maxLength: 40 }}
            />
            <TextField
              id="edit_description"
              label={t("Description")}
              type="text"
              fullWidth
              variant="standard"
              defaultValue={this.state.edit.description}
              inputProps={{ maxLength: 100 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleEditClose}>{t("Cancel")}</Button>
            <Button onClick={() => this.update(this.state.edit)}>
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!_.isEmpty(this.state.subcategories)}
          onClose={this.handleSubcategoriesClose}
        >
          <DialogContent>
            <WorkspaceSubcategories workspace={this.props.workspace} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSubcategoriesClose}>
              {t("Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

export default withTranslation()(WorkspaceCard);
