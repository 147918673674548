import React from "react";
import Container from "@mui/material/Container";
import "./RepositorioDocuSign.css";
import _ from "lodash";
import { Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { Button } from "@mui/material";
import Title from "../../../Title";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHeader from "../../../Utils/TableHeader";
import Chip from "@mui/material/Chip";
import ButtonGroup from "@mui/material/ButtonGroup";
import CPButton from "../../../Utils/CPButton";
import DownloadIcon from "@mui/icons-material/Download";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { withTranslation } from "react-i18next";
import BlockUi from "react-block-ui";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import { authContext } from "../../../../contexts/AuthContext";
import { formatDate } from "../../../../Utils/Format";

class RepositorioDocuSign extends React.Component {
  // Pagina de asignacion de documentos firmados en DocuSign a un cliente
  // Los documentos vienen del Logic App "blpclientportal-docusign-upload"
  constructor() {
    super();

    this.state = {
      docs: [],
      openClientSearch: false,
      foundClients: [],
      showingClients: [],
      navigateClient: null,
      selectedClient: null,
      documentToAssign: null,
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchDocumentList();
  }

  copyToClient = (doc) => {
    this.setState({
      blockingDialog: true,
    });

    let params = {
      filename: doc.filename,
      source: "3",
      category_en: "DocuSign",
      subcategory_en: "Documentos firmados",
      assignedBy: this.context.auth.user.id,
      assignedTo: this.state.selectedClient.id,
      notes: "",
      url: doc.url,
      extension: doc.extension,
      visible: "1",
    };

    let insertUrl =
      "https://blpclientportalfunctions.azurewebsites.net/api/InsertDocument?code=74hsURB0abOx7ak88UW/Y/Q2TPDe1QuNunuduLXWfq2SB6J/orepdw==";
    fetch(insertUrl, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Insert document successful");

        if (doc.subcategory_en === "Unassigned") {
          let assignUrl =
            "https://blpclientportalfunctions.azurewebsites.net/api/ToggleDocuSignAssigned?code=PrObOBc8kWaVqkJyadQTnyhpYcigzGj7QmZj5dU2/rHdsKi5JLj5OQ==";
          fetch(assignUrl, {
            method: "POST",
            body: JSON.stringify({ id: doc.id }),
          })
            .then((res) => res.json())
            .then((data) => {
              console.log("Assignment successful");
            });
        }

        _.find(this.state.docs, { id: doc.id }).subcategory_en = "Assigned";
        this.handleDialogClose();
      })
      .catch((error) => console.log(error));
  };

  searchClients = () => {
    this.setState({
      selectedClient: {},
      blockingDialog: true,
    });

    let params = {
      name: document.getElementById("search_name").value,
      license: document.getElementById("search_license").value,
      brandLabel: window.$brand,
    };

    let searchUrl =
      "https://blpclientportalfunctions.azurewebsites.net/api/SearchClients?code=2Fi157hAtu6uyj6VZKDHJpganlCjQ6PZI58rTrHR8OVGHC5Aa6uZYA==";

    fetch(searchUrl, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        _.forEach(data, (client) => {
          params = {
            parent: client.id,
            brandLabel: window.$brand,
          };

          let listByParentUrl =
            "https://blpclientportalfunctions.azurewebsites.net/api/ListClientsByParent?code=ABu6YYdXe9PQSfpvdSG9slk9rJqSCLMmHxXbZ1rk4NzY6yyrfGviOg==";

          fetch(listByParentUrl, {
            method: "POST",
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((companies) => {
              _.find(data, { id: client.id }).children = companies;
              _.forEach(companies, (company) => {
                params.parent = company.id;

                fetch(listByParentUrl, {
                  method: "POST",
                  body: JSON.stringify(params),
                })
                  .then((response) => response.json())
                  .then((areas) => {
                    _.find(companies, { id: company.id }).children = areas;
                    this.forceUpdate();
                  });
              });
            });
        });
        this.setState({
          foundClients: data,
          showingClients: data,
          blockingDialog: false,
        });
      });
  };

  downloadStorageDocument = (doc) => {
    this.setState({
      snackbarDownloadingOpen: true,
    });

    let filename = /[^/]*$/.exec(doc.url)[0];
    let rqData = {
      container: "prbdocusign",
      filename: filename,
      brandLabel: window.$brand,
      loggedUserId: this.context.auth.user.id,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DownloadFileFromStorage?code=CZpzCnze6WKlahQr1A9fazzVT5MuyhNCpm9a/AS/ZrIOSg3JubTMGw==";

    (async () => {
      const fetchedResource = await fetch(url, {
        method: "POST",
        body: JSON.stringify(rqData),
      });
      const reader = await fetchedResource.body.getReader();

      var parentComponentInReadClientModal = this;
      let chunks = [];
      reader.read().then(function processText({ done, value }) {

        if (done) {

          let extToMimes = {
            ".jpg": "image/jpeg",
            ".jpeg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".doc": "application/msword",
            ".epub": "application/epub+zip",
            ".htm": "text/html",
            ".html": "text/html",
            ".json": "application/json",
            ".odp": "application/vnd.oasis.opendocument.presentation",
            ".ods": "application/vnd.oasis.opendocument.spreadsheet",
            ".odt": "application/vnd.oasis.opendocument.text",
            ".ppt": "application/vnd.ms-powerpoint",
            ".rar": "application/x-rar-compressed",
            ".rtf": "application/rtf",
            ".svg": "image/svg+xml",
            ".tif": "image/tiff",
            ".tiff": "image/tiff",
            ".vsd": "application/vnd.visio",
            ".xls": "application/vnd.ms-excel",
            ".xml": "application/xml",
            ".zip": "application/zip",
            ".7z": "application/x-7z-compressed",
            ".txt": "text/plain",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".bmp": "image/bmp",
            ".gif": "image/gif",
            ".docx":
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pptx":
              "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          };
          let mime = extToMimes.hasOwnProperty(doc.extension)
            ? extToMimes[doc.extension]
            : "";

          const blob = new Blob([chunks], { type: mime });

          // Download document
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          let aUrl = window.URL.createObjectURL(blob);
          a.href = aUrl;
          a.download = doc.filename;
          a.click();
          window.URL.revokeObjectURL(aUrl);

          return;
        }

        const tempArray = new Uint8Array(chunks.length + value.length);
        tempArray.set(chunks);
        tempArray.set(value, chunks.length);
        chunks = tempArray;

        return reader.read().then(processText);
      });
    })();
  };

  fetchDocumentList = () => {
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/ListDocuSignDocuments?code=3YyGNAcZa3CObJXF5ttaXphRk/Cdyk10flQZX2dAMjQDn/rpGov4Kg==";

    fetch(url, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          docs: data,
        });
      });
  };

  handleDialogClose = () => {
    this.setState({
      openClientSearch: false,
      foundClients: [],
      showingClients: [],
      selectedClient: null,
      documentToAssign: null,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {!_.isEmpty(this.state.docs) && (
          <>
            <Title>Documentos sin asignar</Title>
            <TableContainer component={Paper} sx={{ mt: 2, mb: 4 }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHeader cols={["Fecha", "Nombre de archivo", ""]} />
                <TableBody>
                  {_.filter(this.state.docs, {
                    subcategory_en: "Unassigned",
                  }).map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell scope="row">
                        {formatDate(t, row.modified)}
                      </TableCell>
                      <TableCell>{row.filename}</TableCell>
                      <TableCell align="right">
                        <Chip
                          className="btn-open-client"
                          label="Copiar a..."
                          color="primary"
                          onClick={() => {
                            this.setState({
                              openClientSearch: true,
                              documentToAssign: row,
                            });
                          }}
                        />
                        <ButtonGroup variant="string">
                          <CPButton
                            onClick={() => {
                              this.downloadStorageDocument(row);
                            }}
                            icon={<DownloadIcon />}
                          />
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Title>Documentos asignados</Title>
            <TableContainer component={Paper} sx={{ mt: 2, mb: 4 }}>
              <Table sx={{ minWidth: 650 }}>
                <TableHeader cols={["Fecha", "Nombre de archivo", ""]} />
                <TableBody>
                  {_.filter(this.state.docs, {
                    subcategory_en: "Assigned",
                  }).map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell scope="row">
                        {formatDate(t, row.modified)}
                      </TableCell>
                      <TableCell>{row.filename}</TableCell>
                      <TableCell align="right">
                        <Chip
                          className="btn-open-client"
                          label="Copiar a..."
                          color="primary"
                          onClick={() => {
                            this.setState({
                              openClientSearch: true,
                              documentToAssign: row,
                            });
                          }}
                        />
                        <ButtonGroup variant="string">
                          <CPButton
                            onClick={() => {
                              this.downloadStorageDocument(row);
                            }}
                            icon={<DownloadIcon />}
                          />
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <Dialog
          open={this.state.openClientSearch}
          onClose={this.handleDialogClose}
        >
          <BlockUi tag="div" blocking={this.state.blockingDialog}>
            {this.state.selectedClient === null ? (
              <>
                <DialogTitle>Buscar cliente</DialogTitle>
                <DialogContent>
                  <TextField
                    id="search_name"
                    label={t("Name")}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                  />
                  <TextField
                    id="search_license"
                    label={t("ID")}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputProps={{ maxLength: 20 }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose}>
                    {t("Cancel")}
                  </Button>
                  <Button onClick={() => this.searchClients()}>
                    {t("Search")}
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle>Seleccionar cliente</DialogTitle>
                <DialogContent>
                  {this.state.navigateClient !== null && (
                    <Button
                      onClick={() => {
                        if (this.state.navigateClient.parent) {
                          let company = _.find(this.state.foundClients, {
                            id: this.state.navigateClient.parent,
                          });
                          this.setState({
                            navigateClient: company,
                            showingClients: company.children,
                          });
                        } else {
                          this.setState({
                            navigateClient: null,
                            showingClients: this.state.foundClients,
                          });
                        }
                      }}
                    >
                      Regresar
                    </Button>
                  )}
                  {this.state.showingClients.map(
                    (row) =>
                      row.client !== "BLP ABOGADOS" && (
                        <Container sx={{ p: 2, minWidth: 400 }}>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                          >
                            <Radio
                              value={row.id}
                              name="radio-clients"
                              checked={this.state.selectedClient === row}
                              onChange={() => {
                                this.setState({
                                  selectedClient: row,
                                });
                              }}
                            />
                            <Paper
                              elevation={3}
                              sx={{ p: 2 }}
                              className="clientItem"
                            >
                              <Typography variant="subtitle2">
                                <strong>{t("Name")}: </strong>
                                <span
                                  className={
                                    row.children && row.children.length > 0
                                      ? "client-children-clickable"
                                      : "client-children"
                                  }
                                  onClick={() => {
                                    if (
                                      row.children &&
                                      row.children.length > 0
                                    ) {
                                      this.setState({
                                        navigateClient: row,
                                        showingClients: row.children,
                                      });
                                    }
                                  }}
                                >
                                  {row.name}
                                </span>
                              </Typography>
                              <Typography variant="subtitle2">
                                <strong>{t("License")}: </strong>
                                {row.license}
                              </Typography>
                              <Typography variant="subtitle2">
                                <strong>{t("Client_type")}: </strong>
                                {row.type === "person"
                                  ? t("Individual")
                                  : row.type === "client"
                                  ? t("Business")
                                  : row.type === "company"
                                  ? t("Company")
                                  : t("Area")}
                              </Typography>
                            </Paper>
                          </Grid>
                        </Container>
                      )
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose}>
                    {t("Cancel")}
                  </Button>
                  <Button
                    onClick={() =>
                      this.copyToClient(this.state.documentToAssign)
                    }
                  >
                    {t("Select")}
                  </Button>
                </DialogActions>
              </>
            )}
          </BlockUi>
        </Dialog>
      </Container>
    );
  }
}

export default withTranslation()(RepositorioDocuSign);
