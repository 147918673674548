import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const CustomDirectoryTitle = (props) => {
  return (
    <Box sx={{ pt: 2, display: "inline-block" }}>
      <Typography
        style={{
          color: window.$brand === "PRB" ? "#777" : "#777",
          fontSize: "1.2em",
          fontWeight: "900",
          display: "inline",
        }}
        component="div"
        sx={{ ml: 2 }}
        variant="h6"
        gutterBottom
      >
        {props.children}
      </Typography>
    </Box>
  );
};

export default CustomDirectoryTitle;
