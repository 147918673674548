import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "@mui/material/Autocomplete";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import BlockUi from "react-block-ui";
import DocuWareSelectDialog from "./DocuWareSelectDialog";

class DocuWareSearchDialog extends React.Component {
  // Modal para busqueda de documentos en DW
  constructor() {
    super();

    this.state = {
      open: false,
      autocomplete: {
        CLIENTE: [],
        C_DULA_JUR_DICA: [],
        IDENTIFICACION: [],
      },
      blocking: false,
      selectedCabinet: "libros",
      results: [],
      openDocuWareSelect: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        open: this.props.data.open,
        preselectedCustomFile: this.props.data.preselectedCustomFile,
        selectCompanyFor: this.props.data.selectCompanyFor,
      });
    }
  }

  changeAutocomplete = (event, fieldName) => {
    let params = {
      cabinet: this.state.selectedCabinet,
      fieldName: fieldName,
      valuePrefix: event.target.value,
    };
    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/GetDocuwareOptions?code=yhp507aBXmHmamGwBRbOGo8A/eQwtyxQw7xi4zRp/CRY0drq4KWkRw==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        let autocomplete = this.state.autocomplete;
        autocomplete[fieldName] = data.value;
        this.setState({
          autocomplete: autocomplete,
        });
      })
      .catch((error) => console.log(error));
  };

  search = () => {
    this.setState({
      blocking: true,
    });

    let params = {};
    params.cabinet = this.state.selectedCabinet;
    if (document.getElementById("input_cliente").value)
      params.client = document.getElementById("input_cliente").value;
    if (document.getElementById("input_cedula_juridica").value)
      params.license = document.getElementById("input_cedula_juridica").value;

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/SearchDocuware?code=7B6gNwZdWH3Im8GwMNB4yqLGEMxSrkMrg9Ao3rQPCZmmsct/RhswVQ==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        let results = [];
        data.rows.forEach((row) => {
          let document = {};
          let idIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "DWDOCID";
          });
          document.id = row.items[idIdx];
          let clientIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "CLIENTE";
          });
          document.client = row.items[clientIdx];
          let caseIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "CASO";
          });
          document.case = row.items[caseIdx];
          let razonSocialIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "RAZ_N_SOCIAL";
          });
          document.razonSocial = row.items[razonSocialIdx];
          let expedienteIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "EXPEDIENTE";
          });
          document.expediente = row.items[expedienteIdx];
          let ubicacionIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "UBICACI_N_F_SICA";
          });
          document.ubicacion = row.items[ubicacionIdx];
          let remitidoPorIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "REMITIDO_POR";
          });
          document.remitidoPor = row.items[remitidoPorIdx];
          let sectionCountIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "DWSECTIONCOUNT";
          });
          let sectionCount = row.items[sectionCountIdx];
          let extensionIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "DWEXTENSION";
          });
          document.extension =
            parseInt(sectionCount) > 1 ? ".zip" : row.items[extensionIdx];
          let typeIdx = _.findIndex(data.headers, (header) => {
            return (
              header.fieldName ===
              (params.cabinet === "libros" ? "TIPO_LIBRO" : "TIPO_DOCUMENTAL")
            );
          });
          document.type = row.items[typeIdx];
          let dateIdx = _.findIndex(data.headers, (header) => {
            return (
              header.fieldName ===
              (params.cabinet === "libros"
                ? "FECHA_DOCUMENTO"
                : "FECHA_DEL_DOCUMENTO")
            );
          });
          document.date = row.items[dateIdx];
          let nameIdx = _.findIndex(data.headers, (header) => {
            return header.fieldName === "NOMBRE_DOCUMENTO";
          });
          document.name = row.items[nameIdx];
          document.cabinet = params.cabinet;
          results.push(document);
        });

        this.setState(
          {
            results: results,
            openDocuWareSelect: !_.isEmpty(results),
            blocking: false,
          },
          () => {
            if (_.isEmpty(results)) {
              // Alert NO results
            }
          }
        );
        this.props.handleDialogClose();
      })
      .catch((error) => {
        console.log(error);
        this.props.handleDialogClose();
      });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Dialog open={this.state.open} onClose={this.props.handleDialogClose}>
          <BlockUi tag="div" blocking={this.state.blocking}>
            <DialogTitle>{t("search_document")}</DialogTitle>
            <DialogContent>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="input_cabinet_label">
                  {t("Document_type")}
                </InputLabel>
                <Select
                  labelId="input_cabinet_label"
                  id="input_cabinet"
                  defaultValue={"libros"}
                  label={t("Document_type")}
                  required
                  onChange={(e) =>
                    this.setState({
                      selectedCabinet: e.target.value,
                    })
                  }
                >
                  <MenuItem value={"libros"}>Libros</MenuItem>
                  <MenuItem value={"expedientes"}>Expedientes</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Autocomplete
                  freeSolo
                  id="input_cliente"
                  options={this.state.autocomplete["CLIENTE"]}
                  fullWidth
                  variant="standard"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Client")}
                      variant="standard"
                      onChange={(e) => this.changeAutocomplete(e, "CLIENTE")}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <Autocomplete
                  freeSolo
                  id="input_cedula_juridica"
                  options={
                    this.state.autocomplete[
                      this.state.selectedCabinet === "libros"
                        ? "C_DULA_JUR_DICA"
                        : "IDENTIFICACION"
                    ]
                  }
                  fullWidth
                  variant="standard"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("ID") + " / Cédula jurídica"}
                      variant="standard"
                      onChange={(e) =>
                        this.changeAutocomplete(
                          e,
                          this.state.selectedCabinet === "libros"
                            ? "C_DULA_JUR_DICA"
                            : "IDENTIFICACION"
                        )
                      }
                    />
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleDialogClose}>
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => {
                  this.search();
                }}
              >
                {t("Search")}
              </Button>
            </DialogActions>
          </BlockUi>
        </Dialog>

        <DocuWareSelectDialog
          data={{
            open: this.state.openDocuWareSelect,
            item: this.props.data.item,
            subcategories: this.props.data.subcategories,
            selectedClientId: this.props.data.selectedClientId,
            results: this.state.results,
            preselectedCustomFile: this.state.preselectedCustomFile,
            selectCompanyFor: this.state.selectCompanyFor,
          }}
          handleDialogClose={() => this.setState({ openDocuWareSelect: false })}
          refreshFunc={this.props.refreshFunc}
        />
      </>
    );
  }
}

export default withTranslation()(DocuWareSearchDialog);
